




































import Vue from 'vue'
import { HTTP } from '@/axios_api/http-common'

export default Vue.extend({
    props:["sequenceId"],
    data () {
        return {
            selected: ['Project', 'Activity', 'Sequence'], // Must be an array reference!
            options: [
                { text: 'Sequences', value: 'Sequence' },
                { text: 'Projects', value: 'Project' },
                { text: 'Activities', value: 'Activity' }
            ],
            activeView: 'loading',
            projects: true,
            activities: true,
            sequences: true,
            reviewFields: [
                {key: 'number', label: 'S/N'},
                {key: 'model', label: 'Model'},
                {key: 'name', label: 'Name'},
                {key: 'issue', label: 'Issue'},
            ],
            issues: [
                {
                    projectId: 60,
                    activityId: null,
                    sequenceId: 24,
                    issue: 'No sub activity',
                    issueType: 'Rig',
                    model: 'Project'
                },
                {
                    projectId: 61,
                    activityId: null,
                    sequenceId: 24,
                    issue: 'No sub activity',
                    issueType: 'Rig',
                    model: 'Project'
                },
                {
                    projectId: 60,
                    activityId: 6,
                    sequenceId: 24,
                    issue: 'Budget not entered yet',
                    issueType: 'Rig',
                    model: 'Activity'
                },
                {
                    projectId: null,
                    activityId: null,
                    sequenceId: 24,
                    issue: 'Budget not entered yet',
                    issueType: 'Rig',
                    model: 'Sequence'
                },
            ]
        }
    },
    computed: {
        filteredIssues(): any {
            return this.issues.filter((issue:any) => {
                return this.selected.includes(issue.model)
            })
        }
    },
    methods: {
        goToFix(data: any) {
            this.$emit('changeView', data.item)
            // this.$router.push('/sequence/'+data.item.sequenceId)
        }
    },
    mounted () {
        // request goes in here
        HTTP.get("sequence/review?sequenceId="+this.sequenceId).then((response:any) => {
            this.issues = response.data.data
            if (this.issues.length < 1) {
                this.$emit('noIssues')
            }else{
                this.activeView = 'table'
            }
        }).catch((error:any) => window.console.log(error))
    }
})





















































import Vue from 'vue'
import {Component} from 'vue-property-decorator'
import EventBus from '../../../eventBus'
import { required } from "vuelidate/lib/validators"; 
import { Validations } from 'vuelidate-property-decorators';

@Component
export default class ManageNPT extends Vue {
    @Validations()
    validations = {
        npt: {
            name: {required}
        }
    }
    activeScreen: string = 'main'
    loadingMessage: string = 'Please wait while we create new NPT'
    loadingTitle: string = 'Saving...'
    additionalSuccessMessage: string = ''
    successMessage: string = 'New NPT Created Successfully'
    links: Array<object> = [
        {
            text: 'NPT Operations',
            to: '/operations'
        },
        {
            text: 'Manage NPT Operation',
            active: true
        }
    ]
    index: any = null
    error: any = {
        generalMessage: '',
        errors: []
    }
    npt: any = {
        name: "",
        nptRef:"",
        rootCause:"",
        associatedCause: "",
    }
    activeItem: any = null
    nptTypes: any = [
        {value: 'Well Problem', text: 'Well Problem'},
        {value: 'Rig Service/Equipment Failure', text: 'Rig Service/Equipment Failure'},
        {value: 'Downhole Equipment Failure/Malfunction', text: 'Downhole Equipment Failure/Malfunction'},
        {value: 'Surface Equipment Failure/Malfunction', text: 'Surface Equipment Failure/Malfunction'},
        {value: 'Wating', text: 'Waiting'},
    ]
    formErrors: string[] = []

    checkErrors() {
        this.formErrors = []

        if(this.npt.name.length < 1) {
            this.formErrors.push("NPT name is required")
        }
    }

    update () {
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen);
        this.loadingMessage = 'Please wait while we update this NPT operation';
        this.loadingTitle = 'Updating NPT Operation';
        var payload = this.createPayload()
        this.$store.dispatch('nptModule/updateNPTOperation', payload)
            .then((response: any) => {
                this.activeScreen = 'successNoGotIt';
                EventBus.$emit('activeScreen', this.activeScreen);
                this.additionalSuccessMessage = '';
                this.successMessage = 'NPT Operation Updated Successfully';
            }).catch((error:any) => {
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
            })
    }
    
    saveNew () {
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen);
        this.loadingMessage = 'Please wait while we save this NPT operation';
        this.loadingTitle = 'Saving NPT Operation';
        var payload = this.createPayload()
        this.$store.dispatch('nptModule/createNptOperation', payload)
            .then((response: any) => {
                this.activeScreen = 'successRemain';
                EventBus.$emit('activeScreen', this.activeScreen);
                this.additionalSuccessMessage = '';
                this.successMessage = 'Successfully Created '+ payload.name;
            }).catch((error:any) => {
                this.error.generalMessage = error.response.data.message
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
            })
    }

    persistData(){
        if(this.activeItem == null)
            this.saveNew();
        else
            this.update();
    }

    get getNPTById() {
        return this.$store.getters['nptModule/getNptById']
    }
    get checkNTPType() : boolean {
        if (!this.npt.nptType) {
            return false
        } else {
            return true
        }
    }
    get isButtonDisabled(): boolean {
        if(this.formErrors.length > 0) {
            return true
        } else {
            return false
        }
    }

    get associativeCauseOptions():Array<any>{
        switch(this.npt.rootCause){
            case "Well Problem":
                return [
                        {value:"Loss Circulation",text:"Loss Circulation"},
                        {value:"Sticking",text:"Sticking"},
                        {value:"Fluid Influx",text:"Fluid Influx"},
                        {value:"Other Geological Problems",text:"Other Geological Problems"},
                        {value:"Casedhole Well Problems",text:"Casedhole Well Problems"},
                        {value:"Other Well Problems",text:"Other Well Problems"},
                ]
            case "Rig Service/Equipment Failure":
                return [
                    {value:"Drawwork",text:"Darwwork"},
                    {value:"Mud Pumps",text:"Mud Pumps"},
                    {value:"Power Generation System",text:"Power Generation System"},
                    {value:"TDS",text:"TDS"},
                    {value:"Surface BOP",text:"Surface BOP"},
                    {value:"Subsea Equipment",text:"Other Well Problems"},
                    {value:"Mooring",text:"Mooring"},
                ]

            case "Downhole Equipment Failure/Malfunction":
                return [
                    {value:"BHA/Drill String",text:"BHA/Drill String"},
                    {value:"Casing/Liner",text:"Casing/Liner"},
                    {value:"Completion/Test String",text:"Completion/Test String"},
                    {value:"Wireline Equipment",text:"Wireline Equipment"},
                    {value:"Coiled Tubing",text:"Coiled Tubing"},
                    {value:"Junk/Fish",text:"Junk/Fish"},
                    {value:"Others",text:"Others"},
                ]

            case "Surface Equipment Failure/Malfunction":
                return [
                    {value:"Wellhead",text:"Wellhead"},
                    {value:"Company Surface Equipment",text:"Company Surface Equipment"},
                    {value:"Contractor Surface Equipment",text:"Contractor Surface Equipment"},
                    {value:"Others",text:"Others"},
                ]

            case "Wating":
                return [
                    {value:"Weather",text:"Weather"},
                    {value:"Contractor",text:"Contractor"},
                    {value:"Tool/Equipment Supplier",text:"Tool/Equipment Supplier"},
                    {value:"Wireline Equipment",text:"Wireline Equipment"},
                    {value:"Coiled Tubing",text:"Coiled Tubing"},
                    {value:"Junk/Fish",text:"Junk/Fish"},
                    {value:"Others",text:"Others"},
                ]
            default: return []
            }
    }

    createPayload() {
        return {
            name: this.npt.name,
            id: this.activeItem ? this.activeItem.id : 0,
            nptRef: this.npt.nptRef,
            rootCause: this.npt.rootCause,
            associatedCause: this.npt.associatedCause,
        };
    }

    created() {
        if(!this.$route.query){
            this.checkErrors()
        }else{
            this.activeItem = this.getNPTById(this.$route.query.id)
            if(!this.activeItem){
                this.checkErrors()
            }else{
                this.npt.name = this.activeItem.name,
                this.npt.nptRef = this.activeItem.nptRef;
                this.npt.rootCause = this.activeItem.rootCause;
                this.npt.associatedCause = this.activeItem.associatedCause;
            }
        }
    }
}

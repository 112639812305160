<template>
    <b-row >
        <b-form>
            <b-row class="m-1 p-1">
                <b-col md="12" >
                    <label class="m-1">Target Name/Alias</label>
                    <b-input switch class="m-1"  placeholder="E.g Level U-29" v-model="localTarget.name"  />
                </b-col>
                <b-col md="12" >
                    <label class="m-1"> Northing(m) </label>
                    <b-input class="m-1" v-model="localTarget.northing" />
                </b-col>
                <b-col md="12">
                    <label class="m-1"> Easting(m) </label>
                    <b-input class="m-1" v-model="localTarget.easting" />
                </b-col>

                <b-col md="12">
                    <label class="m-1"> Depth(m) </label>
                    <b-input class="m-1" v-model="localTarget.depth"/>
                </b-col>
            </b-row>
        </b-form>
        <b-col  offset-md="3" >
            <div>
                <b-btn class="float-right" variant="dark" @click="$emit('view-list')">Back to List </b-btn>
                <b-btn class="float-right mr-2" variant="primary" v-if="isUpdate" @click="$emit('item-edited',localTarget)">Save Update</b-btn>
                <b-btn class="float-right mr-2" variant="primary" v-else @click="$emit('add-to-list',localTarget);$emit('view-list')">Add To List</b-btn>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    name:'target-form',
    props:{
        target:{
            type:Object,
            default:function() {
                return {}
            }
        }
    },
    data(){
        return{
            isUpdate:false,
            localTarget:{ name:"",northing:0,easting:0,depth:0}
        }
    },
    mounted(){
         if(Object.entries(this.target).length > 0){
             this.isUpdate = true;
             this.localTarget.id = this.target.id
             this.localTarget.name = this.target.name
             this.localTarget.northing = this.target.northing
             this.localTarget.easting = this.target.easting
             this.localTarget.depth = this.target.depth
         }
    }
}
</script>
import { HTTP } from '@/axios_api/http-common'
import Vue from 'vue'
import { format } from 'url';
import { stat } from 'fs';

const state = {
    dailyReports: {},
    dailyReportChanges:[],
    activeReport: -1,
    reportRecipient: []
}
  
interface IState {
    dailyReports: Record<string,Array<any>>,
    dailyReportChanges:Array<any>,
    activeReport: number,
    reportRecipient: any[]
}


const getters = {
    getActiveReport(state: IState) {
        return state.activeReport
    },

    getReportRecipient(state: IState) {
        return state.reportRecipient
    },

    getReportsForProject:(state:IState) => (projectId:any) => {
        const reportsToReturn = state.dailyReports[projectId]
        if(reportsToReturn==undefined)return []
        return reportsToReturn
    },

    getDailyReportById:(state:IState) => (reportId:any) => {
        for(let k in state.dailyReports){
            let dailyReport = state.dailyReports[k].find((thisReport:any) => thisReport.id==reportId)
            if(dailyReport) return dailyReport
        }
    },

    getDailyReportChangesCount: (state:IState) => {
        return state.dailyReportChanges.length
    },

    convertDailyReportToDailyReportModel:(state:IState,getters:any) => (id:any) => {
        
        let dailyReportModel:any = {}
        let dailyReportToConvert = getters.getDailyReportById(id)
        
        dailyReportModel.id = dailyReportToConvert.id
        dailyReportModel.startDepth = dailyReportToConvert.startDepth
        dailyReportModel.endDepth = dailyReportToConvert.endDepth
        dailyReportModel.startTime = dailyReportToConvert.startTime
        dailyReportModel.endTime = dailyReportToConvert.endTime
        dailyReportModel.reportDate = dailyReportToConvert.reportDate
        dailyReportModel.dateUploaded = dailyReportToConvert.dateUploaded
        dailyReportModel.projectId = dailyReportToConvert.projectId

        dailyReportModel.activityLog = dailyReportToConvert.activityLog.map((logEntry:any) => ({
            from: logEntry.from,
            to: logEntry.to,
            hasNpt:logEntry.hasNpt,
            activityInProjectId:logEntry.activity.id,
            activityId:logEntry.milestone.id,
            description:logEntry.description,
            depth:logEntry.depth
        }))
        
        //handle budget updates here
        if(dailyReportToConvert.budgetChanged){
            dailyReportModel.cost = {
                // budgetTemplateId:dailyReportToConvert.cost.budgetTemplateId,
                // activityId:dailyReportToConvert.cost.activityId,
                budgetLines:dailyReportToConvert.cost.budgetLines.map((bl:any) => ({
                    lineItemId:bl.lineItemId,
                    lineItemValue:bl.lineValue,
                    activityId:bl.activityId
                }))
            }
        }

        // window.console.log(dailyReportModel)
        //end of budget update
        return dailyReportModel

    },
}

const mutations = {
    setDailyReports(state:IState,data:any){
        Vue.set(state.dailyReports,data.projectId,data.data)
    },
    setActiveReport(state: IState, data: any) {
        state.activeReport = data
    },
    setProjectId(state:IState,projectId:any){
        if(!state.dailyReports[projectId])Vue.set(state.dailyReports,projectId,[])
    },
    addDailyReport(state:IState,data:any){
        state.dailyReports[data.projectId].push(data.dailyReport)
    },
    updateEditedDailyReport(state:IState,data:any){
       let reportIndex= state.dailyReports[data.projectId].findIndex(dailyReport => dailyReport.id == data.dailyReport.id )
       state.dailyReports[data.projectId][reportIndex] = data.dailyReport;
    },
    setReportRecipient(state: IState, payload: any) {
        state.reportRecipient = payload
    },
    updateNewDailyReport(state:IState, data:any){
        for(let k in state.dailyReports){
          state.dailyReports[k].forEach((thisDailyReport,index,dailyReports) => {
            let indexOfUpdate = data.data.findIndex((thisUpdate:any) => thisUpdate.data.tempKey == thisDailyReport.id)
            if(indexOfUpdate>-1)dailyReports[index] = data.data[indexOfUpdate].data
          })
        }
    },
    updateDailyReport(state:IState,data:any){
        let existingChangeIndex = state.dailyReportChanges.findIndex(thisDailyReportUpdate => thisDailyReportUpdate.id == data.dailyReportToUpdate.id)
        let existingDailyReportIndex = state.dailyReports[data.activityId].findIndex(thisDailyReport => thisDailyReport.id == data.dailyReportToUpdate.id)
        
        if(existingChangeIndex<0){
            state.dailyReportChanges.push({
                id:data.dailyReportToUpdate.id,
                action:"UPDATE",
            })
        }

        for(let key in data.dailyReportToUpdate){
            if(key=="id")continue
            state.dailyReports[data.activityId][existingDailyReportIndex][key] = data.dailyReportToUpdate[key]
        }
    },
    updateTempKeyToActual(state:IState,data:any){
        data.forEach((thisTemActualPair:any) => {
          for(let key in state.dailyReports){
              state.dailyReports[key].forEach((thisDrillingReport:any) => {
                
              })
          }
        })
    },
    clearDailyReportChanges(state:IState,data:any){
        state.dailyReportChanges = []
    },
    updateDailyReportBudget (state:IState, budgetUpdate:any){
        //make sure changes to start and end date are saved to the store
        let existingChangeIndex = state.dailyReportChanges.findIndex(thisReportUpdate => thisReportUpdate.id == budgetUpdate.id)
        let existingReportIndex = state.dailyReports[budgetUpdate.projectId].findIndex(thisReport => thisReport.id == budgetUpdate.id)
    
        state.dailyReports[budgetUpdate.projectId][existingReportIndex].budgetChanged = true
        let currentBudget = state.dailyReports[budgetUpdate.projectId][existingReportIndex].budget

        if(currentBudget==null){
          currentBudget = {budgetTemplateId:"",budgetLines:[],activityId:budgetUpdate.id}
          Vue.set(state.dailyReports[budgetUpdate.projectId][existingReportIndex],'budget', currentBudget)
        }
    
        if(existingChangeIndex<0){
          state.dailyReportChanges.push({
            id:budgetUpdate.id,
            action:"UPDATE",
          })
          existingChangeIndex= state.dailyReportChanges.length -1
        }
    
        let budgetLineIndex = currentBudget.budgetLines.findIndex((thisBudgetLine:any) => thisBudgetLine.lineItemId == budgetUpdate.lineItem.lineItemId)
        //where the budget line index is not found, do a push into budget lines
        if(budgetLineIndex<0){
            state.dailyReports[budgetUpdate.projectId][existingReportIndex].budget.budgetLines.push({lineValue:budgetUpdate.lineItem.lineValue,lineItemId:budgetUpdate.lineItem.lineItemId})
        }else{
            //where the budget line is found, do an update of that particular budget line
            state.dailyReports[budgetUpdate.projectId][existingReportIndex].budget.budgetLines[budgetLineIndex].lineValue = budgetUpdate.lineItem.lineValue
        }
      }
}

const actions = {

    getReportsForProject({ commit,state } : { commit: any,state:IState},projectId:any){
        return new Promise((resolve, reject) => {
            HTTP.get('ddr/?projectId='+projectId)
            .then((response: any) => {
                commit('setDailyReports', {projectId:projectId,data:response.data.data} )         
                resolve(response)                    
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    },

    getReportRecipient({ commit, state} : { commit: any, state: IState }, projectId: any ) {
        return new Promise((resolve, reject) => {
            HTTP.get('reportrecipient/' + projectId)
                .then((response: any) => {
                    commit('setReportRecipient', response.data.data)
                    resolve(response)
                })
                .catch((error: any) => {
                    reject(error)
                })
        })
    },

    createDailyReport({commit}:{commit:any},data:any){
        return new Promise((resolve,reject) => {
            if(data.reports.length>0){
                HTTP.post('ddr/create',data)
                .then((response:any) => {
                    commit('updateNewDailyReport',{data:response.data.data})
                    resolve(null)
                })
                .catch((error:any) => {
                    reject(error)
                })
            }else{
                resolve([])
            }
        })
    },
    uploadFile ({ commit }: { commit : any }, data: any) {     
        return new Promise((resolve, reject) => {
          HTTP.post('ddr/upload', data)
          .then((response: any) => {
            resolve(response)
          })
          .catch((error: any) => {
            reject(error)
          })
    
        })
    },
    getColumnNames ({ commit }: { commit : any }, payload:any) {     
        return new Promise((resolve, reject) => {-
          HTTP.get('ddr/colunmNames?fileName='+payload.fileName+'&sheetName='+payload.sheetName)
          .then((response: any) => {
            resolve(response)
          })
          .catch((error: any) => {
            reject(error)
          })
    
        })
    },
    importDeviationSurvey ({ commit }: { commit : any }, model:any) {     
        return new Promise((resolve, reject) => {-
          HTTP.post('ddr/import/deviationsurvey',model)
          .then((response: any) => {
            resolve(response)
          }) .catch((error: any) => {
            reject(error)
          })
    
        })
    },

    saveChanges({commit,dispatch,state,getters}:{commit:any,dispatch:any,state:IState,getters:any},data:any){
        //if(data)commit("updateTempKeyToActual",data)        
        const allCreateOps:Array<any> = state.dailyReportChanges.filter(d => d.action=="CREATE").map(thisEntry => getters.convertDailyReportToDailyReportModel(thisEntry.id))
        //const allUpdateOps:Array<any> = state.dailyReportChanges.filter(d => d.action=="UPDATE").map(thisEntry => getters.convertDailyReportToDailyReportModel(thisEntry.id))
        //const allDeleteOps:Array<any> = state.dailyReportChanges.filter(d => d.action=="DELETE")
        return new Promise((resolve,reject) => {
          dispatch("createDailyReport",{reports:allCreateOps,type:"List"}).then(() => {
            resolve(null)
            /*
            dispatch("updateMilestonesForActivity",{activities:allUpdateOps,type:"List"}).then((updateRes:any) => {
                dispatch("deleteMilestoneForActivity",allDeleteOps).then((deleteRes:any) => {
                    resolve()
                })
            })
            */
          })
        })
    }
}


const dailyReportModule = {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
  }
  
  export default dailyReportModule;































































































































import Vue from 'vue'
import Component from 'vue-class-component';
import EventBus from './../../../eventBus'

const mustBeGreater = (operations: any) =>  (value: any, vm: any) => {
    return value <= (100 - operations.reduce((total: any,currentValue: any)=>{
        if(currentValue.id!=vm.id) return Number(total) + Number(currentValue.percentage)
        return Number(total)
    },0))
}

@Component
export default class ManageWellActivityTemplate extends Vue{
    // DATA
    showForm: boolean = false
    isUpdate: string = 'VIEW'
    activeView: string = 'templates'
    activeTemplateScreen: string = "FORM"
    selectedOperationFields: Array<any> = [
        {key:'operation.name',label:"Operation Name", sortable:true},
        {key: 'percentage', label: "Percentage"},
        {key: 'button', label: "Actions"} 
    ]
    activityTemplatesFields: Array<any> =[
        {key:"templateName",label:"Name"},
        {key:"button",label:"Actions"}
    ]
    operationFields: Array<any> = [ 
        {key:'name',label:"Operation Name", sortable:true},
        {key: 'department.name',label:"Department", sortable: 'true'},
        {key: 'button', label: "Actions"} 
    ]
    activeTemplate: any ={
        id: '',
        templateName: '',
        operations:[]
    }
    search: string = ''
    error: string = ''
    activeScreen: string = 'loading'
    loadingMessage: string = 'Loading Project Templates'
    loadingTitle: string = 'Loading...'
    deletePromptProperty: boolean = false
    successMessage: string = ''
    additionalSuccessMessage = ''
    loader: boolean = true




    // COMPUTED PROPERTIES
    get getWellOperations () {
        return this.$store.getters['wellactivitiesModule/getWellOperations']
    }
    get fillteredTemplates () {
        return this.$store.getters['templatesModule/fillteredTemplates']
    }
    get filteredOperations(){
        return this.getWellOperations.filter((operation: any) => {
            return operation.name.toLowerCase().match(this.search.toLowerCase()) && this.activeTemplate.operations.find((o: any,i: any)=>o.operation.id == operation.id)==undefined
        });
    }
    get maxPossibleValue(){
            var maxme = 100 - this.activeTemplate.operations.reduce((total: any,currentValue: any)=>{
                return Number(total) + Number(currentValue.percentage)
            },0)
            return maxme
    }





    // VUEX METHODS AND MUTATIONS
    getWellActivities () {
        return this.$store.dispatch('wellactivitiesModule/getWellActivities')
    }
    deleteTemplate (id: any) {
        return this.$store.dispatch('templatesModule/deleteTemplate', id)
    }
    getTemplates () {
        return this.$store.dispatch('templatesModule/getTemplates')
    }
    updateTemplate (template: any) {
        return this.$store.dispatch('templatesModule/updateTemplate', template)
    }
    createTemplate (template: any) {
        return this.$store.dispatch('templatesModule/createTemplate', template)
    }



    // METHODS
    showTemplateData(temp: any){
        this.isUpdate = 'VIEW'
        this.showForm = true;
        this.activeTemplate.id = temp.id
        this.activeTemplate.templateName = temp.templateName;
        this.activeTemplate.operations = temp.operations.map((o: any) =>{
            return {id:o.id,percentage:o.percentage,operation:o.operation}
        })
    }

    backToTeplates(){
        this.activeView = "templates"
        this.showForm = false
    }

    newTemplateClicked(){
        this.isUpdate = 'NEW';
        this.showForm = true;
        this.activeView = 'operations'
        this.activeTemplate.templateName = ''
        this.activeTemplate.operations = []
    }

    editTemplateClicked(temp: any){
        this.activeView = 'operations'
        this.isUpdate = 'UPDATE'
        this.showForm = true;
    }
    
    promptDelete(item: any){
        this.activeTemplate = item;
        this.deletePromptProperty = true
    }

    doTemplateDelete(){
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.loadingMessage = 'Please wait while we delete this template'
        this.loadingTitle = 'Deleting...'
        this.deleteTemplate(this.activeTemplate.id).then(response => {
            this.activeScreen = 'successNoBack'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.additionalSuccessMessage = 'This template will no longer be available'
            this.successMessage = this.activeTemplate.templateName + ' deleted succesfully'
            this.deletePromptProperty = false
            setTimeout(() => {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
            }, 10000);
        },error => {          
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.message
            this.deletePromptProperty = false
            setTimeout(() => {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
            }, 10000);
        })
    }

    addOperationToOpRatio(op: any){
        this.activeTemplate.operations.push({
            id:"-1",
            percentage:0,
            operation:op
        })
    }

    removeOperationFromOpRatio(index: number){
        this.activeTemplate.operations.splice(index,1)
    }

    saveTemplate(){
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.loadingMessage = 'Please wait while we create new template'
        this.loadingTitle = 'Saving...'
        if(this.isUpdate == "UPDATE"){
            let templateToPersist: any  = {id:this.activeTemplate.id,templateName:this.activeTemplate.templateName,operations:this.activeTemplate.operations.map((o: any) => {return {id:o.id,percentage:o.percentage,operation:o.operation.id}})}
            this.updateTemplate(templateToPersist).then(res=>{
                this.activeScreen = 'succesNoBack'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.additionalSuccessMessage = ''
                this.successMessage = 'Activity Updated Successfully'
                setTimeout(() => {
                    this.activeScreen = 'main'
                    EventBus.$emit('activeScreen', this.activeScreen)
                }, 10000);
            },err=>{
                this.error = err.response.data.message
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
                setTimeout(() => {
                    this.activeScreen = 'main'
                    EventBus.$emit('activeScreen', this.activeScreen)
                }, 10000);
            })
        }else{
            var templateToPersist  = {templateName:this.activeTemplate.templateName,operations:this.activeTemplate.operations.map((o: any) => {return {percentage:o.percentage,operation:o.operation.id}})}
            this.createTemplate(templateToPersist).then(res=>{
                this.activeScreen = 'successNoBack'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.additionalSuccessMessage = 'You can now use the newly created Template'
                this.successMessage = 'Successfully Created '+ templateToPersist.templateName
                setTimeout(() => {
                    this.activeScreen = 'main'
                    EventBus.$emit('activeScreen', this.activeScreen)
                }, 10000);
            },(err: any)=>{
                 this.error = err.response.data.message
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
               
                setTimeout(() => {
                    this.activeScreen = 'main'
                    EventBus.$emit('activeScreen', this.activeScreen)
                }, 10000);
            })
        }
    }

    searchValue(e: any) {
        this.search = e
    }


    // CREATED
    mounted() {
        this.getWellActivities()
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        if (this.getWellOperations.length <= 0){
            this.getTemplates().then(() => {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
            })
        } else {
            this.activeScreen = 'main'
            EventBus.$emit('activeScreen', this.activeScreen)
        }
    }
}

import { HTTP } from '@/axios_api/http-common'
import { faCommentMedical } from '@fortawesome/free-solid-svg-icons';

const state = {
    dependencies:  null,
}

interface IState {
    dependencies: Array<any>
}

const mutations ={
    setDependencies(state:IState, dependencyArr: Array<any>){
        state.dependencies = dependencyArr;
    },
    deleteDependency(state: IState, dependency: any){
        var _exIndex = state.dependencies.indexOf(dependency);
        if(_exIndex > -1)
            state.dependencies.splice(_exIndex,1);
    },
    updateDepencies(state: IState, dependency: any) {
        var index_ = -1;
        for (let index = 0; index < state.dependencies.length; index++) {
            if(state.dependencies[index].id == dependency.id) {
               index_ = index;
                break;
            } 
       }
       if(index_ > -1)
           state.dependencies.splice(index_,1,dependency);
    },
    saveDependencyMutation(state: IState, dependency: any) {
        state.dependencies.push(dependency)
    }
}   

const getters = {
    filterDependencies (state: IState) {
        return state.dependencies;
    },
    getDependency:(state:IState) => (id: number) =>{
        let depenndency = null
        if(!state.dependencies)return;
        for (let index = 0; index < state.dependencies.length; index++) {
             if(state.dependencies[index].id == id) {
                depenndency = state.dependencies[index];
                 break;
             } 
        }
        return depenndency
    },
    getDependencyOptions:(state: IState) =>{
        if(!state.dependencies)return [];
        return JSON.parse(JSON.stringify(state.dependencies)).sort((a: any, b: any) => {
            if (a.name < b.name) return -1
            else if (a.name > b.name) return 1
            return 0
        }).map((dep: any) => {
            return {text: dep.name, value:dep}
        })  
    }
}

const actions = {
    getDependencies ({ commit }: { commit: any }) { 
        return new Promise((resolve, reject) => {
            HTTP.get('dependency')
            .then((response: any) => {
                commit('setDependencies', response.data.data)                
                resolve(response)
                
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    updateDependency ({commit} : {commit: any}, payload: any ) {
        return new Promise((resolve, reject) => {
            HTTP.post('dependency/update',payload).then ((response: any) => {
                commit('updateDepencies', payload)
                resolve(response)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    saveDepartment ({commit} : {commit: any}, payload: any) {
        return new Promise((resolve, reject) => {
            HTTP.post('dependency/create',payload).then((res: any) => {
                commit('saveDependencyMutation', payload)
                resolve(res)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    deleteDependencyAction({commit}: {commit: any}, dependency: any)  {
        return new Promise((resolve, reject) => {
            HTTP.delete('dependency/delete/' + dependency.id).then((res: any) => {
                commit('deleteDependency', dependency)
                resolve(res)
            }).catch((error: any) => {
                reject(error)
            })
        })
    }
}

const dependenciesModule = {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}

export default dependenciesModule;
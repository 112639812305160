










































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import EventBus from './../../eventBus'
import {Validations} from 'vuelidate-property-decorators';
import { required, email, minLength } from "vuelidate/lib/validators"

@Component
export default class ManageUser extends Vue {
    activeScreen: string = 'main'
    loadingMessage: string = ''
    loadingTitle: string = 'Saving User...'
    successMessage: string = "New User Created Successfully"
    additionalSuccessMessage = "You can now use newly created user"
    allpermissions =["rig","well","sequence","budgetentry","budgetread","budgetsetting","wellplanner"]
    allroles =["superadmin","admin"]

    userRolesOptions = [
          { text: 'Admin', value: 'admin' },
        ]
    links: Array<object> = [
        {
            text: 'User Accounts',
            to: '/users'
        },
        {
            text: 'Manage Account',
            active: true
        }
    ]
    user = {
        email: '',
        firstName: '',
        lastName: '',
        userName: '',
        password: '',
        position: '',
        department: null,
        team: null,
        roles: [] as Array<any>,
        permissions: [] as Array<any>,
        photo: undefined,
        id: null
    };
    @Validations()
    validations = {
        user: {
            password: { 
                required, 
                minLength: minLength(6)
            },
            email: { required, email },
            department: {required},
            position: {required},
            lastName: {required, minLength: minLength(3)},
            firstName: {required, minLength: minLength(3)},
            userName:{required, minLength: minLength(3)}
        }
    }
    departmentOptions: Array<any> = []
    showPreview: boolean = false
    uploadedPhoto: any
    isCreate: boolean = true
    error: any = {
        generalMessage: '',
        errors: []
    }
    formError: string[] = []

    get isButtonDisabled(): boolean {
        if (this.formError.length > 0) {
            return true
        } else {
            return false
        }
    }


    //Methods

    checkErrors () {
        this.formError = []
        if (!this.user.email) {
            this.formError.push("Email is required")
        } else if(!this.validEmail) {    
            this.formError.push('Invalid Email address')        
        }

        if (!this.user.firstName) {
            this.formError.push('First name is required') 
        } else if(!this.checkNumberFirstname) {
            this.formError.push('There should be no numbers, spaces or symbols in First name')
        }

        if (!this.user.position) {
            this.formError.push('Position is required') 
        }

        if (!this.user.lastName) {
            this.formError.push('Last name is required') 
        } else if(!this.checkNumberLastname) {
            this.formError.push('There should be no numbers, spaces or symbols in Last name')
        }

        if (!this.user.userName) {
            this.formError.push('Username is required') 
        } else if (!this.checkUsernameValidation) {
            this.formError.push('Username should not contain spaces')
        }

        if (!this.user.department) {
            this.formError.push('Department is required') 
        }
        if (!this.user.password) {
            this.formError.push('Password is required') 
        } else if (!this.checkPassword) {
            this.formError.push('Password should contain at least 6 characters')
        }
    }

    get validEmail (): boolean {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(this.user.email);
    }

    get checkDepartment (): boolean {
        if (!this.user.department) {
            return false
        } else {
            return true
        }
    }

    get checkNumberFirstname(): boolean {
        // return /^[a-zA-Z]+$/.test(this.user.firstName)
        return /^\S{1,}$/.test(this.user.firstName)
    }

    get checkUsernameValidation(): boolean {
        return /^\S{1,}$/.test(this.user.userName)
    }

    get checkNumberLastname(): boolean {
        return /^\S{1,}$/.test(this.user.lastName)
    }

    get checkPassword(): boolean {
        if (this.user.password.length < 6) {
            return false
        } else {
            return true
        }
    }

    fileUploaded(e: any){
        let file = e.target.files[0]
        this.user.photo = file
        let reader = new FileReader()
        reader.readAsDataURL(e.target.files[0]) 
        this.showPreview = true;
        reader.onload = (e: any) => {
            const selectedImage = this.$refs.selectedImage as any
            selectedImage.setAttribute("src", e.target.result)
            this.uploadedPhoto = e.target.result
        }
    }

    removePhoto(e: any){
        this.user.photo = undefined;
        this.showPreview = false
        const selectedImage = this.$refs.selectedImage as any
        selectedImage.reset()
        this.uploadedPhoto = undefined
    }

    outGoingDataTransformation(formData: any){
        formData.append("FirstName",this.user.firstName)
        formData.append("LastName",this.user.lastName)
        formData.append("UserName",this.user.userName)
        formData.append("Email",this.user.email)
        formData.append("JobTitle",this.user.position)
        formData.append("Photo",this.user.photo)
        formData.append("password",this.user.password)
        formData.append("teamId",this.user.team)
        if(this.isCreate){
        for(var i=0;i<this.user.roles.length;i++){
          formData.append("roles",this.user.roles[i])
         } 
        for(var index=0;index<this.user.permissions.length;index++){
          formData.append("permissions",this.user.permissions[index])
         } 
        }
        else{
            this.updateUserRoles(this.user.id);
            this.updateUserPermissions(this.user.id);
        }   
         
    }

    incomingDataTransformation(retrivedUser: any){
        this.user.id=retrivedUser.id;
        this.user.email=retrivedUser.email;
        this.user.password="";
        this.user.firstName=retrivedUser.firstName;
        this.user.lastName=retrivedUser.lastName;
        this.user.userName = retrivedUser.userName;
        this.user.position=retrivedUser.jobTitle;
        this.user.department=retrivedUser.departmentId;
        this.user.team=retrivedUser.teamId;
        // this.user.roles=retrivedUser.roles.slice()
        // this.user.permissions=retrivedUser.permissions.slice()

        if(retrivedUser.photo){
            this.uploadedPhoto = "data:image/png;base64," + retrivedUser.photo
            this.user.photo = undefined
            this.showPreview = true
        }
        this.getRetrivedUserRoles(retrivedUser.id);
        this.getRetrivedUserPermissions(retrivedUser.id);
    }

    onSubmit(evt: any){
        evt.preventDefault();
        this.error.generalMessage = ''

        if(this.isCreate){
            this.activeScreen = 'loading'
            this.loadingTitle = 'Saving...'
            this.loadingMessage = "Please wait while we create your new user."
            EventBus.$emit('activeScreen', this.activeScreen)
            let formData = new FormData();
            this.outGoingDataTransformation(formData)
            this.createUser(formData).then((response: any)=>{
                this.activeScreen = 'successRemain'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.user.email = ''
                this.user.firstName = ''
                this.user.lastName = ''
                this.user.userName = ''
                this.user.password = ''
                this.user.position = ''
                this.user.department = null
                this.user.team = null
                this.user.permissions = []
                this.user.roles=[]
                this.user.photo = undefined
                this.user.id = null
                this.$v.$reset()
            },(error: any) => {
                this.error.generalMessage = error.response.data.message
                this.activeScreen= 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
            })
        } else {
            this.activeScreen = 'loading'
            this.loadingTitle = 'Saving...'
            this.loadingMessage = "Please wait while we update this user."
            EventBus.$emit('activeScreen', this.activeScreen)
            let formData = new FormData();
            this.outGoingDataTransformation(formData);
            this.updateUser({id:this.user.id, user:formData}).then(response=>{
                this.activeScreen= 'successNoGotIt'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.successMessage = 'User Updated Successfully'
                this.additionalSuccessMessage = ''
            },error => {
                this.error.generalMessage = error.response.data.message
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)    
            })
        }
    }

    updateUserRoles(id:any){
        var userRoles= {userId:id,roles:[] as Array<any>}
        this.allroles.forEach((role)=> {
            if(this.user.roles.includes(role)){
            userRoles.roles.push({roleName:role, selected:true});      
            }
            else{
             userRoles.roles.push({roleName:role, selected:false});      
            }
     
        });
        this.updateSelectedUserRoles(userRoles);
    }
    updateUserPermissions(id:any){
        var userPermissions= {userId:id,permissions:[] as Array<any>}
        this.allpermissions.forEach((permission)=> {
            if(this.user.permissions.includes(permission)){
                userPermissions.permissions.push({permissionName:permission, selected:true}); 
            }
            else{
                 userPermissions.permissions.push({permissionName:permission, selected:false}); 
            }
                     
        });
        this.updateSelectedUserPermissions(userPermissions);
    }

    b64toBlob(b64Data: any, contentType: any, sliceSize: any) {
            contentType = contentType || '';
            sliceSize = sliceSize || 512;

            var byteCharacters = atob(b64Data);
            var byteArrays = [];

            for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                var slice = byteCharacters.slice(offset, offset + sliceSize);

                var byteNumbers = new Array(slice.length);
                for (var i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                var byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

        var blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    // Vuex actions
    createUser(userToCreate: any) {
        return this.$store.dispatch('usersModule/createUser', userToCreate)
    }

    generatePassword() {
            this.activeScreen = 'loading'
            this.loadingTitle = 'Updating...'
            this.loadingMessage = "Please wait while we generate new user password."
            EventBus.$emit('activeScreen', this.activeScreen)
             this.$store.dispatch('usersModule/generatePassword', this.user.userName).then((res)=>{
                this.activeScreen= 'successNoGotIt'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.successMessage = 'Password Successfully Generated'
                this.additionalSuccessMessage = ''
            },error => {
                this.error.generalMessage = error.response.data.message
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)    
            })
       }

    getDepartments(){
        return this.$store.dispatch('departmentModule/getDepartments')
    }

    getUsers() {
        return this.$store.dispatch('usersModule/getUsers')
    }

    updateUser(data: any) {
        return this.$store.dispatch('usersModule/updateUser', data)
    }
    
    getRetrivedUserRoles(userId:any){
        return this.$store.dispatch('usersModule/getUserRoles',userId)
        .then(response=>{
        var roles = response.data.data.roles;
        roles.forEach((role:any) => {
            if(role.selected){
            this.user.roles.push(role.roleName);
            }
        });
        });
    }
      getRetrivedUserPermissions(userId:any){
        return this.$store.dispatch('usersModule/getUserPermissions',userId)
        .then(response=>{
        var permissions = response.data.data.permissions;
        permissions.forEach((permission:any) => {
            if(permission.selected){
            this.user.permissions.push(permission.permissionName);
            }
        });
        });
    }
    updateSelectedUserRoles(roles:any){
        return this.$store.dispatch('usersModule/updateUserRoles',roles)
        .then(response=>{
        });
    }
     updateSelectedUserPermissions(permissions:any){
        return this.$store.dispatch('usersModule/updateUserPermissions',permissions)
         .then((response:any)=>{
        });
    }

    //Computed Properties
    get getUserById () {
        return this.$store.getters['usersModule/getUserById']
    }
    get  userPermissionOptions () { 
        if(this.user.roles.includes("admin")){
            return [
                { text: 'Budget Read', value: 'budgetread' },
                { text: 'Budget Entry', value: 'budgetentry' },
                { text: 'Budget Setting', value: 'budgetsetting' },
                { text: 'Rig', value: 'rig' },
                { text: 'Sequence', value: 'sequence' },
                { text: 'Well', value: 'well' },
                { text: 'Well Planner', value: 'wellplanner' }
            ] 
        }
        return [
          { text: 'Budget Read', value: 'budgetread' },
          { text: 'Budget Entry', value: 'budgetentry' },
          { text: 'Sequence', value: 'sequence' },
          { text: 'Well Planner', value: 'wellplanner' }
        ] 
        }

    get getDepartmentOptions () {
        return this.$store.getters['departmentModule/getDepartmentOptions']
    }
    get getTeams () {
        return this.$store.getters['departmentModule/getAllTeams']
    }
    get getTeamsOptions(){
        let teamOptions: Array<any>=[]
        this.getTeams.forEach((team: any)=>{
            if(this.user.department){
            if(team.departmentId == this.user.department){
           teamOptions.push({text:team.teamName,value:team.id})
            }
            }
        })
        return teamOptions
    }

    // Created
    created() {  
        var id=this.$route.query.id 
                      
       if(this.getTeams.length === 0){
         this.$store.dispatch('departmentModule/getTeams')
        }
        
        if(this.getDepartmentOptions.length === 0){
            this.getDepartments()
        }   
        if(id){
            var retrivedUser=this.getUserById(id)
            this.isCreate=false 
          //  this.getSelectedUserRoles(id); 
            if(!retrivedUser){
                this.getUsers().then(response=>{
                    retrivedUser=this.getUserById(id)
                    this.incomingDataTransformation(retrivedUser) 
                    this.user.password = 'defaultvalue'
                })
            }
            else{
                this.incomingDataTransformation(retrivedUser)  
                this.user.password = 'defaultvalue'
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)  
            }
        }
        else{
            this.checkErrors()
            this.activeScreen = 'main'
            EventBus.$emit('activeScreen', this.activeScreen)  
        }
    }

}












import Vue from 'vue'
import Component from 'vue-class-component';

@Component ({
    props: {
        action: {
            type: String,
            default: 'delete'
        },
        remove: {
            type: Function,
            required: true
        },
        toBeDeleted: {
            type: String,
            required: true
        }
    }
})
export default class DeleteModal extends Vue{

}




























import Vue from "vue"
import { HTTP } from '@/axios_api/http-common'
export default Vue.extend({
    props:["height"],
    data(){
        return{
            aars:[],
            search:"",
            actionFilter:"All",
            categoryFilter:"All",
            actionOptions:[
                {text:"All Actions",value:"All"},
                {text:"Corrective Actions",value:"Corrective_Action"},
                {text:"Best Practices",value:"Best_Practices"}
            ],
            categoryOptions:[
                {text:"All Categories",value:"All"},
                {text:"Casing and Conductor",value:"Casing_Conductor"},
                {text:"Bit Selection",value:"Bit_Selection"}
            ],
            fields:[
                {key:"serial",label:"S/N"},
                {key:"rigName",label:"Rig"},
                {key:"projectName",label:"Project"},
                {key:"activityName",label:"Activity"},
                {key:"milestoneName",label:"Phase"},
                {key:"action",label:"Category"},
                {key:"health",label:"Status"},
                {key:"reasonForDeviationFromPlannedActivities",label:"Description"},
                {key:"correctiveActions",label:"Corrective Action"}
            ]
        }
    },
    mounted(){
        HTTP.get('aar/?projectId=-1')
        .then((response: any) => {
            this.aars = []
            this.aars  = response.data.data    
        })
        .catch((error: any) => {
              
        })
    },
    computed:{
        computedAAR():Array<any>{
            return this.aars.filter((a:any)=>(a.reasonForDeviationFromPlannedActivities!=null)&&(a.correctiveActions!=null)).filter((a:any) => (a.correctiveActions.toLowerCase().includes(this.search.toLowerCase()))||
            (a.correctiveActions.includes(this.search.toLowerCase())))
        }
    }
})







































import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import KPIForm from "./KPIForm.vue"
import AnalyticsCard from './AnalyticsCard.vue'
import AnalyticsTable from './AnalyticsTable.vue'

@Component({
  components: {
   'kpi-form':KPIForm,
    'analytics-card':AnalyticsCard,
    'analytics-table': AnalyticsTable
  }
})
export default class AnalyticsTab extends Vue {

    @Prop()
    activeTab!:any

    showKPIList:boolean = false
    name:string = "AnalyticsTab"
    view:string =  'main'
    mouseTop:any = '30'
    mouseLeft:any = '30'
    localSelectedSequence:any = null
    selectedProjects:Array<any> = []
    selectedActivities:Array<any> = []
    selectedMilestones:Array<any> = []
    selectedCard:any = {id:'',state:'NONE'}
    width = 650
    height = 325
    backgroundColor = "white"
    tables:any = []

    get kpis():any{
        return this.$store.state.Analytics.kpis
    }

    get preset():any{
        return this.$store.getters["Analytics/getPresetById"](this.$vnode.key)
    }

    get selectedSequence():any{
        return this.$store.state.Analytics.selectedSequence
    }

    get globalSelectedProjects(){
        return this.$store.state.Analytics.selectedProjects
    }

    get activeAnalyticsElement(){
        return this.$store.state.Analytics.activeAnalyticsElements
    }

    get globalBackgroundColor(){
        return this.$store.state.Analytics.backgroundColor
    }

    @Watch("selectedSequence")
    onSelectedSequenceChanged(){
        if(this.activeAnalyticsElement.id==this.$vnode.key&&this.activeAnalyticsElement.type=='PRESET'){
            this.localSelectedSequence = this.selectedSequence
        }
    }

    @Watch("globalSelectedProjects")
    onGlobalSelectedProjectChanged(){
        if(this.activeAnalyticsElement.id==this.$vnode.key&&this.activeAnalyticsElement.type=='PRESET'){
            let itemsToRemove:Array<any> = []
            this.globalSelectedProjects.forEach((p:any)=>{
                if(!this.selectedProjects.includes(p))this.selectedProjects.push(p)
            })
            this.selectedProjects.forEach((p,index)=>{
                //the value here is the list of preset projects only, if an item has been removed from the preset list, only remove it from list of selected projects for this card if it has not been locally added to this card
                if(!this.globalSelectedProjects.includes(p))itemsToRemove.push(index)
            })
            itemsToRemove.forEach(i=>this.selectedProjects.splice(i,1))
        }
    }

    @Watch("activeTab",{immediate:true})
    onActiveTabChanged(){
        if(this.activeTab==this.$vnode.key){
            this.onAnalyticsCanvasClicked({target:{id:"analyticscanvas"}})
        }
    }

    @Watch("globalBackgroundColor")
    onGlobalBackgroundColorChanged(){
        if(this.activeAnalyticsElement.id==this.$vnode.key&&this.activeAnalyticsElement.type=='PRESET'){
            this.backgroundColor = this.globalBackgroundColor
        }
    }

    onAnalyticsCanvasClicked(eventData:any){
        if(eventData.target.id=="analyticscanvas"){
            this.$store.commit("Analytics/updateSelectedSequence",this.localSelectedSequence)
            this.$store.commit('Analytics/updateActiveAnalyticsElement', {id:this.$vnode.key,type:'PRESET',name:this.preset.name});
            this.$nextTick(()=>{
                this.$store.commit('Analytics/updatedSelectedProjects', this.selectedProjects);
                this.$store.commit('Analytics/updatedBackgroundColor',this.backgroundColor)
                this.$store.commit('setRightPaneToTrue');
                this.$store.commit('setActivePane', 'analytics-data-source');
            })
        }
    }

    addChartItem(kpi:number,type:string){
        this.$store.commit("Analytics/addKPIToPreset",{
            presetId:this.$vnode.key,
            kpi:{
                kpiId:kpi,
                x:0,
                y:0,
                width:400,
                height:300
            }
        })
    }

    cardSelected(data:any){
        this.selectedCard.id = data.id
        this.selectedCard.state = data.state
    }

    cardDeSelected(data:any){
        this.selectedCard.state = 'MOUSE_UP'
    }

    onMouseMove(data:any){
        this.mouseLeft = data.x
        this.mouseTop = data.y
    }

    createCustomKPI(){
        ((this.$refs['custom-kpi-modal']) as any).show()
    }

    created(){
        if(this.kpis.length<1)this.$store.dispatch("Analytics/getKPIs")
        let screenWidth=window.innerWidth;
        let screenHeight = window.innerHeight;
        window.addEventListener('mouseup',e=>this.cardDeSelected(e))
        this.preset.kpIs.forEach((kpi:any) => {
            kpi.width= (screenWidth/this.preset.screenWidth)*kpi.width;
            kpi.height = (screenHeight/this.preset.screenHeight)*kpi.height;
            kpi.x = (screenWidth/this.preset.screenWidth)*kpi.x;
            kpi.y = (screenHeight/this.preset.screenHeight)*kpi.y;
        });   
        } 
        }





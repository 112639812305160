











































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { BModal } from 'bootstrap-vue/src/components/modal'
import EventBus from './../../eventBus'
import { required, minLength } from "vuelidate/lib/validators";
import { Validations } from 'vuelidate-property-decorators';


@Component
export default class ManageDepartment extends Vue {
    status:string = "create"
    loading:boolean = false
    teamForm:boolean = false
    teamFields:Array<any> = [
        {key:"number",label:"S/N"},
        {key:"teamName",label:"Name"},
        {key:"teamCode",label:"Code"},
        {key:"numberOfMembers",label:"Size"},
        {key:"teamMail",label:"Team Mail"},
        {key:"actions"}
    ]
    team:any = {
        teamName:"",
        teamCode:"",
        teamMail:""
    }
    activeScreen: string = 'main'
    loadingMessage: string = 'Please wait while we create your new department'
    loadingTitle: string = 'Saving...'
    additionalSuccessMessage: string = 'You can now use the newly created department when creating and updating users'
    successMessage: string = 'New Department Created Successfully'
    links: Array<object> = [
        {
            text: 'Departments',
            to: '/departments'
        },
        {
            text: 'Manage Department',
            active: true
        }
    ]
    @Validations()
    validations = {
        department: {
            name: { required, minLength: minLength(3) },
            code: { required },
        }
    }
    errorMessage: boolean = false
    isCreate: boolean = true
    department: any = {
        name: '',
        code: ''
    }
    cost_center_name: string = ''
    selectedCost: any = {
        name: '',
        isNew: true
    }
    deleted_item: any = null
    index: any = null
    error: any = {
        generalMessage: '',
        errors: []
    }
    deletePromptProperty: boolean = false
    wbsOptions = [
        {text: 'True', value: true},
        {text: 'False', value: false}
    ]
    formError: string[] = []



    // METHODS
    checkErrors () {
        this.formError = []
        if (this.department.name.length < 1) {
            this.formError.push("Department Name is required")
        }

        if (this.department.code.length < 1) {
            this.formError.push("Department Code is required")
        }
    }

    reInitTeam(){
        this.team.teamName = ""
        this.team.teamCode = ""
    }

    addNewTeam(){
        this.teamForm = true

    }

    hideTeamModal()
    {
        this.teamForm = false
    }

     saveTeam () {
        this.loading = true
        if (this.status === 'create') {
            this.$store.dispatch("departmentModule/createTeam",this.team).then(()=>{
                this.reInitTeam()
                this.activeScreen = 'successNoGotIt'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.successMessage = 'Team Created Successfully'
                this.additionalSuccessMessage = ''
                 this.loading = false   
                  this.teamForm = false
            }).catch((error) =>  {
               this.error.generalMessage = error.response.data.message
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.loading = false   
                this.teamForm = false
            })
            this.team.departmentId = this.department.id
        } else {
            this.team.departmentId = this.department.id
            this.$store.dispatch("departmentModule/updateTeam",this.team).then(()=>{
                this.reInitTeam()
                this.activeScreen = 'successNoGotIt'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.successMessage = 'Team Updated Successfully'
                this.additionalSuccessMessage = ''
                this.loading = false   
                this.teamForm = false
            }).catch((error)=>{
                 this.error.generalMessage = error.response.data.message
                 this.activeScreen = 'error'
                 EventBus.$emit('activeScreen', this.activeScreen)
                 this.loading = false   
                 this.teamForm = false
            })
        }
    }

    deleteTeamAction(state:any,id:any){
        switch(state){
            case "CONFIRM":
                this.team.id = id
                this.$bvModal.show('deletemodal')
            break;
            case "delete":
                this.loading = true
                this.$store.dispatch("departmentModule/deleteTeam",{id:this.team.id,departmentId:this.department.id}).then(()=>{
                    this.loading = false
                    this.reInitTeam()
                    this.$bvModal.hide('deletemodal')
                })
            break;
        }
    }

    editTeam(data:any){
        this.team.teamName = data.teamName
        this.team.teamCode = data.teamCode
        this.team.teamMail =data.teamMail
        this.team.id = data.id
        this.status = 'update'
        this.teamForm = true
    }

    deletePrompt (selectedCost: any, e: any) {
        this.selectedCost.name = selectedCost.name
        this.selectedCost.isNew = true;
        this.deleted_item = selectedCost;
        this.index = e
        this.deletePromptProperty = true
    }

    onSubmit (evt: any) {
        evt.preventDefault();
        this.error.generalMessage = ''
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        if (this.isCreate) {
            this.createDepartment(this.department).then(response => {
                this.activeScreen = 'successRemain'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.successMessage = 'Successfully Created '+this.department.name
                this.additionalSuccessMessage = 'You can now use the newly created department when creating and updating users'
                this.department.name = ''
                this.department.code = ''
                this.$v.$reset()
                // this.department.costCenterss = []
            }, error => {
                this.error.generalMessage = error.response.data.message
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
            })
        } else {
            this.loadingMessage = 'Please wait while we update this department'
            this.loadingTitle = 'Updating...'
            this.updateDepartment(this.department).then(response => {
                this.activeScreen = 'successNoGotIt'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.successMessage = 'Department Updated Successfully'
                this.additionalSuccessMessage = ''
            }, error => {
                this.error.generalMessage = error.response.data.message
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
            })
        }
    }

    // VUEX METHODS
    updateDepartment(department: any) {
        return this.$store.dispatch('departmentModule/updateDepartment', department)
    }

    createDepartment(department_object: any) {
        return this.$store.dispatch('departmentModule/createDepartment', department_object)
    }

    getDepartments() {
        return this.$store.dispatch('departmentModule/getDepartments')
    }

    delete(payload: any) {
        return this.$store.commit('departmentModule/delete', payload)
    }

    // COMPUTED PROPERTIES
    get getDepartmentById () {
        return this.$store.getters['departmentModule/getDepartmentById']
    }

    get filtered_centers() {
        return this.department.costCenters.filter((x: any) => {
            return x.directive != 'delete'
        })
    }

    get isButtonDisabled(): boolean {
        if (this.formError.length > 0) {
            return true
        } else {
            return false
        }
    }



    // CREATED
    created() {
        let queryId = this.$route.query.id

        if (queryId) {
            let department = this.getDepartmentById(queryId)
            if(!department){
                this.getDepartments().then(r=>{
                    department = this.getDepartmentById(queryId)  
                    this.department.name = department.name
                    this.department.code = department.code
                    this.department.id = parseInt(department.id)
                    this.department.teams = department.teams 
                    this.isCreate = false;        
                },e=>{

                })
            }else{
                this.isCreate = false;
                this.department.name = department.name
                this.department.code = department.code
                this.department.id = parseInt(department.id)
                this.department.teams = department.teams
            }
        } else {
            this.checkErrors()
        }
    }
}




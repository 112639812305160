import Vue from 'vue'
import Vuex from 'vuex'
import { HTTP } from '@/axios_api/http-common'
// Other modules
import usersModule from './modules/users'
import departmentModule from './modules/departments'
import rigsModule from './modules/rigs'
import wellsModule from './modules/wells'
import sequencesModule from './modules/sequence'
import dependenciesModule from './modules/dependencies'
import wellactivitiesModule from './modules/wellActivities'
import fundingsModule from './modules/funding'
import templatesModule from './modules/projectTemplates'
import budgetLineModule from './modules/budgetLine'
import budgetTemplateModule from './modules/budgetTemplates'
import nptModule from './modules/npt'
import projectModule from './modules/project'
import settingsModule from './modules/settings'
import ActivityModule from './modules/Activity'
import DailyReportModule from './modules/DailyReport'
import MilestoneModule from './modules/Milestone'
import BudgetModule from './modules/budget'
import AAR from './modules/AAR'
import Analytics from './modules/Analytics'
import Notifications from './modules/Notifications'
import UnitsModule from './modules/Unit'
import ApplicationLogModule from './modules/ApplicationLog'

Vue.use(Vuex)
  //state
  const state = {
    activeUser: null,
    activeUserAccess:null,
    rightPane: false,
    sidenav: true,
    activePane: '',
    currentRig: null,
    currentRiglessBucket:null,
    general: {},
    dynamicStart:new Date(),
    dynamicEnd: new Date()
  }

  interface IState {
    activeUser: any,
    activeUserAccess: any,
    rightPane: boolean,
    activePane: string,
    currentRig: any,
    sidenav: boolean,
    general: {},
    dynamicStart:Date,
    dynamicEnd:Date,
    currentRiglessBucket:any
  }

  // mutations
  const mutations = { 
    setActiveUser(state: IState, user:any){
      state.activeUserAccess = new Object();
        state.activeUser = user
        state.activeUser.access.forEach((access: any) => {
           state.activeUserAccess[access.category] = access.type;
        });
    },
    setRightPane(state: IState) {
      state.rightPane = !state.rightPane
    },

    setSideNav(state: IState) {
      state.sidenav = !state.sidenav
    },

    setRightPaneToTrue(state: IState) {
      state.rightPane = true;
    },
    setSideNavToFalse(state: IState) {
        state.sidenav = false;
    },

    setSideNavToTrue(state: IState) {
        state.sidenav = true;
    },

    setRightPaneToFalse(state: IState) {
      if(state.rightPane)state.rightPane = false
    },

    setActivePane(state: IState, payload: string) {
      state.activePane = payload;
    },

    setCurrentRig(state: IState, payload: any) {
      state.currentRig = payload
    },

    setCurrentRiglessBucket(state: IState, payload: any) {
      state.currentRiglessBucket = payload
    },
    
    setGeneral(state: IState, payload: any) {
      state.general = payload
    },

    setDynamicStart(state:IState,payload:Date){
      state.dynamicStart = payload
    },

    setDynamicEnd(state:IState,payload:Date){
      state.dynamicEnd = payload
    }

  }

  // getters
  const getters = {
    activeUser: (state: IState ) => {return state.activeUser},
    isAdmin: (state: IState ) => {return state.activeUser.roles[0] == "admin"},
    canCreateSequence: (state: IState ) => true,  
    getPaneStatus: (state: IState) => {
      return state.rightPane
    },
    getSideNavStatus: (state: IState) => {
      return state.sidenav
    },
    getActivePane: (state: IState) => {
      return state.activePane
    },
    getCurrentRig: (state: IState) => {
      return state.currentRig
    },
    getCurrentRigessBucket: (state: IState) => {
      return state.currentRiglessBucket
    },
    getGeneral(state: IState) {
      return state.general
    },
    getDynamicStartDate(state:IState){
      return state.dynamicStart
    },
    getDynamicEndDate(state:IState){
      return state.dynamicEnd
    }
  }

  // actions
  const actions = {

    logOut({ commit }: { commit: any}){
      return new Promise((resolve, reject) => {
         commit("setActiveUser", {roles:[],firstName:"",lastName:"",userName:"",access:[]})
          localStorage.dsodsessionid = ""
          localStorage.dsodsessiontoken = ""

          resolve(true)
        })
    },
  
    authenticateUser ({ commit }: { commit: any}, user: any){
      // commit('setStatus', 'loading')
      return new Promise((resolve, reject) => {
          HTTP.post('auth/token', user).then((response:any) => {
            let returnedUser = response.data.data.userData;
            let token = 'Bearer ' + response.data.data.token
            HTTP.defaults.headers.common['Authorization'] = token 
            localStorage.dsodsessiontoken = response.data.data.token
            if(!returnedUser.isFirstLogin) {
               localStorage.dsodsessionid = returnedUser.id
               commit('setActiveUser', returnedUser)                     
            }
            resolve({isFirstLogin:returnedUser.isFirstLogin}) 
          }).catch( (error: any) => {
            if(error.response.status=="400"){
              reject({message:"Invalid Login Credentials"})
            }
            if(error.response.status=="403"){
              reject({message:"Your Account is Locked"})
            }
            reject(error)
          })
        })
    }, 
  
  }

  const store = new Vuex.Store({
    state,
    mutations,
    getters,
    actions,
    modules: {
      usersModule,
      departmentModule,
      rigsModule,
      wellsModule,
      sequencesModule,
      dependenciesModule,
      wellactivitiesModule,
      fundingsModule,
      templatesModule,
      budgetLineModule,
      budgetTemplateModule,
      nptModule,
      projectModule,
      settingsModule,
      ActivityModule,
      MilestoneModule,
      BudgetModule,
      DailyReportModule,
      ApplicationLogModule,
      AAR,
      Analytics,
      Notifications,
      UnitsModule
    }
  })
  
  export default store

































































import targetform from "./targetForm";
import targetList from './targetList'
import Vue from 'vue'

export default Vue.extend({
    components: {
        'target-form': targetform,
        'target-list': targetList
    },
    name: 'project-scope',
    data() {
        return {
            objective:"",
            estimatedProduction:0,
            targets:[],
            isLoading: false,
            hasError: false,
            active_target: {},
            isViewingTargetList: true,
        }
    },
    methods: {
        get_display_name():string{
            if (this.project.well)
                return this.project.well.name;
            else
                return ""
        },
        edit_target(target:any) {
            this.isViewingTargetList = false;
            this.active_target = target
        },

        saveUpdate(target:any){
            this.$store.commit("projectModule/addOrUpdateTargetToProject",{sequenceId:this.selectedSequence.Id,id:this.project.id,target})
            this.isViewingTargetList =true
        },

        add_new_target(target:any) {
            this.$store.commit("projectModule/addOrUpdateTargetToProject",{sequenceId:this.selectedSequence.Id,id:this.project.id,target})
            this.isViewingTargetList = true
        },

        deleteTarget(target:any){
            this.$store.commit("projectModule/deleteTargetInProject",{sequenceId:this.selectedSequence.Id,id:this.project.id,target})
        },

        create_target() {
            this.isViewingTargetList = false;
            this.active_target = {}
        },

        onObjectiveChanged(newVal:any){
            this.$store.commit("projectModule/updateProject",{sequenceId:this.selectedSequence.Id,id:this.project.id,objective:newVal})
        },

        onEstimatedProductionChanged(newVal:any){
            this.$store.commit("projectModule/updateProject",{sequenceId:this.selectedSequence.Id,id:this.project.id,estimatedProduction:newVal})
        }
    },
    computed: {
        isValid():boolean {
            return !this.isLoading && !this.hasError && this.project.well;
        },
        selectedSequence():any {
            return this.$store.getters['projectModule/getSelectedSequence']
        },
        project():any{
            return this.$store.getters["projectModule/getSelectedProject"]
        }
    },
    watch:{
        project:{
            deep:true,
            immediate:true,
            handler(){
                this.estimatedProduction = this.project.estimatedProduction
                this.objective = this.project.objective
                this.targets = this.project.targets == null?[]:this.project.targets
            }
        }
    }
})





















































import Vue from 'vue'
import { Component, Mixins } from 'vue-property-decorator';
import EventBus from './../../eventBus'
import UserProVisioning from '@/mixins/userProvisioning'
import {Watch} from 'vue-property-decorator'

@Component ({
    
})
export default class Container extends Mixins(UserProVisioning){
    //Data
    assetManagementRoutes:Array<any> = [
        {
            caption:"Users",
            icon: 'users',
            link:"/users"
        },
        {
            caption:"Departments",
            icon: 'building',
            link:"/departments"
        },
        {
            caption:"Rigs",
            icon: 'spinner',
            link:"/rigs"
        },
        {
            caption:"Wells",
            icon: 'spinner',
            link:"/wells"
        },
        {
            caption:"Activities & NPT",
            icon: 'tasks',
            link:"/operations"
        }
    ]

    budgetRoutes:Array<any> = [
        {
            caption:"Budget Lines",
            icon: 'bolt',
            link:"/budgeting-lines"
        },
        {
            caption:"Budget Templates",
            icon: 'file',
            link:"/budget-templates"
        },
        {
            caption:"Funding Mechanism",
            icon: 'dollar-sign',
            link:"/fundings"
        }
    ]

    get getSettingsRoutes(){
       if(this.canEditSettings){
        return [
        {
            caption:"Chart Settings",
            icon: 'cog',
            link:"/chart-settings"
        },
        {
            caption:"Unit Settings",
            icon: 'cog',
            link:"/unit-settings"
        },
        {
            caption:"Application Log",
            icon: 'list',
            link:"/application-log"
        }
        ]
       }
       return [];
    }
    //computed
    get sidenav () {
        return this.$store.getters['getSideNavStatus']
    }

    get unreadNotifications (){
        return this.$store.getters["Notifications/getNumberOfUnreadNotifications"]
    }

    toggleSideNav(){
        this.$store.commit('setSideNav')
    }

    mounted(){
          this.$store.dispatch('UnitsModule/getUnits')
          this.$store.dispatch('UnitsModule/getUnitSystem')
          this.$store.dispatch('settingsModule/fetchSettings')
        if(this.$store.getters["Notifications/isNotificationEmpty"])this.$store.dispatch("Notifications/getNotificaionsAction")
    }
}

import { stat } from 'fs'
import { HTTP } from '../../axios_api/http-common'
import {IUnit} from '../../types/Unit'
import {IQuantity} from '../../types/quantity'
import Quantity from '../../quantity.ts'
import Unit from '../../units.ts'
import unitManager from '../../unitManager.ts'

interface IState {
    units: Array<InstanceType<typeof Unit>>,
    quantities: Array<InstanceType<typeof Quantity>>
    currentUnitSystem:string
}

const state = {
    units:[],
    currentUnitSystem:"si"
}
const getters={
    getRigByQuantity: (state: IState) => (quantity: number) => {
        if(state.currentUnitSystem=="si"){
            return "m"
        }else{
            return "ft"
        }
    },
    getCurrentUnitSystem (state: IState) {
        return state.currentUnitSystem
    },
    getUnits (state: IState) {
        return state.units
    },
    getQuantities (state: IState) {
        return state.quantities
    },
    getQuantityFromUnits(state: IState) {
        let arrayOfQuantities: Array<InstanceType<typeof Quantity>> = []
        let baseData = [
            {
                quantity: "pressure",
                baseSI: 'Pa',
                baseField: 'psia'
            },
            {
                quantity: "length",
                baseSI: 'm',
                baseField: 'ft'
            },
            {
                quantity: "power",
                baseSI: 'W',
                baseField: 'hp'
            },
            {
                quantity: "volume",
                baseSI: 'm3',
                baseField: 'bbl'
            },
            {
                quantity: "force",
                baseSI: 'N',
                baseField: 'lbf'
            },
            {
                quantity: "velocity",
                baseSI: 'm/s',
                baseField: 'ft/s'
            },
            {
                quantity: "area",
                baseSI: 'm2',
                baseField: 'ft2'
            },
            {
                quantity: "velocity",
                baseSI: 'm/h',
                baseField: 'ft/h'
            },
        ]
        state.units.forEach((unit: any) => {
            if (arrayOfQuantities.findIndex((el: any) => el.name === unit.quantity) < 0) {
                let quantity = new Quantity(unit.quantity, '', '')
                let data = baseData.find((data:any) => data.quantity === unit.quantity)
                if (data) {
                    quantity.baseSI = data.baseSI
                    quantity.baseField = data.baseField
                    arrayOfQuantities.push(quantity)
                }
            }
        })
        arrayOfQuantities.forEach((quantity: any) => {
            state.units.forEach((unit: any) => {
                if (unit.quantity === quantity.name) {
                    quantity.units.push(unit)
                }
            })
        })
        if(unitManager.quantities.length == 0) unitManager.quantities = arrayOfQuantities
        // window.console.log(JSON.stringify(arrayOfQuantities))
        return arrayOfQuantities
    }
}
const mutations={
    setUnits(state: IState, units: Array<InstanceType<typeof Unit>>){
        state.units = units
    },
    setQuantities(state: IState, quantities: Array<InstanceType<typeof Quantity>>){
        state.quantities = quantities
    },
    updateCurrentUnitSystem(state:IState,currentUnitSystem:string){
        state.currentUnitSystem = currentUnitSystem
    }
}
const actions={
    
    getUnits({ commit }: { commit: any }) {
        let si = ['m', 's', 'N', 'm2', 'Pa', 'W', 'kg', 'm3', 'm/h', 'kg/m3', 'm/s', 'm3/s', 'm3/d', 'm3/h', '1/Pa', 'ms2/kg', "pa-s"]
        let field = ['ft', 'hr', 'min', 'day', 'lbf', 'hp', 'ft2', 'ft/s', 'in2', 'acre', 'bbl/ft', 'mD', 'D', 'in3', 'ft3', 'bbl', 
            'galUk', 'L', 'psi', 'lbf/ft2', 'dynes/cm2', 'tonUS', 'bbl/d', 'lbm', 'lbm/galUS', 'ppb', 'lbm/ft3', 'psi/ft', 'ft/h', 'ft/min', 'ft/day', 'ft3/s', 'ft3/day', 'ft3/day', 'bbl/min', 'bbl/day', 'gal/min', 'gal/hr', 'gal/day', '1/psi', 'P', 'cP', 'st', 'm2/s', 'ft2/sec', 'ft2/hr', 'ft2/day', 'bbl/ft-hr', 'bbl/ft-day']
        let unitsToBeUsed = ['length', 'time', 'mass', 'force', 'volume rate flow', 'area', 'volume', 'power', 'pressure', 'density', 'speed', 'volume', 'compressibility', 'viscosity', 'diffusion']
        return new Promise((resolve, reject) => {
            HTTP.get('units')
            .then((response: any) => {
                // window.console.log(response.data.data)
                let mainUnits = response.data.data.filter((x:any)=>unitsToBeUsed.includes(x.quantity))
                let units = mainUnits.map((u:IUnit) => {
                    if (si.includes(u.symbol)) {
                        return new Unit(u.name, u.quantity, u.symbol, u.a, u.b, u.c, u.d, true, false)
                    } else if (field.includes(u.symbol)) {
                        return new Unit(u.name, u.quantity, u.symbol, u.a, u.b, u.c, u.d, false, true)
                    } else {
                        return new Unit(u.name, u.quantity, u.symbol, u.a, u.b, u.c, u.d, false, false)
                    }
                    
                })
                commit('setUnits', units)
                resolve(response)
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    },
    getUnitSystem({commit}:{commit:any}){
        return new Promise((resolve, reject) => {
            HTTP.get("appsettings/1")
            .then((response: any) => {
                resolve(response)
                commit("updateCurrentUnitSystem",response.data.data.value)
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    },
    updateCurrentUnitSystem({commit}:{commit:any},newUnitSystem:any){
        return new Promise((resolve, reject) => {
            HTTP.put('appsettings/'+'1',{key:"UNIT",value:newUnitSystem})
            .then((response: any) => {
                resolve(response)
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    }
}


const unitModule={
    namespaced:true,
    state,
    mutations,
    getters,
    actions
}


export default unitModule
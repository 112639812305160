











































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class MudProperties extends Vue {
  @Prop({}) 
  mudType!:any;
  @Prop({}) 
  mudWeight!:any;
  @Prop({}) 
  funnelViscosity!:any;
  @Prop({}) 
  eCD!:any;
  @Prop({}) 
  plasticViscosity!:any;
  @Prop({}) 
  yieldPoint!:any;
  @Prop({}) 
  gelStrength!:any;
  @Prop({}) 
  pitTemp!: any;
  @Prop({}) 
  hTHP!: any;
  @Prop({}) 
  cake!: any;
  @Prop({}) 
  solids!:any;
  @Prop({}) 
  dailyMudCost!:any;
  @Prop({}) 
  prevMudCost!:any;
  @Prop({}) 
  totalMudCost!:any;


  private get mudTypeInput() {
    return this.mudType;
  }
  private set mudTypeInput(value: any) {
    this.$emit("update:mudType", value);
  }
   private get mudWeightInput() {
    return this.mudWeight;
  }
  private set mudWeightInput(value: any) {
    this.$emit("update:mudWeight", value);
  }
   private get funnelViscosityInput() {
    return this.funnelViscosity;
  }
  private set funnelViscosityInput(value: any) {
    this.$emit("update:funnelViscosity", value);
  }
   private get eCDInput() {
    return this.eCD;
  }
  private set eCDInput(value: any) {
    this.$emit("update:eCD", value);
  }
  private get plasticViscosityInput() {
    return this.plasticViscosity;
  }
  private set plasticViscosityInput(value: any) {
    this.$emit("update:plasticViscosity", value);
  }
   private get yieldPointInput() {
    return this.yieldPoint;
  }
  private set yieldPointInput(value: any) {
    this.$emit("update:yieldPoint", value);
  }
   private get gelStrengthInput() {
    return this.gelStrength;
  }
  private set gelStrengthInput(value: any) {
    this.$emit("update:gelStrength", value);
  }
   private get pitTempInput() {
    return this.pitTemp;
  }
  private set pitTempInput(value:any) {
    this.$emit("update:pitTemp", value);
  }
     private get hTHPInput() {
    return this.hTHP;
  }
  private set hTHPInput(value:any) {
    this.$emit("update:hTHP", value);
  }
     private get cakeInput() {
    return this.cake;
  }
  private set cakeInput(value:any) {
    this.$emit("update:cake", value);
  }
  private get solidsInput() {
    return this.solids;
  }
  private set solidsInput(value:any) {
    this.$emit("update:solids", value);
  }
  private get dailyMudCostInput() {
    return this.dailyMudCost;
  }
  private set dailyMudCostInput(value:any) {
    this.$emit("update:dailyMudCost", value);
  }
  private get prevMudCostInput() {
    return this.prevMudCost;
  }
  private set prevMudCostInput(value:any) {
    this.$emit("update:prevMudCost", value);
  }
  private get totalMudCostInput() {
    return this.totalMudCost;
  }
  private set totalMudCostInput(value:any) {
    this.$emit("update:totalMudCost", value);
  }
}

import axios from 'axios';

export const $http: any = axios.create({
  headers: {'Access-Control-Allow-Origin': '*'},
   //baseURL: (process.env.NODE_ENV == 'production') ? 'http://sepmsvdi01:44332/api' : 'http://localhost:44332/api'
   baseURL: (process.env.NODE_ENV == 'production') ? 'http://cclapps.eastus.cloudapp.azure.com:44332/api' : 'http://cclapps.eastus.cloudapp.azure.com:44332/api'
})

// 'http://192.168.0.3:3000/'
export const HTTP: any = axios.create({
  headers: {'Access-Control-Allow-Origin': '*'},
  //baseURL: (process.env.NODE_ENV === 'production')? 'http://sepmsvdi01:44332/api' : 'http://localhost:44332/api'
  baseURL: (process.env.NODE_ENV == 'production') ? 'http://cclapps.eastus.cloudapp.azure.com:44332/api' : 'http://cclapps.eastus.cloudapp.azure.com:44332/api'
});                 

// https://192.168.0.3:44322/




import { HTTP } from '@/axios_api/http-common'

const state = {
    npt: []
}

interface IState {
    npt: any[]
}

const getters = {
    getAllNpt(state:IState) {
        return state.npt
    },
    getNptById:(state: IState) => (id: any) =>{
        let npt = null
        if(!state.npt)return;
        for (let index = 0; index < state.npt.length; index++) {
             if(state.npt[index].id == id) {
                npt = state.npt[index];
                 break;
             } 
        }
        return npt
    },
}

const mutations = {
    createNewNpt(state: IState, payload: any) {
        state.npt.push(payload)
    },
    setNptOperations(state: IState, payload: any) {
        state.npt = payload
    },
    updateNpt(state: IState, payload: any) {
        var indexToUpdate: number | null = 0
        state.npt.forEach((nptItem: any, index: any, budgetLineArray: any) => {
            if (payload.id === nptItem.id) {
                indexToUpdate = index
            }
        })
        state.npt.splice(indexToUpdate, 1, payload)
    },
    deleteNPTOperation(state: IState, id: number){
        for (let index = 0; index < state.npt.length; index++) {
            if(state.npt[index].id == id) {
                state.npt.splice(index,1)
                break;
            }
       }
    },
}

const actions = {
    createNptOperation({commit} : {commit: any}, payload: any) {
        return new Promise ((resolve, reject) => {
            HTTP.post('NPTOperation/create', payload).then((response: any) => {
                commit('createNewNpt', response.data.data)
                resolve(response)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    fetchNptOperation({commit} : {commit:any}) {
        return new Promise((resolve, reject) => {
            HTTP.get('NPTOperation').then((response: any) => {
                commit('setNptOperations', response.data.data)
                resolve(response)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    updateNPTOperation({commit}: {commit: any}, payload: any) {
        return new Promise ((resolve, reject) => {
            HTTP.put('NPTOperation/update/'+ payload.id, payload).then((response: any) => {
                commit('updateNpt', payload)
                resolve(response)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    deleteNPTOperationAction({commit}: {commit: any}, payload: any){
        return new Promise ((resolve, reject) => {
            HTTP.delete('NPTOperation/delete/' + payload.id).then((response: any) =>{
                resolve(response)
                commit('deleteNPTOperation', payload.id)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
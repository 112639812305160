import PptxGenJS from "pptxgenjs";

const convertToInches = function (val: number) {
    return val / 160;
}

const printHeatMap = function (sequenceName: any, tableID: string,forMail = false) {
    const currentDate = new Date();
    //variable used in position calculation
    const margin = convertToInches(30);
    const headerHieght = convertToInches(30);
    
    var masterObjects: any = [];
    var pptx: any = new PptxGenJS();


    pptx.tableToSlides(tableID, {
        addText: { text: sequenceName+" Heat Map", options: { x: 7, y: 0.2, color: "#000000", fontSize: 15, w: '50%' } }
    })

    var pptName = sequenceName + " Heat Map"
    if(forMail){
        return pptx.write('blob')
    }else{
        pptx.writeFile(pptName);
    }
}

export default printHeatMap
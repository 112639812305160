


















import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';

@Component
export default class RigLessBucketInfo extends Vue  {
    get riglessBucket () {
        return this.$store.getters['getCurrentRigessBucket']
    }
    takeRightPane() {
        return this.$store.commit('setRightPaneToFalse')
    }
}






































































import Vue from 'vue'
import Component from 'vue-class-component'
import EventBus from './../../eventBus'
import { required } from 'vuelidate/lib/validators'
import { Validations } from 'vuelidate-property-decorators'

@Component
export default class ManageFunding extends Vue {
    @Validations()
    validations = {
        funding: {
            name: { required },
            shortCode: { required },
            lineStyle: { required }
        }
    }
    activeScreen: string = 'main'
    loadingMessage: string = 'Please wait while we create new funding'
    loadingTitle: string = 'Saving...'
    additionalSuccessMessage: string = ''
    successMessage: string = 'New Funding Created Successfully'
    links: Array<object> = [
        {
            text: 'Fundings',
            to: '/fundings'
        },
        {
            text: 'Manage Fundings',
            active: true
        }
    ]
    error: any = {
        generalMessage: '',
        errors: []
    }
    lineStyles: Array <any> = [
        {text:'Solid',value:'Solid'}, 
        {text:'Dashed', value:'Dashed'},
        {text:'Dotted', value:'Dotted'}
    ]
    funding: any = {
        name: '',
        lineColor: '',
        lineStyle: 'Solid',
        shortCode: ''
    }
    activeItem: any = null
    formErrors: string[] = []

    get checkLineStyle (): boolean {
        if (!this.funding.lineStyle) {
            return false
        } else {
            return true
        }
    }


    // METHODS
    checkErrors() {
        this.formErrors = []

        if(this.funding.name.length < 1) {
            this.formErrors.push('Funding Name is Required')
        }
        if(this.funding.shortCode.length < 1) {
            this.formErrors.push('Funding Short Code is Required')
        }
        if(!this.checkLineStyle){
            this.formErrors.push('Select a Funding line style')
        }
        if(!this.funding.lineColor.hex) {
            this.formErrors.push("Select a Funding line color")
        }
    }
    
    newActivity(){
        this.funding.name = '';
        this.funding.shortCode = '';
        this.$v.$reset()
    }

    persistData(){
        this.activeScreen = 'loading';
        EventBus.$emit('activeScreen', this.activeScreen)        
        if(this.activeItem == null)
            this.saveNew();
        else
            this.update();
    }

    update(){
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.loadingMessage = 'Please wait while we update this funding'
        this.loadingTitle = 'Saving...'
        var payload = this.createPayload()
        this.$store.dispatch('fundingsModule/updateFundingAction', payload)
        .then((res: any) => 
            {
                this.activeScreen = 'successNoGotIt'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.additionalSuccessMessage = ''
                this.successMessage = 'Funding Updated Successfully'
            }
        ).catch((err: any) => {
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error.generalMessage = err.message
        })
    }
 
    createPayload() {
        return {
            name: this.funding.name,
            id: this.activeItem ? this.activeItem.id : 0,
            lineColor:  typeof(this.funding.lineColor)=='string'?this.funding.lineColor:this.funding.lineColor.hex,
            code: this.funding.shortCode,
            lineStyle: this.funding.lineStyle,
        }
    }

    saveNew(){
        var payload = this.createPayload()
        this.$store.dispatch('fundingsModule/saveNewFunding', payload)
        .then((res: any) =>
            {
                this.activeScreen = 'successRemain'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.additionalSuccessMessage = 'You can now use the newly created funding'
                this.successMessage = 'Successfully Created '+ payload.name
                this.newActivity()
            }
        ).catch((err: any) => {
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error.generalMessage = err.message
        })
    }



    // VUEX METHODS




    // COMPUTED PROPERTIES
    get getFunding () {
        return this.$store.getters['fundingsModule/getFunding']
    }
    
    get isButtonDisabled(): boolean {
        if (this.formErrors.length > 0) {
            return true
        } else {
            return false
        }
    }



    // CREATED
    created() {
        if(this.$route.query.id){
            this.activeItem = this.getFunding(this.$route.query.id)
            if(!this.activeItem){
                this.$store.dispatch("fundingsModule/fetchFundings").then(()=>{

                    this.activeItem = this.getFunding(this.$route.query.id)

                    this.funding.name = this.activeItem.name,
                    this.funding.lineColor = '#' + this.activeItem.lineColor;
                    this.funding.shortCode = this.activeItem.code;
                    this.funding.lineStyle = this.activeItem.lineStyle;

                })
            }else{
                this.funding.name = this.activeItem.name,
                this.funding.lineColor = '#' + this.activeItem.lineColor;
                this.funding.shortCode = this.activeItem.code;
                this.funding.lineStyle = this.activeItem.lineStyle;
            }
        }else{
            this.checkErrors()
        }
    }
}



















































import Vue from "vue"
import {Component, Prop, Watch} from 'vue-property-decorator'

@Component({})
export default class AnalyticsDataSource extends Vue {
    selectedActivities:Array<any> = []
    selectedMilestones:Array<any> = []
    backColor: string = ''

    get sequences():Array<any>{
        const sequences = this.$store.getters["sequencesModule/getSequences"]
        if(sequences==null||sequences==undefined) return []
        return sequences.filter((s:any) => s.isRoot)
    }

    get sequenceOptions():Array<any>{
        if(this.sequences) return this.sequences.map((s:any)=>({value:s.id,text:s.name}))
        return []
    }

    get projects():Array<any>{
        if(!this.selectedSequence)return []
        let projects = this.$store.getters["projectModule/getProjectsForSequence"](this.selectedSequence)
        if(projects.length<1){
            this.$store.dispatch("projectModule/getProjectsForSequence",this.selectedSequence)
            return []
        }
        return projects
    }

    get activeAnalyticsElement(){
        window.console.log(this.$store.state.Analytics.activeAnalyticsElements)
        return this.$store.state.Analytics.activeAnalyticsElements
    }

    get selectedProjects():Array<any>{
        return this.$store.state.Analytics.selectedProjects
    }

    set selectedProjects(value){
        this.$store.commit("Analytics/updatedSelectedProjects",value)
    }

    get selectedSequence():any{
        return this.$store.state.Analytics.selectedSequence
    }

    set selectedSequence(value){
        this.$store.commit("Analytics/updateSelectedSequence",value)
    }

    sequenceSelectionChanged(){
        this.selectedProjects = []
    }

    closePropertyGrid(){
        this.$store.commit("setRightPaneToFalse")
    }

    changeBack() {
        let back = this.backColor as any
        this.$store.commit('Analytics/updatedBackgroundColor', back.hex)
    }
    
    mounted(){
        if(!this.sequences||this.sequences.length<1)this.$store.dispatch("sequencesModule/fetchSequences")
    }
}

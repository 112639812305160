














































































































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import EventBus from './../../../eventBus'
import { BModal } from 'bootstrap-vue'
import {Sequence} from '../../../types/Sequence'
import {ComboboxOption} from '../../../types/ComboboxOption'
import {CheckedOption} from '../../../types/CheckedOption'
import {Department} from '../../../types/Department'
import {Rig} from '../../../types/Rig'
import settings from '../../../store/modules/settings'
import { required } from "vuelidate/lib/validators"; 
import { Validations } from 'vuelidate-property-decorators';

@Component
export default class SequenceForm extends Vue {
    @Validations()
    validations = {
        sequence: {
            name: { required }
        }
    }
    @Prop ({type: Object}) formData!: Sequence
    @Prop ({type: String, default: 'Create'}) okTitle!: String
    form = false
    rigless:any = {
        alias: '',
        description: '',
        color: {
            hex: ''
        }
    }
    titleString = this.okTitle==='Create'?'Create Sequence':'Edit Sequence'
    message = ''
    errorMessage = ''
    title = ''
    additionalSuccessMessage = ''
    successMessage = ''
    annualizedBudgets :Array<any>= []
    search = ''
    colorPicker:any = {
        color:'',
        activeIndex:-1
    }
    sequence: Sequence = {
        id:"",
        name: '',          
        rigIds: [],
        rigs:[],
        departmentIds: [],
        departments:[],
        startYear: new Date().getFullYear(),
        endYear: new Date().getFullYear(),
        description: '',
        annualizedBudgets:[],
        riglessBuckets:[],
        userId:0,
        cloneId:0
    }
    riglessUnits : {alias:string; description:string; color:string}[] = []
    riglessFields = [
        {label: 'S/N', key: 'index'},
        {label: 'Alias', key: 'alias'},
        {label: 'Description', key: 'description'},
        {label: 'Color', key: 'color'},
    ]

    years: Array<ComboboxOption> = []
    activeForm: string = "General"

    checkAllRigs: boolean = false
    checkAllDepts: boolean = false
    deptOptions:Array<CheckedOption> = []
    rigOptions:Array<CheckedOption> = []

    riglessBucketFields:Array<any> = [
        {
            key:'alias',
            label:'Name'
        },
        "color",
        "description",
        "actions"
    ]

    close () {
        let form = this.$refs.form as BModal
        form.hide()        
    }

    @Watch('sequence.startYear',{immediate:true})
    startYearWatcher(val: number, oldVal: number) {
        var yearsToAdd:Array<any> = []
        for(let i=val;i<=this.sequence.endYear;i++){
            let isPresent = false
            this.annualizedBudgets.forEach((thisAnnualizedBudget,index) => {
                if(thisAnnualizedBudget.year>=val&&thisAnnualizedBudget.year<=this.sequence.endYear){
                    this.annualizedBudgets[index].isProcessed = true
                }else{
                    this.annualizedBudgets[index].isProcessed  = false
                }
                if(thisAnnualizedBudget.year==i)isPresent = true
            })
            if(!isPresent)yearsToAdd.unshift({year:i,amount:0,isProcessed:true})
        }
        yearsToAdd.forEach(thisYearToAdd => this.annualizedBudgets.unshift(thisYearToAdd))
    }
    
    @Watch('sequence.endYear',{immediate:true})
    endYearWatcher(val: number, oldVal: number) {
        var yearsToAdd:Array<any> = []
        for(let i=this.sequence.startYear;i<=val;i++){
            let isPresent = false
            this.annualizedBudgets.forEach((thisAnnualizedBudget,index) => {
                if(thisAnnualizedBudget.year>=this.sequence.startYear&&thisAnnualizedBudget.year<=val){
                    this.annualizedBudgets[index].isProcessed = true
                }else{
                    this.annualizedBudgets[index].isProcessed  = false
                }
                if(thisAnnualizedBudget.year==i)isPresent = true
            })
            if(!isPresent)yearsToAdd.push({year:i,amount:0,isProcessed:true})
        }
        yearsToAdd.forEach(thisYearToAdd => this.annualizedBudgets.push(thisYearToAdd))
    }

    addRiglesBucket(){
        if(this.sequence.riglessBuckets){
            this.sequence.riglessBuckets.push({
                alias:"",
                color:"696969",
                description:""
            })
        }
    }
    
    onCheckAllDeptsChanged(value:boolean){
        this.deptOptions.forEach(thisDept => {
            thisDept.selected = value
        })
    }

    addRigless(){
        this.riglessUnits.push({
            alias: this.rigless.alias,
            description: this.rigless.description,
            color: this.rigless.color.hex
        })
        this.rigless.alias = ''
        this.rigless.description = ''
        this.rigless.color = ''
    }


    removeBucket(index:number){
        if(this.sequence.riglessBuckets){
            this.sequence.riglessBuckets.splice(index,1)
        }
    }

    showColorPicker(activeIndex:number){
        this.colorPicker.activeIndex = activeIndex
    }

    hideColorPicker(){
        if(this.sequence.riglessBuckets!=undefined){
            this.sequence.riglessBuckets[this.colorPicker.activeIndex].color = this.colorPicker.color.hex.substring(1,this.colorPicker.color.hex.length)
            this.colorPicker.activeIndex = -1
        }
    }

    onDeptOptionChanged(value:boolean){
        this.checkAllDepts = this.deptOptions.every(thisDept => thisDept.selected)
    }

    onCheckAllRigsChanged(value:boolean){
        this.rigOptions.forEach(thisRig => {
            thisRig.selected = value
        })
    }

    onRigOptionChanged(value:boolean){
        this.checkAllRigs = this.rigOptions.every(thisRig => thisRig.selected)
    }

    createDeptOptions(allDepts:Array<Department>){
        this.deptOptions = allDepts.map(thisDept => {
            let selectionState = false
            if(this.sequence.departments&&this.sequence.departments.findIndex(thisSequenceDept => thisSequenceDept.id == thisDept.id)>-1) selectionState = true
            return {
                text:thisDept.name,
                value:thisDept.id,
                selected:selectionState
            }
        })
        this.checkAllDepts = this.deptOptions.every(thisDeptOption => thisDeptOption.selected)
    }

    createRigOptions(allRigs:Array<Rig>) {
        this.rigOptions = allRigs.map(thisRig => {
            let selectionState = false
            if(this.sequence.rigs&&this.sequence.rigs.findIndex(thisSequenceRig => thisSequenceRig.id == thisRig.id)>-1) selectionState = true
            return {
                text:thisRig.name,
                value:thisRig.id,
                selected:selectionState
            }
        })
        this.checkAllRigs = this.rigOptions.every(thisRigOption => thisRigOption.selected)
    }
    
    get selectDept () {
        return this.deptOptions.filter(thisDeptOption => thisDeptOption.selected)
    }
    
    get selectedRiigs () {
        return this.rigOptions.filter(thisRigOption => thisRigOption.selected)
    }

    get filteredDepartmentOptions () {
        return this.deptOptions.filter((result: any) => {
            return result.text.toUpperCase().match(this.search.toUpperCase())
        })
    }

    get filteredRigOptions () {
        return this.rigOptions.filter((result: any) => {
            return result.text.toUpperCase().match(this.search.toUpperCase())
        })
    }

    get activeUser () {
        return this.$store.getters['activeUser']
    }

    get isAdmin () {
        return this.$store.getters['isAdmin']
    }
    
    updateAction (sequence: any) {
        return this.$store.commit('sequencesModule/update', sequence)
    }

    addStore(sequence: any) {
        return this.$store.commit('sequencesModule/add', sequence)
    }

    create() {
        this.title = 'Saving...'
        this.titleString = "Sequence Creation"
        this.message = "Please wait while we create new sequence."
        this.successMessage = 'Successfully Created '+ this.sequence.name
        this.additionalSuccessMessage = 'You can now use the newly created sequence'

        this.sequence.userId = this.activeUser.id;
        this.sequence.rigIds = this.rigOptions.filter(thisRigOption => thisRigOption.selected).map(thisSelectedRig => thisSelectedRig.value)
        this.sequence.departmentIds = this.deptOptions.filter(thisDeptOption => thisDeptOption.selected).map(thisSelectedDeptOption => thisSelectedDeptOption.value)
        this.sequence.annualizedBudgets = this.annualizedBudgets.filter(thisAnnualizedBudget => thisAnnualizedBudget.isProcessed)

        this.activeForm = 'Loading'
        this.$store.dispatch('sequencesModule/create', this.sequence)
             .then((response: any) => {
                 this.activeForm = 'Success'
        })
        .catch((error: any) => {
             this.errorMessage = error
             this.activeForm = 'Error'
             this.$emit('notSuccessful', 'error', error )
        })
    }

    edit() {
        this.titleString = "Sequence Update"
        this.title = 'Updating...'
        this.message = "Please wait while we update new sequence."
        this.successMessage = 'Sequence Upadted Successfully'
        this.additionalSuccessMessage = ''
        
        this.activeForm = 'Loading'

        this.sequence.rigIds = this.rigOptions.filter(thisRigOption => thisRigOption.selected).map(thisSelectedRig => thisSelectedRig.value)
        this.sequence.departmentIds = this.deptOptions.filter(thisDeptOption => thisDeptOption.selected).map(thisSelectedDeptOption => thisSelectedDeptOption.value)
        this.sequence.annualizedBudgets = this.annualizedBudgets.filter(thisAnnualizedBudget => thisAnnualizedBudget.isProcessed)
         this.sequence.userId = this.activeUser.id;
        this.$store.dispatch('sequencesModule/edit', this.sequence)
        .then((response: any) => {
            this.activeForm = 'Success'
        })
        .catch((error: any) => {
            this.errorMessage = error
            this.activeForm = 'Error'     
        })
    }

    clone(){
        this.sequence.userId = this.activeUser.id;
        this.$store.dispatch('sequencesModule/clone', this.sequence)
            .then((response: any) => {
        })
        .catch((error: any) => {
        })
    }

    action () {
        if (this.okTitle === 'Create') {
            this.create()
        } else if (this.okTitle === 'Edit') {
            this.edit()
        } else {
            this.clone()
        }
    }

    getRigs(){
        let allRigs = this.$store.getters['rigsModule/getAllrigs']
        //Get all rigs and convert to rig options
        if(allRigs.length == 0){
            this.$store.dispatch('rigsModule/getRigs').then(r => {
                allRigs = this.$store.getters['rigsModule/getAllrigs']
                this.createRigOptions(allRigs)
            })
        }else{
            this.createRigOptions(allRigs)
        }
        //end of get all rigs
    }

    getDepts(){
        let allDepts = this.$store.getters['departmentModule/getAllDepartments']
        //Get all departments and convert to dept options
        if(allDepts.length == 0){
            this.$store.dispatch('departmentModule/getDepartments').then(d => {
                allDepts = this.$store.getters['departmentModule/getAllDepartments']
                this.createDeptOptions(allDepts)
            })
        }else{
            this.createDeptOptions(allDepts)
        }
        //end of get all depts
    }

    created () {
        var thisYear = new Date(Date.now()).getFullYear();
        for (let index = thisYear - 5; index < thisYear + 20; index++) {
            this.years.push({text:index.toString(), value: index})
        }
        if(this.formData.annualizedBudgets){
            this.sequence = JSON.parse(JSON.stringify(this.formData));
            this.annualizedBudgets = this.formData.annualizedBudgets.map(thisAnnualizedBudget => {return {year:thisAnnualizedBudget.year,amount:thisAnnualizedBudget.amount,isProcessed:true}})
        }

        this.getRigs()
        this.getDepts()

    }
} 

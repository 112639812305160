








































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import UnitConverter from '../../mixins/unitConverter.ts'

@Component({
  mixins: [UnitConverter]
})
export default class MudVolume extends Vue {
  @Prop({}) 
  volTotMudStart!:any;
  @Prop({}) 
  volMudDumped!:any;
  @Prop({}) 
  volMudReceived!:any;
  @Prop({}) 
  volMudReturned!:any;
  @Prop({}) 
  volMudBuilt!:any;
  @Prop({}) 
  volMudString!:any;
  @Prop({}) 
  volMudCasing!:any;
  @Prop({}) 
  volMudHole!:any;
  @Prop({}) 
  volMudRiser!:any;

  private get volTotMudStartInput() {
    return this.volTotMudStart;
  }
  private set volTotMudStartInput(value:any) {
    this.$emit("update:volTotMudStart", value);
  }
  private get volMudDumpedInput() {
    return this.volMudDumped;
  }
  private set volMudDumpedInput(value: any) {
    this.$emit("update:volMudDumped", value);
  }
  private get volMudReceivedInput() {
    return this.volMudReceived;
  }
  private set volMudReceivedInput(value: any) {
    this.$emit("update:volMudReceived", value);
  }
  private get volMudReturnedInput() {
    return this.volMudReturned;
  }
  private set volMudReturnedInput(value: any) {
    this.$emit("update:volMudReturned", value);
  }
  private get volMudBuiltInput() {
    return this.volMudBuilt;
  }
  private set volMudBuiltInput(value: any) {
    this.$emit("update:volMudBuilt", value);
  }
  private get volMudStringInput() {
    return this.volMudString;
  }
  private set volMudStringInput(value: any) {
    this.$emit("update:volMudString", value);
  }
  private get volMudCasingInput() {
    return this.volMudCasing;
  }
  private set volMudCasingInput(value: any) {
    this.$emit("update:volMudCasing", value);
  }
  private get volMudHoleInput() {
    return this.volMudHole;
  }
  private set volMudHoleInput(value: any) {
    this.$emit("update:volMudHole", value);
  }
    private get volMudRiserInput() {
    return this.volMudRiser;
  }
  private set volMudRiserInput(value: any) {
    this.$emit("update:volMudRiser", value);
  }
}





































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'

@Component
export default class BaseFormComponent extends Vue {
    @Prop()
    private formColumn !: number

    @Prop({default: true})
    private isShow !: number

    @Prop()
    private listColumn !: number

    @Prop()
    private items !: any[]

    @Prop()
    private nameOfEachItem !: string
}

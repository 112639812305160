import { HTTP } from '@/axios_api/http-common' 
import { Project } from '../../types/Project'
import SwapStates from '../../utils/SwapState.js'
import Vue from 'vue'
import store from "@/store/index"

const state = {
    projects: {},
    projectChanges:[],
    activeSequence:{Id:"",readOnly:true,viewMode:"rig"},
    activeProjectId:"",
    historyMode:false,
    swapState:SwapStates.IDLE,
    updatedProjectId:{old:"",new:""},
    projectState: []
}

interface IState {
    projects: Record<string, Array<any>>,
    projectChanges:Array<any>
    activeSequence:any,
    activeProjectId:string,
    historyMode:boolean,
    swapState:string,
    updatedProjectId:any,
    projectState: Array<any>
}


function convertProjectToProjectModel(projectToConvertId:any,sequenceId:any):any{
    
    let projectToConvert:any = (state as IState).projects[sequenceId].find((thisProject:any) => thisProject.id == projectToConvertId)
    let projectModel:any = {}

    projectModel.id = projectToConvert.id
    projectModel.sequenceId = sequenceId
    projectModel.name = projectToConvert.name
    projectModel.wellId = projectToConvert.well.id;
    projectModel.start = projectToConvert.start
    projectModel.end = projectToConvert.end
    projectModel.objective = projectToConvert.objective
    projectModel.estimatedProduction = projectToConvert.estimatedProduction
    projectModel.targets = projectToConvert.targets
    projectModel.preProcesses = projectToConvert.preProcesses
    projectModel.projectType = projectToConvert.projectType
    if(projectToConvert.isRigless){
        projectModel.riglessBucketId = projectToConvert.riglessBucket.id
    }else{
        projectModel.rigId = projectToConvert.rig.id
    }
    projectModel.isRigless = projectToConvert.isRigless


    if(projectToConvert.funding) projectModel.FundingMechanismId = projectToConvert.funding.id
    return projectModel
    
}


const mutations = {
    setProjects (state: IState, data:any) {
        Vue.set(state.projects,data.sequenceId,data.data)
    },
    setSwapState(state:IState,data:any){
        state.swapState = data
    },

    setActiveSequence(state:IState,payload:any){
        state.activeSequence.Id = payload.sequenceId
        state.activeSequence.readOnly = payload.readOnly
        state.activeSequence.viewMode = payload.viewMode
    },

    setActiveProject(state:IState,projectId:string){
        state.activeProjectId = projectId
    },

    updateNewProjects(state:IState,data:any){
        for(var key in state.projects){
            state.projects[key].forEach((thisProject,index,projects) => {
                let indexOfUpdate = data.data.findIndex((thisUpdate:any) => thisUpdate.data.tempKey == thisProject.id)
                if(indexOfUpdate>-1){
                    let projectUpdate = data.data[indexOfUpdate].data
                    projectUpdate.activities = thisProject.activities
                    Vue.set(state.projects[key],index,projectUpdate)
                    state.updatedProjectId = {new:data.data[indexOfUpdate].data.id,old:data.data[indexOfUpdate].data.tempKey}
                    if(state.activeProjectId == thisProject.id) state.activeProjectId = projectUpdate.id
                }
           })
        }
    },

    updateProjects(state:IState, data:any){
        for(var key in state.projects){
            state.projects[key].forEach((thisProject,index,projects) => { 
                let indexOfUpdate = data.data.findIndex((thisUpdate:any) => thisUpdate.id == thisProject.id)
                if(indexOfUpdate>-1){
                    let projectUpdate = data.data[indexOfUpdate].data
                    projectUpdate.activities = state.projects[key][indexOfUpdate].activities
                    Vue.set(state.projects[key],index,projectUpdate)
                }
            })
        }
    },

    addProject(state:IState, projectToAdd:any){
        let tempId = Math.abs((Math.floor(Math.random() * (10 - +4000))  +10)+(Math.floor(Math.random() * (10 - +4000))  +10)+(Math.floor(Math.random() * (10 - +4000)))); 
        projectToAdd.id = tempId
        state.projects[projectToAdd.sequenceId].push(projectToAdd)
        if(store.getters.activeUser.permissions.includes("wellplanner")){
            state.projectChanges.push({
                id:tempId,
                action:"CREATE",
                sequenceId:projectToAdd.sequenceId
            })
           }
    },

    updateProject (state:IState, projectToUpdate:any){
        let existingChangeIndex = state.projectChanges.findIndex(thisProjectUpdate => thisProjectUpdate.id == projectToUpdate.id)
        let projectIndex:number = state.projects[projectToUpdate.sequenceId].findIndex(thisProject => thisProject.id == projectToUpdate.id)

        for(let key in projectToUpdate){
            if(key=="id"||key=="sequenceId")continue
            state.projects[projectToUpdate.sequenceId][projectIndex][key] = projectToUpdate[key]
        }
       
        if(existingChangeIndex<0){
            if(store.getters.activeUser.permissions.includes("wellplanner")){
                state.projectChanges.push({
                    id:projectToUpdate.id,
                    action:"UPDATE",
                    sequenceId:projectToUpdate.sequenceId
                })
            }
        }

    },

    addOrUpdateTargetToProject(state:IState,data:any){
        
        let existingChangeIndex = state.projectChanges.findIndex(thisProjectUpdate => thisProjectUpdate.id == data.id)
        let projectIndex:number = state.projects[data.sequenceId].findIndex(thisProject => thisProject.id == data.id)
        let tempId = Math.abs((Math.floor(Math.random() * (10 - +4000))  +10)+(Math.floor(Math.random() * (10 - +4000))  +10)); 

        if(typeof state.projects[data.sequenceId][projectIndex].targets =='undefined'||state.projects[data.sequenceId][projectIndex].targets==null){
            state.projects[data.sequenceId][projectIndex].targets = []
            data.target.id = tempId
            state.projects[data.sequenceId][projectIndex].targets.unshift(data.target)
        }else{
            let existingTargetIndex = state.projects[data.sequenceId][projectIndex].targets.findIndex((t:any) => data.target.id == t.id)
            if(existingTargetIndex>-1){
                state.projects[data.sequenceId][projectIndex].targets[existingTargetIndex] = data.target
            }else{
                data.target.id = tempId
                state.projects[data.sequenceId][projectIndex].targets.unshift(data.target)
            }
        }
       
        if(existingChangeIndex<0){
            state.projectChanges.push({
                id:data.id,
                action:"UPDATE",
                sequenceId:data.sequenceId
            })
        }

    },


    addOrUpdatePlanningItemToProject(state:IState,data:any){
        let existingChangeIndex = state.projectChanges.findIndex(thisProjectUpdate => thisProjectUpdate.id == data.id)
        let projectIndex:number = state.projects[data.sequenceId].findIndex(thisProject => thisProject.id == data.id)
        let tempId = Math.abs((Math.floor(Math.random() * (10 - +4000))  +10)+(Math.floor(Math.random() * (10 - +4000))  +10)); 

        if(typeof state.projects[data.sequenceId][projectIndex].preProcesses =='undefined'||state.projects[data.sequenceId][projectIndex].preProcesses==null){
            state.projects[data.sequenceId][projectIndex].preProcesses = []
            data.preProcess.id = tempId
            state.projects[data.sequenceId][projectIndex].preProcesses.unshift(data.preProcess)
        }else{
            let existingPreProcessIndex = state.projects[data.sequenceId][projectIndex].preProcesses.findIndex((p:any) => data.preProcess.id == p.id)
            if(existingPreProcessIndex>-1){
                state.projects[data.sequenceId][projectIndex].targets[existingPreProcessIndex] = data.preProcess
            }else{
                data.preProcess.id = tempId
                state.projects[data.sequenceId][projectIndex].preProcesses.unshift(data.preProcess)
            }
        }
       
        if(existingChangeIndex<0){
            state.projectChanges.push({
                id:data.id,
                action:"UPDATE",
                sequenceId:data.sequenceId
            })
        }
    },

    deleteTargetInProject(state:IState,data:any){
        let existingChangeIndex = state.projectChanges.findIndex(thisProjectUpdate => thisProjectUpdate.id == data.id)
        let projectIndex:number = state.projects[data.sequenceId].findIndex(thisProject => thisProject.id == data.id)
        let indexToSplice = -1
        state.projects[data.sequenceId][projectIndex].targets.forEach((t:any,index:number) => {
            if(t.id == data.target.id){
                indexToSplice = index
            }
        })
        state.projects[data.sequenceId][projectIndex].targets.splice(indexToSplice,1)
        if(existingChangeIndex<0){
            state.projectChanges.push({
                id:data.id,
                action:"UPDATE",
                sequenceId:data.sequenceId
            })
        }
    },

    deleteProject(state:IState,projectToDelete:any){

        let existingChangeIndex = state.projectChanges.findIndex(thisProjectUpdate => thisProjectUpdate.id == projectToDelete.id)
        let existingProjectIndex = state.projects[projectToDelete.sequenceId].findIndex(thisProject => thisProject.id == projectToDelete.id);
        
        if(existingChangeIndex>-1){
            if(state.projectChanges[existingChangeIndex].action=="CREATE"){
                state.projectState.push(projectToDelete)
                state.projectChanges.splice(existingChangeIndex,1)
            }else{
                state.projectChanges[existingChangeIndex].action = "DELETE"
            }
            state.projects[projectToDelete.sequenceId].splice(existingProjectIndex,1);
        }else{
            if(store.getters.activeUser.permissions.includes("wellplanner")){
                state.projectChanges.push({
                    id:projectToDelete.id,
                    action:"DELETE",
                })
               }
            state.projects[projectToDelete.sequenceId].splice(existingProjectIndex,1);
        }

    },

    // addAfterUndo(state: IState) {
    //     if (state.projectState.length > 0) {
    //         let lastProject = state.projectState[state.projectState.length - 1]
    //         state.projects[lastProject.sequenceId].push(lastProject)
    //         state.projectChanges.push({
    //             id: lastProject.id,
    //             action: "CREATE",
    //             sequenceId: lastProject.sequenceId
    //         })
    //         state.projectState.pop()
    //     }
    // },

    registerProjectChange(state:IState,data:any){
        let existingChangeIndex = state.projectChanges.findIndex(thisProjectUpdate => thisProjectUpdate.id == data.projectId)
        if(existingChangeIndex<0){
            state.projectChanges.push({
                id:data.projectId,
                action:"UPDATE",
                sequenceId:data.sequenceId
            })
        }
    },

    clearProjectChanges(state:IState){
        state.projectChanges = []
    }
}

const getters = {
    getProjectState(state: IState) {
        return state.projectState
    },
    getProjectChanges: (state: IState) => {
        return state.projectChanges
    },
    getSelectedProject: (state:IState,getters:any) => {
        return getters.getProjectById(state.activeProjectId)
    },
    
    getSelectedSequence: (state:IState,getters:any) => {
        return state.activeSequence
    },

    getProjects: (state: IState) => {

        let projectToReturn: Array<any> = []
        for(let k in state.projects){
            projectToReturn.push(state.projects[k])
        }
        return projectToReturn

    },

    getProjectById:(state:IState) => (id:any) => {
        for(let k in state.projects){
            for(let p=0;p<state.projects[k].length;p++){
                let thisProject = state.projects[k][p]
                if(thisProject.id==id)return thisProject
            }
        }
        return undefined
    },

    getProjectIndexById:(state:IState) => (id:string) => {
        let indexToReturn = -1;
        for(let k in state.projects){
            state.projects[k].forEach((thisProject:Project,index:number) => {
                if(thisProject.id == id) indexToReturn = index
            })
            if(indexToReturn>-1)break
        }
        return indexToReturn
    },

    getProjectsForSequence: (state: IState) => (sequenceId:string) => {

        if(!sequenceId)return []
        const projectToReturn = state.projects[sequenceId]
        if(projectToReturn==undefined)return []
        return projectToReturn

    },

    getProjectChangesCount: (state:IState) => {
        return state.projectChanges.length
    },

    getProjectChangesValidity:(state:IState) => {
        //check if all changed projects have wells selected
        let validityState = true;
        state.projectChanges.forEach(thisProjectChange => {
            if(thisProjectChange.action=="CREATE"||thisProjectChange.action=="UPDATE"){
                for(let k in state.projects){
                    let projectToCheck = state.projects[k].find(thisproject =>  thisproject.id == thisProjectChange.id)
                    if(projectToCheck){
                        if(projectToCheck.isRigless){
                            if((Number.parseInt(projectToCheck.well.id)<1)||(projectToCheck.riglessBucket.id.length<1)) validityState = false
                        }else{
                            if((Number.parseInt(projectToCheck.well.id)<1)||Number.parseInt(projectToCheck.rig.id)<1) validityState = false
                        }
                        
                    }
                }
            }
        })
        return validityState;
    },

    historyMode : (state:IState) => {
        return state.historyMode
    }
    
}

const actions = {

    getProject({ commit,state } : { commit: any,state:IState},projectId:string){

        return new Promise((resolve, reject) => {
            HTTP.get('projects/'+projectId)
            .then((response: any) => {
                commit('setProjects', {sequenceId:response.data.data.sequenceId,data:[response.data.data]})         
                resolve(response)                    
            })
            .catch((error: any) => {
                reject(error)
            })
        })

    },

    getProjectsForSequence ({ commit,state } : { commit: any,state:IState}, sequenceId:string) {   

        return new Promise((resolve, reject) => {
            HTTP.get('projects/?sequenceId='+sequenceId)
            .then((response: any) => {
                commit('setProjects', {sequenceId:sequenceId,data:response.data.data})         
                resolve(response)                    
            })
            .catch((error: any) => {
                reject(error)
            })
        })

    },

    createProjectsForSequence({commit}:{commit:any},data:any){

        return new Promise((resolve,reject) => {
            if(data.projects.length>0){
                HTTP.post('projects/create',data)
                .then((response:any) => {
                    commit('updateNewProjects',{sequenceId:data.sequenceId,data:response.data.data})
                    let tempActualKeyPairs = response.data.data.map((thisProject:any) => {return {id:thisProject.data.id,tempKey:thisProject.data.tempKey}})
                    resolve(tempActualKeyPairs)
                })
                .catch((error:any) => {
                    reject(error)
                })
            }else{
                resolve([])
            }
        })

    },

    updateProjectsForSequence({commit}:{commit:any},data:any){

        return new Promise((resolve,reject) => {
            if(data.projects.length>0){
                HTTP.put('projects/update',data)
                .then((reponse:any) => {
                    commit('updateProjects',{sequenceId:data.sequenceId,data:reponse.data.data})
                    resolve(reponse)
                })
                .catch((error:any) => {
                    reject(error)
                })
            }else{
                resolve([])
            }
        })

    },

    deleteProjectsForSequence({commit}:{commit:any},data:any){

        return new Promise((resolve,reject) => {
            if(data.length>0){
                HTTP.post('projects/delete',data)
                .then((response:any) => {
                    resolve(response)
                })
                .catch((error:any) => {
                    reject(error)
                })
            }else{
                resolve([])
            }
        })

    },

    exportProject({commit}:{commit:any},data:any){
        return new Promise((resolve,reject) => {
                HTTP.post('projects/excelExportProjectSheet',data,{responseType:"arraybuffer"} )
                .then((response:any) => {
                    resolve(response)
                })
                .catch((error:any) => {
                    reject(error)
                })
        })
    },

    saveChanges({dispatch,state}:{dispatch:any,state:IState}){

        let creatOps:Array<any> = state.projectChanges.filter(thisEntry => thisEntry.action == "CREATE").map(thisEntry => convertProjectToProjectModel(thisEntry.id,thisEntry.sequenceId))
        let updateOps:Array<any> = state.projectChanges.filter(thisEntry => thisEntry.action == "UPDATE").map(thisEntry => convertProjectToProjectModel(thisEntry.id,thisEntry.sequenceId))
        let deleteOps:Array<any> = state.projectChanges.filter(thisEntry => thisEntry.action == "DELETE").map(thisEntry => thisEntry.id)
      window.console.log(updateOps);
        let sequenceId = ""
        if(creatOps.length>0){
            sequenceId = creatOps[0].sequenceId
        }else{
            if(updateOps.length>0)updateOps[0].sequenceId
        }

        return new Promise((resolve,reject) => {
            dispatch("createProjectsForSequence",{sequenceId,projects:creatOps,type:"List"}).then((tempActualKeyPairs:any) => {
                dispatch("updateProjectsForSequence",{sequenceId,projects:updateOps,type:"List"}).then((updateRes:any) => {
                    dispatch("deleteProjectsForSequence",deleteOps).then((deleteRes:any) => {
                        resolve({})
                    })
                })
            })
        })

    }

}


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
import { HTTP } from '../../axios_api/http-common'
import { resolve } from 'dns'
import analyticsModule from './Analytics'
import { rejects } from 'assert'
import { settings } from 'cluster'
import Vue from 'vue'

const state = {
    settings: []
}

interface IState {
    // rigTerrainSettings: ISettings[],
    // wellFluidSettings: ISettings[],
    // timeSettings: ISettings[],
    settings: Array<any>
}

interface ISettings {
    id: string,
    property: string,
    color: string,
}

const mutations = {
    setSettings (state: IState, payload: any) {
        state.settings = payload
    },
    updateSettings (state: IState, payload: any) {
        var _exIndex = -1;
        for (let index = 0; index < state.settings.length; index++) {
            if (state.settings[index].id == payload.id) {
                _exIndex = index;
                break;
            }
        }
        if (_exIndex > -1)
            state.settings.splice(_exIndex, 1, payload);
    },
    updateAnnotationSettings(state:IState,payload: any){
        //use payload.position and setting.value to find who to update
        //
        window.console.log(JSON.stringify(payload,null,4))
        const settingIndex = state.settings.findIndex((as:any) => as.value == payload.position)

        if(settingIndex<0) return;

        const settingToUpdate = state.settings[settingIndex]

        const settingsData =  settingToUpdate.meta.split("/")
        let property = settingsData[0]
        let options = settingsData[1]
        let settingState = settingsData[2]
        let shape = settingsData[3]
        let backgroundColors = settingsData[4]
        let textColors = settingsData[5]

        switch(payload.changedProperty){
            case "property": property = payload.property; break;
            case "options": options = payload.options; break;
            case "shape": shape = payload.shape; break;
            case "state": 
                var stateData = payload.state.split("*")
                stateData[payload.index] = payload.state
                settingState = stateData[payload.stateIndex].join('*')
                break;
            case "background": 
                var backgroundData = backgroundColors.split("*")
                backgroundData[payload.index] = payload.background; 
                backgroundColors = backgroundData[payload.stateIndex].join('*')
                break;

            case "textColor": 
                var textColorData = textColors.split("*")
                textColorData[payload.index] = payload.textColor; 
                textColors = textColorData[payload.stateIndex].join('*')
                break;
        }

        const updatedSettings = property+'/'+options+'/'+settingState+'/'+shape+'/'+backgroundColors+'/'+textColors
        settingToUpdate.meta = updatedSettings;

        Vue.set(state.settings,settingIndex,settingToUpdate)
    }
}

const getters = {
    getSettings(state: IState) {
        return state.settings
    }
}

const actions = {
    saveSettings({commit}: {commit: any}, payload:any) {
        return new Promise ((resolve, reject) => {
            HTTP.put("appsettings/"+ payload.id, {
                key: payload.key,
                value: payload.value,
                meta: payload.meta
            })
                .then((res: any) => {
                    commit('updateSettings', res.data.data)
                    resolve(res.data.data)
                })
                .catch((err:any) => {
                    reject(err)
                })
        })
    },
    fetchSettings({commit}: {commit: any}) {
        return new Promise((resolve, reject)=> {
            HTTP.get("appsettings")
                .then((res: any) => {
                    commit('setSettings', res.data.data)
                    resolve(res)
                })
                .catch((err: any) => {
                    reject(err)
                })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
}
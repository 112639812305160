




















































































































































































































































































































































































































































































































































































































































































import Vue from "vue"
export default Vue.extend({
    props:{
        dailyReport:{
            type:Object,
            default:()=>({
                activityLog : [],
                startDepth:0,
                endDepth:0,
                reportDate:"",
                startTime:'06:00',
                endTime:'06:00',
                cost:{budgetTemplateId:"",budgetLines:[]},
                personnelContactsLog: [],
                timeBreakDown: {
                    circulation: 0,
                    rIH: 0,
                    pOOH: 0,
                    drilling: 0,
                    cementing: 0,
                    tCP: 0,
                    others: 0,
                },
            })
        },
        isOverview: {
            default: false
        },
        project:{
            type:Object,
            default:()=>({
                well:{}
            })
        },
        number:{
            type:Number,
            default:0
        },
    },
    name:"",
    data(){
        return {
        }
    },
    mounted() {
        this.$store.dispatch('budgetLineModule/fetchBudgetLines')
        window.console.log(this.dailyReport)
        window.console.log(this.isOverview)
    },
    computed:{
        costColumnNames():Array<any>{
            let columnNamesToReturn:Array<any> = []
            this.dailyReport.cost.budgetLines.forEach((budgetLine:any) => {
                let existingColumnNameIndex = columnNamesToReturn.findIndex(c => c.activityId==budgetLine.activityId)
                if(existingColumnNameIndex<0)columnNamesToReturn.push({activityName:budgetLine.acivityName,activityId:budgetLine.activityId})
            });
            return columnNamesToReturn
        },
        currentUnitSystem(){
            return this.$store.getters['UnitsModule/getCurrentUnitSystem']
        },
        sumOfEachMilestone() {
            const milestones: any[] = []
            const reducer = (accumulator: any, currentValue: any) => accumulator + currentValue;
            this.dailyReport.cost.budgetLines.forEach((x: any) => {
                const exists = milestones.findIndex((t: any) => t == x.acivityName)
                if (exists > -1) {
                    return false
                } else {
                    milestones.push(x.acivityName)
                }
            })
            const mili: any[] = []
            milestones.forEach((x: any) => {
                mili.push(this.dailyReport.cost.budgetLines.filter((b: any) => b.acivityName === x))
            })
            const sum: any[] = []
            mili.forEach((z: any) => {
                sum.push(z.map((y: any) => {
                    return y.lineValue
                }).reduce(reducer, 0))
            })
            return sum
        },
        budgetLineItems() {
            return this.$store.getters['budgetLineModule/getAllBudgetLines']
        }
    },
    methods:{
        getBudgetLineBasedOnID(id: number) {
            return this.budgetLineItems.find((x: any) => {
                return x.id === id
            })
        },
        getTimeDifferenceInHours(startDateTime:string,endDateTime:string){
         let endTime = this.timeStringToFloat(endDateTime);
         let startTime =this.timeStringToFloat(startDateTime)
         if(endTime<= startTime){
             endTime = endTime +24;
         }
          return Math.round(Math.abs(endTime - startTime))
        },
        getCumulativeTimeForRow(index:number):Number{
            let cum = 0
            for(let i=0;i<=index;i++){
                let thisLog = this.dailyReport.activityLog[i]
                cum = cum + this.getTimeDifferenceInHours(thisLog.from,thisLog.to)
            }
            return cum
        },
         timeStringToFloat(time:any) {
              var hoursMinutes = time.split(/[.:]/);
               var hours = parseInt(hoursMinutes[0], 10);
               var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
         return hours + minutes / 60;
       },
        convertDate(inputFormat: any) {
            function pad(s:any) { 
                return (s < 10) ? '0' + s : s;
            }
            var d = new Date(inputFormat)
            return !inputFormat?null:[d.getFullYear(),pad(d.getMonth()+1),pad(d.getDate())].join('-')
        }

    },
    filters:{
        prettytime(dateTime:any){
            let passedDate = new Date(Date.parse(dateTime))
            return (passedDate.getHours()<10?"0"+passedDate.getHours():passedDate.getHours())+":"+(passedDate.getMinutes()<10?("0"+passedDate.getMinutes()):passedDate.getMinutes());
        },
    },
})




































import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';
import convertUnit from '../../utils/convertUnit.ts'
import { FormRatingPlugin } from 'bootstrap-vue';

@Component
export default class RigInfo extends Vue {
    // @Prop({default: () =>{return {name: 'Abrua'}}})
    // public rig!: any;
    rating:any = convertUnit(this.rig.minimumHPRating.value, this.currentUnitSystem, this.rig.minimumHPRating.uom)

    get ratings () {
        return this.rating
    }

    set ratings (rate) {
        this.rating = convertUnit(this.rig.minimumHPRating.value, this.currentUnitSystem, this.rig.minimumHPRating.uom)
    }

    takeRightPane() {
        return this.$store.commit('setRightPaneToFalse')
    }

    @Watch('rig')
    onrateateChanged(newVal:any){
        this.rating = convertUnit(newVal.minimumHPRating.value, this.currentUnitSystem, newVal.minimumHPRating.uom)
    }

    get currentUnitSystem(){
        return this.$store.getters['UnitsModule/getCurrentUnitSystem']
    }

    get rig () {
        return this.$store.getters['getCurrentRig']
    }
}



















































































































































import Vue from 'vue'
import Component from 'vue-class-component';
import EventBus from './../../eventBus'
import UnitConverter from '../../mixins/unitConverter.ts'
import WellsImport from "@/components/WellImport.vue"

@Component({
    mixins: [UnitConverter],
    components:{
    "wells-import": WellsImport
    }
})
export default class WellsOverview extends Vue{
    //Data
    deletePromptProperty: boolean = false
    loader: boolean = true
    loadingTitle: string = 'Loading...'
    loadingMessage: string = 'Loading Wells'
    successMessage: string = ''
    additionalSuccessMessage = ''
    error: string = ''
    fields: Array<any> = [
        {key: 'name', sortable: 'true', label: 'Well Name'},
        {key: 'oml', sortable: 'true', label: 'Lease ID'},
        {key: 'terrain', sortable: 'true', label: 'Terrain'},
        {key: 'wellType', sortable: 'true', label: 'Fluid Type'},
        {key: 'wellConfiguration', label: 'Well Profile'},
        {key: 'button', label: "Actions"} 
    ]
    selectedWell: any ={
        id: '',
        normDepth: {value: 0, uom: 'm'},
        trueDepth: {value: 0, uom: 'm'},
        targetDepth: {value: 0, uom: 'm'}, 
        wellHeadPressure: {value: 0, uom: 'm'}, 
        name: '',
        status: '',
        wellType: ''
    }
    search: string = ''
    items : Array<any> = []
    activeScreen: string = ''
    isView: boolean = false
    showModal:boolean = false
    
    get selctedProperties() {
        let arrayOfObjectToPush = []
        if (this.selectedWell) {
            for (let prop in this.selectedWell) {
                arrayOfObjectToPush.push({
                    property: prop,
                    value: this.selectedWell[prop]
                })
            }
        } else {
            return {}
        }
        return arrayOfObjectToPush
    }

    // Methods
    gotoEdit(well_id: number){
        let path: object = {path:'/manage-well/', query:{id:well_id} }
        this.$router.push(path)
    }
    doDelete(bvEvt: any){
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.loadingTitle = 'Deleting'
        this.loadingMessage = 'Deleting '+ this.selectedWell.name
        this.deleteWellAction(this.selectedWell.id).then(response => {
            this.activeScreen="successNoBack"
            EventBus.$emit('activeScreen', this.activeScreen)
            this.successMessage = this.selectedWell.name + ' deleted succesfully'
            this.additionalSuccessMessage = 'This Well will no longer be available'
            this.deletePromptProperty = false
        },error => {
            this.error=error.response.data.message
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.deletePromptProperty = false
        })
        
    }

    deleteWellprompt(selected: any){
        this.selectedWell.id = selected.id
        this.selectedWell.name = selected.name
        this.deletePromptProperty = true
    }

    view(selected: any){
        this.selectedWell = selected
        this.isView = true
    }

    searchValue (e: string) {
        this.search = e
    }


    // VUEX ACTIONS
    getWellsAction () {
        return this.$store.dispatch('wellsModule/getWellsAction')
    }

    deleteWellAction (well_id: number) {
        return this.$store.dispatch('wellsModule/deleteWellAction', well_id)
        }

   fetchSequences() {
        return this.$store.dispatch('sequencesModule/fetchSequences')
    }


    // Computed propertied
    get filteredWells () {
        return this.$store.getters['wellsModule/filteredWells']
    }
    
  get filteredSequences() {
        return this.$store.getters['sequencesModule/filteredRootSequences']
    }
    


    // CREATED
    created() {
        if(this.filteredWells('').length===0){
            this.getWellsAction().then(response=>{
                // window.console.log(response.data.data)
                this.activeScreen = 'main'; 
                EventBus.$emit('activeScreen', this.activeScreen)
                this.loader = false
                // window.console.log(this.filteredWells(''))     
            },error => {
                this.activeScreen = 'error'; 
                this.error = error.message
                EventBus.$emit('activeScreen', this.activeScreen)
                this.loader = false
                // window.console.log(this.filteredWells(''))
            })
        } else {
            this.activeScreen = 'main'; 
            EventBus.$emit('activeScreen', this.activeScreen)
            this.loader = false 
        }
        
         if(this.filteredSequences('').length === 0) {
            this.fetchSequences();
        }
    }
    
}

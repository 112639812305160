


























































































import Vue from 'vue'
import Component from 'vue-class-component';
import EventBus from './../../eventBus'

@Component
export default class Operations extends Vue{
    // DATA
    fields: Array<any> = [
        {key:'name',label:"Operation Name", sortable:true},
        {key: 'department', sortable: 'true'},'SampleDisplay',
        {key: 'button', label: "Actions"}  
    ]
    selectedDepartment:any = "ALL"
    activeItem: any = null
    view: number = 0
    search: string = ''
    error: string = ''
    activeScreen: string = 'loading'
    loadingMessage: string = 'Loading Well Activities'
    loadingTitle: string = 'Loading...'
    deletePromptProperty: boolean = false
    successMessage: string = ''
    additionalSuccessMessage = ''
    loader: boolean = true
    fieldsNpt: Array<any> = [
         {key:'name',label:"Name", sortable:true},
        {key:'nptRef',label:"Ref"},
        {key: 'rootCause', label: "Root Cause"}, 
        {key: 'associatedCause', label: "Associated Cause"}, 
        {key: 'button', label: "Actions"}, 
    ]


    // COMPUTED PROPERTIES
    get getWellOperations () {
        return this.selectedDepartment=="ALL"?this.$store.getters['wellactivitiesModule/getWellOperations']:this.$store.getters['wellactivitiesModule/getWellOperations'].filter((w:any)=>w.departmentId==this.selectedDepartment)
    }
    get activityTypes () {
        return this.$store.state.wellactivitiesModule.activityTypes
    }
    get getDepartmentById(){
        return this.$store.getters['departmentModule/getDepartmentById']
    }
    get fillteredDepartments() {
        return this.$store.getters['departmentModule/fillteredDepartments']
    }
    get allNpt () {
        return this.$store.getters['nptModule/getAllNpt']
    }

    get departmentOption(){
        return this.$store.getters["departmentModule/getDepartmentOptions"]
    }


    // VUEX METHODS AND MUTATIONS
    getWellActivities () {
        return this.$store.dispatch('wellactivitiesModule/getWellActivities')
    }

    getDepartments (){
        return this.$store.dispatch('departmentModule/getDepartments')
    }
       
    update(item: any, pathString: string){
        this.activeItem = item;
        this.$router.push({path: pathString, query:{id:item.id}})
    }

    promptDelete(item: any){
        this.activeItem = item;
        this.deletePromptProperty = true
        // window.console.log("ajkhfshfjkshdjkshd")
    }

    searchValue(e: any) {
        this.search = e
    }

    doDelete(){
        if (this.view === 0) {
            this.activeScreen = 'loading'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.loadingMessage = 'Please wait while we delete this activity'
            this.loadingTitle = 'Deleting...'
            this.$store.dispatch('wellactivitiesModule/deleteWellActivity', this.activeItem)
            .then((res: any) =>{
                this.activeScreen = 'successNoBack'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.additionalSuccessMessage = 'This activity will no longer be available'
                this.successMessage = this.activeItem.name + ' deleted succesfully'
                this.deletePromptProperty = false
            })
            .catch((error: any) => {
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.error = error.response.data.message
                this.deletePromptProperty = false
            })
        } else {
            this.activeScreen = 'loading'
            this.loadingMessage = 'Please wait while we delete this NPT Operation'
            this.loadingTitle = 'Deleting...'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.$store.dispatch('nptModule/deleteNPTOperationAction', this.activeItem).then(response => {
                this.activeScreen = 'successRemain'
                this.additionalSuccessMessage = 'This NPT Operation will no longer be available'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.successMessage = this.activeItem.name + ' deleted succesfully'
                this.deletePromptProperty = false
            }, error => {          
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.error = error.response.data.message
                this.deletePromptProperty = false
            })
        }
    }



    // CREATED
    created() {
        this.activeScreen = 'loading';
        EventBus.$emit('activeScreen', this.activeScreen)
        if(this.allNpt.length <= 0) {
            this.$store.dispatch('nptModule/fetchNptOperation')
        }
        if(this.fillteredDepartments('').length===0){
            this.getDepartments()
        }
        if(this.getWellOperations.length == 0){
            this.getWellActivities()
            this.activeScreen = 'main';
            EventBus.$emit('activeScreen', this.activeScreen)
            this.loader = false
        } else {
            this.activeScreen = 'main';
            EventBus.$emit('activeScreen', this.activeScreen)
            this.loader = false
        }
    }

    beforeDestroy() {
        window.localStorage.setItem('LastRoute', '')
    }
}
















import Vue from 'vue';
import {Component, Prop, Watch} from 'vue-property-decorator';

@Component
export default class TimeUnits extends Vue {
    
    start = new Date()
    end = new Date()

    onStartDateChanged(){
        this.$store.commit('setDynamicStart', this.start)
    }

    onEndDateChanged(){
        this.$store.commit('setDynamicEnd', this.end)
    }

    get dynamicStart(){
        return this.$store.getters['getDynamicStartDate']
    }

    get dynamicEnd(){
        return this.$store.getters['getDynamicEndDate']
    }

    @Watch("dynamicStart",{immediate:true})
    onDynamicStartChanged(){
        this.start = this.dynamicStart
    }

    @Watch("dynamicEnd",{immediate:true})
    onDynamicEndChanged(){
        this.end = this.dynamicEnd
    }

    takeRightPane() {
        return this.$store.commit('setRightPaneToFalse')
    }

    created () {
        this.start = new Date(this.dynamicStart.getFullYear(), 0, 1)
        this.end = this.dynamicEnd
    }
}

<template>
    <div @mouseenter="isFocused=true" @mouseleave="isFocused=false" @mousedown="onMouseDown" class="analytics-card border rounded shadow" :class="{'on-top':(this.selectedCard.id==this.$vnode.key||isFocused)}" :style="{top:top+'px',left:left+'px',height:height+'px',width:width+'px', backgroundColor: backgroundColor}">
        
        <template v-if="isFocused">
            <span class="top-left-ardoner ardoner-style" @mousedown="topLeftArdonerMouseDown"/>
            <span class="top-right-ardoner ardoner-style" @mousedown="topRightArdonerMouseDown"/>
            <span class="bottom-left-ardoner ardoner-style" @mousedown="bottomLeftArdonerMouseDown"/>
            <span class="bottom-right-ardoner ardoner-style" @mousedown="bottomRightArdonerMouseDown"/>
        </template>
        <span class="card-close-button" @mousedown.stop="facker" @mouseup.stop="facker" @click.stop="removeCard"><font-awesome-icon class="text-center d-block mx-auto mt-5" style="color: white" icon="times" size="1x"/></span>
        <div>
            <component :height="height" v-if="readyToShowChart=='READY'" :options="chartOptions" :chartdata="chartData" :is="activeChartType" :styles="{width:width+'px',height:height+'px',position:'relative'}" class="mt-1"/>
            <loader v-if="readyToShowChart=='LOADING'" class="mt-5">
                <p style=" user-select: none" class="text-center mt-5" v-if="selectedProjects.length>0">Setting up your KPI data</p>
                <p style=" user-select: none"  class="text-center mt-5 mx-2" v-else>Please select one or more data sources to get a plot of <b>{{kpi.name}}</b></p>
            </loader>
            <template v-if="readyToShowChart=='ERROR'">
                <font-awesome-icon class="text-center d-block mx-auto mt-5" style="color: red" icon="exclamation-triangle" size="3x"/>
                <p style=" user-select: none" class="text-light text-center mt-4 mx-5">Unable to resolve some configuration settings for this KPI, please check the KPI data</p>
            </template>
        </div>
        
    </div>
</template>

<script>
import Bar from "./charts/Bar.vue"
import HorizontalBar from "./charts/HorizontalBar.vue"
import Line from "./charts/Line.vue"
import Pie from "./charts/Pie.vue"
import Scatter from "./charts/Scatter.vue"
import {HTTP} from "../../axios_api/http-common"
import BaseResizableElement from "../../mixins/BaseResizableElement.js"
import AARAnalytics from "./AARAnalytics.vue"
let selectedProjectsWatcher = null


export default {
    mixins:[BaseResizableElement],
    components:{
        'bar-chart':Bar,
        'line-chart':Line,
        'pie-chart':Pie,
        'scatter-chart':Scatter,
        'horizontal-bar':HorizontalBar,
        'aar':AARAnalytics
    },
    props:{
        presetKpi:{
            type:Object,
            default:() => ({})
        },
        presetId:{
            
        },
        selectedCard:{
            type:Object,
            default:()=>({})
        },
        activeTab:{
            type:String
        },
        position:{
            type:Object,
            default:()=>({top:'0',left:'0'})
        },
        presetProjects:{
            type:Array,
            default:()=>[]
        },
        presetActivities:{
            type:Array,
            default:()=>[]
        },
        presetMilestones:{
            type:Array,
            default:()=>[]
        },
    },
    data(){
        return{
            selectedProjects:[],
            selectedActivities:[],
            selectedMilestones:[],
            localProjects:[],
            localActivities:[],
            localMilestones:[],
            backgroundColor:"white",
            colorArray: ['#ED750A', '#FFC517'],
            top:0,
            left: 0,
            height: 0,
            width: 0,
            mouseDownPositionY:0,
            mouseDownPositionX:0,
            activeHandler:null,
            isFocused:false,
            dataLoaded:false,
            activeChartType:'',
            atPositionChanged: false,
            chartOptions:{
                title: {
                    display: true,
                    text: 'Custom Chart Title'
                },
                maintainAspectRatio: false,
                responsive:true,
                scales: {
                    xAxes: [{gridLines: { color: "black" }, ticks: {beginAtZero: true,min:0}, 
                           scaleLabel: { display: true,labelString: "X-Axis"}}],
                    yAxes: [{gridLines: { color: "black" }, ticks: {beginAtZero: true,min:0}, 
                           scaleLabel: { display: true,labelString: "Y-Axis" }}]
                }
            },
            chartData:{
                labels:[],
                datasets:[]
            },
        }
    },
    computed:{
        kpi(){
            return this.$store.getters["Analytics/getKPIById"](this.presetKpi.kpiId)
        },
        readyToShowChart(){
            if(!this.kpi||!this.kpi.chartOptions||this.kpi.chartOptions.length<1){
                return "ERROR"
            }
            if(this.dataLoaded){
                return "READY"
            }else{
                return "LOADING"
            }
        },
        activePane(){
            return this.$store.getters['getActivePane']
        },
        rightPane(){
            return this.$store.getters['getPaneStatus']
        },
        activeAnalyticsElement(){
            return this.$store.state.Analytics.activeAnalyticsElements
        },
        globalSelectedProjects(){
            return this.$store.state.Analytics.selectedProjects
        },
        globalBackgroundColor(){
            return this.$store.state.Analytics.backgroundColor
        }
    },
    methods:{
        getConditionalValue(targetName,conditionalProperties){
            let conditionValues = ""
            let conditionalPropertiesArray = conditionalProperties.split(',')
            conditionalPropertiesArray.forEach(thisConditionalProperty => {
                switch(targetName){
                    case "BaseActivity":
                        if(conditionValues.length<1){
                            conditionValues = conditionValues+(this.selectedActivities[0]!=undefined?this.selectedActivities[0].id:-1)
                        }else{
                            conditionValues = ","+conditionValues+(this.selectedActivities[0]!=undefined?this.selectedActivities[0].id:-1)
                        }
                    break
                }
            })
            return conditionValues;
        },
        extractQueryData(){
            return this.kpi.kpiTargets.map(thisKPITarget => ({
                targetName:thisKPITarget.targetName ,
                targetProperties:thisKPITarget.targetProperties,
                conditionTypes:thisKPITarget.conditionTypes,
                conditionalProperties:thisKPITarget.conditionalProperties,
                conditionValues:this.getConditionalValue(thisKPITarget.targetName,thisKPITarget.conditionalProperties)
            }))
        },
        randomInt(min, max) {
            return min + Math.floor((max - min) * Math.random());
        },
        getTargetOptionsByTargetName(name){
            switch(name){
                case 'Milestone':
                return []
            }
        },
        getProjectNameFromId(id){
            return (this.$store.getters["projectModule/getProjectById"](id)).name
        },
        handleSelectedProjectChanged(){
            this.dataLoaded = false
            this.chartData.labels.splice(0,this.chartData.labels.length)
            this.chartData.datasets.splice(0,this.chartData.datasets.length)
            if(this.selectedProjects.length>0){
                this.chartOptions.title.text = this.kpi.name+" for "+this.getProjectNameFromId(this.selectedProjects[0])
                this.chartOptions.scales.xAxes[0].scaleLabel.labelString= this.kpi.xAxisTitle
                this.chartOptions.scales.yAxes[0].scaleLabel.labelString= this.kpi.yAxisTitle
                if(this.kpi.dataSource=="query/timevsdepth"){
                    this.chartOptions.scales.yAxes[0].ticks.reverse=true
                    this.dataLoaded = false
                    this.chartData.labels.splice(0,this.chartData.labels.length)
                    this.chartData.datasets.splice(0,this.chartData.datasets.length)
                    HTTP.get("analytics/"+this.kpi.dataSource+"/?projectId="+this.selectedProjects[0]).then(r => {
                        this.dataLoaded = true
                        //this.chartData.labels = r.data.data.columns.find(thisColumn => thisColumn.columnName==this.kpi.chartOptions[0].xProperty).values
                        this.kpi.chartOptions.forEach((thisChartOption,index) => {
                            var yData = r.data.data.columns.find(thisColumn => thisColumn.columnName==thisChartOption.yProperty).values
                            var xData = r.data.data.columns.find(thisColumn => thisColumn.columnName==thisChartOption.xProperty).values
                            var data = [] 
                            for(let i=0;i<yData.length;i++){
                                data.push({
                                    x:xData[i],
                                    y:yData[i]
                                })
                            }
                            this.chartData.datasets.push({
                                label:thisChartOption.caption,
                                lineTension:0,
                                showLine:true,
                                borderColor:this.colorArray[index],
                                backgroundColor: this.activeChartType=='scatter-chart'?'transparent':this.colorArray[index],
                                data:data
                            })
                        })
                    })
                }else{
                    HTTP.get("analytics/"+this.kpi.dataSource+"/?projectId="+this.selectedProjects[0]).then(r => {
                        this.dataLoaded = true
                        this.chartData.labels = r.data.data.columns.find(thisColumn => thisColumn.columnName==this.kpi.chartOptions[0].xProperty).values
                        this.kpi.chartOptions.forEach((thisChartOption,index) => {
                        if(thisChartOption.chartType=="PIE_CHART"){
                            this.chartOptions.scales=null
                            this.chartData.datasets.push({
                                backgroundColor:["green","yellow","red","blue","purple"],
                                data:r.data.data.columns.find(thisColumn => thisColumn.columnName==thisChartOption.yProperty).values
                            })
                        }
                        else{
                            this.chartData.datasets.push({
                                label:thisChartOption.caption,
                                lineTension:0,
                                borderColor:this.colorArray[index],
                                backgroundColor: this.activeChartType=='line-chart'?'transparent':this.colorArray[index],
                                data:r.data.data.columns.find(thisColumn => thisColumn.columnName==thisChartOption.yProperty).values
                            })
                        }
                        })
                        this.$emit('atProjectChange', {chart:r.data.data.columns, kpi: this.kpi.id})
                    })
                }
            }
        },
        handleSelectedActivityChanged(){
            this.chartOptions.scales.xAxes[0].scaleLabel.labelString= this.kpi.xAxisTitle
            this.chartOptions.scales.yAxes[0].scaleLabel.labelString= this.kpi.yAxisTitle
            if(this.kpi.dataSource=="query/timevsdepth"){
                this.chartOptions.scales.yAxes[0].ticks.reverse=true
                this.dataLoaded = false
                this.chartData.labels.splice(0,this.chartData.labels.length)
                this.chartData.datasets.splice(0,this.chartData.datasets.length)
                if(this.selectedProjects.length>0){
                    HTTP.get("analytics/"+this.kpi.dataSource+"/?activityId="+this.selectedActivities[0].id).then(r => {
                        this.dataLoaded = true
                        //this.chartData.labels = r.data.data.columns.find(thisColumn => thisColumn.columnName==this.kpi.chartOptions[0].xProperty).values
                        this.kpi.chartOptions.forEach(thisChartOption => {
                            var yData = r.data.data.columns.find(thisColumn => thisColumn.columnName==thisChartOption.yProperty).values
                            var xData = r.data.data.columns.find(thisColumn => thisColumn.columnName==thisChartOption.xProperty).values
                            var data = [] 
                            for(let i=0;i<yData.length;i++){
                                data.push({
                                    x:xData[i],
                                    y:yData[i]
                                })
                            }
                            this.chartData.datasets.push({
                                label:thisChartOption.caption,
                                lineTension:0,
                                showLine:true,
                                borderColor:this.colorArray[this.randomInt(1,this.colorArray.length-1)],
                                backgroundColor: this.activeChartType=='scatter-chart'?'transparent':this.colorArray[this.randomInt(1,this.colorArray.length-1)],
                                data:data
                            })
                        })
                    })
                }
            }else{
                this.dataLoaded = false
                this.chartData.labels.splice(0,this.chartData.labels.length)
                this.chartData.datasets.splice(0,this.chartData.datasets.length)
                if(this.selectedProjects.length>0){
                    HTTP.get("analytics/"+this.kpi.dataSource+"/?activityId="+this.selectedActivities[0].id).then(r => {
                        this.dataLoaded = true
                        this.chartData.labels = r.data.data.columns.find(thisColumn => thisColumn.columnName==this.kpi.chartOptions[0].xProperty).values
                        this.kpi.chartOptions.forEach(thisChartOption => {
                            this.chartData.datasets.push({
                                label:thisChartOption.caption,
                                lineTension:0,
                                borderColor:this.colorArray[this.randomInt(1,this.colorArray.length-1)],
                                backgroundColor: this.activeChartType=='line-chart'?'transparent':this.colorArray[this.randomInt(1,this.colorArray.length-1)],
                                data:r.data.data.columns.find(thisColumn => thisColumn.columnName==thisChartOption.yProperty).values
                            })
                        })
                    })
                }
            }
        },
        handleSelectedMilestoneChanged(){

        },
        removeCard(){
            this.$store.commit("Analytics/removeKPIFromPreset",{presetId:this.presetId,kpiId:this.presetKpi.id})
        },
        facker(){
            //just a fake listener to mouse up event on the span used for deleting to allow stop propagation of mouse up and mouse down event
        }
    },
    watch:{
        selectedCard:{
            deep:true,
            handler(newVAL,oldVal){
                if(this.selectedCard.id==this.$vnode.key&&this.selectedCard.state=='MOUSE_UP'){
                    if(this.activeHandler!=null){
                        this.activeHandler()
                        this.activeHandler = null
                    }
                    if(this.atPositionChanged){
                        this.$store.commit("Analytics/updateKPIInPreset",{presetId:this.presetId,kpiId: this.presetKpi.id, x:this.left, y: this.top, height: this.height, width: this.width})
                        this.atPositionChanged = false
                    }else{
                        this.$store.commit('Analytics/updateActiveAnalyticsElement', {id:this.$vnode.key,type:'KPI',name:this.kpi.name});
                        this.$nextTick(()=>{
                            this.$store.commit('Analytics/updatedSelectedProjects', this.selectedProjects);
                            this.$store.commit('Analytics/updatedBackgroundColor',this.backgroundColor)
                            this.$store.commit('setRightPaneToTrue');
                            this.$store.commit('setActivePane', 'analytics-data-source');
                        })
                    }
                }
            }
        },
        activeTab(){
            if(this.$vnode.key!=this.activeTab){
                if(selectedProjectsWatcher!=null)selectedProjectsWatcher()
            }
        },
        presetProjects:{
            immediate:true,
            handler(){
                let itemsToRemove = []
                this.presetProjects.forEach(p=>{
                    if(!this.selectedProjects.includes(p))this.selectedProjects.push(p)
                })
                this.selectedProjects.forEach((p,index)=>{
                    //the value here is the list of preset projects only, if an item has been removed from the preset list, only remove it from list of selected projects for this card if it has not been locally added to this card
                    if(!this.presetProjects.includes(p)&&!this.localProjects.includes(p))itemsToRemove.push(index)
                })
                itemsToRemove.forEach(i=>this.selectedProjects.splice(i,1))
            }
        },
        globalSelectedProjects:{
            immediate:true,
            handler(value){
                if(this.activeAnalyticsElement.id==this.$vnode.key&&this.activeAnalyticsElement.type=='KPI'){
                    let itemsToRemove = []
                    let localItemsToRemove = []
                    value.forEach(p=>{
                        if(!this.selectedProjects.includes(p)){
                            this.selectedProjects.push(p)
                            this.localProjects.push(p)
                        }
                    })
                    this.selectedProjects.forEach((p,index)=>{
                        //The value list here contains both global and local, so, if anything is unchecked, just remove it
                        if(!value.includes(p)){
                            itemsToRemove.push(index)
                            let indexOfRemovedItemInLocalProjects = this.localProjects.findIndex(lp=>lp==p)
                            if(indexOfRemovedItemInLocalProjects>-1)this.localProjects.splice(indexOfRemovedItemInLocalProjects,1)
                        }
                    })
                    itemsToRemove.forEach(i=>this.selectedProjects.splice(i,1))
                }
            }
        },
        globalBackgroundColor(){
            if(this.activeAnalyticsElement.id==this.$vnode.key&&this.activeAnalyticsElement.type=='KPI'){
                this.backgroundColor = this.globalBackgroundColor
            }
        }
    },
    mounted(){

        if(this.readyToShowChart!='ERROR'){
            let defaultChartOption = this.kpi.chartOptions.find(c => c.isDefault)
            if(!defaultChartOption) defaultChartOption = this.kpi.chartOptions[0]
            switch(defaultChartOption.chartType){
                case 'BAR_CHART':
                    this.activeChartType = 'bar-chart'
                break;
                case 'LINE_CHART':
                    this.activeChartType = 'line-chart'
                break;
                case 'SCATTER_CHART':
                    this.activeChartType = 'scatter-chart'
                break;
                case 'HORIZONTAL_BAR_CHART':
                    this.activeChartType = 'horizontal-bar'
                break;
                case 'PIE_CHART':
                    this.activeChartType = 'pie-chart'
                break;               
                case 'aar':
                    this.activeChartType = 'aar'
                break;
            }
        }
        
        switch(this.kpi.kpiTargets[0].targetName){
            case "PROJECT": 
                selectedProjectsWatcher = this.$watch('selectedProjects',{immediate:true,handler:this.handleSelectedProjectChanged})
                break;
            case "AAR": 
                selectedProjectsWatcher = this.$watch('selectedProjects',{immediate:true,handler:()=>{this.dataLoaded = true}})
                break;
            case "ACTIVITY":
                this.$watch('selectedActivities', this.handleSelectedActivityChanged)
                break;
        }
    },
    created(){
        //set initial position to incoming values
        this.top = this.presetKpi.y
        this.left = this.presetKpi.x
        this.height = this.presetKpi.height
        this.width = this.presetKpi.width
    }
}
</script>

<style scoped>
    .analytics-card{
        position: absolute;
        min-height: 20px;
        min-width: 20px;
        background: white;
    }

    .analytics-card:active{
        cursor:move;
    }

    .ardoner-style{
        position:absolute;
        background:red;
        min-width:10px;
        min-height:10px;
    }

    .bottom-right-ardoner{
        bottom:-5px;
        right:-5px;
        cursor:nwse-resize
    }

    .bottom-left-ardoner{
        bottom:-5px;
        left:-5px;
        cursor:nesw-resize
    }

    .top-left-ardoner{
        top:-5px;
        left:-5px;
        cursor:nwse-resize
    }

    .top-right-ardoner{
        top:-5px;
        right:-5px;
        cursor:nesw-resize
    }

    .on-top{
        z-index: 1000;
    }

    .chart-container{
        position: relative;
        margin: auto;
    }

    .right-bar{
        position: absolute;
        right:0;
        top: 0;
        height: 100%;
        min-height: 100%;
        overflow-y: auto;
        width: 0;
        background: white;
        transition: 0.3s;
    }

    .right-bar-visible{
        width: 250px;
        transition: 0.3s;
    }

    .right-bar-margin{
       margin-right: 255px!important;
       transition: 0.3s;
    }

    .context-ul {
        list-style:none;
        position: absolute;
        min-width: 150px;
        right: 0;
        padding:0;
        display:block;
        max-width:150px;
        box-shadow: 1px 2px 20px rgb(20, 20, 20);
        background:white;
        border-radius: 2px;
        z-index: 1000;
    }

    .context-ul li {
        padding: 5px;
        border-bottom: 1px solid #ccc;
        cursor: pointer;
    }

    .card-close-button{
        position:absolute;
        top:5px;
        right:5px;
        background:red;
        width:15px;
        height:15px;
        border-radius: 100%
    }
</style>















































import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import {Validations} from 'vuelidate-property-decorators'
import { required, minLength } from "vuelidate/lib/validators"

@Component
export default class SequenceVersion extends Vue {
    @Prop()
    sequenceId !: any

    @Validations()
    validations = {
        version: {
            name: {required, minLength: minLength(3)},
        }
    }

    activeScreen = 'form'
    busy:boolean = false
    title = 'Saving'
    message = 'Please wait while we save new sequence version'
    successMessage = ''
    additionalSuccessMessage = 'You can view the newly created version (in read only mode) by using the version selector at top left corner of the screen'
    errorMessage = ''

    version = {
         name: '',
         sourceSequenceId: 0,
         description: ''
    }

    activeScreenNull() {
        let form = this.$refs.sequenceVersion as any
        form.hide()
    }

    get isButtonDisabled () {
        if (this.$v.$invalid) {
            return true
        } else {
            return false
        }
    }

    doCreateNewVersion(){
        this.activeScreen = 'loading'
        let form = this.$refs.sequenceVersion as any
        this.$store.dispatch("sequencesModule/createNewVersion", {
            name: this.version.name,
            sourceSequenceId: Number(this.sequenceId),
            description: this.version.description
        }).then(r=>{
            this.successMessage = 'Successfully Created new Sequence version '+ this.version.name
            this.activeScreen = 'success'
        }).catch((error: any) => {
            this.activeScreen = 'error'
            setTimeout(()=> {
                form.hide()
                // this.activeScreen = 'form'
            }, 3000)
            this.errorMessage = error
        })
    }
}

import Unit from './units'

const Quantity = class {
    public name: string
    public units: Array<InstanceType<typeof Unit>> = []
    public baseSI: string
    public baseField: string

    constructor (name: string, baseSI:string, baseField: string) {
        this.name = name
        this.baseField = baseField
        this.baseSI = baseSI
    }

    public getBaseUnit(system: string): string {
        if (system === "si") {
            let base = this.units.filter(u => u.symbol === this.baseSI)[0]
            if (base) return base.getSymbol();
            return ''
        } else {
            let base = this.units.filter(u => u.symbol === this.baseField)[0]
            if (base) return base.getSymbol();
            return ''
        }
    }

    public addUnit(unit: InstanceType<typeof Unit>, isBaseUnit: boolean) {
        this.units.splice(isBaseUnit? 0: this.units.length, 0, unit)
    }

    public getUnits(): ReadonlyArray<InstanceType<typeof Unit>> {
        return this.units
    }

}

export default Quantity





































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class PersonnelContact extends Vue {
  @Prop({}) 
  contactName!:any;
  @Prop({}) 
  email!:any;
  @Prop({}) 
  mobile!:any;
  @Prop({}) 
  company!:any;
  @Prop({}) 
  position!:any;
  @Prop({}) 
  office!:any;
  @Prop({}) 
  title!:any

  private get contactNameInput() {
    return this.contactName;
  }
  private set contactNameInput(value: any) {
    this.$emit("update:contactName", value);
  }
   private get emailInput() {
    return this.email;
  }
  private set emailInput(value: any) {
    this.$emit("update:email", value);
  }
   private get mobileInput() {
    return this.mobile;
  }
  private set mobileInput(value: any) {
    this.$emit("update:mobile", value);
  }
   private get companyInput() {
    return this.company;
  }
  private set companyInput(value: any) {
    this.$emit("update:company", value);
  }
  private get positionInput() {
    return this.position;
  }
  private set positionInput(value: any) {
    this.$emit("update:position", value);
  }
   private get officeInput() {
    return this.office;
  }
  private set officeInput(value: any) {
    this.$emit("update:office", value);
  }
   private get titleInput() {
    return this.title;
  }
  private set titleInput(value: any) {
    this.$emit("update:title", value);
  }
}

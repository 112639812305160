<template>
    <div>
        <b-btn size="sm" class="my-2" variant="dark" @click="$emit('create-target')">Add Target </b-btn>

        <div v-if="!targets || targets.length < 1">
            <h1 class="text-center mt-5" style="color:gray">No Targets Have Been Defined </h1>
        </div>

        <div v-else  class="pr-2">
            <div v-for="(target,i) in targets" :key="i">
                <div class="host menu-item">
                    <div class="target-header mt-1 invert" >
                        <b class="ml-1" v-if="target.name">{{target.name.toUpperCase()}}</b>
                        <b-alert show variant="info" class="p-1 m-0" v-else>No Name Defined </b-alert>
                    </div>
                    <hr style="padding:0px;margin:0px" class="invert" />
                    <div class="ml-3 target-details">
                        <b-row>
                            <b-col cols="4">
                                    <div class="description">Northing(m) </div>
                                    <div class="description">Easting(m)</div>
                                    <div class="description northing">Depth(m)</div>
                            </b-col>
                            <b-col cols="4" class="right-border">
                                    <div class="description">{{target.northing}} </div>
                                    <div class="description">{{target.easting}}</div>
                                    <div class="description">{{target.depth}}</div>
                            </b-col>
                        </b-row>
                    </div>
                    

                    <div class="action"><hr style="padding:0px;margin:0px" class="invert" />

                        <b-alert :show="pending_delete == target" variant="danger" class="mt-1">
                            <div>
                                <h4>Warning!</h4>This is an irreversible operation. <br> Do you wish to continue ? <br>
                                <b-btn variant="danger" class="m-1" @click="$emit('delete-target', target)">Yes! Delete </b-btn> 
                                <b-btn class="m1-1" @click="pending_delete = null" >No! Cancel</b-btn>
                            </div>
                        </b-alert>

                        <div v-if="pending_delete != target" class="my-2 ml-3">
                            <b-btn size="sm" variant="dark" class="px-3 mr-1" @click="$emit('edit-target', target)">
                                <font-awesome-icon color="white" icon="edit" size="1x" class="mr-2"/>Edit
                            </b-btn>
                            <b-btn size="sm" variant="danger" @click="pending_delete = target" class="ml-1">
                                <font-awesome-icon color="white" icon="trash" size="1x" class="mr-2"/>Delete
                            </b-btn>
                        </div>
                                
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'target-list',
    props:{
        targets:{
            type:Array,
            default:function(){
                return []
            }
        },
        isFrozen: {
            default:false
        }
    },
    data(){
        return{
            pending_delete:null,
        }
    },
    mounted(){
        
    }
}
</script>

<style scoped>

    ::-webkit-scrollbar {
        height: 13px;
        width:10px;
        background-color: rgb(138, 138, 138);
    }

    .right-border{
        border-left: 1px solid lightgray;
    }

    .host{
        border-radius: 5px;
        border:1px solid lightgray;
        margin-top: 5px;
        box-shadow: 2px 3px 2px #ccc;
    }

    .description{
        font-size: 13.5px;
        padding:3px;
    }

    .action{
        display: none;
    }

    .host:hover .action{
        display: block;
    }

    .target-header{
        padding:3px;

    }

</style>
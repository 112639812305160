






































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import departmentModule from '../../../sore/modules/departments'
import UnitConverter from '@/mixins/unitConverter.ts'
import convertSymbol from '../../../utils/convertSymbol.ts'
import convertUnit from '../../../utils/convertUnit.ts'
import { throws } from 'assert'

@Component({
    mixins: [UnitConverter]
})
export default class ProjectHeatMapChart extends Vue {
    @Prop({default: 'Drilling Sequence Report Sheet'})
    sequence_name !: string
    @Prop()
    projects: any
    @Prop({default: 'heatmap'})
    tableID !: string

    get fields(){
        let preProcesses = []  as Array<any>
        preProcesses.push({key:"name", label:" ", stickyColumn: true,tdClass:'bg-light'})
        if(this.projects != null || this.projects != undefined){
        this.projects.forEach((project:any) => {
            project.preProcesses.forEach((preProcess:any) => {
            preProcesses.push({key:preProcess.name, label:preProcess.name,tdClass: this.applyTdClass})
            });
        });
        }
        return preProcesses
    }

    applyTdClass(value:any, key:any, item:any) {
        const classes = [];
        classes.push('rowHeight');
        let result =  item.preProcesses.find((x:any) => x.name.toLowerCase() == key.toLowerCase())
        if(result){
            if(result.preprocessValue){
                if(this.checkGreenCodeTask(result)){       
                    classes.push('bg-success');
                }
                if(this.checkGrayCodeTask(result)){
                    classes.push('bg-secondary');
                }
                if(this.checkYellowCodeTask(result)){
                    classes.push('bg-warning');
                }
                if(this.checkRedCodeTask(result)){
                    classes.push('bg-danger');
                }
            }
        }
        return classes;
    }

    setItemLabel(preProcesses:Array<any>, columnName:any):string{
        let result =  preProcesses.find((x:any) => x.name.toLowerCase() == columnName.label.toLowerCase())
        if(result){
            if( result.preprocessValue){
                return  result.preprocessValue;
            }
            else {
                return "NULL"
            }
        }
    return "N/A"
    }

    checkGreenCodeTask(preprocess:any):boolean{
        let results = ["Yes","COMPLETED","100"]
        if((preprocess.maxValue == 0 && preprocess.minValue==0 && results.includes(preprocess.preprocessValue))||
            (preprocess.maxValue>preprocess.minValue && preprocess.maxValue== preprocess.preprocessValue)){
                return true
        }
        return false
    }

    checkRedCodeTask(preprocess:any):boolean{
        let results = ["No","AT_RISK","0"]
        if((preprocess.maxValue == 0 && preprocess.minValue==0 && results.includes(preprocess.preprocessValue))||
            (preprocess.maxValue>preprocess.minValue &&  preprocess.preprocessValue<=preprocess.minValue)){
                return true
        }
        return false
    }

    checkYellowCodeTask(preprocess:any):boolean{
        let results = ["IN PROGRESS"]
        if((preprocess.maxValue == 0 && preprocess.minValue==0 && (preprocess.preprocessValue== "IN PROGRESS" || (preprocess.preprocessValue<100 &&preprocess.preprocessValue>0))) ||
            (preprocess.maxValue>preprocess.minValue && preprocess.maxValue > preprocess.preprocessValue && preprocess.minValue < preprocess.preprocessValue )){
                return true
        }
        return false
    }

    checkGrayCodeTask(preprocess:any):boolean{
        if(preprocess.preprocessValue == "SUSPENDED"){
            return true
        }
        return false
    }
}

import { HTTP } from '@/axios_api/http-common'

const state = {
    sequences:  null,
    sequence_key_pairs: {}
}

interface IState {
    sequences: Array<any>,
    sequence_key_pairs: any
}

const mutations ={
    
    addVersion(state: IState, data: any){
        let indexToUpdate = state.sequences.findIndex(s => s.id == data.sourceSequenceId)
        if(indexToUpdate>-1){
            state.sequences[indexToUpdate].versions.push(data.newVersion)
        }
    },
    removeVersion(state: IState, data: any){
        let indexToUpdate = state.sequences.findIndex(s => s.id == data.sequenceid)
        if(indexToUpdate>-1){
            let versionIndex =  state.sequences[indexToUpdate].versions.indexOf(data.version)
            state.sequences[indexToUpdate].versions.splice(versionIndex,1)
        }
    },


    update_template(state: IState, data: any){
        data.project = data.template;
    },

    setProjects(state: IState, pack: any){
        if(!pack.projects)
            pack.projects = []
        state.sequence_key_pairs[pack.seq_id] = pack.projects;
    },

    add_new_projects(state: IState, pack: any){
        if(!state.sequence_key_pairs[pack.id])
            state.sequence_key_pairs[pack.id] = []
        state.sequence_key_pairs[pack.id].push(...pack.projects)
    },

    remove_from_store(state: IState, deleted_projects: any){
        deleted_projects.forEach((project: any) =>{
            state.sequence_key_pairs[project.sequenceId] = state.sequence_key_pairs[project.sequenceId].filter((x: any) => x.uniqueKey != project.uniqueKey)
        })
    },

    add_to_store(state: IState, pack: any){
        if(!state.sequence_key_pairs[pack.id])
            state.sequence_key_pairs[pack.id] = []
        state.sequence_key_pairs[pack.id].push(...pack.projects)
    },

    updateStore(state: IState, pack: any){
        var projects = state.sequence_key_pairs[pack.id];
        if(!pack.projects)return;
        if(projects){
            pack.projects.forEach((update_project: any) => {
                for (let index = 0; index < projects.length; index++) {
                    const project_in_store = projects[index];
                    if(project_in_store.uniqueKey == update_project.uniqueKey){
                        projects[index] = update_project;
                        break;
                    }
                }
            });
        }
    },

    updateYear(state: IState, sequences: any){
        if(!sequences)return;
        if(sequences.length < 1)return;
        var year = sequences[0].year;

        var new_list = state.sequences.filter(x => x.year != year);
        new_list.push(...sequences);
        state.sequences = new_list;
    },

    setSequences(state: IState, sequences: any){
        state.sequences = sequences;
    },

    setSequence(state: IState, data:any){
        if(state.sequences == null){
            state.sequences = []
            state.sequences.push(data)
        }else{
            var index = state.sequences.findIndex( thisSequence => thisSequence.id == data.sequenceId )
            if(index>-1){
                state.sequences[index] = data
            }
            else{
                state.sequences.push(data)
            }
        } 
    },

    deleteSequence(state: IState, sequence: any){
        var _exIndex = state.sequences.indexOf(sequence);
        if(_exIndex > -1)
            state.sequences.splice(_exIndex,1);
    },

    add(state: IState, sequence: any){
        state.sequences.push(sequence);
    },

    update(state: IState, sequence: any){
        var _exIndex = -1;
        if(!state.sequences)return ;
        for (let index = 0; index < state.sequences.length; index++) {
             if(state.sequences[index].id == sequence.id) {
                _exIndex = index;
                 break;
             } 
        }
        if(_exIndex > -1)
            state.sequences.splice(_exIndex,1,sequence);
        
    },

    updateState(state: IState, data: any){
        const indexToUpdate = state.sequences.findIndex(thisSequence =>  thisSequence.id == data.id);
        if(indexToUpdate>-1){
            state.sequences[indexToUpdate].state = data.state
            state.sequences[indexToUpdate].versions = data.versions
        } 
    }
}   

const getters = {
    getSequence:(state: IState) => (id: string) => {
        let sequence = {}
        if( state.sequences == null ) return sequence;
        for ( let index = 0; index < state.sequences.length; index++ ) {
            if(state.sequences[index].id == id) {
                sequence = state.sequences[index];
                break;
            }
        }
        return sequence
    },
    getSequences: (state: IState) =>{
        return state.sequences;
    },
    filteredSequences: (state: IState) => (filter: string) => {
        if(!state.sequences)return [];
        return state.sequences.filter((sequence: any) => {
            return sequence.name.toLowerCase().match(filter.toLowerCase())
        });
    },
    filteredRootSequences: (state: IState) => (filter: string) => {
        if(!state.sequences)return [];
        return state.sequences.filter((sequence: any) => {
            return sequence.name.toLowerCase().match(filter.toLowerCase())&&sequence.isRoot
        });
    },
    getSequenceGroups:(state: IState) =>{
        if(state.sequences == null)return [];
        var distinctYears = [...new Set(state.sequences.map(x => x.year))];
        distinctYears.sort(function(a, b){return a - b});
        var orderedSequences: Array<any> = []
        distinctYears.forEach(year => {
            var yearGroup = {
                Year: year,
                Sequences:state.sequences.filter(x => x.year == year)
            }
            orderedSequences.push(yearGroup)
        });
        return orderedSequences;
    },
    getSequenceProjects:(state: IState) => (id: number) =>{
        if(state.sequence_key_pairs[id])
            return JSON.parse(JSON.stringify(state.sequence_key_pairs[id]));
        else return undefined;
    },
    getRiglessBucketsForSequence:(state:IState) => (id:number) =>{
        return state.sequences.find(sequence => sequence.id == id).riglessBuckets
    }
}

const actions = {
    getSequence({ commit }: { commit : any }, sequenceId:string){
        return new Promise((resolve, reject) => {
            HTTP.get('sequence/'+sequenceId)
            .then((response:any) => {
                commit('setSequence', {newSequence:response.data.data,sequenceId:sequenceId})     
                resolve(response.data.data)
            })
            .catch((error:any) => {
                reject(error)
            })
        })
    },
    fetchSequences ({ commit }: { commit : any }) { 
        return new Promise((resolve, reject) => {
            HTTP.get('sequence')
            .then((response: any) => {
                commit('setSequences', response.data.data)                
                resolve(response)                
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    create ({commit}: {commit: any}, sequence: any) {
        return new Promise((resolve, reject) => {
            HTTP.post('sequence/create', sequence)
            .then((response: any) => {
                commit('add', response.data.data)
                resolve(response)     
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    },
    delete ({ commit} : { commit : any }, sequence : any) { 
        return new Promise((resolve, reject) => {
            HTTP.delete('sequence/delete/' + sequence.id)
            .then((response: any) => {
                commit('deleteSequence', sequence)                
                resolve(response)                
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    edit ({commit} : {commit: any}, sequence: any) {
        return new Promise ((resolve, reject) => {
            HTTP.put('sequence/update/'+sequence.id, sequence)
            .then((res: any) => {
                commit('update', res.data.data)
                resolve(res)
            })
            .catch((err: any) => {
                reject(err)
            })
        })
    },
    createNewVersion({commit} : {commit: any}, version: any){
        return new Promise ((resolve, reject) => {
            HTTP.post("sequence/versioning",version)
            .then((res:any) => {
                commit("addVersion",{sourceSequenceId:version.sourceSequenceId,newVersion:res.data.data})
                resolve(res)
            })
            .catch((err:any) => {
                reject(err)
            })
        })
    },
    deleteVersion({commit} : {commit: any}, data:any){
        return new Promise ((resolve, reject) => {
            HTTP.delete("sequence/version/delete/" +data.version.versionID)
            .then((res:any) => {
                commit("removeVersion",data)
                resolve(res)
            })
            .catch((err:any) => {
                reject(err)
            })
        })
    },
    freeze ({ commit} : { commit : any}, sequence: any) { 
        return new Promise((resolve, reject) => {
            HTTP.post('sequence/freeze' , sequence)
            .then((response: any) => {
                commit('updateYear', response.data.data)                
                resolve(response)                
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    set_active_action({ commit } : { commit: any }, sequence: any) { 
        return new Promise((resolve, reject) => {
            HTTP.post('sequence/activate' , sequence)
            .then((response: any) => {
                commit('updateYear', response.data.data)                
                resolve(response)                
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    clone({commit}: {commit: any}, sequence: any) {
        return new Promise ((resolve, reject) => {
            HTTP.post('sequence/clone', sequence)
            .then((res: any) => {
                commit('add', res.data.data)
                // window.console.log('Cloned Succesfully')  
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    },
    fetchProjectsForSequence({commit} : { commit: any }, id: any){
        return new Promise((resolve, reject) => {
            HTTP.get('sequence/getprojects/' + id).then((response: any) => {
                commit('setProjects', {seq_id: id, projects: response.data.data})
                resolve(response)
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    },
    updateState({commit}:{commit:any},data:any){
        return new Promise((resolve,reject) => {
            HTTP.patch('sequence/state/'+data.id,{newState:data.state}).then((response:any) => {
                commit("updateState",response.data.data)
                resolve()
            }).catch((error:any) => {
                reject(error)
            })
        })
    },
    broadcast({commit}:{commit:any},data:any){
        return new Promise((resolve,reject) => {
            HTTP.post('sequence/broadcast',data).then((response:any) => {
                resolve(null)
            }).catch((error:any) => {
                reject(error)
            })
        })
    },
    broadcastWithAttachment({commit}:{commit:any},data:any){
        return new Promise((resolve,reject) => {
            HTTP.post('mail/sendmail',data,).then((response:any) => {
                resolve(null)
            }).catch((error:any) => {
                reject(error)
            })
        })
    }

}

const sequencesModule = {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}

export default sequencesModule;





































import Vue from 'vue'
import Component from 'vue-class-component';
import EventBus from './../../eventBus'

@Component
export default class Login extends Vue{
  // Data
  user: object = {
      userName: '',
      password: ''
  }
  nextURL: string = ''
  error: any = {
      generalMessage: '',
      errors: []
  }
  activeScreen: string = 'main'

  //computed
  get activeUser () {
    return this.$store.state.activeUser
  }

  // methods
  onSubmit() {
    this.activeScreen = 'loading'
    EventBus.$emit('activeScreen', this.activeScreen)
    this.$store.dispatch("authenticateUser", this.user).then((response: any) => {
      if(response.isFirstLogin == true){
         this.$router.replace({name:'change-password'})
        }
      else{
        if( this.nextURL ){
          this.$router.replace({ path : this.nextURL })
        } else {
          if(this.activeUser.roles.indexOf("admin") > -1){
            this.$router.replace({path:'/sequences'})
          }else{
            this.$router.replace({path:'/sequences'})
          }
        }
      }

    }).catch((err: any) => {
      this.error.generalMessage = err.message
      this.activeScreen = "main"
      EventBus.$emit('activeScreen', this.activeScreen)
    });
  }

}













































































































































































import Vue from 'vue'
import {Component, Mixins} from 'vue-property-decorator'
import EventBus from '../../eventBus'
import { required } from 'vuelidate/lib/validators'
import { Validations } from 'vuelidate-property-decorators'
import UserProvisioning from '@/mixins/userProvisioning'

@Component
export default class BudgetingTemplate extends Mixins(UserProvisioning) {
    @Validations()
    validations = {
        activeTemplate: {
            templateName: {required},
            //departmentId: {required},
            sections: {
                required, 
                $each: {
                    departmentId:{ required },
                    sectionName: { required },
                    budgetLineItems: { required, $each: {required} }
                }
            },
        }
    }
    private activeScreen: string = 'loading'
    private error: string = '';
    private additionalSuccessMessage: string = '';
    private successMessage: string = '';
    private loadingMessage: string = '';
    private loadingTitle: string = '';
    private activeView: string = 'templates'
    private showForm: boolean = false;
    private isUpdate: boolean = false;
    private search: string = '';
    private deletePromptProperty: boolean = false
    moreInfo= false
    private activeTemplate: any = {
        id: '',
        templateName: '',
        departmentId: '',
        sections: []
    };
    // private budgetLineFields: any[] = [
    //     { key: 'lineItem', label: 'Name'},
    //     { key: 'numberOfDependentTemplates', label: 'No of Dependent Template'},
    //     { key: 'buttons', label: 'Actions'},
    // ];
    formErrors: string[] = []

    get isButtonDisabled ():boolean {
        if(this.formErrors.length > 0 || this.$v.activeTemplate.$invalid) {
            return true
        } else {
            return false
        }
    }

    get getFields(){
        if(this.canWriteBudgetSettings){
            return  [{ key: 'templateName', label: 'Name'},
                     //{ key: 'departmentId', label: 'Dept.'},
                     { key: 'budgetLineItems', label: 'Info'},
                     { key: 'button', label: 'Actions'},
                    ];
                    }
        return  [{ key: 'templateName', label: 'Name'},
                //{ key: 'departmentId', label: 'Dept.'},
                { key: 'budgetLineItems', label: 'Info'},
                ];
    }
    get checkDept (): boolean {
        if (!this.activeTemplate.departmentId) {
            return false
        } else {
            return true
        }
    }

    checkErrors() {
        this.formErrors = []

        if(this.activeTemplate.templateName.length < 1) {
            this.formErrors.push('Template name is Required')
        }
        /*
        if(!this.checkDept) {
            this.formErrors.push('Template Department is Required')
        }
        */
    }

    private get getAllTemplates () {
        return this.$store.getters['budgetTemplateModule/getAllTemplates'];
    }

    private get getAllBudgetLines () {
        let allSelectedBudgetLineItems:Array<any> = []
        this.activeTemplate.sections.forEach((s:any) => {
            s.budgetLineItems.forEach((b:any) => allSelectedBudgetLineItems.push(b.id))
        })
        let allBudgetLines = this.$store.getters['budgetLineModule/getAllBudgetLines'].map((thisBudgetLine:any) => ({value:thisBudgetLine.id,text:thisBudgetLine.lineItem,disabled:allSelectedBudgetLineItems.includes(thisBudgetLine.id)}))
        return allBudgetLines
    }

    private getDepartmentById(deptId:any) {
        let returnedDept = this.$store.getters['departmentModule/getDepartmentById'](deptId)
        if(returnedDept==null) return {name:""}
        return returnedDept;
    }

    private get getDepartmentOptions() {
        return this.$store.getters['departmentModule/getDepartmentOptions'];
    }

    private editTemplate (e: any) {
        this.showForm = true;
        this.isUpdate = true;
        this.activeTemplate.id = e.id
        this.activeTemplate.templateName = e.templateName;
        this.activeTemplate.departmentId = e.departmentId;
        this.activeTemplate.sections = e.sections
        this.$v.$reset()
        this.checkErrors()
    }

    private AddSection(){
        this.activeTemplate.sections.push({
            sectionName:"",
            budgetLineItems:[]
        })
    }

    private removeSection(sectionIndex:number){
        this.activeTemplate.sections.splice(sectionIndex,1)
    }

    private AddBudgetLine(sectionIndex:number){
        this.activeTemplate.sections[sectionIndex].budgetLineItems.push({
            id:null,
            lineItem:"",

        })
    }

    private removeBudgetLineItem(sectionIndex:number,budgetLineIndex:number){
        this.activeTemplate.sections[sectionIndex].budgetLineItems.splice(budgetLineIndex,1)
    }

    private searchValue(e: any) {
        this.search = e;
    }

    private promptDelete(item: any){
        this.activeTemplate = item;
        this.deletePromptProperty = true
    }

    private doDelete (template: any) {
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.loadingMessage = 'Please wait while we delete this template'
        this.loadingTitle = 'Deleting...'
        this.$store.dispatch('budgetTemplateModule/deleteBudgetTemplate', this.activeTemplate).then((response:any) => {
            this.activeScreen = 'successNoBack'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.additionalSuccessMessage = 'This template will no longer be available'
            this.successMessage = this.activeTemplate.templateName + ' deleted succesfully'
            this.deletePromptProperty = false
        },(error:any) => {          
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.response.data.message
            this.deletePromptProperty = false
        })
    }

    private saveTemplate() {
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        let templateToPersist: any  = {
            id:this.activeTemplate.id,
            templateName:this.activeTemplate.templateName,
            departmentId:parseInt(this.activeTemplate.sections[0].departmentId), 
            budgetTemplateSections: this.activeTemplate.sections.map((s:any)=> ({id:s.id,sectionName:s.sectionName,departmentId:s.departmentId,budgetLineItemIds:s.budgetLineItems.map((b:any)=>b.id)}))
        }
        // Update existing Budget Template
        if(this.isUpdate){
            this.loadingMessage = 'Please wait while we update this template'
            this.loadingTitle = 'Updating...'
            this.$store.dispatch('budgetTemplateModule/updateBudgetTemplate', templateToPersist).then((response: any) => {
                this.activeScreen = 'successNoBack'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.additionalSuccessMessage = ''
                this.successMessage = 'Budget Template Updated Successfully'
            }).catch((error: any) => {
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.error = 'Please, ensure all fields are filled up and a budget line item is selected'
            })
        }
        // Create New Budget Template
        else{
            this.loadingMessage = 'Please wait while we create new template'
            this.loadingTitle = 'Saving...'
            this.$store.dispatch('budgetTemplateModule/createNewBudgetTemplate', templateToPersist).then((response: any) => {
                this.activeScreen = 'successNoBack'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.additionalSuccessMessage = ''
                this.successMessage = 'Successfully Created '+ templateToPersist.templateName
            }).catch((error: any) => {
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.error = 'Please, ensure all fields are filled up and a budget line item is selected'
            })
        }
    }

    private view(data: any) {
        this.activeTemplate = data
        this.moreInfo = true
    }

    private backToTemplates () {
        this.activeView = "templates";
        this.showForm = false;
    }

    private newBudegetTemplate () {
        this.activeView = 'operations';
        this.isUpdate = false;
        this.showForm = true;
        this.activeTemplate.templateName = '';
        this.activeTemplate.departmentId = '';
        this.activeTemplate.sections = [];
        this.$v.$reset()
        this.checkErrors()
    }

    private addBudgetLine(el:any) {
        this.activeTemplate.budgetLineItems.push({
            id: el.id,
            lineItem: el.lineItem,
            numberOfDependentTemplates: el.numberOfDependentTemplates
        })
    }

    private removeBudgetLine(index: number) {
        this.activeTemplate.budgetLineItems.splice(index,1)
    }

    private getNumberOfLines(template:any){
        let totalNumberOfSections = 0
        if(!template.sections) return 0
        template.sections.forEach((section:any) => {
            totalNumberOfSections+=section.budgetLineItems.length
        });
        return totalNumberOfSections
    }

    mounted() {
        this.loadingMessage = 'Loading Budget Templates';
        this.loadingTitle = 'Loading...';
        
        if(this.getDepartmentOptions.length<1){
            this.$store.dispatch('departmentModule/getDepartments').then(()=> {
                 if (this.getAllTemplates == 0){
                    this.$store.dispatch('budgetTemplateModule/fetchBudgetTemplates')
                    this.activeScreen = 'main'
                    EventBus.$emit('activeScreen', this.activeScreen)
                 } else {
                    this.activeScreen = 'main'
                    EventBus.$emit('activeScreen', this.activeScreen)
                }
            })
        }else{
            if (this.getAllTemplates == 0){
                this.$store.dispatch('budgetTemplateModule/fetchBudgetTemplates')
            } else {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
            }
        }
        

        if(this.getAllBudgetLines.length<1){
            this.$store.dispatch('budgetLineModule/fetchBudgetLines')
        }
    }
}














































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import UnitConverter from '../../../mixins/unitConverter.ts'
import project from '../../../store/modules/project'
import convertUnit from '../../../utils/convertUnit.ts'
import convertSymbol from '../../../utils/convertSymbol.ts'

@Component({
    name:"scopeofwork",
    mixins:[UnitConverter]
})
export default class ScopeOfWork extends Vue {

    @Prop()
    projectId !:string

    activeView = 1
    objective = ""
    estimatedProduction = 0
    targets = []
    isLoading = false
    hasError = false
    active_target = {}
    isViewingTargetList = true

    targetFields = [
        {
            key:"name",
            label:"Reservoir Name"
        },
        {
            key:"northing",
            label:"Northing"
        },
        {
            key:"easting",
            label:"Easting"
        },
        {
            key:"depth",
        },
        {
            key:"actions",
            label:"Actions"
        }
    ]

    get project():any{
        return this.$store.getters['projectModule/getProjectById'](this.projectId)
    }
    
    get currentUnitSystem(){
        return this.$store.getters['UnitsModule/getCurrentUnitSystem']
    }

    onObjectiveChanged(newVal:any){
        this.$store.commit("projectModule/updateProject",{sequenceId:this.project.sequenceId,id:this.project.id,objective:newVal})
    }

    onEstimatedProductionChanged(newVal:any){
        this.$store.commit("projectModule/updateProject",{sequenceId:this.project.sequenceId,id:this.project.id,estimatedProduction:newVal})
    }

    gotoNext(){
        if(this.activeView<=1){
            this.activeView++
        }
    }

    gotoPrevious(){
        if(this.activeView>1)this.activeView--
    }

    addTarget(){
        let target = {
            id:"",
            name:"",
            northing:0,
            easting:0,
            depth: 0
        }
        this.$store.commit("projectModule/addOrUpdateTargetToProject",{sequenceId:this.project.sequenceId,id:this.project.id,target})
    }

    removeTarget(target:any){
        this.$store.commit("projectModule/deleteTargetInProject",{sequenceId:this.project.sequenceId,id:this.project.id,target})
    }

    registerProjectChange(){
        this.$store.commit("projectModule/registerProjectChange",{sequenceId:this.project.sequenceId,projectId:this.project.id})
    }

    @Watch('project',{immediate:true})
    onProjectChangeHandler(){
        this.estimatedProduction = this.project.estimatedProduction
        this.objective = this.project.objective
        this.project.well.normDepth.value = convertUnit(this.project.well.normDepth.value, this.currentUnitSystem, this.project.well.normDepth.uom)
        this.project.well.trueDepth.value = convertUnit(this.project.well.trueDepth.value, this.currentUnitSystem, this.project.well.trueDepth.uom)
        this.project.well.targetDepth.value = convertUnit(this.project.well.targetDepth.value, this.currentUnitSystem, this.project.well.targetDepth.uom)
        if(this.project.targets == null){
            this.project.targets = []
        }else{
            this.project.targets = this.project.targets.map((t:any)=>({id:t.id,name:t.name,northing:t.northing,easting:t.easting,depth:convertUnit(t.depth.value,this.currentUnitSystem,t.depth.uom)}))
        }
    }
}

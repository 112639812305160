import { HTTP } from '@/axios_api/http-common' 

const state = {
  departments: [],
  teams: []
}

interface IState {
    departments: Array<IDepartments>
    teams:any
}

interface IDepartments {
    id: number,
    name: string,
    teams: Array<any>
}

const mutations = {
    setDepartments (state: IState, departments: Array<IDepartments>) {
        state.departments = departments
    },
    setTeams (state: IState, teams: any) {
        state.teams = teams
    },
    addDepartment(state: IState, department: IDepartments){
        state.departments.push(department)
    },
    addTeam(state:IState,team:any){
        let indexToUpdate = state.departments.findIndex(d=> d.id==team.departmentId)
        if(indexToUpdate>-1)state.departments[indexToUpdate].teams.push(team)
    },
    updateDepartment(state: IState, department: IDepartments){
        var _exIndex = -1;
        for (let index = 0; index < state.departments.length; index++) {
            if(state.departments[index].id == department.id) {
               _exIndex = index;
                break;
            } 
       }
       if(_exIndex > -1)
           state.departments.splice(_exIndex,1,department);
    },
    updateTeam(state:IState,team:any){
        let departmentIndexToUpdate = state.departments.findIndex(d=> d.id==team.departmentId)
        let teamIndexToUpdate = state.departments[departmentIndexToUpdate].teams.findIndex(t=>t.id==team.id)
        state.departments[departmentIndexToUpdate].teams.splice(teamIndexToUpdate,1,team)
    },
    deleteDepartment(state: IState, id: number){
        for (let index = 0; index < state.departments.length; index++) {
            if(state.departments[index].id == id) {
                state.departments.splice(index,1)
                break;
            }
       }
    },
    deleteTeam(state:IState,data:any){
        let departmentIndexToUpdate = state.departments.findIndex(d=> d.id==data.departmentId)
        let contractIndexToUpdate = state.departments[departmentIndexToUpdate].teams.findIndex(t=>t.id==data.id)
        state.departments[departmentIndexToUpdate].teams.splice(contractIndexToUpdate,1)
    },
}

const getters = {
    getDepartmentOptions:(state: IState)=>{
        let departmentOptions: Array<any>=[]
        state.departments.forEach((department: any)=>{
            departmentOptions.push({text:department.name,value:department.id})
        })
        return departmentOptions
    },

    getAllDepartments: (state: IState) => {
        return state.departments
    },
 

    getAllTeams:()=>{
        return state.teams
    },

    fillteredDepartments:(state: IState) =>(filter: string)=> {
        if(!state.departments)return [];
        return state.departments.filter((department: any) => {
            return department.name.toLowerCase().match(filter.toLowerCase())
        });
    },

    getDepartmentById:(state: IState)=>(id: number)=>{
        let department = null
        for (let index = 0; index < state.departments.length; index++) {
             if(state.departments[index].id == id) {
                 department = state.departments[index];
                 break;
             } 
        }
        return department
     }
}

const actions = {
    getDepartments ({ commit } : { commit: any}) {       
        return new Promise((resolve, reject) => {
            HTTP.get('departments')
            .then((response: any) => {
                commit('setDepartments', response.data.data)         
                resolve(response)                    
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    },
    getTeams ({ commit } : { commit: any}) {       
        return new Promise((resolve, reject) => {
            HTTP.get('departments/teams')
            .then((response: any) => {
                commit('setTeams', response.data.data)         
                resolve(response)                    
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    },
    createDepartment ({ commit } : { commit: any }, department_object: any) {
        
        return new Promise((resolve, reject) => {
            HTTP.post('departments/create', department_object)
            .then((response: any) => {
                commit('addDepartment',response.data.data) 
                resolve(response)
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    updateDepartment ({ commit }: { commit: any }, department: any) {
        return new Promise((resolve, reject) => {
            // window.console.log(department.id)
            HTTP.put('departments/update/' + department.id, department )
            .then((response: any) => {
                commit('updateDepartment', department)
                resolve(response)
            })
            .catch((error: any) => {
                reject(error)
            });
        })        
    },
    deleteDepartment ({ commit } : { commit : any }, id: any) {
        return new Promise((resolve, reject) => {
            HTTP.delete('departments/delete/' +  id)
            .then((response: any) => {
                
                commit('deleteDepartment', id)

                resolve(response)
            })
            .catch((error: any) => {
                reject(error)
            })
        })      
    },
    createTeam({commit}:{commit:any},team:any){
        return new Promise((resolve,reject)=>{
            HTTP.post('departments/team/create',team).then((response:any) => {
                commit('addTeam',response.data.data) 
                resolve(response);
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    updateTeam({commit}:{commit:any},team:any){
        return new Promise((resolve,reject)=>{
            HTTP.put('departments/team/update/'+team.id,team)
            .then((response:any) => {
                commit('updateTeam', response.data.data)
                resolve(response)
            })
            .catch((error:any)=>{
                reject(error)
            })
        })
    },
    deleteTeam({ commit } : {commit: any}, data: any) {
        return new Promise((resolve, reject) => {
            HTTP.delete('departments/team/delete/' + data.id)
                .then((response: any) => {
                    commit('deleteTeam', data)
                    resolve(response)
                })
                .catch((error: any) => {
                    reject(error)
                })
        })
    }, 
}

const departmentModule = {
  namespaced:true,
  state,
  mutations,
  getters,
  actions
}

export default departmentModule;

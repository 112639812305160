




































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import EventBus from './../../eventBus'
import { required, minLength } from "vuelidate/lib/validators"; 
import { Validations } from 'vuelidate-property-decorators';
import UnitConverter from '../../mixins/unitConverter.ts'
import convertUnit from '../../utils/convertUnit.ts'

@Component({
    mixins: [UnitConverter]
})
export default class ManageWell extends Vue {
    @Validations()
    validartions = {
        well: {
            wellType: { required },
            wellName: { required, minLength: minLength(3) },
            wellId: { required },
            wellConfiguration:{ required },
        }
    }
    activeScreen: string = 'main'
    loadingMessage: string = 'Please wait while we create your new well'
    loadingTitle: string = 'Saving...'
    additionalSuccessMessage: string = 'You can now use the newly created well'
    successMessage: string = 'New Well Created Successfully'
    links: Array<object> = [
        {
            text: 'Wells',
            to: '/wells'
        },
        {
            text: 'Manage Wells',
            active: true
        }
    ]
    errorMessage: boolean = false
    isCreate: boolean = true
    error: any = {
        generalMessage: '',
        errors: []
    }
    configurations: object = {
        Vertical: 'Vertical',
        Horizontal: 'Horizontal',
        Deviated: 'Deviated',
        MultiLateral: 'MultiLateral'
    }
    wellTypes: object = {
        Gas : 'Gas', 
        OilGas : 'Oil/Gas',
        Oil: 'Oil',
        Condensate: 'Condensate'
    }
    completionTypes: object = {
        Single: 'Single', 
        Dual: 'Dual'
    }
    statuses: object = {
        Approved : 'Approved', 
        Not_Approved : 'Not Approved'
    }
    Terrains: object = {
        Land : 'Land', 
        Swamp : 'Swamp', 
        Offshore : 'Offshore'
    }
    CompletionSizes: Array<object> = [
        {value: '3/4', text: '3/4'},
        {value: '1', text: '1'},
        {value: '1-1/4', text: '1-1/4'},
        {value: '1-1/2', text: '1-1/2'},
        {value: '2-1/16', text: '2-1/16'},
        {value: '2-3/8', text: '2-3/8'},
        {value: '2-7/8', text: '2-7/8'},
        {value: '3-1/2', text: '3-1/2'},
        {value: '4', text: '4'},
        {value: '4-1/2', text: '4-1/2'},
    ]
    well: any = {
        wellName: '',
        wellId: '',
        oml: '',
        wellType: null,
        targetDepth:0,
        x: '',
        y: '',
        completionSize1: '',
        completionSize2: '',
        status: null,
        wellConfiguration: null,
        wellHeadPressure: 0,
        estimatedProduction: 0,
        field: '',
        spudDate: new Date(),
        tdDate: new Date(),
        terrain: 'Land',
        normDepth: 0,
        trueDepth: 0,
        completionType: 'Single',
        dateOfCompletion: new Date()
    }

    get checkWellType (): boolean {
        if (!this.well.wellType) {
            return false
        } else {
            return true
        }
    }

    get checkWellConfig (): boolean {
        if (!this.well.wellConfiguration) {
            return false
        } else {
            return true
        }
    }

    get isButtonDisabled(): boolean {
        let arr = [this.well.wellHeadPressure, this.well.normDepth, this.well.trueDepth, this.well.targetDepth]
        if (this.formErrors.length > 0 || arr.includes('')) {
            return true
        } else {
            return false
        }
    }
    formErrors: string[] = []

    checkErrors () {
        this.formErrors = []

        if (this.well.wellName.length < 1) {
            this.formErrors.push("Well Name is required")
        }
        if (this.well.wellId.length < 1) {
            this.formErrors.push("Well ID is required")
        }
        if (!this.checkWellConfig) {
            this.formErrors.push("Well Configuration is required")
        }
        if (!this.checkWellType) {
            this.formErrors.push("Fluid Type is required")
        }
    }


    // METHODS
    incomingDataTransformation (well: any) {
        this.well.wellName = well.name
        this.well.wellId = well.wellId
        this.well.oml = well.oml
        this.well.wellType = well.wellType

        this.well.x = well.location.split(",")[0]
        this.well.y = well.location.split(",")[1]
        this.well.completionType = well.completionType

        
        this.well.normDepth = convertUnit(well.normDepth.value, this.currentUnitSystem, well.normDepth.uom)
        this.well.trueDepth = convertUnit(well.trueDepth.value, this.currentUnitSystem, well.trueDepth.uom)
        this.well.targetDepth = convertUnit(well.targetDepth.value, this.currentUnitSystem, well.targetDepth.uom)

        this.well.completionSize1 = well.completionSize
        this.well.completionSize2 = well.completionSize2
        
        this.well.wellConfiguration = well.wellConfiguration

        this.well.wellHeadPressure = convertUnit(well.wellHeadPressure.value, this.currentUnitSystem, well.wellHeadPressure.uom)
        this.well.field = well.field
        this.well.terrain = well.terrain,
        this.well.completionType = well.completionType,
        

        this.well.spudDate = new Date(well.spudDate)
        this.well.tdDate = new Date(well.tdDate)
        this.well.dateOfCompletion =  new Date(well.dateOfCompletion)
        
        this.well.id = well.id
    }

    outgoingDataTransformation (well: any){
        well.id = this.well.id
        well.Name = this.well.wellName
        well.WellId = this.well.wellId
        well.WellType = this.well.wellType
        well.oml = this.well.oml

        well.TargetDepth = this.well.targetDepth
        well.Location = this.well.x+","+this.well.y
        well.CompletionType = this.well.completionType

        well.CompletionSize1 = this.well.completionSize1
        well.CompletionSize2 = this.well.completionSize2
        well.WellConfiguration = this.well.wellConfiguration

        well.WellHeadPreassure = this.well.wellHeadPressure
        well.field = this.well.field
        well.terrain = this.well.terrain
        well.trueDepth = this.well.trueDepth
        well.normDepth = this.well.normDepth
        well.completionType = this.well.completionType

        well.SpudDate = this.well.spudDate.toISOString()
        well.TDDate = this.well.tdDate.toISOString()
        well.DateOfCompletion = this.well.dateOfCompletion.toISOString()
    }

    onSubmit (evt: any) {
        evt.preventDefault();
        this.error.generalMessage = ''

        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        var well = {}
        if (this.isCreate) {
            this.outgoingDataTransformation(well)
            this.loadingTitle = 'Saving...'
            this.loadingMessage = "Please wait while we create new well."
            this.createWellAction(well).then(response => {
                this.activeScreen = 'successRemain'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.successMessage = 'New Well Created Successfully'
                this.additionalSuccessMessage = 'You can now use the newly created well'
                this.well.id = ''
                this.well.wellName= ''
                this.well.wellId = ''
                this.well.wellType = ''
                this.well.oml = ''
                this.well.targetDepth = 0
                this.well.y = ''
                this.well.x = ''
                this.well.completionType = ''
                this.well.completionSize2 = ''
                this.well.wellConfiguration = ''
                this.well.wellHeadPressure = 0
                this.well.field = ''
                this.well.terrain = 'Land'
                this.well.trueDepth = 0
                this.well.normDepth = 0
                this.well.completionType = 'Single'
                this.well.SpudDate = new Date()
                this.well.TDDate = new Date () 
                this.well.DateOfCompletion = new Date()
                this.$v.$reset()
                setTimeout(() => {
                    this.activeScreen = 'main'
                    EventBus.$emit('activeScreen', this.activeScreen)                    
                }, 10000);
            },error => {
                this.error.generalMessage = error.response.data.message
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
                setTimeout(() => {
                    this.activeScreen = 'main'
                    EventBus.$emit('activeScreen', this.activeScreen)                    
                }, 10000);
            });
        } else {
            this.outgoingDataTransformation(well)
            this.loadingTitle = 'Saving...'
            this.loadingMessage = "Please wait while we update this well."
            this.updateWellAction(well).then(
            response => {
                this.activeScreen = 'successNoGotIt'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.successMessage = 'Well Updated Successfully'
                this.additionalSuccessMessage = ''
            },
            error => {
                this.error.generalMessage = error.response.data.message
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
            });
        }
    }
    get currentUnitSystem(){
        return this.$store.getters['UnitsModule/getCurrentUnitSystem']
    }




    // VUEX METHODS
    getWellsAction () {
        return this.$store.dispatch('wellsModule/getWellsAction')
    }

    createWellAction (well_object: any) {
        return this.$store.dispatch('wellsModule/createWellAction', well_object)
    }

    updateWellAction (well: any) {
        return this.$store.dispatch('wellsModule/updateWellAction', well)
    }




    // COMPUTED PROPERTIES
    get filteredWells () {
        return this.$store.getters['wellsModule/filteredWells']
    }

    get getWellById () {
        return this.$store.getters['wellsModule/getWellById']
    }



    // CREATED
    created() {
        let queryId = this.$route.query.id;

        if (queryId) {
            
            this.isCreate = false

            let well = this.getWellById(queryId)

            if(well){
                // window.console.log(well)
                this.incomingDataTransformation(well)
            }
            else{
                this.getWellsAction().then(r=>{
                    well = this.getWellById(queryId)
                    this.incomingDataTransformation(well)
                },e=>{
                    
                })
            }
            
        } else {
            this.checkErrors()
        }
    }
}



















































































































































import Vue from 'vue'
import SequenceVersion from './SequenceVersion'
import EventBus from './../../eventBus'
import ActivityPane from '../../components/RightPaneComponents/ActivityPane'
import InfoCost from '../../components/RightPaneComponents/InformationCost'
import RigInfo from '../../components/RightPaneComponents/RigInfo'
import RiglessInfo from '../../components/RightPaneComponents/RiglessInfo'
import TimeUnitsComponent from '../../components/RightPaneComponents/timeUnitsComponent'
import sequeneceVersionModal from './Modals/SequenceVersionModal'
import review from '@/components/reviewTable.vue'
import sequencesModule from '@/store/modules/sequence'

export default Vue.extend({
    props:['sequenceId'],
    data(){
        return {
            canSaveActivity:true,
            showNotifications:false,
            showReview: false,
            busy:false,
            historyMode:false,
            savingStatusMessage:"",
            showFundingMechnism:0,
            showWBS:0,
            viewMode:"ROW",
            activeVersions:[] as Array<any>,
            selectedSequenceVersion:{name:"",projects:[]},
            sequenceVersionModal: false,
            projectId: '',
            visibleWellActivities:[] as Array<any>,
            showDelete:false

        }
    },
    computed:{
        fluidTypes():any{
            return this.$store.getters["settingsModule/getSettings"].filter((x:any)=> x.id>4&&x.id<=8)
        },
        fnudingMechanismLegend():any{
            return this.$store.getters['fundingsModule/getFundings'].map((f:any) => {
                return {name:f.code,color:'#'+f.lineColor,style:f.lineStyle.toLowerCase()}
            })
        },
        sequence():any{
            var retrieveSequence = this.$store.getters['sequencesModule/getSequence'](this.sequenceId)

            if(Object.entries(retrieveSequence).length>0)return retrieveSequence
            this.$store.dispatch('sequencesModule/fetchSequences')
            return {versions:[]}
        },
        sidenav():any{
            return this.$store.getters['getSideNavStatus']
        },
        rightPane():any{
            return this.$store.getters['getPaneStatus']
        },
        activePane():any{
            return this.$store.getters['getActivePane']
        },
        activeUser():any{
            return this.$store.getters['activeUser']
        },
        unreadNotifications():Number{
            return this.$store.getters["Notifications/getNumberOfUnreadNotifications"]
        },
        activities(): any{
            if (this.projectId !== '') {
                return this.$store.getters['ActivityModule/getActivitiesForProject'](this.projectId)
            } else {
                return []
            }
        },
        wellActivities (): any {
            return this.$store.getters["wellactivitiesModule/getWellOperations"].filter((w:any) => this.visibleWellActivities.includes(w.id))
        },
        computedColumnSpan():any{
            if(
                (this.viewMode == "COLUMN"&&this.activeVersions.length<2)||
                this.viewMode == "STACKED"||this.viewMode == "ROW"
            ){
                return 12
            }else{
                return 6
            }
        },
        //check here for the height settings ************************************************************************************
        computedColumnHeight():any{
            if(
                (this.viewMode == "COLUMN"&&this.activeVersions.length <3)||
                this.viewMode == "STACKED"||
                (this.viewMode == "ROW"&&this.activeVersions.length <2)
            ){
                return '75vh'
            }else{
                return '35vh'
            }
        },
        showNavigationControls(): any {
            if(
                (this.viewMode == "COLUMN"&&this.activeVersions.length >4)||
                (this.viewMode == "STACKED"&&this.activeVersions.length >1)||
                (this.viewMode == "ROW"&&this.activeVersions.length >2)
            ){
                return true
            }else{
                return false
            }
        },
        getProjectChangesCount():any{
            return this.$store.getters['projectModule/getProjectChangesCount']
        },
        getActivityChangesCount():any{
            return this.$store.getters['ActivityModule/getActivityChangesCount']
        },
        getMilestoneChangesCount():any{
            return this.$store.getters['MilestoneModule/getMilestoneChangesCount']
        },
        getDailyReportChangesCount():any{
            return this.$store.getters['DailyReportModule/getDailyReportChangesCount']
        },
        getTotalChanges():any{
            return this.getProjectChangesCount+this.getActivityChangesCount+this.getMilestoneChangesCount+this.getDailyReportChangesCount
        },
        getProjectChangesValidity():any{
            return this.$store.getters['projectModule/getProjectChangesValidity']
        },
        getActivityValidityState():any{
            return this.$store.getters['ActivityModule/getActivityValidityState']
        }
    },
    methods:{
        getReviewData(selectedVersion:any){
            this.selectedSequenceVersion = selectedVersion
            this.showReview = true
        },
        deleteVersion(selectedVersion:any,sequenceid:any){
        this.$store.dispatch("sequencesModule/deleteVersion",{version:selectedVersion,sequenceid:sequenceid}).then(response => {
        })
        },
        projectIdChange(data: any) {
            this.projectId = data
        },
        onFundingMechanismVisibilityChanged(state:boolean){
            //basically, each time any sequece version request for funding mechanism, 
            //increase the number,if a version turns it off, then decrease it, if its zero, no version needs the legend,
            //if its greater than zero, then at least one version needs the legend
            if(state){
                this.showFundingMechnism++
            }else{
                this.showFundingMechnism--
            }
        },
        onNotificationClicked(){
           this.showNotifications=!this.showNotifications
            this.$store.dispatch("Notifications/updateNotificationStatus")
        },
        onWorkBrakdownScheduleChanged(state:boolean){
            if(state){
                this.showWBS++
            }else{
                this.showWBS --
            }
            window.console.log(this.showWBS)
        },

        onVisibleActivitiesChanged(activities:Array<any>){
            activities.forEach(a=>{
                if(!this.visibleWellActivities.includes(a))this.visibleWellActivities.push(a)
            })
        },

        
        toggleSideNav(){
            this.$store.commit('setSideNav')
            this.$router.back()
        },
        upBackNavClicked():void{
            if(this.viewMode!="STACKED"){
                //implementation coming soon
            }else{
                //implementation coming soon
            }
        },
        downForwardNavClicked(){
            if(this.viewMode!="STACKED"){
                // window.console.log("down")
                //implementation coming soon
            }else{
                //implementation coming soon
                // window.console.log("forward")
            }
        },
        createDelayedMessage(message:string,showImmediately:boolean = false){
            if(showImmediately){
                this.savingStatusMessage  = message
            }else{
                setTimeout(() => this.savingStatusMessage  = message, 1000);
            }
        },
        saveChanges():any{
            this.busy = true

            if(this.getProjectChangesCount>0)this.createDelayedMessage("Saving "+this.getProjectChangesCount+" projects",true)
            
            this.$store.dispatch("projectModule/saveChanges").then((res:any) => {
                if(this.getProjectChangesCount>0)this.createDelayedMessage("Successfully saved "+this.getProjectChangesCount+" projects",true)
                this.$store.commit("projectModule/clearProjectChanges")
                if(this.getActivityChangesCount>0)this.createDelayedMessage("Saving "+this.getActivityChangesCount+" activities")
                this.$store.dispatch("ActivityModule/saveChanges").then((activityTempActualKeyPairs:any) => {
                    if(this.getActivityChangesCount>0)this.createDelayedMessage("Successfully saved "+this.getActivityChangesCount+" activities",true)
                    this.$store.commit("ActivityModule/clearActivityChanges")
                    if(this.getMilestoneChangesCount>0)this.createDelayedMessage("Saving "+this.getMilestoneChangesCount+" milestones")
                    this.$store.dispatch("MilestoneModule/saveChanges",activityTempActualKeyPairs).then((milestoneTempActualKeyPairs:any) => {
                        if(this.getMilestoneChangesCount>0)this.createDelayedMessage("Successfully saved "+this.getMilestoneChangesCount+" milestones",true)
                        this.$store.commit("MilestoneModule/clearMilestoneChanges")
                        if(this.getDailyReportChangesCount>0)this.createDelayedMessage("Saving "+this.getDailyReportChangesCount+" daily reports")
                        this.$store.dispatch("DailyReportModule/saveChanges").then(() => {
                            if(this.getDailyReportChangesCount>0)this.createDelayedMessage("Successfully saved "+this.getDailyReportChangesCount+" daily reports",true)
                            this.$store.commit("DailyReportModule/clearDailyReportChanges")
                            setTimeout(() => this.busy = false, 1000);
                        }).catch(e => {
                            this.busy = false
                        })
                    }).catch(e => {
                        this.busy = false
                    })
                }).catch(e => {
                    this.busy = false
                })
            }).catch(e => {
                this.busy = false
            })
        },
        toggleHistoryMode(val:boolean){
            this.$store.commit("projectModule/setHistoryMode",val)
        },
        onPrintSequence(id:any){

        },
        createNewVersionPrompt(){

        },
        createNewVersion(){
            this.sequenceVersionModal = true
        },
        logout(){
            this.$router.push("/")
            this.$store.dispatch("logOut")
        }
    },
    watch:{
        
    },
    filters:{
        dateformatter(value:any){
            let passedDate = new Date(value)
            return passedDate.getDay()+"/"+passedDate.getMonth()+"/"+passedDate.getFullYear()
        }
    },
    mounted(){
        this.$store.commit('setSideNavToFalse')
        this.activeVersions.push(this.sequenceId)
        if(this.fnudingMechanismLegend.length<1)this.$store.dispatch("fundingsModule/fetchFundings")
        if(this.wellActivities.length<1)this.$store.dispatch("wellactivitiesModule/getWellActivities")
        if(this.$store.getters["Notifications/isNotificationEmpty"])this.$store.dispatch("Notifications/getNotificaionsAction")
    },
    components: {
        'sequence-version':SequenceVersion,
        'activity-pane':ActivityPane,
        'rig-info':RigInfo,
        'time-units-component':TimeUnitsComponent,
        'info-cost':InfoCost,
        'sequence-version-modal': sequeneceVersionModal,
        'rigless-info': RiglessInfo,
        'review-table': review
    }
})

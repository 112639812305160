



















import ProjectChart from './ProjectChart'
import ActivityChart from './ActivityChart'
import ProjectSheet from './Modals/ProjectReport'
import Vue from 'vue'
export default Vue.extend({
props:['currentHieght','currentWidth','versionId','sideNav','readOnlyView'],
name: 'app',
  data(){
    return {
      activeView:"projects",
      selectedProjectId:""
    }
  },
  
  computed:{
    computedCurrentHeight():any{
      return this.currentHieght
    },
    computedCurrentWidth():any{
      return this.currentWidth
    },
    updatedProjectId():any{
      return this.$store.state.projectModule.updatedProjectId
    }
  },

  watch:{
    updatedProjectId(value){
      if(this.selectedProjectId==value.old){
        this.selectedProjectId = value.new
      }
    }
  },

  methods:{
   onFundingMechanismVisibilityChanged(val:any){
     this.$emit('show-funding',val)
   },
   onWorkBrakdownScheduleChanged(val:any){
     this.$emit('show-wbs',val)
   },
   onVisibleActivitiesChanged(val:any){
     this.$emit('visible-activities-changed',val)
   },
   openActivity(projectId:any){
     this.selectedProjectId  = projectId
     this.$emit('projectId', this.selectedProjectId)
     this.activeView = 'activities'
   },
   changeActiveView(data:any){
     if(data.projectId){
       this.selectedProjectId = data.projectId
     }
     this.activeView=data.view
   }
  },
  components:{
    'projects':ProjectChart,
    'activities':ActivityChart,
    'project-sheet': ProjectSheet
  }
})

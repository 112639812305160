






























































































import Vue from 'vue'
import AnalyticsTab from "./AnalyticsTab"
import {HTTP} from "../../axios_api/http-common"
import AnalyticsDataSource from '@/components/RightPaneComponents/AnalyticsDataSource';
import { required } from 'vuelidate/lib/validators'

export default Vue.extend({
    components:{
        'analytics-tab':AnalyticsTab,
        'analytics-data-source':AnalyticsDataSource
    },
    name:"AnalyticsMain",
    data(){
        return {
            showNotifications :false,
            newDashboard:{
                name:"",
                description:""
            },
            selectedPresets:[] as Array<any>,
            activeTab: {} as any,
            busy:false
        }
    },
    validations: {
        newDashboard: {
            name: {required},
            description: {required},
        },
        
    },
    methods:{
        toggleSideNav(){
            this.$store.commit('setSideNav')
        },
        createNewDashboard(){
            ((this.$refs['new-dashboard-modal']) as any).show()
        },
        doDahboardCreation(){
            let tempId = Math.abs((Math.floor(Math.random() * (10 - +4000))  +10)+(Math.floor(Math.random() * (10 - +4000))  +10)); 
            this.$store.commit("Analytics/add",{id:tempId,name:this.newDashboard.name,description:this.newDashboard.description,kpIs:[]
            ,screenWidth:window.innerWidth,screenHeight:window.innerWidth})
            this.selectedPresets.push(tempId)
            this.activeTab = tempId
            this.newDashboard.name = ""
            this.newDashboard.description = "";
            this.$v.$reset();
            ((this.$refs['new-dashboard-modal']) as any).hide()
        },
        tabClicked(id:any){
            if(this.activeTab!=id){
                this.activeTab=id
            }
        },
        logout(){
            this.$router.push("/")
            this.$store.dispatch("logOut")
        },
        savePresetChanges(){
            this.busy = true
            this.$store.dispatch("Analytics/saveChanges").then(()=>{
                this.busy = false
                this.$store.commit("Analytics/clearPresetChanges")
            })
        },
        onNotificationClicked(){
            this.showNotifications=!this.showNotifications
            this.$store.dispatch("Notifications/updateNotificationStatus")
         }
    },
    computed:{
        savedPresets():Array<any>{
            return this.$store.state.Analytics.presets
        },
        computedSelectedPresets():Array<any>{
            return this.selectedPresets.map(presetId=>{
                return this.savedPresets.find(sp=>sp.id==presetId)
            })
        },
         unreadNotifications():Number{
            return this.$store.getters["Notifications/getNumberOfUnreadNotifications"]
        },
        sidenav():any{
            return this.$store.getters['getSideNavStatus']
        },
        activeUser():any{
            return this.$store.getters['activeUser']
        },
        rightPane():any{
            return this.$store.getters['getPaneStatus']
        },
        activePane():any{
            return this.$store.getters['getActivePane']
        },
        presetChanges():any{
            return this.$store.getters["Analytics/getPresetChangesCount"]
        }
    },
    mounted(){
        this.$store.commit('setSideNavToFalse')
        this.$store.dispatch("Analytics/getKPIs")
        if(this.savedPresets.length<1)this.$store.dispatch("Analytics/getPresets")
    },
    beforeDestroy(){
        this.$store.commit('setRightPaneToFalse');
        this.$store.commit('setActivePane', '');
    }
})

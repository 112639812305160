
























































































































































import Vue from 'vue'
import { Component , Mixins} from 'vue-property-decorator'
import EventBus from './../../eventBus'
import SequenceForm from './SequenceComponents/sequenceForm.vue'
import review from '@/components/reviewTable.vue'

@Component({
    components: {
        'sequence-form': SequenceForm,
        'review-table': review
    }
})
export default class SequenceCatalog extends Vue{
    formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 2
    })
    activeScreen: string = 'main'
    pending_freeze: any = null
    pending_delete: any = {}
    loadingTitle: string = 'Loading...'
    loadingMessage: string = 'Loading Sequences'
    additionalSuccessMessage: string = ''
    successMessage: string = ''
    loader: boolean = true
    error: string = ''
    search: string = ''
    style: string = 'grid'
    deletePromptProperty: boolean = false
    freezePromptProperty: boolean = false
    selectedSequence: any = {}
    showCreateProperty: boolean = false
    createModalStatus: string = ''
    sequence: any = {}
    startExecutionState:string = ""
    sequenceVersions = []
    selectedVersion: number = 0
    showMailerForm: boolean = false

    fetchSequences() {
        return this.$store.dispatch('sequencesModule/fetchSequences')
    }

    get getProjectChangesCount():any{
        return this.$store.getters['projectModule/getProjectChangesCount']
    }
    get getActivityChangesCount():any{
        return this.$store.getters['ActivityModule/getActivityChangesCount']
    }
    get getMilestoneChangesCount():any{
        return this.$store.getters['MilestoneModule/getMilestoneChangesCount']
    }
    get getDailyReportChangesCount():any{
        return this.$store.getters['DailyReportModule/getDailyReportChangesCount']
    }
    get getTotalChanges():any{
        return this.getProjectChangesCount+this.getActivityChangesCount+this.getMilestoneChangesCount+this.getDailyReportChangesCount
    }
    get messageBody():string{
        let message = "\nSEPAL DIAP NOTIFICATION,\n\n"+this.activeUser.firstName+' '+this.activeUser.lastName+
                        ' made important changes to the '+this.selectedSequence.name+' sequence.\n\nFind below, summary of changes made'
        return message
    }

    load(e: string, loadingTitle: string, loadingMessage: string) {
        // this.activeScreen = e
        // EventBus.$emit('activeScreen', this.activeScreen)
        // this.loadingTitle = loadingTitle
        // this.loadingMessage = loadingMessage
        window.console.log(e)
    }

    success(e: string, successMessage: string, additionalSuccessMessage: string) {
        // this.activeScreen = e
        // EventBus.$emit('activeScreen', this.activeScreen)
        // this.additionalSuccessMessage = additionalSuccessMessage
        // this.successMessage = successMessage
        window.console.log(e)
    }

    errorAction(e: string, error: string){
        // this.activeScreen = e
        // EventBus.$emit('activeScreen', this.activeScreen)
        // this.error = error
        window.console.log(e)
    }

    delete(sequence: any) {
        return this.$store.dispatch('sequencesModule/delete', sequence)
    }

    set_active_action(sequence: any) {
        return this.$store.dispatch('sequencesModule/set_active_action', sequence)
    }

    freeze(sequence: any) {
        return this.$store.dispatch('sequencesModule/freeze', sequence)
    }

    deleteSequencePrompt(selected: any) {
        this.selectedSequence = selected
        this.deletePromptProperty = true
    }

    freezeSequencePrompt(selected: any) {
        this.selectedSequence.name = selected.name
        this.freezePromptProperty = true
    }

    get sequenceVersionOptions () {
        return this.sequenceVersions.map((version: any) => {
            return {
                text: version.versionName,
                value: version.versionID
            }
        })
    }

    saveChanges():any{        
        this.$store.dispatch("projectModule/saveChanges").then((res:any) => {
            this.$store.commit("projectModule/clearProjectChanges")
            this.$store.dispatch("ActivityModule/saveChanges").then((activityTempActualKeyPairs:any) => {
                this.$store.commit("ActivityModule/clearActivityChanges")
                this.$store.dispatch("MilestoneModule/saveChanges",activityTempActualKeyPairs).then((milestoneTempActualKeyPairs:any) => {
                    this.$store.commit("MilestoneModule/clearMilestoneChanges")
                    this.$store.dispatch("DailyReportModule/saveChanges").then(() => {
                        this.$store.commit("DailyReportModule/clearDailyReportChanges")
                    })
                })
            })
        })
        window.console.log('Done')
    }

    startExecuting(sequence:any,state:any){
        switch(state){
            case 'INIT':
                this.selectedSequence = sequence
                this.startExecutionState = "INIT"
                this.$bvModal.show("start-execution-status-modal")
                this.sequenceVersions = this.selectedSequence.versions
                this.sequenceVersions.forEach((version: any) => {
                    if (version.versionName == 'Current Version') {
                        this.selectedVersion = version.versionID
                    }
                })
                break;
            case 'LOADING':
                this.$store.dispatch("sequencesModule/updateState",{id:this.selectedVersion, state:"EXECUTING"}).then(res => {
                    this.$router.push({path:'/sequence/'+this.selectedVersion})
                    this.$store.commit('setRightPaneToFalse')
                })
                this.startExecutionState = "LOADING"
                break;
            case 'UNSAVED':
                this.startExecutionState = this.$store.getters['projectModule/getProjectChangesCount']+this.$store.getters['DailyReportModule/getDailyReportChangesCount']+this.$store.getters['ActivityModule/getActivityChangesCount']+this.$store.getters['MilestoneModule/getMilestoneChangesCount']> 0 ? "UNSAVED": "ISSUESTABLE"
                break;
            case 'ISSUESTABLE':
                if (this.$store.getters['projectModule/getProjectChangesCount']+this.$store.getters['DailyReportModule/getDailyReportChangesCount']+this.$store.getters['ActivityModule/getActivityChangesCount']+this.$store.getters['MilestoneModule/getMilestoneChangesCount']> 0) {
                    this.saveChanges()
                }
                this.startExecutionState = "ISSUESTABLE"
                break;
            case 'VERSIONS':
                this.startExecutionState = "VERSIONS"
                break;
            case 'NO':
                this.$bvModal.hide("start-execution-status-modal")
                this.startExecutionState = ""
                break;
        }
    }

    startPlanning(sequence:any){
        this.$bvModal.show("save-sequence-status-modal")
        this.$store.dispatch("sequencesModule/updateState",{id:sequence.id,state:"PLANNING"}).then(res => {
            this.openSequence(sequence)
        })
    }

    openSequence(sequence: any) {
        //this.$store.commit('setSideNavToFalse')
        //EventBus.$emit('activeScreen', 'loading')
        //this.loadingTitle = 'Loading'
        //this.loadingMessage = 'Loading Sequence Chart'
      //  window.console.log(window.global.canWriteSequence);
        this.$router.push({path:'/sequence/'+sequence.id})
        this.$store.commit('setRightPaneToFalse')
        /*
        setTimeout(() => {
            this.$router.push({path:'/sequence/'+sequence.id})
        }, 700);
        */
    }

    clonePrompt (seq: any) {
        EventBus.$on('seq', (sequence: any) => {
            sequence.name = seq.name
            sequence.amount = seq.amount
            sequence.year = seq.year
            sequence.description = seq.description
        })
        this.selectedSequence = seq
        this.createModalStatus = 'Clone'
        this.showCreateProperty = true
    }

    createPrompt () {
        EventBus.$on('seq', (sequence: any) => {
            sequence.name = ''
            sequence.amount = ''
            sequence.year = ''
            sequence.description = ''
        })
        this.selectedSequence = {}
        this.createModalStatus = 'Create'
        this.showCreateProperty = true
    }

    editPrompt (seq: any) {
        this.selectedSequence = seq
        this.createModalStatus = 'Edit'
        this.showCreateProperty = true
    }

    clone() {
        this.showCreateProperty = false
        window.console.log('clone')
    }

    doDelete () {
        this.deletePromptProperty = false
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.loadingTitle = 'Deleting...'
        this.loadingMessage = "Please wait while we delete this sequence"
        this.delete(this.selectedSequence).then((res: any) => {
            this.activeScreen = 'successNoBack'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.successMessage = this.selectedSequence.name + ' Deleted Successfully'
            this.additionalSuccessMessage = 'This sequence will no longer be available'
            setTimeout(() => {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
            }, 10000);
        }, (error: any) => {
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.data.response.message
            setTimeout(() => {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
            }, 10000);
        })
    }

    set_active(sequence: any) {
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.loadingTitle = 'Loading...'
        this.loadingMessage = "Setting sequence as active"
        this.set_active_action(sequence).then(res => {
            this.activeScreen = 'successNoBack'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.successMessage = sequence.name + ' is now active'
            this.additionalSuccessMessage = ''
            setTimeout(() => {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
            }, 10000);
        }, (error: any) => {
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.data.response.message
            setTimeout(() => {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
            }, 10000);
        })
        this.pending_freeze = null;
    }

    // doFreeze(sequselectedSequence.id
    //     this.freeze(sequence).then((res: any) => {
    //         window.console.log('Freezed')
    //         window.console.log(res)
    //     }, (error: any) => {
    //         window.console.log(error)
    //     })
    //     this.pending_freeze = null;
    // }


    // Search
    searchValue(e: string) {
        this.search = e
    }

    // Grid and list style controls
    grid () {
        this.style = 'grid'
    }

    list () {
        this.style = 'list'
    }



    // COMPUTED
    get filteredSequences() {
        return this.$store.getters['sequencesModule/filteredRootSequences']
    }
    get activeUser() {
        return this.$store.getters['activeUser']
    }

    get canCreateSequence() {
        return this.$store.getters['canCreateSequence']
    }

    get isAdmin() {
        return this.$store.getters['isAdmin']
    }

    get columns () {
        if (this.style === 'grid') {
            return 6
        } else {
            return 12
        }
    }

    stateBackground (state: string) {
        if (state === 'PLANNING') {
            return '#4f4ff2'
        } else if (state === 'INITIATED') {
            return '#61f47a'
        } else if (state === 'EXECUTING') {
            return '#5f20e8'
        }
    }

    broadCastClicked(selectedSequence:any){
        this.selectedSequence = selectedSequence
        this.showMailerForm = true
    }

     onBroadcastFormHidden(){
        this.selectedSequence = {}
        this.showMailerForm = false
    }


    // CREATED 
    created () {
        this.$store.commit('setSideNavToTrue')
        if(this.filteredSequences('').length === 0) {
            this.fetchSequences().then(res => {
                this.activeScreen = 'main';
                EventBus.$emit('activeScreen', this.activeScreen)
                this.loader = false
            })
        } else {
            this.activeScreen = 'main';
            EventBus.$emit('activeScreen', this.activeScreen)
            this.loader = false
        }
    }
}
















































import Vue from 'vue'
import {Component, Mixins} from 'vue-property-decorator';
import EventBus from './../../eventBus'
import UserProvisioning from '@/mixins/userProvisioning'

@Component
export default class FundingsOverview extends Mixins(UserProvisioning){
    // DATA
    activeItem: any = null
    search: string = ''
    error: string = ''
    activeScreen: string = 'loading'
    loadingMessage: string = 'Loading Funding Mechanisms'
    loadingTitle: string = 'Loading...'
    deletePromptProperty: boolean = false
    successMessage: string = ''
    additionalSuccessMessage = ''




    // COMPUTED PROPERTIES
    get getFundings () {
        return this.$store.getters['fundingsModule/getFundings']
    }

    get getFields(){
        if(this.canWriteBudgetSettings)
        {
           return [
               {key:'name',label:"Funding Tranche", sortable:true},
               {key: 'code', label:"Short Code", sortable: 'true'},'SampleDisplay',
               {key: 'button', label: "Actions"} 
                 ]
        }
    return [
        {key:'name',label:"Funding Tranche", sortable:true},
        {key: 'code', label:"Short Code", sortable: 'true'},'SampleDisplay']
    }

    // VUEX METHODS AND MUTATIONS
    deleteFunding (funding: any) {
        return this.$store.commit('fundingsModule/deleteFunding', funding)
    }
    fetchFundings () {
        return this.$store.dispatch('fundingsModule/fetchFundings')
    }



    // METHODS
    updateFunding(item: any){
        this.activeItem = item;
        this.$router.push({path:'/manage-funding/', query:{id:item.id}})
    }

    promptDelete(item: any){
        this.activeItem = item;
        this.deletePromptProperty = true
    }

    searchValue(e: any) {
        this.search = e
    }

    doDelete(){
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.loadingMessage = 'Please wait while we delete this funding mechanism'
        this.loadingTitle = 'Deleting...'
        this.$store.dispatch('fundingsModule/deleteFundingAciton', this.activeItem)
        .then((res: any) =>{
            this.activeScreen = 'successNoBack'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.additionalSuccessMessage = 'This funding mechanism will no longer be available'
            this.successMessage = this.activeItem.name + ' deleted succesfully'
            this.deletePromptProperty = false
        })
        .catch((error: any) => {
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.message
            this.deletePromptProperty = false
        })
    }


    // CREATED
    mounted() {
        this.activeScreen = 'loading';
        EventBus.$emit('activeScreen', this.activeScreen)
        if (this.getFundings.length <= 0) {
            this.fetchFundings().then(() => {
                this.activeScreen = 'main';
                EventBus.$emit('activeScreen', this.activeScreen)
            })
        } else {
            this.activeScreen = 'main';
            EventBus.$emit('activeScreen', this.activeScreen)
        }
    }
}

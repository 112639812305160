










































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import General from "../../components/dailyReportTabs/General.vue";
import MudProperties from "@/components/dailyReportTabs/mudProperties.vue";
import BudgetSheet from "@/components/BudgetSheet";
import DailyReporViewer from "./DailyReport/DailyReportViewer";
import mudVolume from "@/components/dailyReportTabs/mudVolume.vue";
import { HTTP } from "@/axios_api/http-common";
import MudVolume from "../../components/dailyReportTabs/mudVolume.vue";
import MudLosses from "@/components/dailyReportTabs/mudLosses.vue";
import PersonnelContact from "@/components/dailyReportTabs/personnelContact.vue";
import TimeBreakDown from "@/components/dailyReportTabs/timeBreakDown.vue";
import Safety from "@/components/dailyReportTabs/safety.vue";
import Bits from "@/components/dailyReportTabs/bits.vue";
import DrillingParameters from "@/components/dailyReportTabs/drillingParameters.vue";
import { report } from 'process';
import DailyReportViewer from "./DailyReport/DailyReportViewer.vue";
import AFE from '@/components/dailyReportTabs/AFEsummary.vue'
import depthSummary from '@/components/dailyReportTabs/depthSummary.vue'
import DeviationSurveyImport from "@/components/DeviationSurveyImport.vue"

var currentDate = new Date(Date.now());

@Component({
  components: {
    "budget-sheet": BudgetSheet,
    "report-viewer": DailyReporViewer,
    "deviation-import": DeviationSurveyImport,
    general: General,
    "mud-properties": MudProperties,
    "mud-volume": MudVolume,
    "mud-losses": MudLosses,
    "personnel-contact": PersonnelContact,
    "time-break-down": TimeBreakDown,
    safety: Safety,
    bits: Bits,
    DrillingParameters,
    'afe-summary': AFE,
    depthSummary
  },
})
export default class ReportSheet extends Vue {
  @Prop({ default: -1 })
  projectId!: any;
  @Prop({default:false}) isEditingDailyReport!:boolean;
  @Prop({}) editedReport!:any;
  @Prop({default: false}) isEdit!:any;

  busy: string = "NONE";
  bit = 0;
  mud = 0;
  cost = 0;
  showDeviationSurveyImportModel=false

  get selectedProject() {
    return this.$store.getters["projectModule/getProjectById"](this.projectId);
  }
 
  activityLogFields: Array<any> = [
    {
      key: "from",
      label: "Start",
    },
    {
      key: "to",
      label: "End",
    },
    {
      key: "depth",
      label: "Depth (ft)",
    },
    "activity",
    "milestone",
    "description",
    {
      key: "hasNpt",
      label: "NPT ?",
    },
    {
      key: "nptRef",
      label: "NPT Ref",
    },
    {
      key: "nptHours",
      label: "NPT Hours",
    },
    {
      key: "nptDescription",
      label: "NPT Description",
    },
    {
      key: "actions",
      label: "",
    },
  ];
  personnelFields: Array<any> = [
    { key: "contactName", label: "Name" },
    { key: "position", label: "Position" },
    { key: "email", label: "Email" },
    { key: "mobile", label: "Mobile" },
    { key: "company", label: "Company" },
    { key: "office", label: "Office Ext." },
    { key: "button", label: "Action" },
  ];
  personnelOnBoardFields: Array<any> = [
    { key: "company", label: "Company" },
    { key: "serviceType", label: "Service Type" },
    { key: "workHour", label: "Work Hours" },
    { key: "standByHour", label: "Stand-By Hours" },
    { key: "count", label: "Count" },
    { key: "note", label: "Notes" },
    { key: "button", label: "Action" },
  ];
  deviationFields: Array<any> = [
    { key: "index", label: "Seq. No." },
    { key: "mD", label: "MD" },
    { key: "inclinationAngle", label: "Inclination (deg)" },
    { key: "azimuth", label: "Azimuth (deg)" },
    { key: "tVD", label: "TVD" },
    { key: "verticalSection", label: "Vertical Section" },
    { key: "nS", label: "+N/S-" },
    { key: "eW", label: "+E/W-" },
    { key: "dLS", label: "DLS("+String.fromCharCode(176)+"/100ft)" },
    { key: "button", label: "Action" },
  ];
  incidentField: Array<any> = [
    { key: "date", label: "Date" },
    { key: "time", label: "Time" },
    { key: "reporterName", label: "Reporter's Name" },
    { key: "numMinorInjury", label: "Minor Injuries" },
    { key: "numMajorInjury", label: "Major Injuries" },
    { key: "numOfDeath", label: "Persons Killed" },
    { key: "isNearMiss", label: "Near Miss?" },
    { key: "descLocation", label: "Location Description" },
    { key: "descAccident", label: "Accident Description" },
    { key: "remedialActionDesc", label: "Remedial Action Description" },
    { key: "causeDesc", label: "Cause Description" },
    { key: "eTimLostGross", label: "Lost Hours" },
    { key: "costLossGross", label: "Gross Cost Estimate" },
    { key: "responsibleCompany", label: "Responsible Company" },
    { key: "button", label: "Actions" },
  ];
  lineSum = 0
  activeView = "General"
  activityLog = [] as Array<any>;
  showViewer = false
  dailyReport = {
    activityLog: [] as Array<any>,
    id:0,
    startDepth: 0,
    endDepth: 0,
    rkbDepth: 0,
    dfe: 0,
    reportDate: currentDate,
    startTime: "06:00",
    endTime: "06:00",
    budgetChanged: false,
    cost: { budgetTemplateId: "", budgetLines: [] },
    mud: {
      mudType: "",
      mudWeight: 0,
      funnelViscosity: 0,
      eCD: 0,
      plasticViscosity: 0,
      yieldPoint: 0,
      gelStrength: 0,
      pitTemp: 0,
      hTHP: 0,
      cake: 0,
      solids: 0,
      dailyMudCost: 0,
      prevMudCost: 0,
      totalMudCost: 0,
    },
    mudVolume: {
      volTotMudStart: 0,
      volMudDumped: 0,
      volMudReceived: 0,
      volMudReturned: 0,
      volMudBuilt: 0,
      volMudString: 0,
      volMudCasing: 0,
      volMudHole: 0,
      volMudRiser: 0,
    },
    depthSummaryModel: {
      currentHoleSize: 0,
      currentDepth: 0,
      totalDepth: 0,
      actualTotalDepth: 0,
      previousDepth: 0,
      depthProgress: 0,
      intermidateCasingSize: 0,
      intermidateCasingDepth: 0,
      productionCasingSize: 0,
      productionCasingDepth: 0,
      conductorCasingSize: 0,
      conductorCasingDepth: 0,
      linearSize: 0,
      linearDepth: 0,
      lot: this.lastDdr?this.lastDdr.depthSummary.lot:0,
      fit: this.lastDdr?this.lastDdr.depthSummary.fit:0,
    },
    scheduleModel: {
      plannedRigMoveStartDate: null,
      plannedRigMoveEndDate: null,
      plannedRigMoveDays: 0,
      plannedOperationsDays: 0,
      actualRigMoveDays: 0,
      rigMoveStartDate: null,
      rigMoveEndDate: null,
      spudDate: null,
      spudTime: "06:00",
      daysOnLocation: 0,
      daysFromSpud: 0,
      operatingDaysVariance: 0
    },
    aFESummaryModel: {
      code: '',
      supplementaryCode: '',
      amount: 0,
      supplementaryAmount: 0,
      actualBudgetRatio: 0,
      supplementaryBudgetRatio: 0,
      dailyCost: 0,
      cummulativeCost: 0,
      percentage: 0,
      suppPercentage: 0,
      isAmount: false
    },
    mudLosses: {
      volLostShakerSurf: 0,
      volLostMudCleanerSurf: 0,
      volLostPitsSurf: 0,
      volLostTrippingSurf: 0,
      volLostOtherSurf: 0,
      volLostCircHole: 0,
      volLostCsgHole: 0,
      volLostCmtHole: 0,
      volLostBhdCsgHole: 0,
      volLostAbandonHole: 0,
      volLostOtherHole: 0,
    },
    personnelContactsLog: [] as Array<any>,
    timeBreakDown: {
      circulation: 0,
      rIH: 0,
      pOOH: 0,
      drilling: 0,
      cementing: 0,
      tCP: 0,
      others: 0,
    },
    safetyModel: {
      lastBOPDrill: null,
      nextBOPTest: null,
      lastSafetyMeeting: null,
      lastFireDrill: null,
      lastBOPTest: null,
      lastCsgPres: null,
    },
    bitModel: {
      bha: 0,
      bitSize: 0,
      modelNo: '',
      bitType: '',
      iadcNo: 0,
      serialNo: 0,
      bitHours: 0,
      bitNozzless: 0,
      tfa: 0,
      impact: 0,
      jetVelocity: 0,
      rop: 0,
      rpm: 0,
      bitRevolution: 0,
      bitRotationHours: 0,
      averageCummulative: 0,
      wob: 0,
      footage: 0
    },
    deviationSurveyData: [] as Array<any>,
    drillingParametersModel: {
      torque: 0,
      depth: 0,
      puWeight: 0,
      soWeight: 0,
      maxOPull: 0,
      rotatingWeight: 0,
    },
    personnelOnBoardLog: [] as Array<any>
  } as any;

  get filteredMilestones(): any {
    return this.dailyReport.activityLog.map((a: any) => a.milestone);
  }

  get activitiesForProject(){
    return this.$store.getters["ActivityModule/getActivitiesForProject"](this.projectId)
  }
  get rigMove() {
    return this.activitiesForProject.find((x: any) => x.wellActivity.id === '3')
  }
  get drilling() {
    return this.activitiesForProject.find((x: any) => x.wellActivity.id === '1')
  }
  get completion() {
    return this.activitiesForProject.find((x: any) => x.wellActivity.id === '2')
  }
  get activityOptions(): any {
    return this.activitiesForProject.map((thisActivity: any) => ({
      text: thisActivity.wellActivity.name,
      value: thisActivity,
    }));
  }
  
  currentDepthChange() {
    this.dailyReport.depthSummaryModel.depthProgress = this.dailyReport.depthSummaryModel.currentDepth - this.dailyReport.depthSummaryModel.previousDepth;
    this.dailyReport.depthSummaryModel.actualTotalDepth = this.dailyReport.depthSummaryModel.currentDepth;
  }

  plannedRigDays() {
    this.dailyReport.scheduleModel.plannedRigMoveDays = !this.dailyReport.scheduleModel.plannedRigMoveStartDate|| !this.dailyReport.scheduleModel.plannedRigMoveEndDate?1:this.getDaysDifference(this.dailyReport.scheduleModel.plannedRigMoveStartDate, this.dailyReport.scheduleModel.plannedRigMoveEndDate)
  }
  actualRigDays() {
    this.dailyReport.scheduleModel.actualRigMoveDays = !this.dailyReport.scheduleModel.rigMoveStartDate || !this.dailyReport.scheduleModel.rigMoveEndDate?1:this.getDaysDifference(this.dailyReport.scheduleModel.rigMoveStartDate, this.dailyReport.scheduleModel.rigMoveEndDate)
  }
  addDays() {
    const date = new Date(this.dailyReport.scheduleModel.rigMoveStartDate)
    this.dailyReport.scheduleModel.rigMoveEndDate = new Date(date.setDate(date.getDate() + Number(this.dailyReport.scheduleModel.actualRigMoveDays - 1)))
  }
  
  get getPlanBudgetForProject(){
    let totalBudget = 0
    this.activitiesForProject.forEach((activity:any) => {
      if(activity.budget){
        activity.budget.budgetLines.forEach((budgetLine:any) => {
          totalBudget+=budgetLine.lineValue
        })
      }
    });
    return totalBudget
  }
  
  get showCostForm(): any {
    if (this.dailyReport.activityLog.length < 1) {
      return false;
    } else {
      if (
        this.dailyReport.activityLog.some((a: any) => a.activity == null) ||
        this.dailyReport.activityLog.some((a: any) => a.milestone == null)
      )
        return false;
      if (this.dailyReport.activityLog[0].activity.budget == null) return false;
      return true;
    }
  }

  get nptOptions(): any {
    return this.nptOperations.map((n: any) => ({
      text: n.name + " (" + n.nptRef + ")",
      value: n,
    }));
  }
  get canSaveReport(){
    let result = true;
    if(this.dailyReport.activityLog.length>0){      
      this.dailyReport.activityLog.forEach((logEntry:any) => {
      if(logEntry.nptOperation != undefined){
        if(logEntry.hasNpt && (logEntry.nptOperation.id == undefined || logEntry.nptOperation.id == "")){
            return result = false;
        }
        }
        if(logEntry.milestone == null || logEntry.activity == null )
        {
            return result = false;
        }
      });
    }
    return result;
  }
    downloadDeviationSurvey(){
     HTTP.get('ddr/export/deviationsurvey/?dailyReportId=' + this.dailyReport.id, { responseType: "blob" })
        .then((res: any) => {
          var blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          const downloadUrl = window.URL.createObjectURL(blob);
          link.href = downloadUrl;
          //link.download = res.headers.filename
          link.setAttribute("download", res.headers.filename + ".xlsx"); //any other extension
          document.body.appendChild(link);
          link.click();
         // link.remove();
        })
        .catch((error: any) => {});
    }
  addRow() {
    this.dailyReport.activityLog.push({
      id:0,
      from: "06:00",
      to: "06:00",
      milestone: null,
      activity: null,
      description: "",
      depth: 0,
      hasNpt: false,
      nptHours:0,
      nptDescription:"",
      nptOperation: {
        name: "",
        nPTRef:  "",
        rootCause:  "",
        associatedCause:  "",
        id:"",
       },
    });
  }

  addPersonnel() {
    this.dailyReport.personnelContactsLog.push({
      id:0,
      contactName: "",
      email: "",
      mobile: "",
      company: "",
      position: "",
      office: "",
    });
  }

  addDeviationSurvey() {
    this.dailyReport.deviationSurveyData.push({
      mD: 0,
      inclinationAngle: 0,
      azimuth: 0,
      tVD: 0,
      verticalSection: 0,
      nS: 0,
      eW: 0,
      dLS: 0
    });
  }
  @Watch('dailyReport', {immediate:true,deep:true})
  OnReportChanged(a: any,b: any) {
      let sum = this.dailyReport.cost.budgetLines.reduce((a: any, b: any) => a + (b['lineValue'] || 0), 0);
      this.lineSum = sum
      if (this.dailyReport.aFESummaryModel) {
        this.dailyReport.aFESummaryModel.dailyCost = sum
        this.dailyReport.aFESummaryModel.cummulativeCost = this.lastDdr?sum+Number(this.lastDdr.afeSummary.dailyCost):sum
      }
  }

  addPersonnelOnBoard() {
    this.dailyReport.personnelOnBoardLog.push({
      id:0,
      company : "",
      serviceType: "",
      workHour: "",
      standByHour: "",
      count: 0,
      note: '',
    });
  }
  removePersonnel(index: number) {
    this.dailyReport.personnelContactsLog.splice(index, 1);
  }
  removePersonnelOnBoard(index: number) {
    this.dailyReport.personnelOnBoardLog.splice(index, 1);
  }
  removeDeviationSurvey(index: number) {
    this.dailyReport.deviationSurveyData.splice(index, 1);
  }
  reInitDailyReport() {
    this.dailyReport = {
      id:0,
      activityLog: [] as Array<any>,
      startDepth: 0,
      endDepth: 0,
      reportDate: currentDate,
      startTime: "06:00",
      endTime: "06:00",
      budgetChanged: false,
      rkbDepth: 0,
      dfe: 0,
      cost: { budgetTemplateId: "", budgetLines: [] },
      mud: {
        mudType: "",
        mudWeight: 0,
        funnelViscosity: 0,
        eCD: 0,
        plasticViscosity: 0,
        yieldPoint: 0,
        gelStrength: 0,
        pitTemp: 0,
        hTHP: 0,
        cake: 0,
        solids: 0,
        dailyMudCost: 0,
        prevMudCost: 0,
        totalMudCost: 0,
      },
      depthSummaryModel: {
        currentHoleSize: 0,
        currentDepth: 0,
        totalDepth: this.selectedProject.well.normDepth.value,
        actualTotalDepth: this.dailyReport.depthSummaryModel.currentDepth,
        previousDepth: this.lastDdr?this.lastDdr.depthSummary.currentDepth:0,
        depthProgress: 0,
        intermidateCasingSize: 0,
        intermidateCasingDepth: 0,
        productionCasingSize: 0,
        productionCasingDepth: 0,
        conductorCasingSize: 0,
        conductorCasingDepth: 0,
        linearSize: 0,
        linearDepth: 0,
        lot: this.lastDdr?this.lastDdr.depthSummary.lot:0,
        fit: this.lastDdr?this.lastDdr.depthSummary.fit:0,
      },
      scheduleModel: {
        plannedRigMoveStartDate: this.rigMove?this.rigMove.start:null,
        plannedRigMoveEndDate: this.rigMove?this.rigMove.end:null,
        plannedRigMoveDays: this.rigMove?this.getDaysDifference(this.dailyReport.scheduleModel.plannedRigMoveStartDate, this.dailyReport.scheduleModel.plannedRigMoveEndDate): 0,
        plannedOperationsDays: this.getDaysDifference(this.selectedProject.start, this.selectedProject.end),
        actualRigMoveDays: 0,
        rigMoveStartDate: null,
        rigMoveEndDate: null,
        spudDate: this.lastDdr?this.lastDdr.schedule.spudDate:null,
        spudTime: this.lastDdr?this.lastDdr.schedule.spudTime:'06:00',
        daysOnLocation: this.rigMove?this.getDaysDifference(currentDate, this.rigMove.end):0,
        daysFromSpud: this.dailyReport.scheduleModel.spudDate ?this.getDaysDifference(currentDate, this.dailyReport.scheduleModel.spudDate):0,
        operatingDaysVariance: this.dailyReport.scheduleModel.plannedOperationsDays - this.dailyReport.scheduleModel.daysFromSpud
      },
      aFESummaryModel: {
        code: '',
        supplementaryCode: '',
        amount: 0,
        supplementaryAmount: 0,
        actualBudgetRatio: 0,
        supplementaryBudgetRatio: 0,
        dailyCost: 0,
        cummulativeCost: this.lastDdr?this.dailyReport.aFESummaryModel.dailyCost + this.lastDdr.afeSummary.dailyCost:this.dailyReport.aFESummaryModel.dailyCost,
        percentage: 0,
        suppPercentage: 0,
        isAmount: false
      },
      mudVolume: {
        volTotMudStart: 0,
        volMudDumped: 0,
        volMudReceived: 0,
        volMudReturned: 0,
        volMudBuilt: 0,
        volMudString: 0,
        volMudCasing: 0,
        volMudHole: 0,
        volMudRiser: 0,
      },
      mudLosses: {
        volLostShakerSurf: 0,
        volLostMudCleanerSurf: 0,
        volLostPitsSurf: 0,
        volLostTrippingSurf: 0,
        volLostOtherSurf: 0,
        volLostCircHole: 0,
        volLostCsgHole: 0,
        volLostCmtHole: 0,
        volLostBhdCsgHole: 0,
        volLostAbandonHole: 0,
        volLostOtherHole: 0,
      },
      personnelContactsLog: [] as Array<any>,
      timeBreakDown: {
        circulation: 0,
        rIH: 0,
        pOOH: 0,
        drilling: 0,
        cementing: 0,
        tCP: 0,
        others: 0,
      },
      safetyModel: {
        lastBOPDrill: null,
        nextBOPTest: null,
        lastSafetyMeeting: null,
        lastFireDrill: null,
        lastBOPTest: null,
        lastCsgPres: null,
      },
      bitModel: {
        bha: this.lastDdr?this.lastDdr.bit.bha:0,
        bitSize: this.lastDdr?this.lastDdr.bit.bitSize:0,
        modelNo: this.lastDdr?this.lastDdr.bit.modelNo:'',
        bitType: this.lastDdr?this.lastDdr.bit.bitType:'',
        iadcNo: this.lastDdr?this.lastDdr.bit.iadcNo:0,
        serialNo: this.lastDdr?this.lastDdr.bit.serialNo:0,
        bitHours: 0,
        bitNozzless: 0,
        tfa: 0,
        impact: 0,
        jetVelocity: 0,
        rop: 0,
        rpm: 0,
        bitRevolution: 0,
        bitRotationHours: 0,
        averageCummulative: 0,
        wob: 0,
        footage: 0
    },
    deviationSurveyData: [] as Array<any>,
    drillingParametersModel: {
      torque: 0,
      depth: 0,
      puWeight: 0,
      soWeight: 0,
      maxOPull: 0,
      rotatingWeight: 0,
    },
    personnelOnBoardLog: [] as Array<any>
  };
  }

  get columns(): Array<any> {
    let columnsToReturn = [] as Array<any>
     this.filteredMilestones.forEach((m: any) => {
      if(columnsToReturn.findIndex((c:any)=>c.id==m.id)<0){
        let budgetLinesForThisMilestone = []
        if(this.dailyReport.cost&&this.dailyReport.cost.budgetLines){
          budgetLinesForThisMilestone = this.dailyReport.cost.budgetLines.filter((b:any) => b.activityId==m.id)
        }
        columnsToReturn.push({id:m.id,name:m.name+" (K$)",budget:{budgetLines:budgetLinesForThisMilestone},editable:true});
      }
    });
    return columnsToReturn
  }

  get rowsData():any{
    let result:any = {rows:[],groupExtractor:undefined}
    let budgetTemplatesToProcess:Array<any> = []
    this.dailyReport.activityLog.forEach((a:any) => {
      if(a.activity!=null&&a.activity.budget!=undefined){
        if(budgetTemplatesToProcess.findIndex((b:any)=>b.id==a.activity.budget.budgetTemplateId)<0){
          budgetTemplatesToProcess.push(this.budgetTemplates.find((b:any)=>b.id==a.activity.budget.budgetTemplateId))
        }
      }
    })
    if(budgetTemplatesToProcess.length>1){
      budgetTemplatesToProcess.forEach((budgetTemplate:any)=>{
        budgetTemplate.sections.forEach((s:any)=>{
          result.rows.push(...s.budgetLineItems.filter((b:any)=>!(result.rows.some((lr:any)=>lr.key==b.id))).map((b:any)=>({key:b.id,lineItem:b.lineItem,sectionId:s.sectionName})))
        })
      })
    }else{
      result.groupExtractor = (s:any)=>({key:s.id,label:s.sectionName,rows:s.budgetLineItems})
      result.rows =  budgetTemplatesToProcess[0].sections
    }
    return result;
  }

  get nptOperations(){
       return this.$store.getters["nptModule/getAllNpt"];
  }

  get budgetTemplates(){
    return this.$store.getters["budgetTemplateModule/getAllTemplates"]
  }

  cummulativePercentage(a: any, b: any, c: any) {
    let d = Number(b)+Number(c)
    let q = Number(a)/Number(d)
    let v = q*100
    return v.toFixed(2)
  }
  
  percentage(a: any, b: any) {
    let q = a/b
    let v = q * 100
    return v.toFixed(2)
  }

  setDailyReportProperties() {
     this.$store.dispatch("nptModule/fetchNptOperation").then(() => {
      var currentReportDeviationSurvey = this.editedReport.deviationSurveys.filter((x:any)=>x.dailyReportId == this.editedReport.id)
     this.dailyReport = {
      id:this.editedReport.id,
      startDepth:  this.editedReport.startDepth,
      endDepth:this.editedReport.endDepth,
      rkbDepth: this.editedReport.rkbDepth,
      dfe: this.editedReport.dfe,
      reportDate:  this.editedReport.reportDate,
      startTime:  this.editedReport.startTime,
      endTime:   this.editedReport.endTime,
      cost: this.editedReport.cost,
      mud: {
        mudType: this.editedReport.mud.mudType,
        mudWeight: this.editedReport.mud.mudWeight,
        funnelViscosity: this.editedReport.mud.funnelViscosity,
        eCD: this.editedReport.mud.ecd,
        plasticViscosity: this.editedReport.mud.plasticViscosity,
        yieldPoint: this.editedReport.mud.yieldPoint,
        gelStrength: this.editedReport.mud.gelStrength,
        pitTemp: this.editedReport.mud.pitTemp,
        hTHP: this.editedReport.mud.hthp,
        cake: this.editedReport.mud.cake,
        solids: this.editedReport.mud.solids,
        dailyMudCost: this.editedReport.mud.dailyMudCost,
        prevMudCost: this.editedReport.mud.prevMudCost,
        totalMudCost: this.editedReport.mud.totalMudCost,
      },
      depthSummaryModel: {
        currentHoleSize: this.editedReport.depthSummary.currentHoleSize,
        currentDepth: this.editedReport.depthSummary.currentDepth,
        totalDepth: this.editedReport.depthSummary.totalDepth,
        depthProgress: this.editedReport.depthSummary.depthProgress,
        previousDepth: this.editedReport.depthSummary.previousDepth,
        actualTotalDepth: this.editedReport.depthSummary.actualTotalDepth,
        intermidateCasingSize: this.editedReport.depthSummary.intermidateCasingSize,
        intermidateCasingDepth: this.editedReport.depthSummary.intermidateCasingDepth,
        productionCasingSize: this.editedReport.depthSummary.productionCasingSize,
        productionCasingDepth: this.editedReport.depthSummary.productionCasingDepth,
        conductorCasingSize: this.editedReport.depthSummary.conductorCasingSize,
        conductorCasingDepth: this.editedReport.depthSummary.conductorCasingDepth,
        linearSize: this.editedReport.depthSummary.linearSize,
        linearDepth: this.editedReport.depthSummary.linearDepth,
        lot: this.editedReport.depthSummary.lot,
        fit: this.editedReport.depthSummary.fit,
      },
      scheduleModel: {
        plannedRigMoveStartDate: this.editedReport.schedule.plannedRigMoveStartDate,
        plannedRigMoveEndDate: this.editedReport.schedule.plannedRigMoveEndDate,
        plannedRigMoveDays: this.editedReport.schedule.plannedRigMoveDays,
        plannedOperationsDays: this.editedReport.schedule.plannedOperationsDays,
        actualRigMoveDays: this.editedReport.schedule.actualRigMoveDays,
        rigMoveStartDate: this.editedReport.schedule.rigMoveStartDate,
        rigMoveEndDate: this.editedReport.schedule.rigMoveEndDate,
        spudDate: this.editedReport.schedule.spudDate,
        spudTime: this.editedReport.schedule.spudTime,
        daysOnLocation: this.editedReport.schedule.daysOnLocation,
        daysFromSpud: this.editedReport.schedule.daysFromSpud,
        operatingDaysVariance: this.editedReport.schedule.operatingDaysVariance,
      },
      aFESummaryModel: {
        code: this.editedReport.afeSummary.code,
        supplementaryCode: this.editedReport.afeSummary.supplementaryCode,
        amount: this.editedReport.afeSummary.amount,
        supplementaryAmount: this.editedReport.afeSummary.supplementaryAmount,
        actualBudgetRatio: this.editedReport.afeSummary.actualBudgetRatio,
        supplementaryBudgetRatio: this.editedReport.afeSummary.supplementaryBudgetRatio,
        dailyCost: this.editedReport.afeSummary.dailyCost,
        cummulativeCost: this.editedReport.afeSummary.cummulativeCost,
        percentage: this.editedReport.afeSummary.percentage,
        suppPercentage: this.editedReport.afeSummary.suppPercentage,
        isAmount: this.editedReport.afeSummary.isAmount,
      },
      mudVolume: {
        volTotMudStart: this.editedReport.mudVolume.startVolume,
        volMudDumped: this.editedReport.mudVolume.mudDumped,
        volMudReceived:this.editedReport.mudVolume.mudReceived,
        volMudReturned: this.editedReport.mudVolume.mudReturned,
        volMudBuilt: this.editedReport.mudVolume.mudBuilt,
        volMudString: this.editedReport.mudVolume.mudWithinActiveString,
        volMudCasing: this.editedReport.mudVolume.mudContainedInCasing,
        volMudHole: this.editedReport.mudVolume.mudInOpenAnnulus,
        volMudRiser: this.editedReport.mudVolume.mudInRiserSection,
      },
      mudLosses: {
        volLostShakerSurf: this.editedReport.mudLosses.shaker,
        volLostMudCleanerSurf: this.editedReport.mudLosses.cleaningEquipment,
        volLostPitsSurf: this.editedReport.mudLosses.pitRoom,
        volLostTrippingSurf: this.editedReport.mudLosses.tripping,
        volLostOtherSurf: this.editedReport.mudLosses.otherSurface,
        volLostCircHole: this.editedReport.mudLosses.circulating,
        volLostCsgHole: this.editedReport.mudLosses.runningCasing,
        volLostCmtHole:this.editedReport.mudLosses.cementing,
        volLostBhdCsgHole: this.editedReport.mudLosses.behindCasing,
        volLostAbandonHole: this.editedReport.mudLosses.abandonment,
        volLostOtherHole:this.editedReport.mudLosses.otherLocation,
      },
      personnelContactsLog: this.editedReport.personnelContactsLog.map(
        (personnelContact: any) => ({
          id:personnelContact.id,
          position: personnelContact.position,
          contactName: personnelContact.contactName,
          company: personnelContact.company,
          email: personnelContact.email,
          office: personnelContact.office,
          mobile: personnelContact.mobile,
        })
      ),
      personnelOnBoardLog: this.editedReport.personnelonBoards.map(
        (personnelOnBoards: any) => ({
          id:personnelOnBoards.id,
          company: personnelOnBoards.company,
          serviceType: personnelOnBoards.serviceType,
          workHour: personnelOnBoards.workHour,
          standByHour: personnelOnBoards.standByHour,
          count: personnelOnBoards.count,
          note: personnelOnBoards.note,
        })
      ),
      deviationSurveyData: currentReportDeviationSurvey.map(
        (deviationSurvey: any) => ({
          id:deviationSurvey.id,
          mD:deviationSurvey.md,
          inclinationAngle: deviationSurvey.inclinationAngle,
          azimuth: deviationSurvey.azimuth,
          tVD: deviationSurvey.tvd,
          verticalSection: deviationSurvey.verticalSection,
          nS: deviationSurvey.ns,
          eW: deviationSurvey.ew,
          dLS: deviationSurvey.dls,
        })
      ),
      timeBreakDown: {
        circulation: this.editedReport.timeBreakDown.circulation,
        rIH:this.editedReport.timeBreakDown.rih,
        pOOH: this.editedReport.timeBreakDown.pooh,
        drilling: this.editedReport.timeBreakDown.drilling,
        cementing: this.editedReport.timeBreakDown.cementing,
        tCP:this.editedReport.timeBreakDown.tcp,
        others: this.editedReport.timeBreakDown.others,
      },
      safetyModel: {
        lastBOPDrill: this.editedReport.safety.lastBOPDrill,
        nextBOPTest: this.editedReport.safety.nextBOPTest,
        lastSafetyMeeting: this.editedReport.safety.lastSafetyMeeting,
        lastFireDrill: this.editedReport.safety.lastFireDrill,
        lastBOPTest: this.editedReport.safety.lastBOPTest,
        lastCsgPres: this.editedReport.safety.lastCsgPres,
      },
      drillingParametersModel: {
        torque: this.editedReport.drillingParameter.torque,
        depth: this.editedReport.drillingParameter.depth,
        puWeight: this.editedReport.drillingParameter.puWeight,
        soWeight: this.editedReport.drillingParameter.soWeight,
        maxOPull: this.editedReport.drillingParameter.maxOPull,
        rotatingWeight: this.editedReport.drillingParameter.rotatingWeight,
      },
      bitModel: {
        bha: this.editedReport.bit.bha,
        bitSize: this.editedReport.bit.bitSize,
        modelNo: this.editedReport.bit.modelNo,
        bitType: this.editedReport.bit.bitType,
        iadcNo: this.editedReport.bit.iadcNo,
        serialNo: this.editedReport.bit.serialNo,
        bitHours: this.editedReport.bit.bitHours,
        bitNozzless: this.editedReport.bit.bitNozzless,
        tfa: this.editedReport.bit.tfa,
        impact: this.editedReport.bit.impact,
        jetVelocity: this.editedReport.bit.jetVelocity,
        rop: this.editedReport.bit.rop,
        rpm: this.editedReport.bit.rpm,
        bitRevolution: this.editedReport.bit.bitRevolution,
        bitRotationHours: this.editedReport.bit.bitRotationHours,
        averageCummulative: this.editedReport.bit.averageCummulative,
        wob: this.editedReport.bit.wob,
        footage: this.editedReport.bit.footage,
      },
     activityLog : this.editedReport.activityLog.map((logEntry:any) => ({
      id:logEntry.id,
      from: logEntry.from,
      to: logEntry.to,
      hasNpt: logEntry.hasNpt,
      activity:  this.activitiesForProject.find((activityInProject:any)=> activityInProject.id == logEntry.activityInProjectId),
      milestone: this.activitiesForProject.find((activityInProject:any)=> activityInProject.id == logEntry.activityInProjectId)
               .milestones.find((milestone:any)=> milestone.id == logEntry.activityId),
      description: logEntry.description,
      depth: logEntry.depth,
      nptOperation: this.nptOperations.find((nptOperation:any)=> nptOperation.id == logEntry.npt.nptOperationId),
      nptDescription: logEntry.npt.nptDescription,
      nptHours: logEntry.npt.nptHours,
      }))
      }
     })
  }

  get DDR () {
    return  {
      id:this.dailyReport.id,
      startDepth:  this.dailyReport.startDepth,
      endDepth:  this.dailyReport.endDepth,
      rkbDepth: this.dailyReport.rkbDepth,
      dfe: this.dailyReport.dfe,
      reportDate:  this.dailyReport.reportDate,
      startTime:  this.dailyReport.startTime,
      endTime:   this.dailyReport.endTime,
      cost: this.dailyReport.cost,
      activityLog: this.dailyReport.activityLog.map((logEntry: any) => ({
      id:logEntry.id,
      from: logEntry.from,
      to: logEntry.to,
      activityInProjectName: logEntry.activity != null ? logEntry.activity.name: "",
      activityName:logEntry.milestone != null ? logEntry.milestone.name: "",
      hasNpt: logEntry.hasNpt,
      activityInProjectId: logEntry.activityInProjectId,
      activityId: logEntry.activityId,
      description: logEntry.description,
      depth: logEntry.depth,
      nptRef:logEntry.hasNpt? logEntry.nptOperation.nptRef: "",
      npt:{
      nptHours:logEntry.hasNpt? logEntry.nptHours: "",
      nptDescription: logEntry.hasNpt? logEntry.nptDescription: "",
      }
      })),
      mud: {
        mudType: this.dailyReport.mud.mudType,
        mudWeight: this.dailyReport.mud.mudWeight,
        funnelViscosity: this.dailyReport.mud.funnelViscosity,
        ecd: this.dailyReport.mud.eCD,
        plasticViscosity: this.dailyReport.mud.plasticViscosity,
        yieldPoint: this.dailyReport.mud.yieldPoint,
        gelStrength: this.dailyReport.mud.gelStrength,
        pitTemp: this.dailyReport.mud.pitTemp,
        hthp: this.dailyReport.mud.hTHP,
        cake: this.dailyReport.mud.cake,
        solids: this.dailyReport.mud.solids,
        dailyMudCost: this.dailyReport.mud.dailyMudCost,
        prevMudCost: this.dailyReport.mud.prevMudCost,
        totalMudCost: this.dailyReport.mud.totalMudCost,
      },
      depthSummary: {
        currentHoleSize: this.dailyReport.depthSummaryModel.currentHoleSize,
        currentDepth: this.dailyReport.depthSummaryModel.currentDepth,
        totalDepth: this.dailyReport.depthSummaryModel.totalDepth,
        depthProgress: this.dailyReport.depthSummaryModel.depthProgress,
        previousDepth: this.dailyReport.depthSummaryModel.previousDepth,
        actualTotalDepth: this.dailyReport.depthSummaryModel.actualTotalDepth,
        intermidateCasingSize: this.dailyReport.depthSummaryModel.intermidateCasingSize,
        intermidateCasingDepth: this.dailyReport.depthSummaryModel.intermidateCasingDepth,
        productionCasingSize: this.dailyReport.depthSummaryModel.productionCasingSize,
        productionCasingDepth: this.dailyReport.depthSummaryModel.productionCasingDepth,
        conductorCasingSize: this.dailyReport.depthSummaryModel.conductorCasingSize,
        conductorCasingDepth: this.dailyReport.depthSummaryModel.conductorCasingDepth,
        linearSize: this.dailyReport.depthSummaryModel.linearSize,
        linearDepth: this.dailyReport.depthSummaryModel.linearDepth,
        lot: this.dailyReport.depthSummaryModel.lot,
        fit: this.dailyReport.depthSummaryModel.fit,
      },
      schedule: {
        plannedRigMoveStartDate: this.dailyReport.scheduleModel.plannedRigMoveStartDate,
        plannedRigMoveEndDate: this.dailyReport.scheduleModel.plannedRigMoveEndDate,
        plannedRigMoveDays: this.dailyReport.scheduleModel.plannedRigMoveDays,
        plannedOperationsDays: this.dailyReport.scheduleModel.plannedOperationsDays,
        actualRigMoveDays: this.dailyReport.scheduleModel.actualRigMoveDays,
        rigMoveStartDate: this.dailyReport.scheduleModel.rigMoveStartDate,
        rigMoveEndDate: this.dailyReport.scheduleModel.rigMoveEndDate,
        spudDate: this.dailyReport.scheduleModel.spudDate,
        spudTime: this.dailyReport.scheduleModel.spudTime,
        daysOnLocation: this.dailyReport.scheduleModel.daysOnLocation,
        daysFromSpud: this.dailyReport.scheduleModel.daysFromSpud,
        operatingDaysVariance: this.dailyReport.scheduleModel.operatingDaysVariance,
      },
      afeSummary: {
        code: this.dailyReport.aFESummaryModel.code,
        supplementaryCode: this.dailyReport.aFESummaryModel.supplementaryCode,
        amount: this.dailyReport.aFESummaryModel.amount,
        supplementaryAmount: this.dailyReport.aFESummaryModel.supplementaryAmount,
        actualBudgetRatio: this.dailyReport.aFESummaryModel.actualBudgetRatio,
        supplementaryBudgetRatio: this.dailyReport.aFESummaryModel.supplementaryBudgetRatio,
        dailyCost: this.dailyReport.aFESummaryModel.dailyCost,
        cummulativeCost: this.dailyReport.aFESummaryModel.cummulativeCost,
        percentage: this.dailyReport.aFESummaryModel.percentage,
        suppPercentage: this.dailyReport.aFESummaryModel.suppPercentage,
        isAmount: this.dailyReport.aFESummaryModel.isAmount,
      },
      mudVolume: {
        startVolume: this.dailyReport.mudVolume.volTotMudStart,
        mudDumped: this.dailyReport.mudVolume.volMudDumped,
        mudReceived:this.dailyReport.mudVolume.volMudReceived,
        mudReturned: this.dailyReport.mudVolume.volMudReturned,
        mudBuilt: this.dailyReport.mudVolume.volMudBuilt,
        mudWithinActiveString: this.dailyReport.mudVolume.volMudString,
        mudContainedInCasing: this.dailyReport.mudVolume.volMudCasing,
        mudInOpenAnnulus: this.dailyReport.mudVolume.volMudHole,
        mudInRiserSection: this.dailyReport.mudVolume.volMudRiser,
      },
      mudLosses: {
        shaker: this.dailyReport.mudLosses.volLostShakerSurf,
        cleaningEquipment: this.dailyReport.mudLosses.volLostMudCleanerSurf,
        pitRoom: this.dailyReport.mudLosses.volLostPitsSurf,
        tripping: this.dailyReport.mudLosses.volLostTrippingSurf,
        otherSurface: this.dailyReport.mudLosses.volLostOtherSurf,
        circulating: this.dailyReport.mudLosses.volLostCircHole,
        runningCasing: this.dailyReport.mudLosses.volLostCsgHole,
        cementing:this.dailyReport.mudLosses.volLostCmtHole,
        behindCasing: this.dailyReport.mudLosses.volLostBhdCsgHole,
        abandonment: this.dailyReport.mudLosses.volLostAbandonHole,
        otherLocation:this.dailyReport.mudLosses.volLostOtherHole,
      },
      personnelContactsLog: this.dailyReport.personnelContactsLog.map(
        (personnelContact: any) => ({
          id:personnelContact.id,
          position: personnelContact.position,
          contactName: personnelContact.contactName,
          company: personnelContact.company,
          email: personnelContact.email,
          office: personnelContact.office,
          mobile: personnelContact.mobile,
        })
      ),
      personnelonBoards: this.dailyReport.personnelOnBoardLog.map(
        (personnelOnBoards: any) => ({
          id:personnelOnBoards.id,
          company: personnelOnBoards.company,
          serviceType: personnelOnBoards.serviceType,
          workHour: personnelOnBoards.workHour,
          standByHour: personnelOnBoards.standByHour,
          count: personnelOnBoards.count,
          note: personnelOnBoards.note,
        })
      ),
      deviationSurveys: this.dailyReport.deviationSurveyData.map(
        (deviationSurvey: any) => ({
          md:deviationSurvey.mD,
          inclinationAngle: deviationSurvey.inclinationAngle,
          azimuth: deviationSurvey.azimuth,
          tvd: deviationSurvey.tVD,
          verticalSection: deviationSurvey.verticalSection,
          ns: deviationSurvey.nS,
          ew: deviationSurvey.eW,
          dls: deviationSurvey.dLS,
        })
      ),
      timeBreakDown: {
        circulation: this.dailyReport.timeBreakDown.circulation,
        rih:this.dailyReport.timeBreakDown.rIH,
        pooh: this.dailyReport.timeBreakDown.pOOH,
        drilling: this.dailyReport.timeBreakDown.drilling,
        cementing: this.dailyReport.timeBreakDown.cementing,
        tcp:this.dailyReport.timeBreakDown.tCP,
        others: this.dailyReport.timeBreakDown.others,
      },
      safety: {
        lastBOPDrill: this.dailyReport.safetyModel.lastBOPDrill,
        nextBOPTest: this.dailyReport.safetyModel.nextBOPTest,
        lastSafetyMeeting: this.dailyReport.safetyModel.lastSafetyMeeting,
        lastFireDrill: this.dailyReport.safetyModel.lastFireDrill,
        lastBOPTest: this.dailyReport.safetyModel.lastBOPTest,
        lastCsgPres: this.dailyReport.safetyModel.lastCsgPres,
      },
      drillingParameter: {
        torque: this.dailyReport.drillingParametersModel.torque,
        depth: this.dailyReport.drillingParametersModel.depth,
        puWeight: this.dailyReport.drillingParametersModel.puWeight,
        soWeight: this.dailyReport.drillingParametersModel.soWeight,
        maxOPull: this.dailyReport.drillingParametersModel.maxOPull,
        rotatingWeight: this.dailyReport.drillingParametersModel.rotatingWeight,
      },
      bit: {
        bha: this.dailyReport.bitModel.bha,
        bitSize: this.dailyReport.bitModel.bitSize,
        modelNo: this.dailyReport.bitModel.modelNo,
        bitType: this.dailyReport.bitModel.bitType,
        iadcNo: this.dailyReport.bitModel.iadcNo,
        serialNo: this.dailyReport.bitModel.serialNo,
        bitHours: this.dailyReport.bitModel.bitHours,
        bitNozzless: this.dailyReport.bitModel.bitNozzless,
        tfa: this.dailyReport.bitModel.tfa,
        impact: this.dailyReport.bitModel.impact,
        jetVelocity: this.dailyReport.bitModel.jetVelocity,
        rop: this.dailyReport.bitModel.rop,
        rpm: this.dailyReport.bitModel.rpm,
        bitRevolution: this.dailyReport.bitModel.bitRevolution,
        bitRotationHours: this.dailyReport.bitModel.bitRotationHours,
        averageCummulative: this.dailyReport.bitModel.averageCummulative,
        wob: this.dailyReport.bitModel.wob,
        footage: this.dailyReport.bitModel.footage,
      },
    };
  }
  updateReport() {
    let dailyReport = {
      id:this.dailyReport.id,
      startDepth: this.dailyReport.startDepth,
      endDepth: this.dailyReport.endDepth,
      rkbDepth: this.dailyReport.rkbDepth,
      dfe: this.dailyReport.dfe,
      startTime: this.connvertTimeStringToDate(this.dailyReport.startTime),
      endTime: this.connvertTimeStringToDate(this.dailyReport.endTime),
      reportDate: this.dailyReport.reportDate,
      projectId: this.projectId,
      cost: {budgetLines:this.dailyReport.cost.budgetLines.map((b:any) => ({lineItemValue:b.lineValue,lineItemId:b.lineItemId,activityId:b.activityId}))},
      activityLog: this.dailyReport.activityLog.map((logEntry: any) => ({
        id:logEntry.id,
        from: logEntry.from,
        to: logEntry.to,
        hasNpt: logEntry.hasNpt,
        activityInProjectId: logEntry.activity.id,
        activityId: logEntry.milestone.id,
        description: logEntry.description,
        depth: logEntry.depth,
        nptRef:logEntry.hasNpt? logEntry.nptOperation.nptRef: "",
        npt:{
        nptHours:logEntry.hasNpt? logEntry.nptHours: 0,
        nptDescription: logEntry.hasNpt? logEntry.nptDescription: "",
        nPTOperationId: logEntry.hasNpt?  logEntry.nptOperation.id: ""}
      })),
      mud: {
        mudType: this.dailyReport.mud.mudType,
        mudWeight: this.dailyReport.mud.mudWeight,
        funnelViscosity: this.dailyReport.mud.funnelViscosity,
        plasticViscosity: this.dailyReport.mud.plasticViscosity,
        yieldPoint: this.dailyReport.mud.yieldPoint,
        gelStrength: this.dailyReport.mud.gelStrength,
        htHp: this.dailyReport.mud.hTHP,
        cake: this.dailyReport.mud.cake,
        solids: this.dailyReport.mud.solids,
        eCD: this.dailyReport.mud.eCD,
        pitTemp: this.dailyReport.mud.pitTemp,
        dailyMudCost: this.dailyReport.mud.dailyMudCost,
      },
      timeBreakDown: {
        circulation: this.dailyReport.timeBreakDown.circulation,
        rIH: this.dailyReport.timeBreakDown.rIH,
        pOOH: this.dailyReport.timeBreakDown.pOOH,
        drilling: this.dailyReport.timeBreakDown.drilling,
        cementing: this.dailyReport.timeBreakDown.cementing,
        tCP: this.dailyReport.timeBreakDown.tCP,
        others: this.dailyReport.timeBreakDown.others,
      },
      depthSummaryModel: {
        currentHoleSize: this.dailyReport.depthSummaryModel.currentHoleSize,
        currentDepth: this.dailyReport.depthSummaryModel.currentDepth,
        actualTotalDepth: this.dailyReport.depthSummaryModel.actualTotalDepth,
        // totalDepth: this.dailyReport.depthSummaryModel.totalDepth,
        // previousDepth: this.dailyReport.depthSummaryModel.previousDepth,
        // depthProgress: this.dailyReport.depthSummaryModel.depthProgress,
        intermidateCasingSize: this.dailyReport.depthSummaryModel.intermidateCasingSize,
        intermidateCasingDepth: this.dailyReport.depthSummaryModel.intermidateCasingDepth,
        productionCasingSize: this.dailyReport.depthSummaryModel.productionCasingSize,
        productionCasingDepth: this.dailyReport.depthSummaryModel.productionCasingDepth,
        conductorCasingSize: this.dailyReport.depthSummaryModel.conductorCasingSize,
        conductorCasingDepth: this.dailyReport.depthSummaryModel.conductorCasingDepth,
        linearSize: this.dailyReport.depthSummaryModel.linearSize,
        linearDepth: this.dailyReport.depthSummaryModel.linearDepth,
        lot: this.dailyReport.depthSummaryModel.lot,
        fit: this.dailyReport.depthSummaryModel.fit,
      },
      scheduleModel: {
        plannedRigMoveStartDate: this.dailyReport.scheduleModel.plannedRigMoveStartDate,
        plannedRigMoveEndDate: this.dailyReport.scheduleModel.plannedRigMoveEndDate,
        plannedRigMoveDays: this.dailyReport.scheduleModel.plannedRigMoveDays,
        plannedOperationsDays: this.dailyReport.scheduleModel.plannedOperationsDays,
        actualRigMoveDays: this.dailyReport.scheduleModel.actualRigMoveDays,
        rigMoveStartDate: this.dailyReport.scheduleModel.rigMoveStartDate,
        rigMoveEndDate: this.dailyReport.scheduleModel.rigMoveEndDate,
        spudDate: this.dailyReport.scheduleModel.spudDate,
        spudTime: this.dailyReport.scheduleModel.spudTime,
        daysOnLocation: this.dailyReport.scheduleModel.daysOnLocation,
        daysFromSpud: this.dailyReport.scheduleModel.daysFromSpud,
        operatingDaysVariance: this.dailyReport.scheduleModel.operatingDaysVariance,        
      },
      aFESummaryModel: {
        code: this.dailyReport.aFESummaryModel.code,
        amount: this.dailyReport.aFESummaryModel.amount,
        supplementaryCode: this.dailyReport.aFESummaryModel.supplementaryCode,
        actualBudgetRatio: this.dailyReport.aFESummaryModel.actualBudgetRatio,
        supplementaryBudgetRatio: this.dailyReport.aFESummaryModel.supplementaryBudgetRatio,
        supplementaryAmount: this.dailyReport.aFESummaryModel.supplementaryAmount,
        dailyCost: this.dailyReport.aFESummaryModel.dailyCost,
        isAmount: this.dailyReport.aFESummaryModel.isAmount,
      },
      personnelContactsLog: this.dailyReport.personnelContactsLog.map(
        (personnelContact: any) => ({
          id:personnelContact.id,
          position: personnelContact.position,
          contactName: personnelContact.contactName,
          company: personnelContact.company,
          email: personnelContact.email,
          office: personnelContact.office,
          mobile: personnelContact.mobile,
        })
      ),
      personnelOnBoardLog: this.dailyReport.personnelOnBoardLog.map(
        (personnelOnBoards: any) => ({
          id:personnelOnBoards.id,
          company: personnelOnBoards.company,
          serviceType: personnelOnBoards.serviceType,
          workHour: personnelOnBoards.workHour,
          standByHour: personnelOnBoards.standByHour,
          count: personnelOnBoards.count,
          note: personnelOnBoards.note,
        })
      ),
      deviationSurveyData: this.dailyReport.deviationSurveyData.map(
        (deviationSurvey: any) => ({
          id:deviationSurvey.id,
          mD:deviationSurvey.mD,
          inclinationAngle: deviationSurvey.inclinationAngle,
          azimuth: deviationSurvey.azimuth,
          tVD: deviationSurvey.tVD,
          verticalSection: deviationSurvey.verticalSection,
          nS: deviationSurvey.nS,
          eW: deviationSurvey.eW,
          dLS: deviationSurvey.dLS,
        })
      ),
      safetyModel: {
        lastBOPDrill: this.dailyReport.safetyModel.lastBOPDrill,
        nextBOPTest: this.dailyReport.safetyModel.nextBOPTest,
        lastSafetyMeeting: this.dailyReport.safetyModel.lastSafetyMeeting,
        lastFireDrill: this.dailyReport.safetyModel.lastFireDrill,
        lastBOPTest: this.dailyReport.safetyModel.lastBOPTest,
        LastCsgPresTest: this.dailyReport.safetyModel.lastCsgPres,
      },
      mudLosses: {
        shaker: this.dailyReport.mudLosses.volLostShakerSurf,
        pitRoom: this.dailyReport.mudLosses.volLostPitsSurf,
        cleaningEquipment:this.dailyReport.mudLosses.volLostMudCleanerSurf,
        tripping: this.dailyReport.mudLosses.volLostTrippingSurf,
        otherSurface: this.dailyReport.mudLosses.volLostOtherSurf,
        circulating: this.dailyReport.mudLosses.volLostCircHole,
        runningCasing: this.dailyReport.mudLosses.volLostCsgHole,
        cementing: this.dailyReport.mudLosses.volLostCmtHole,
        behindCasing: this.dailyReport.mudLosses.volLostBhdCsgHole,
        abandonment: this.dailyReport.mudLosses.volLostAbandonHole,
        otherLocation: this.dailyReport.mudLosses.volLostOtherHole,
      },
      mudVolume: {
        startVolume: this.dailyReport.mudVolume.volTotMudStart,
        mudDumped: this.dailyReport.mudVolume.volMudDumped,
        mudReceived:this.dailyReport.mudVolume.volMudReceived,
        mudReturned: this.dailyReport.mudVolume.volMudReturned,
        mudBuilt: this.dailyReport.mudVolume.volMudBuilt,
        mudWithinActiveString: this.dailyReport.mudVolume.volMudString,
        mudContainedInCasing: this.dailyReport.mudVolume.volMudCasing,
        mudInOpenAnnulus: this.dailyReport.mudVolume.volMudHole,
        mudInRiserSection:this.dailyReport.mudVolume.volMudRiser,
      },
      drillingParametersModel: {
        torque: this.dailyReport.drillingParametersModel.torque,
        depth: this.dailyReport.drillingParametersModel.depth,
        puWeight: this.dailyReport.drillingParametersModel.puWeight,
        soWeight: this.dailyReport.drillingParametersModel.soWeight,
        maxOPull: this.dailyReport.drillingParametersModel.maxOPull,
        rotatingWeight: this.dailyReport.drillingParametersModel.rotatingWeight,
      },
      bitModel: {
        bha: this.dailyReport.bitModel.bha,
        bitSize: this.dailyReport.bitModel.bitSize,
        modelNo: this.dailyReport.bitModel.modelNo,
        bitType: this.dailyReport.bitModel.bitType,
        iadcNo: this.dailyReport.bitModel.iadcNo,
        serialNo: this.dailyReport.bitModel.serialNo,
        bitHours: this.dailyReport.bitModel.bitHours,
        bitNozzless: this.dailyReport.bitModel.bitNozzless,
        tfa: this.dailyReport.bitModel.tfa,
        impact: this.dailyReport.bitModel.impact,
        jetVelocity: this.dailyReport.bitModel.jetVelocity,
        rpm: this.dailyReport.bitModel.rpm,
        bitRevolution: this.dailyReport.bitModel.bitRevolution,
        bitRotationHours: this.dailyReport.bitModel.bitRotationHours,
        wob: this.dailyReport.bitModel.wob,
      }
    };
    HTTP.put("ddr/update", dailyReport)
      .then((response: any) => {
        this.busy = "SUCCESS";
        this.$store.commit("DailyReportModule/updateEditedDailyReport", {
          dailyReport: response.data.data,
          projectId: this.projectId,
        });
        setTimeout(() => {
          this.busy = "NONE";
        }, 3000);
      })
      .catch((error: any) => {
          this.busy = "NONE";
        // window.console.log(error);
      });
  }
  connvertTimeStringToDate(timeString: string) {
    let hr = timeString.split(":")[0];
    let min = timeString.split(":")[1];
    if (hr.charAt(0) == "0") hr = hr.charAt(1);
    if (min.charAt(0) == "0") min = min.charAt(1);
    let today = new Date();
    today.setHours(parseInt(hr));
    today.setMinutes(parseInt(min));
    return today;
  }
   @Watch('editedReport',{ immediate:true})onPropertyChanged(value: string, oldValue: string) {
     if(this.editedReport.mud){
     this.setDailyReportProperties();
     }
  }
  get isEditingReport(){
    if(this.isEditingDailyReport == false){
     this.reInitDailyReport();
    }
  return this.isEditingDailyReport;
 }

  saveReport() {
    this.busy = "LOADING";
    let dailyReport = {
      startDepth: this.dailyReport.startDepth,
      endDepth: this.dailyReport.endDepth,
      rkbDepth: this.dailyReport.rkbDepth,
      dfe: this.dailyReport.dfe,
      startTime: this.connvertTimeStringToDate(this.dailyReport.startTime),
      endTime: this.connvertTimeStringToDate(this.dailyReport.endTime),
      reportDate: this.dailyReport.reportDate,
      projectId: this.projectId,
      cost: {budgetLines:this.dailyReport.cost.budgetLines.map((b:any) => ({lineItemValue:b.lineValue,lineItemId:b.lineItemId,activityId:b.activityId}))},
      activityLog: this.dailyReport.activityLog.map((logEntry: any) => ({
        from: logEntry.from,
        to: logEntry.to,
        hasNpt: logEntry.hasNpt,
        activityInProjectId: logEntry.activity.id,
        activityId: logEntry.milestone.id,
        description: logEntry.description,
        depth: logEntry.depth,
        nptRef:logEntry.hasNpt? logEntry.nptOperation.nptRef:"",
        npt:{
        nPTDescription:logEntry.hasNpt? logEntry.nptDescription:"",
        nPTHours: logEntry.hasNpt? logEntry.nptHours:0,
        nPTOperationId: logEntry.hasNpt? logEntry.nptOperation.id :"",
        }
      })),
      mud: {
        mudType: this.dailyReport.mud.mudType,
        mudWeight: this.dailyReport.mud.mudWeight,
        funnelViscosity: this.dailyReport.mud.funnelViscosity,
        plasticViscosity: this.dailyReport.mud.plasticViscosity,
        yieldPoint: this.dailyReport.mud.yieldPoint,
        gelStrength: this.dailyReport.mud.gelStrength,
        htHp: this.dailyReport.mud.hTHP,
        cake: this.dailyReport.mud.cake,
        solids: this.dailyReport.mud.solids,
        eCD: this.dailyReport.mud.eCD,
        pitTemp: this.dailyReport.mud.pitTemp,
        dailyMudCost: this.dailyReport.mud.dailyMudCost,
      },
      depthSummaryModel: {
        currentHoleSize: this.dailyReport.depthSummaryModel.currentHoleSize,
        currentDepth: this.dailyReport.depthSummaryModel.currentDepth,
        actualTotalDepth: this.dailyReport.depthSummaryModel.actualTotalDepth,
        // totalDepth: this.dailyReport.depthSummaryModel.totalDepth,
        // previousDepth: this.dailyReport.depthSummaryModel.previousDepth,
        // depthProgress: this.dailyReport.depthSummaryModel.depthProgress,
        intermidateCasingSize: this.dailyReport.depthSummaryModel.intermidateCasingSize,
        intermidateCasingDepth: this.dailyReport.depthSummaryModel.intermidateCasingDepth,
        productionCasingSize: this.dailyReport.depthSummaryModel.productionCasingSize,
        productionCasingDepth: this.dailyReport.depthSummaryModel.productionCasingDepth,
        conductorCasingSize: this.dailyReport.depthSummaryModel.conductorCasingSize,
        conductorCasingDepth: this.dailyReport.depthSummaryModel.conductorCasingDepth,
        linearSize: this.dailyReport.depthSummaryModel.linearSize,
        linearDepth: this.dailyReport.depthSummaryModel.linearDepth,
        lot: this.dailyReport.depthSummaryModel.lot,
      },
      scheduleModel: {
        plannedRigMoveStartDate: this.dailyReport.scheduleModel.plannedRigMoveStartDate,
        plannedRigMoveEndDate: this.dailyReport.scheduleModel.plannedRigMoveEndDate,
        plannedRigMoveDays: this.dailyReport.scheduleModel.plannedRigMoveDays,
        plannedOperationsDays: this.dailyReport.scheduleModel.plannedOperationsDays,
        actualRigMoveDays: this.dailyReport.scheduleModel.actualRigMoveDays,
        rigMoveStartDate: this.dailyReport.scheduleModel.rigMoveStartDate,
        rigMoveEndDate: this.dailyReport.scheduleModel.rigMoveEndDate,
        spudDate: this.dailyReport.scheduleModel.spudDate,
        spudTime: this.dailyReport.scheduleModel.spudTime,
        daysOnLocation: this.dailyReport.scheduleModel.daysOnLocation,
        daysFromSpud: this.dailyReport.scheduleModel.daysFromSpud,
        operatingDaysVariance: this.dailyReport.scheduleModel.operatingDaysVariance,
      },
      aFESummaryModel: {
        code: this.dailyReport.aFESummaryModel.code,
        amount: this.dailyReport.aFESummaryModel.amount,
        supplementaryCode: this.dailyReport.aFESummaryModel.supplementaryCode,
        supplementaryBudgetRatio: this.dailyReport.aFESummaryModel.supplementaryBudgetRatio,
        actualBudgetRatio: this.dailyReport.aFESummaryModel.actualBudgetRatio,
        supplementaryAmount: this.dailyReport.aFESummaryModel.supplementaryAmount,
        dailyCost: this.dailyReport.aFESummaryModel.dailyCost,
        isAmount: this.dailyReport.aFESummaryModel.isAmount,
      },
      timeBreakDown: {
        circulation: this.dailyReport.timeBreakDown.circulation,
        rIH: this.dailyReport.timeBreakDown.rIH,
        pOOH: this.dailyReport.timeBreakDown.pOOH,
        drilling: this.dailyReport.timeBreakDown.drilling,
        cementing: this.dailyReport.timeBreakDown.cementing,
        tCP: this.dailyReport.timeBreakDown.tCP,
        others: this.dailyReport.timeBreakDown.others,
      },
      personnelContactsLog: this.dailyReport.personnelContactsLog.map(
        (personnelContact: any) => ({
          position: personnelContact.position,
          contactName: personnelContact.contactName,
          company: personnelContact.company,
          email: personnelContact.email,
          office: personnelContact.office,
          mobile: personnelContact.mobile,
        })
      ),
      personnelOnBoardLog: this.dailyReport.personnelOnBoardLog.map(
        (personnelOnBoards: any) => ({
          id:personnelOnBoards.id,
          company: personnelOnBoards.company,
          serviceType: personnelOnBoards.serviceType,
          workHour: personnelOnBoards.workHour,
          standByHour: personnelOnBoards.standByHour,
          count: personnelOnBoards.count,
          note: personnelOnBoards.note,
        })
      ),
      deviationSurveyData: this.dailyReport.deviationSurveyData.map(
        (deviationSurvey: any) => ({
          mD:deviationSurvey.mD,
          inclinationAngle: deviationSurvey.inclinationAngle,
          azimuth: deviationSurvey.azimuth,
          tVD: deviationSurvey.tVD,
          verticalSection: deviationSurvey.verticalSection,
          nS: deviationSurvey.nS,
          eW: deviationSurvey.eW,
          dLS: deviationSurvey.dLS,
        })
      ),
      safetyModel: {
        lastBOPDrill: this.dailyReport.safetyModel.lastBOPDrill,
        nextBOPTest: this.dailyReport.safetyModel.nextBOPTest,
        lastSafetyMeeting: this.dailyReport.safetyModel.lastSafetyMeeting,
        lastFireDrill: this.dailyReport.safetyModel.lastFireDrill,
        lastBOPTest: this.dailyReport.safetyModel.lastBOPTest,
        lastCsgPresTest: this.dailyReport.safetyModel.lastCsgPres,
      },
      mudLosses: {
        shaker: this.dailyReport.mudLosses.volLostShakerSurf,
        pitRoom: this.dailyReport.mudLosses.volLostPitsSurf,
        cleaningEquipment:this.dailyReport.mudLosses.volLostMudCleanerSurf,
        tripping: this.dailyReport.mudLosses.volLostTrippingSurf,
        otherSurface: this.dailyReport.mudLosses.volLostOtherSurf,
        circulating: this.dailyReport.mudLosses.volLostCircHole,
        runningCasing: this.dailyReport.mudLosses.volLostCsgHole,
        cementing: this.dailyReport.mudLosses.volLostCmtHole,
        behindCasing: this.dailyReport.mudLosses.volLostBhdCsgHole,
        abandonment: this.dailyReport.mudLosses.volLostAbandonHole,
        otherLocation: this.dailyReport.mudLosses.volLostOtherHole,
      },
      mudVolume: {
        startVolume: this.dailyReport.mudVolume.volTotMudStart,
        mudDumped: this.dailyReport.mudVolume.volMudDumped,
        mudReceived:this.dailyReport.mudVolume.volMudReceived,
        mudReturned: this.dailyReport.mudVolume.volMudReturned,
        mudBuilt: this.dailyReport.mudVolume.volMudBuilt,
        mudWithinActiveString: this.dailyReport.mudVolume.volMudString,
        mudContainedInCasing: this.dailyReport.mudVolume.volMudCasing,
        mudInOpenAnnulus: this.dailyReport.mudVolume.volMudHole,
        mudInRiserSection:this.dailyReport.mudVolume.volMudRiser,
      },
      drillingParametersModel: {
        torque: this.dailyReport.drillingParametersModel.torque,
        depth: this.dailyReport.drillingParametersModel.depth,
        puWeight: this.dailyReport.drillingParametersModel.puWeight,
        soWeight: this.dailyReport.drillingParametersModel.soWeight,
        maxOPull: this.dailyReport.drillingParametersModel.maxOPull,
        rotatingWeight: this.dailyReport.drillingParametersModel.rotatingWeight,
      },
      bitModel: {
        bha: this.dailyReport.bitModel.bha,
        bitSize: this.dailyReport.bitModel.bitSize,
        modelNo: this.dailyReport.bitModel.modelNo,
        bitType: this.dailyReport.bitModel.bitType,
        iadcNo: this.dailyReport.bitModel.iadcNo,
        serialNo: this.dailyReport.bitModel.serialNo,
        bitHours: this.dailyReport.bitModel.bitHours,
        bitNozzless: this.dailyReport.bitModel.bitNozzless,
        tfa: this.dailyReport.bitModel.tfa,
        impact: this.dailyReport.bitModel.impact,
        jetVelocity: this.dailyReport.bitModel.jetVelocity,
        rpm: this.dailyReport.bitModel.rpm,
        bitRevolution: this.dailyReport.bitModel.bitRevolution,
        bitRotationHours: this.dailyReport.bitModel.bitRotationHours,
        wob: this.dailyReport.bitModel.wob,
      },
    };
    HTTP.post("ddr/create", dailyReport)
      .then((response: any) => {
        this.busy = "SUCCESS";
        this.$store.commit("DailyReportModule/addDailyReport", {
          dailyReport: response.data.data,
          projectId: this.projectId,
        });
        this.reInitDailyReport();
        setTimeout(() => {
          this.busy = "NONE";
        }, 3000);
      })
      .catch((error: any) => {
          this.busy = "NONE";
        // window.console.log(error);
      });
  }

  get convertDailyReportToDailyReportModel() {
    return this.$store.getters[
      "DailyReportModule/convertDailyReportToDailyReportModel"
    ](this.dailyReport.id);
  }

  getDaysDifference(day1: any, day2: any) {
    const date1: any = new Date(day1)
    const date2: any = new Date(day2);
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1
  }

  removeActivityLog(index: any) {
    this.dailyReport.activityLog.splice(index, 1);
  }

  onLineValueChanged(budgetUpdate: any) {
    if (!this.dailyReport.budgetChanged) this.dailyReport.budgetChanged = true;

    let budgetLineIndex = this.dailyReport.cost.budgetLines.findIndex(
      (thisBudgetLine: any) =>
        thisBudgetLine.lineItemId == budgetUpdate.lineItemId &&
        thisBudgetLine.activityId == budgetUpdate.columnId
    );
    //where the budget line index is not found, do a push into budget lines
    if (budgetLineIndex < 0) {
      this.dailyReport.cost.budgetLines.push({
        lineValue:Number(budgetUpdate.value),
        lineItemId: budgetUpdate.lineItemId,
        activityId: budgetUpdate.columnId,
      });
    } else {
      //where the budget line is found, do an update of that particular budget line
      this.dailyReport.cost.budgetLines[budgetLineIndex].lineValue =Number(budgetUpdate.value);
    }
  }
    
  get currentUnitSystem(){
      return this.$store.getters['UnitsModule/getCurrentUnitSystem']
  }


  get ddrs(): Array<any> {
    return this.$store.getters["DailyReportModule/getReportsForProject"](this.projectId);
  }

  get lastDdr() {
    return this.ddrs[this.ddrs.length-1]
  }

  private number = 0
  activated() {
    this.activeView = 'General';
    this.$store.dispatch(
        "DailyReportModule/getReportsForProject",
        this.selectedProject.id
    ).then(res => {
        this.number = res.data.data.length+1
    })
    if (this.$store.state.budgetTemplateModule.budgetTemplates.length < 1)
      this.$store.dispatch("budgetTemplateModule/fetchBudgetTemplates");
    if (this.nptOperations.length < 1)
      this.$store.dispatch("nptModule/fetchNptOperation");
    this.$store.dispatch("DailyReportModule/getReportsForProject", this.projectId)
    if (!this.isEdit) {
      window.console.log(this.rigMove)
      this.dailyReport.scheduleModel.plannedRigMoveStartDate = this.rigMove?this.rigMove.start:null
      this.dailyReport.depthSummaryModel.totalDepth= this.selectedProject.well.normDepth.value
      this.dailyReport.scheduleModel.plannedOperationsDays = this.drilling?this.getDaysDifference(this.drilling.start, this.drilling.end): 0
      this.dailyReport.scheduleModel.plannedRigMoveEndDate = this.rigMove?this.rigMove.end:null
      this.dailyReport.scheduleModel.plannedRigMoveDays = this.rigMove?this.getDaysDifference(this.dailyReport.scheduleModel.plannedRigMoveStartDate, this.dailyReport.scheduleModel.plannedRigMoveEndDate): 0
      this.dailyReport.depthSummaryModel.previousDepth = this.lastDdr?this.lastDdr.depthSummary.currentDepth:0
      this.dailyReport.depthSummaryModel.lot = this.lastDdr?this.lastDdr.depthSummary.lot:0
      this.dailyReport.scheduleModel.plannedOperationsDays = this.getDaysDifference(this.selectedProject.start, this.selectedProject.end)
      this.dailyReport.bitModel.bha = this.lastDdr? this.lastDdr. bit.bha:0
      this.dailyReport.bitModel.bitType = this.lastDdr? this.lastDdr. bit.bitType:0
      this.dailyReport.bitModel.modelNo = this.lastDdr? this.lastDdr. bit.modelNo:0
      this.dailyReport.bitModel.iadcNo = this.lastDdr? this.lastDdr. bit.iadcNo:0
      this.dailyReport.bitModel.serialNo = this.lastDdr? this.lastDdr. bit.serialNo:0
      this.dailyReport.mud.prevMudCost = this.lastDdr? Number(this.lastDdr. mud.dailyMudCost):0
      this.dailyReport.scheduleModel.daysOnLocation =  this.rigMove?this.getDaysDifference(currentDate, this.rigMove.end):0
      this.dailyReport.scheduleModel.daysFromSpud =  this.dailyReport.scheduleModel.spudDate ?this.getDaysDifference(currentDate, this.dailyReport.scheduleModel.spudDate):0
      this.dailyReport.scheduleModel.spudDate =  this.lastDdr?this.lastDdr.schedule.spudDate:null
      this.dailyReport.scheduleModel.spudTime =  this.lastDdr?this.lastDdr.schedule.spudTime:null
      this.dailyReport.scheduleModel.operatingDaysVariance =  this.dailyReport.scheduleModel.plannedOperationsDays- this.dailyReport.scheduleModel.daysFromSpud
      this.dailyReport.aFESummaryModel.cummulativeCost = this.lastDdr?this.dailyReport.aFESummaryModel.dailyCost + this.lastDdr.afeSummary.dailyCost:this.dailyReport.aFESummaryModel.dailyCost
      this.dailyReport.aFESummaryModel.dailyCost = this.lineSum
      this.dailyReport.rkbDepth = this.lastDdr.rkbDepth?this.lastDdr.rkbDepth:null
      this.dailyReport.dfe = this.lastDdr.dfe?this.lastDdr.dfe:null
    }
  }
}





















































import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class UsersImport extends Vue {
 @Prop({default:false,required:true}) showModal!:boolean;

    firstName = ""
    lastName=""
    email=""
    userName=""
    team=""
    jobTitle=""
    sheetName = null
    sheetNames:Array<string> =[]
    fileName:string=""
    columnNames:Array<string> =[]
    usersFile = null as any
    fileUploaded=false
    sheetSelected=false
    loading=false
    okBtnTitle="Upload"
    errors:Array<string> =[]

    get validated(){
        if(this.okBtnTitle == "Import Users" && (this.firstName =="" ||  this.lastName=="" || this.jobTitle=="" ||this.team=="" || this.email=="" || this.team =="")){
           return true;
        }
        else if(this.okBtnTitle == "OK" && this.sheetName =="" ){
           return true;
        }
       return false;
    }
    importUsers() {
        this.loading = true
        this.okBtnTitle="Importing Users..."
        let model = {
        filePath: this.fileName,
        sheetName: this.sheetName,
        columnNames: {
        firstName: this.firstName,
        lastName: this.lastName,
        email:this.email,
        teamName:this.team,
        jobTitle:this.jobTitle,
        userName:this.userName,
        }
     }
      this.$store.dispatch('usersModule/createImportedUsers', model).then((res)=>{
          this.$bvModal.hide("users-import-modal")      
          if(res.data.data.errors.length>0){
            this.errors = res.data.data.errors
            this.$bvModal.show("error-list-modal")
          }

      }).catch(()=>{
          this.loading = false     
        this.okBtnTitle="Import Users"})
    }
    

    getColumnNames(){
        this.okBtnTitle = "Loading..."
        this.loading = true
        this.$store.dispatch('usersModule/getColumnNames', {fileName:this.fileName,sheetName:this.sheetName}).then((res)=>{
          this.columnNames = res.data.data
          this.sheetSelected = true
          this.okBtnTitle = "Import Users"
          this.loading=false
          }).catch(()=>{
             this.loading = false
             this.okBtnTitle = "OK"
         })

    }

    uploadFile(){
        this.loading = true
        this.okBtnTitle = "Uploading..."
         let form = new FormData()
         form.append("file",this.usersFile)
        this.$store.dispatch('usersModule/uploadFile', form).then((response=>{
            this.sheetNames = response.data.data.sheetNames
            this.fileName = response.data.data.fileName
             this.fileUploaded = true
            this.loading =false
             this.okBtnTitle = "OK"
         })).catch(()=>{
             this.loading = false
             this.okBtnTitle = "Upload"
         })
    }

    btnClicked(bvModalEvt:any){
        bvModalEvt.preventDefault()
        if(this.okBtnTitle == "Upload"){
           this.uploadFile()
        }
        else if(this.okBtnTitle == "OK"){
            this.getColumnNames()
        }
        else if(this.okBtnTitle == "Import Users"){
            this.importUsers()
        }
    }
    closeModal(){
      this.$emit("users-import-modal-closed")
    }

    @Watch('showModal', {immediate: true}) onShowModalChanged(value: boolean, oldValue: boolean) {
       if(this.showModal){
            this.fileUploaded = false
            this.sheetSelected =false
            this.loading =false
            this.okBtnTitle ="Upload"
            this.$bvModal.show("users-import-modal")
       }
    }
    
}

import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import PptxGenJS from "pptxgenjs";
@Component
export default class UserProvisioning extends Vue {

    slideMaster: Array<any> = [] 
    pptx: any = null
    
    //chart constants
    standardSlideWidth = 13.3
    standardSlideHeight = 7.5
    slideTopOffset = 0
    slideLeftOffset = 0
    slideBottomOffset = 0
    slideRightOffset = 0
    slideWidth = 13.3
    slideHeight = 7.5
    timeSectionBoxHeight = 0.1

    printConfig = {
        showTopLeftAnnotation:true,
        showTopRightAnnotation:false,
        showBottomLeftAnnotation:false,
        showBottomRightAnnotation:true,
        showWBS:true,
        numberOfYearsPerSlide:1,
        chartUnit:"WEEK",
        dimension:"WIDESCREEN",
        xScale:1
    }

    rigOrWellNameAreaWidth = 1.8
    headerHeight = 0.8
    rigLineHeight = this.yScale * 0.7
    widthPerDay = (this.standardSlideWidth - (this.slideLeftOffset + this.slideRightOffset + this.rigOrWellNameAreaWidth))/365
    slidesToPrint:Array<{startDate:Date,endDate:Date,projects:Array<{startDate:Date,endDate:Date,color:string,name:string,sections:Array<{color:string,width:number}>}>}> = []
    mainTimeUnitFontSize = 8
    monthByDays= [
        {month:"",days:0,monthShortName:""},
        {month:"JAN",days:31,monthShortName:'J'},
        {month:"FEB",days:28,monthShortName:'F'},
        {month:"MAR",days:31,monthShortName:'M'},
        {month:"APR",days:30,monthShortName:'A'},
        {month:"MAY",days:31,monthShortName:'M'},
        {month:"JUN",days:30,monthShortName:'J'},
        {month:"JUL",days:31,monthShortName:'J'},
        {month:"AUG",days:31,monthShortName:'A'},
        {month:"SEP",days:30,monthShortName:'S'},
        {month:"OCT",days:31,monthShortName:'O'},
        {month:"NOV",days:30,monthShortName:'N'},
        {month:"DEC",days:31,monthShortName:'D'}
    ]

    get xScale(){
        return ((this.slideWidth-this.rigOrWellNameAreaWidth)/(this.standardSlideWidth - this.rigOrWellNameAreaWidth)) * (365/(this.printConfig.numberOfYearsPerSlide * 365))
    }

    get yScale(){
        return this.slideHeight/this.standardSlideHeight
    }

    drawSequenceName(){
        const currentDate = new Date()
        this.slideMaster.push({
            'text': {
                text: (this as any).sequenceObject.name+",Printed: " + currentDate.getDate() + "/" + (currentDate.getMonth() + 1) + "/" + currentDate.getFullYear(),
                options: {
                    x: this.slideLeftOffset,
                    y: this.slideTopOffset,
                    w: this.rigOrWellNameAreaWidth,
                    h: this.headerHeight,
                    fontSize: 8,
                    bold: true,
                    valign: 'bottom',
                    //fill:'#DCDCDC'
                }
            }
        })
    }

    drawFluidsLegend(){
        const context = this as any
        const fluids = [{name:"Gas",color:context.settings[6].value,type:'fluid'},{name:"OilGas",color:context.settings[5].value,type:'fluid'},{name:"Oil",color:context.settings[4].value,type:'fluid'},{name:"Condensate",color:context.settings[7].value,type:'fluid'}];
        
        (this.slidesToPrint as any).forEach((slideToPrint:any) => {
            slideToPrint.projects.forEach((p:any) => {
                if(p.sections){
                    p.sections.forEach((s:any) => {
                        if(!fluids.some((f:any) => f.name==s.activityName)) fluids.push({name:s.activityName,color:s.color,type:'fluid'})
                    });
                }
            });
        });

        if(this.printConfig.showBottomLeftAnnotation){
            fluids.push({name:"Rig Ready",color:'#006400',type:'Rig'})
            fluids.push({name:"Rig Not Ready",color:'#FF0000',type:'Rig'})  
        }
        
        fluids.forEach((f:any,fIndex:number) => {
            let previousWbsSum = 0
            if (fIndex > 0) {
                previousWbsSum = fluids.slice(0, fIndex).map(x => x.name.length*0.13).reduce((a, b) => a + b)
            }
            if(f.type == "fluid"){
                this.slideMaster.push({
                    'text': {
                        text: f.name,
                        options: {
                            x: this.slideLeftOffset + this.rigOrWellNameAreaWidth + previousWbsSum,
                            y: this.slideTopOffset,
                            w: 0.13*f.name.length,
                            h:  this.headerHeight/4,
                            fontSize: 8,
                            valign: 'middle',
                            align: 'center',
                            fill:f.color,
                            color:'ffffff'
                        }
                    }
                })
            }else{
                this.slideMaster.push({
                    'text': {
                        text:' ',
                        options:{
                            x: this.slideLeftOffset + this.rigOrWellNameAreaWidth + previousWbsSum,
                            y: this.slideTopOffset,
                            w: 0.2,
                            h: this.headerHeight/4,
                            color:f.color,
                            bullet:true
                        }
                    }
                })
                this.slideMaster.push({
                    'text': {
                        text: f.name,
                        options: {
                            x: this.slideLeftOffset + this.rigOrWellNameAreaWidth + 0.2 + previousWbsSum,
                            y: this.slideTopOffset,
                            w: 0.1*f.name.length,
                            h: this.headerHeight/4,
                            fontSize: 8,
                            valign: 'middle',
                            align: 'left',
                            bold: true,
                        }
                    }
                })
            }
        })
    }

    drawActivityLegend(){
       
    }

    drawAnnotationLegend(){
       
    }

    drawSingleTimeHeaderSlot(text:string,index:number,numberOfDays:number,y:number,backgroundColor:string='	#FF0000',slideObject:any = null){
        const objectToDraw = {
            'text': {
                text: text,
                options: {
                    x: this.slideLeftOffset + this.rigOrWellNameAreaWidth + (this.xScale * this.widthPerDay * index),
                    y: y,
                    w: this.xScale * this.widthPerDay * numberOfDays,
                    h: 0.25 * this.headerHeight,
                    align: 'center',
                    fill: backgroundColor,
                    fontSize: this.mainTimeUnitFontSize,
                    color: '#FFFFFF',
                    line: {
                        pt: 2,
                        color: '#FFFFFF'
                    }
                }
            }
        }
        if(slideObject != null){
            slideObject.addText(objectToDraw.text.text,objectToDraw.text.options)
        }else{
            this.slideMaster.push(objectToDraw)
        }
    }

    drawSingleVerticalCalibration(index:number){
        this.slideMaster.push({
            'line': {
                x: this.slideLeftOffset + this.rigOrWellNameAreaWidth + (this.xScale * this.widthPerDay * index),
                y: this.slideTopOffset + this.headerHeight,
                w: 0,
                h: (this as any).filteredRigs.length * this.rigLineHeight,//this.slideHeight - (this.slideTopOffset + this.headerHeight + this.slideBottomOffset) ,
                line: '808080',
                lineSize: 1
            }
        })
    }

    drawChartCalibration(){

        let mainY = this.slideTopOffset + this.headerHeight - (0.25 * this.headerHeight)
        let subY = this.slideTopOffset + this.headerHeight - (0.5 * this.headerHeight)
        
        if(this.printConfig.chartUnit=='WEEK'){
            let weekNumber = 0
            const numberOfWeekTicks = this.printConfig.numberOfYearsPerSlide * 52
            const numberOfMonthTicks = this.printConfig.numberOfYearsPerSlide * 12

            for(var i=0;i<numberOfWeekTicks;i++){
                if(weekNumber==4)weekNumber=1
                else weekNumber ++
                this.drawSingleTimeHeaderSlot(weekNumber.toString(),(i*7),7,mainY,(this as any).settings[10].value)
                this.drawSingleVerticalCalibration(i*7)
            }
            
            for(var j=1;j<=numberOfMonthTicks;j++){
                const actualIndex = j%12==0?12:(j%12)
                const monthDay = this.monthByDays[actualIndex]
                let daysGone = 0
                daysGone = this.monthByDays.slice(0,actualIndex).map((m:any) => m.days).reduce((a,b)=>a+b)
                if(j%12==0 )daysGone = daysGone + (365 * (Math.floor(j/12) - 1))
                else daysGone = daysGone + (365 * (Math.floor(j/12)))
                this.drawSingleTimeHeaderSlot(monthDay.month,daysGone,monthDay.days,subY,(this as any).settings[9].value)
            }
        }

        if(this.printConfig.chartUnit=='MONTH'){
            const numberOfMonthTicks = this.printConfig.numberOfYearsPerSlide * 12
            const numberOfQautersTicks = this.printConfig.numberOfYearsPerSlide * 4
            
            for(var m=1;m<=numberOfMonthTicks;m++){
                const displayedMonthNumber = m%12==0 ? 12 : m%12
                let displayedMonthName = this.monthByDays[displayedMonthNumber].monthShortName
                const actualIndex = m%12==0?12:(m%12)
                const monthDay = this.monthByDays[actualIndex]
                let daysGone = 0
                daysGone = this.monthByDays.slice(0,actualIndex).map((m:any) => m.days).reduce((a,b)=>a+b)
                if(m%12==0 )daysGone = daysGone + (365 * (Math.floor(m/12) - 1))
                else daysGone = daysGone + (365 * (Math.floor(m/12)))
                this.drawSingleTimeHeaderSlot(displayedMonthName,daysGone,monthDay.days,mainY,(this as any).settings[10].value)
                this.drawSingleVerticalCalibration(daysGone)
            }

            this.mainTimeUnitFontSize = 5
            for(var q=1;q<=numberOfQautersTicks;q++){
                const actualQuarterNumber = q%4==0 ? 4 : q%4
                const quarterEndMonth = actualQuarterNumber*3
                const quarterStartMonth = (quarterEndMonth - 3) + 1
                const daysInThisQuarter =  this.monthByDays.slice(quarterStartMonth,(quarterEndMonth+1)).map((m:any) => m.days).reduce((a,b)=>a+b) 
                let daysBeforeThisQuarter = quarterStartMonth==0 ? 0 : this.monthByDays.slice(0,quarterStartMonth).map((m:any) => m.days).reduce((a,b)=>a+b) 
                if( (q*3)%12==0 )daysBeforeThisQuarter = daysBeforeThisQuarter + (365 * (Math.floor((q*3)/12) - 1))
                else daysBeforeThisQuarter = daysBeforeThisQuarter + (365 * (Math.floor((q*3)/12)))
                this.drawSingleTimeHeaderSlot('Q'+actualQuarterNumber.toString(),daysBeforeThisQuarter,daysInThisQuarter,subY,(this as any).settings[9].value)
            }
            this.mainTimeUnitFontSize = 8
        }
    }

    drawSingleRigLine(rigName:string, rigTerrain:string,rigLineIndex:number,fill:any,textColor:string='#000000'){
        this.slideMaster.push({
            'line': {
                x: this.slideLeftOffset,
                y: this.slideTopOffset + this.headerHeight + (rigLineIndex * this.rigLineHeight) ,
                w: this.slideWidth,
                h: 0,
                line: '808080',
                lineSize: .5
            }
        })

        this.slideMaster.push({
            'rect': {
                x: this.slideLeftOffset,
                y: this.slideTopOffset + this.headerHeight + (rigLineIndex * this.rigLineHeight) ,
                w: this.rigOrWellNameAreaWidth,
                h: this.rigLineHeight,
                fill,
            }
        })

        this.slideMaster.push({
            'text':{
                text:rigName,
                options:{
                    x: this.slideLeftOffset,
                    y: this.slideTopOffset + this.headerHeight + (rigLineIndex * this.rigLineHeight) ,
                    w: this.rigOrWellNameAreaWidth,
                    h: this.rigLineHeight/2,
                    fontSize:this.mainTimeUnitFontSize,
                    color:textColor,
                    valign: 'bottom',
                    bold: true
                }
            }
        })

        this.slideMaster.push({
            'text':{
                text:rigTerrain,
                options:{
                    x: this.slideLeftOffset,
                    y: this.slideTopOffset + this.headerHeight + (rigLineIndex * this.rigLineHeight) + (this.rigLineHeight/2) ,
                    w: this.rigOrWellNameAreaWidth,
                    h: this.rigLineHeight/2,
                    fontSize:this.mainTimeUnitFontSize,
                    color:textColor,
                    valign: 'top'
                }
            }
        })
    }

    drawRows(){
        const context:any = this
        const viewMode = (this as any).viewMode
        if(viewMode == 'rig') {
            (this as any).filteredRigs.forEach((rig:any,index:number) => {
                const adjustedIndex = index //+ 1
                if(rig.resourceType=='RIG'){
                    this.drawSingleRigLine(rig.name, rig.terrain, adjustedIndex,context.getColorForRig(rig))
                }else{
                    this.drawSingleRigLine(rig.alias, '', adjustedIndex,'#'+rig.color)
                }
            });
        } else {
            (this as any).filteredRigs.forEach((well:any, index:number) => {
                this.drawSingleRigLine(well.name, well.terrain, index+1,well)
            });
        }
    }

    convertItemRowIdToY(idToConvert:string){
        const index = (this as any).filteredRigs.findIndex((r:any) => r.id == idToConvert)
        return index * this.rigLineHeight
    }

    setupPresentation(){
        this.pptx.defineLayout({
            name: 'DSP',
            width: this.slideWidth,
            height: this.slideHeight
        });

        this.pptx.layout = 'DSP'

        this.pptx.defineSlideMaster({
            title: "DSPMASTER",
            margin: 1,
            objects: this.slideMaster
        })
    }
    
    drawSingleProject(pptSlideObject:any,slide:any,project:any){
        //draw project bar
        //draw project sections
        //draw project annotations
        //place project name
        const context:any = this
        const projectWidth = (this.widthPerDay * context.getDateDifferenceInDays(project.start,project.end) * this.xScale)
        const projectX = (this.widthPerDay * context.getDateDifferenceInDays(slide.startDate,project.start) * this.xScale) + this.slideLeftOffset+this.rigOrWellNameAreaWidth
        let projectRectBakground = undefined
        if(this.printConfig.showWBS && project.sections.length>0){
            const totalNumberOfProjectDays = context.getDateDifferenceInDays(project.start,project.end)
            let projectStart = (this.widthPerDay * context.getDateDifferenceInDays(slide.startDate,project.start) * this.xScale)+this.slideLeftOffset+this.rigOrWellNameAreaWidth
            pptSlideObject.addShape('rect',{
                x:projectStart,
                w:(this.widthPerDay * totalNumberOfProjectDays * this.xScale),
                h:this.rigLineHeight/2,
                y:this.slideTopOffset + (this.rigLineHeight/4) + this.headerHeight+this.convertItemRowIdToY(project.rowId),
                fill:{color:'#ffffff'},
            })
            project.sections.forEach((section:any,index:number) => {
                let sectionStart = 0
                if(index > 0){
                    sectionStart = (project.sections.filter((s: any,i: number) => i < index).map((s: any) => s.width).reduce((w:number,a:number) => w+a)) * this.widthPerDay * this.xScale
                }
                pptSlideObject.addShape('rect',{
                    x:projectStart + sectionStart,
                    w:(this.widthPerDay * section.width * this.xScale),
                    h:this.rigLineHeight/2,
                    y:this.slideTopOffset + (this.rigLineHeight/4) + this.headerHeight+this.convertItemRowIdToY(project.rowId),
                    fill:{color:section.color},
                })
            });

        }else{
            projectRectBakground = project.color
        }

        pptSlideObject.addText(project.name,{
            x:projectX,
            w:projectWidth,
            h:this.rigLineHeight/2,
            y:this.slideTopOffset + (this.rigLineHeight/4) + this.headerHeight+this.convertItemRowIdToY(project.rowId),
            fill:projectRectBakground?{color:projectRectBakground}:undefined,
            fontSize: this.mainTimeUnitFontSize,
            align: 'center',
            color: 'FFFFFF'
        })

        if(this.printConfig.showBottomRightAnnotation && project.budget){
            pptSlideObject.addText('K$'+project.budget,{
                x:projectX + projectWidth - 0.6,
                w:0.6,
                h:this.rigLineHeight/4,
                y:this.slideTopOffset + (this.rigLineHeight * 0.76) + this.headerHeight + this.convertItemRowIdToY(project.rowId),
                fill:{color:'#ff0000'},
                fontSize: this.mainTimeUnitFontSize,
                align: 'center',
                color: 'FFFFFF'
            })
        }

        if(this.printConfig.showTopLeftAnnotation && project.estimatedProduction){
            pptSlideObject.addText(project.estimatedProduction,{
                x:projectX,
                w:0.5,
                h:this.rigLineHeight/4.1,
                y:this.slideTopOffset + this.headerHeight + this.convertItemRowIdToY(project.rowId),
                fill:{color:'#0288d1'},
                fontSize: this.mainTimeUnitFontSize,
                align: 'center',
                color: 'FFFFFF'
            })
        }

        if(this.printConfig.showBottomLeftAnnotation && project.rigReadiness){
            pptSlideObject.addShape('ellipse',{
                x:projectX,
                w:this.rigLineHeight/4.1,
                h:this.rigLineHeight/4.1,
                y:this.slideTopOffset + (this.rigLineHeight * 0.76) + this.headerHeight + this.convertItemRowIdToY(project.rowId),
                fill:{color:project.rigReadiness?'#006400':'#FF0000'},
            })
        }
    }

    computeSlides(){
        const context = (this as any)
        const sequenceStart = new Date(context.sequenceObject.startYear,0,1)
        const sequenceEnd = new Date(context.sequenceObject.endYear,0,1)
        let numberOfYears = (sequenceEnd.getFullYear() - sequenceStart.getFullYear())
        if(numberOfYears==0)numberOfYears = 1
        let numberOfSlidesToPrint = numberOfYears%this.printConfig.numberOfYearsPerSlide == 0 ? numberOfYears/this.printConfig.numberOfYearsPerSlide : Math.floor(numberOfYears/this.printConfig.numberOfYearsPerSlide) + 1 
        
        for(let i=0; i <= numberOfSlidesToPrint; i++){
            const slideStartYear = sequenceStart.getFullYear() + (i * this.printConfig.numberOfYearsPerSlide)
            const slideEndYear = slideStartYear + this.printConfig.numberOfYearsPerSlide
            const slideStartDate = new Date(slideStartYear,0,1,0,0,0,0)
            const slideEndDate = new Date(slideEndYear,0,1,0,0,0,0)
            const projectsForSlide = context.projects.filter((p:any) => {
                const projectStartDate = new Date(p.start)
                const projectEndDate  = new Date(p.end)
                return (projectStartDate.getTime()>=slideStartDate.getTime() && projectStartDate.getTime()<slideEndDate.getTime())||(projectEndDate.getTime()>=slideStartDate.getTime() && projectEndDate.getTime() < slideEndDate.getTime())
            }).map((p:any) => ({
                start:new Date(p.start).getTime()<slideStartDate.getTime()?slideStartDate:new Date(p.start),
                end:new Date(p.end).getTime()>slideEndDate.getTime()?slideEndDate:new Date(p.end),
                sections:context.computePrintSections({...p,start:new Date(p.start).getTime()<slideStartDate.getTime()?slideStartDate:new Date(p.start),end:new Date(p.end).getTime()>slideEndDate.getTime()?slideEndDate:new Date(p.end)}),
                color:context.getColorForProject(p),
                rowId:context.viewMode=='rig'?(p.isRigless?p.riglessBucket.id:p.rig.id):p.well.id,
                budget:context.getPlanBudgetForProject(p.activities),
                estimatedProduction:p.estimatedProduction,
                rigReadiness:p.preProcesses?p.preProcesses.find((p:any) => p.name.toLowerCase() == 'rig readiness' ):undefined,
                name:p.name
            }))
            this.slidesToPrint.push({startDate:slideStartDate,endDate:slideEndDate,projects:projectsForSlide})
        }
    }

    drawSlides(){
        const sequenceStart = new Date((this as any).sequenceObject.startYear,0,1).getFullYear()
        //this.computeSlides()
        const today = new Date()
        this.slidesToPrint.forEach((slide,i) => {
            const valToAdd = i * this.printConfig.numberOfYearsPerSlide
            var pptSlide = this.pptx.addSlide("DSPMASTER");

            //handle year
            for(let yi=0;yi<this.printConfig.numberOfYearsPerSlide;yi++){
                this.drawSingleTimeHeaderSlot((sequenceStart+valToAdd+yi).toString(),yi*365,365,this.slideTopOffset+this.headerHeight/4,(this as any).settings[8].value,pptSlide)
            }

            //handle today line
            if(slide.startDate.getTime()<today.getTime()){
                pptSlide.addShape('line',{
                    x: this.slideLeftOffset + this.rigOrWellNameAreaWidth + (this.xScale * this.widthPerDay * (this as any).getDateDifferenceInDays(slide.startDate,today)),
                    y: this.slideTopOffset + this.headerHeight,
                    w: 0,
                    h: (this as any).filteredRigs.length * this.rigLineHeight,//this.slideHeight - (this.slideTopOffset + this.headerHeight + this.slideBottomOffset) ,
                    line: '#FF0000',
                    lineSize: 2
                })
            }

            slide.projects.forEach(project => {
                this.drawSingleProject(pptSlide, slide, project)
            })
        })
    }

    doChartPrint(){
        this.pptx = new PptxGenJS();
        this.slideMaster.splice(0)
        this.slidesToPrint.splice(0)
        this.computeSlides()
        const context = (this as any)
        const currentDate = new Date();
        this.drawSequenceName()   
        this.drawFluidsLegend()
        this.drawActivityLegend()
        this.drawAnnotationLegend()
        this.drawChartCalibration()
        this.drawRows()
        this.setupPresentation()
        this.drawSlides()
        var pptName = context.sequenceObject.name + "(" + currentDate.getDay() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + ")"
        this.pptx.writeFile(pptName);
        this.$bvModal.hide('print-modal')
    }

    @Watch('printConfig.numberOfYearsPerSlide')
    onChartNumberOfYearsPerSlideChange(newVal:number){
        if(this.printConfig.dimension == 'WIDESCREEN'){
            if(newVal <= 2) this.printConfig.chartUnit = 'WEEK'
            if(newVal>2 && newVal <=10){
                if(this.printConfig.chartUnit != 'MONTH') this.printConfig.chartUnit = 'MONTH'
            }
            if(newVal>10){
                if(this.printConfig.chartUnit != 'YEAR') this.printConfig.chartUnit = 'YEAR'
            }
        }
    }
}
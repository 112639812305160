import { HTTP } from '@/axios_api/http-common' 
import { PropSync } from 'vue-property-decorator'

interface IState {
    kpis: Array<any>,
    presets:Array<any>,
    presetChanges:Array<any>,
    selectedProjects:Array<any>,
    selectedSequence:any,
    activeAnalyticsElements:any,
    backgroundColor:string
}

function converPresetToPresetModel(presetToConvertId:any):any{
    let preset:any = state.presets.find((p:any)=>p.id==presetToConvertId)
    if(preset != undefined){
        preset.screenWidth = window.innerWidth;
        preset.screenHeight = window.innerHeight;
    }
    return preset
}

const state = {
  kpis: [],
  presets:[],
  presetChanges:[],
  selectedProjects:[],
  selectedSequence:"",
  activeAnalyticsElements:{id:"",type:"",name:""},
  backgroundColor:""
}

const mutations = {
    setKPIs (state: IState, kpis: Array<any>) {
        state.kpis = kpis
    },
    setPresets (state: IState, presets: Array<any>) {
        state.presets = presets
    },
    setPreset(state: IState, data:any) {
       let presetIndex = state.presets.findIndex(p=>p.id==data.presetId)
       state.presets[presetIndex] = data.data
    },
    updatePersistedPreset(state:IState,data:any){
        let presetIndex = state.presets.findIndex(p=>p.id==data.presetId)
        state.presets.splice(presetIndex,1,data.data);
    },
    add(state: IState, preset: any){
        state.presets.push(preset)
        state.presetChanges.push({
            id:preset.id,
            action:"CREATE",
        })
    },
    addKPIToPreset(state: IState, data: any){
        let tempId = Math.abs((Math.floor(Math.random() * (10 - +4000))  +10)+(Math.floor(Math.random() * (10 - +4000))  +10)); 
        let presetIndex = state.presets.findIndex(p=>p.id==data.presetId)

        state.presets[presetIndex].kpIs.push({id:tempId,...data.kpi})
        let existingChangeIndex = state.presetChanges.findIndex(thisPresentChange => thisPresentChange.id == data.presetId)
        if(existingChangeIndex<0){
            state.presetChanges.push({
                id:data.presetId,
                action:"UPDATE",
            })
        }
    },
    removeKPIFromPreset(state:IState,data:any){
        let presetIndex = state.presets.findIndex(p=>p.id==data.presetId)
        let kpiInPresetIndex = state.presets[presetIndex].kpIs.findIndex((k:any)=>k.id==data.kpiId)
        state.presets[presetIndex].kpIs.splice(kpiInPresetIndex,1)
        let existingChangeIndex = state.presetChanges.findIndex(thisPresentChange => thisPresentChange.id == data.presetId)
        if(existingChangeIndex<0){
            state.presetChanges.push({
                id:data.presetId,
                action:"UPDATE",
            })
        }
    },
    updateKPIInPreset(state:IState,data:any){
        let presetIndex = state.presets.findIndex(p=>p.id==data.presetId)
        let kpiInPresetIndex = state.presets[presetIndex].kpIs.findIndex((k:any)=>k.id==data.kpiId)
        for(let key in data){
            if(key=="presetId"||key=="kpiId")continue
            state.presets[presetIndex].kpIs[kpiInPresetIndex][key] = data[key]
        }
        let existingChangeIndex = state.presetChanges.findIndex(thisPresentChange => thisPresentChange.id == data.presetId)
        if(existingChangeIndex<0){
            state.presetChanges.push({
                id:data.presetId,
                action:"UPDATE",
            })
        }
    },
    updatePreset(state:IState,data:any){
        let presetIndex = state.presets.findIndex(p=>p.id==data.presetId)
        for(let key in data){
            if(key=="presetId")continue
            state.presets[presetIndex][key] = data[key]
        }
        let existingChangeIndex = state.presetChanges.findIndex(thisPresentChange => thisPresentChange.id == data.presetId)
        if(existingChangeIndex<0){
            state.presetChanges.push({
                id:data.presetId,
                action:"UPDATE",
            })
        }
    },
    updateSelectedSequence(state:IState,selectedSequence:any){
        state.selectedSequence = selectedSequence
    },
    updatedSelectedProjects(state:IState,selectedProject:any){
        state.selectedProjects = selectedProject
    },
    updateActiveAnalyticsElement(state:IState,data:any){
        state.activeAnalyticsElements.id = data.id,
        state.activeAnalyticsElements.type = data.type
        state.activeAnalyticsElements.name = data.name
    },
    updatedBackgroundColor(state:IState,backgroundColor:string){
        state.backgroundColor = backgroundColor
    },
    clearPresetChanges(state:IState){
        state.presetChanges = []
    }
}

const getters = {
    getPresetById:(state:IState) => (id:any) =>{
        return state.presets.find(p=>p.id==id)
    },
    getKPIById:(state:IState) => (id:number) => {
        return state.kpis.find(k => k.id == id)
    },
    getPresetChangesCount: (state:IState) => {
        return state.presetChanges.length
    }
}

const actions = {
    getKPIs ({ commit } : { commit: any}) {       
        return new Promise((resolve, reject) => {
            HTTP.get('analytics/kpis')
            .then((response: any) => {
                commit('setKPIs', response.data.data)         
                resolve(response)                    
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    },
    getPresets ({ commit } : { commit: any}) {       
        return new Promise((resolve, reject) => {
            HTTP.get('analytics/presets')
            .then((response: any) => {
                commit('setPresets', response.data.data)         
                resolve(response)                    
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    },
    saveChanges({dispatch,state,commit}:{dispatch:any,state:IState,commit:any}){
        return new Promise((resolve,reject) => {
            let responseTrackers = 0;
            state.presetChanges.forEach(p=>{
                let presetModel = converPresetToPresetModel(p.id)
                if(p.action=="CREATE"){
                    HTTP.post('analytics/presets/create',presetModel)
                    .then((response:any) => {
                        commit('setPreset', {presetId:p.id,data:response.data.data})    
                        responseTrackers++
                        if(responseTrackers==state.presetChanges.length){
                            resolve(response)    
                        }     
                    })
                }
                if(p.action=="UPDATE"){
                    HTTP.put('analytics/presets/update/'+p.id,presetModel)
                    .then((response:any) => {
                        commit('updatePersistedPreset', {presetId:p.id,data:response.data.data})    
                        responseTrackers++
                        if(responseTrackers==state.presetChanges.length){
                            resolve(response)    
                        }     
                    })
                }
            })
        })
    }
}

const analyticsModule = {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
  }
  
  export default analyticsModule;

























































































































import Vue from "vue"
export default Vue.extend({
  props:{
    projectId:{
        default:-1
    },
    totalPlanCost:{
        default:0
    },
    totalActualCost:{
        default:0
    },
    actualNumberOfDays:{
        default:0
    },
    planMilestones:{
        default:()=>[]
    },
    reviewId:{
        default:-1
    }
  },
  data(){
      return {
          busy:"NONE",
          activeAction:"EDIT",
          actionReview:{
              jobStatus:"NOT_STARTED",
              reasonForDeviationFromPlannedDays:"",
              reasonForDeviationFromPlannedActivities:"",
              correctiveActions:"",
              production:0,
              activityId:"",
              milestoneId:""
          }
      }
  },
  methods:{
      submitAAR(){
          this.busy = 'LOADING'
          this.$store.dispatch("AAR/createAAR",{projectId:this.projectId,data:{
              projectId:this.projectId,
              activityId : this.actionReview.activityId,
              milestoneId : this.actionReview.milestoneId,
              production:this.actionReview.production,
              correctiveActions:this.actionReview.correctiveActions,
              reasonForDeviationFromPlannedActivities:this.actionReview.reasonForDeviationFromPlannedActivities,
              reasonForDeviationFromPlannedDays:this.actionReview.reasonForDeviationFromPlannedDays,
              projectStatus:this.actionReview.jobStatus
          }}).then(() => {
              this.busy = 'SUCCESS'
              this.activeAction = "EDIT"
              this.reInit()
              setTimeout(()=>{
                this.busy = 'NONE'
              },3000)
          })
      },
      createNewReview(){
          this.activeAction = "EDIT"
          this.reInit()
          this.$emit("new-review")
      },

      reInit(){
          this.actionReview.jobStatus = "NOT_STARTED";
          this.actionReview.reasonForDeviationFromPlannedDays = ""
          this.actionReview.reasonForDeviationFromPlannedActivities = ""
          this.actionReview.correctiveActions = ""
          this.actionReview.production = 0
          this.actionReview.activityId  = "",
          this.actionReview.milestoneId = ""
      }
  },
  computed:{
    project():any{
        return this.$store.getters['projectModule/getProjectById'](this.projectId)
    },
    computePlanDays():any{
        if(this.project.start==""||this.project.end=="")return 0
        let startDateMill = (new Date(this.project.start)).getTime()
        let endDateMill = (new Date(this.project.end)).getTime()
        let diff = endDateMill - startDateMill
        if(diff<1)return 1
        return Math.round(diff/(1000 * 3600 * 24))+1
    },
    showSubmitButton():any{
        return this.activeAction=="EDIT"&&
        (
            this.actionReview.production>0||
            this.actionReview.jobStatus!=null||
            this.actionReview.reasonForDeviationFromPlannedDays!=""||
            this.actionReview.reasonForDeviationFromPlannedActivities!=""
        )
    },
    activityOptions():any{
        return this.$store.getters["ActivityModule/getActivitiesForProject"](this.projectId).map((thisActivity:any) => ({text:thisActivity.wellActivity.name,value:thisActivity.id}))
    },
    milestoneOptions():any{
        let selectedActivity = this.$store.getters["ActivityModule/getActivitiesForProject"](this.projectId).find((a:any)=>a.id==this.actionReview.activityId)
        if(selectedActivity){
            return selectedActivity.milestones.map((m:any)=>({text:m.name,value:m.id}))
        }else{
            return []
        }
    }
  },  
  watch:{
      reviewId(){
          if(this.reviewId>-1){
              let aar = this.$store.getters["AAR/getAARById"](this.reviewId)
              this.actionReview.jobStatus = aar.projectStatus==null?"NOT":aar.projectStatus
              this.actionReview.reasonForDeviationFromPlannedDays = aar.reasonForDeviationFromPlannedDays
              this.actionReview.reasonForDeviationFromPlannedActivities = aar.reasonForDeviationFromPlannedActivities
              this.actionReview.correctiveActions = aar.correctiveActions
              this.actionReview.production = aar.production
              this.activeAction = "VIEW"
          }
      }
  }
})

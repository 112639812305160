import { HTTP } from '@/axios_api/http-common'

const state = {
    logs: [
        // { fullName: "Harry Power", modelChanged: 'Project', description: 'Harry my manchy', date: '12-02-2021' },
        // { fullName: "Harry Power", modelChanged: 'Model', description: 'Kolo kolo kolo', date: '22-02-2021' },
        // { fullName: "Sambisa Boy", modelChanged: 'Model', description: 'Inside the Sabisa Forest', date: '02-02-2021' },
        // { fullName: "Lori Iro", modelChanged: 'Sub Activity', description: 'dubule lati iho ọrun apadi', date: '02-02-2021' },
        // { fullName: "Cash App", modelChanged: 'Model', description: 'Usain Bolt, run am', date: '02-02-2021' },
        // { fullName: "KPK", modelChanged: 'Activity', description: 'Talo so pe Ko po ke', date: '02-02-2021' },
    ]
}

interface IState {
    logs: Array<any>
}

const mutations = {
    setLogs(state: IState, logs: Array<any>) {
        state.logs = logs
    },
}

const getters = {
    getLogs: (state: IState) => {
        return state.logs.map((l:any) => {
            const incommingDateSplit = l.date.split("-")
            const incommingTimeSplit = l.time.split(":")
            const date = new Date(Date.UTC(incommingDateSplit[2],incommingDateSplit[1],incommingDateSplit[0],incommingTimeSplit[0],incommingTimeSplit[1].split(" ")[0],0))
            const outGoingMonth = date.getMonth()
            
            return {...l,...{date:date.getFullYear()+"-"+date.getMonth()+"-"+date.getDate(),time:date.getHours()+":"+date.getMinutes()+" "+incommingTimeSplit[1].split(" ")[1]}}
        })
    },
}

const actions = {
    getApplicationLogs({ commit }: { commit: any }) {
        return new Promise((resolve, reject) => {
            HTTP.get('applicationLog')
                .then((response: any) => {
                    commit('setLogs', response.data)
                    resolve(response)
                })
                .catch((error: any) => {
                    reject(error)
                })
        })
    },
}

const applicationLogModule = {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}

export default applicationLogModule;

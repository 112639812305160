














































































































































import Vue from 'vue'
export default Vue.extend({
    props:["title","subject","allowSubjectEdit","body","allowBodyEdit","hasAttachment","attachment"],
    data(){
        return {
            userSearch:"",
            eventSearch:"",
            selectedUsers:[] as Array<any>,
            selectedLogs:[] as Array<any>,
            actualSubject:"",
            additionalInfo:"",
            selectedDepartments:[] as Array<string>,
            selectedTeams:[] as Array<string>,
            selectedModels:[],
            activeScreen:"FORM",
            selectedDepartmentAll:true,
            selectUserAll:false,
            selectedDepartmentIndeterminate:false,
            selectedTeamAll:true,
            selectedTeamIndeterminate:false
        }
    },
    computed:{
        activeUser():any{
            return this.$store.getters["activeUser"]
        },
        users():any{
            return this.$store.getters["usersModule/fillteredUsers"](this.userSearch,-1)
        },
        filteredUsers():any{
            return this.users.filter((u:any) => !this.selectedUsers.some((su:any) => su.id==u.id) && this.selectedTeams.some(t=>t==u.teamId))
        },
        logs():any{
            return this.$store.getters['ApplicationLogModule/getLogs']
        },
        filterLog():any{
            return this.logs.filter((l:any) => l.fullName == this.activeUser.firstName+' '+this.activeUser.lastName).map((l:any) => ({...l,selected:this.selectedLogs.some((sl:any)=>sl.id==l.id)}))
        },
        reciepient():string{
            return this.selectedUsers.join(',')
        },
        actualBody():any{
            let concatenatedEvents = "\n"
            this.selectedLogs.forEach((l:any,index) => {
                concatenatedEvents = concatenatedEvents+"\n  "+(index+1)+'. '+l.description
            })
            return this.body+concatenatedEvents+"\n\n"+this.additionalInfo
        },
        departments():any{
            return this.$store.getters["departmentModule/getAllDepartments"]
        },
        teams():any{
            const teamsOptions = [] as Array<any>
            this.$store.getters["departmentModule/getAllDepartments"].filter((d:any) => this.selectedDepartments.some(sd=>sd==d.id)).forEach((d:any) => {
                teamsOptions.push(...d.teams.map((t:any) => ({id:t.id,name:t.teamName})))
            });
            return teamsOptions;
        }
    },
    methods:{
        removeUser(userId:any){
            const userToRemoveIdex = this.selectedUsers.findIndex((u:any) => u.id == userId)
            if(userToRemoveIdex>-1) this.selectedUsers.splice(userToRemoveIdex,1)
        },
        onSelectedUserChanged(id:any,name:any,email:any){
            this.selectedUsers.unshift({id,name,email})
        },
        onSelectedEventChanged(id:any,description:any){
            const eventIndexToManipulate = this.selectedLogs.findIndex((l:any) => l.id == id)
            if(eventIndexToManipulate>-1){
                this.selectedLogs.splice(eventIndexToManipulate,1)
            }else{
                this.selectedLogs.push({id:id,description:description})
            }
        },
        broadcast(){
            this.activeScreen = "LOADING"
            if(this.hasAttachment){
                let reciepients = this.selectedUsers.map((su:any) => su.email)
                let mailForm = new FormData()
                mailForm.append("FileToSend",this.attachment)
                mailForm.append("FileName","heatMap")
                reciepients.forEach(item => {
                mailForm.append("Reciepients", item);
                })
                mailForm.append("Subject",this.actualSubject)
                mailForm.append("Body",this.actualBody)
                this.$store.dispatch("sequencesModule/broadcastWithAttachment",mailForm).then((res: any) => {
                      this.activeScreen = "SUCCESS"
                 })}
                else{
                this.$store.dispatch("sequencesModule/broadcast",{subject:this.actualSubject,body:this.actualBody.replace("\n","<p>").replace(/\n/gi,"</p><p>"),reciepients:this.selectedUsers.map((su:any) => su.email)}).then(() => {
                    this.activeScreen = "SUCCESS"
                })
            }
        },
        selectAllUsersClicked(){
            if(this.selectUserAll){
                this.selectUserAll = false
                this.selectedUsers.splice(0,this.selectedUsers.length)
            }else{
                this.selectUserAll = true
                this.filteredUsers.forEach((user:any) => {
                    if(!this.selectedUsers.some((su => su.id==user.id))) this.selectedUsers.push({id:user.id,name:user.firstName+' '+user.lastName,email:user.email})
                });
            }
        },
        selectedDeptChanged(deptId:any){
            let indexOfSelectedDept = this.selectedDepartments.findIndex((sd:any) => sd == deptId)
            const selectedDept = this.departments.find((d:any) => d.id == deptId)
            if(selectedDept){
                if(indexOfSelectedDept<0){
                    this.selectedTeams.push(...selectedDept.teams.map((t:any)=>t.id))
                }else{
                    const teamsToRemove:Array<string> = selectedDept.teams.map((t:any) => t.id)
                    const selectedTeamCount = this.selectedTeams.length
                    this.selectedTeams.splice(0,selectedTeamCount,...this.selectedTeams.filter(t=>!teamsToRemove.some((tr)=>tr==t)))
                }
            }
            this.updateDeptCheckAllState()
            this.updateTeamsCheckAllState()
        },
        selectedTeamsChanged(){
            this.updateTeamsCheckAllState()
        },
        toggleAllDepts(state:Boolean){
            if(state){
                const elementsNotAdded = this.departments.filter((d:any) => !this.selectedDepartments.some((sd:string) => sd==d.id) )
                this.selectedDepartments.push(...elementsNotAdded.map((d:any) => d.id))
                elementsNotAdded.forEach((d:any) => {
                    this.selectedTeams.push(...d.teams.map((t:any) => t.id))
                });
            }else{
                this.selectedDepartments.splice(0,this.selectedDepartments.length)
                this.selectedTeams.splice(0,this.selectedTeams.length)
            }
            this.updateDeptCheckAllState()
            this.updateTeamsCheckAllState()
        },
        toggleAllTeams(state:Boolean){
            if(state){
                const elementsNotAdded = this.teams.map((t:any) => t.id).filter( (t:string) => !this.selectedTeams.some((st:string) => st==t) )
                this.selectedTeams.push(...elementsNotAdded)
            }else{
                this.selectedTeams.splice(0,this.selectedTeams.length)
            }
            this.updateTeamsCheckAllState()
        },
        updateDeptCheckAllState(){
            this.$nextTick(() => {
                if(this.selectedDepartments.length==0){
                    this.selectedDepartmentAll = false
                    this.selectedDepartmentIndeterminate = false
                }
                else if (this.selectedDepartments.length == this.departments.length) {
                    this.selectedDepartmentAll = true
                    this.selectedDepartmentIndeterminate = false
                }else{
                    this.selectedDepartmentIndeterminate = true;
                    this.selectedDepartmentAll = false
                }
            })
        },

        updateTeamsCheckAllState(){
            this.$nextTick(() => {
                if(this.selectedTeams.length==0){
                    this.selectedTeamAll = false
                    this.selectedTeamIndeterminate = false
                }
                else if (this.selectedTeams.length == this.teams.length) {
                    this.selectedTeamAll = true
                    this.selectedTeamIndeterminate = false
                }else{
                    this.selectedTeamIndeterminate = true;
                    this.selectedTeamAll = false
                }
            })
        }
    },
    mounted(){
        this.actualSubject = this.subject
        if(this.users.length==0)this.$store.dispatch("usersModule/getUsers")
        if(this.logs.length == 0)this.$store.dispatch("ApplicationLogModule/getApplicationLogs")
        if(this.departments.length == 0){
            this.$store.dispatch("departmentModule/getDepartments").then(() => {
                this.selectedDepartments = this.departments.map((d:any)=>d.id)
                this.departments.forEach((d:any) => {
                    this.selectedTeams.push(...d.teams.map((t:any)=>t.id))
                });
            })
        }else{
            this.selectedDepartments = this.departments.map((d:any)=>d.id)
            this.departments.forEach((d:any) => {
                this.selectedTeams.push(...d.teams.map((t:any)=>t.id))
            });
        }
    }
})

import { HTTP } from '@/axios_api/http-common' 
import Vue from 'vue'

const state = {
  budget: {},
  budgetChanges:{},
  activityId:"",
}

interface IState {
    budget: any
    activityId:string,
    budgetChanges: any
}

const getters = {
    getBudgetsForActivity (state: IState) {
      if(!state.activityId)return []
      const activityToReturn = state.budget[state.activityId]
      if(activityToReturn==undefined)return []
      return activityToReturn
    },
    getBudgeteChangesCount: (state:IState) => {
      let count = 0
      for(let k in state.budgetChanges){
        count+=state.budgetChanges[k].length
      }
      return count
    }
}

const mutations = {
  setActivityId(state:IState, activityId:any){
    state.activityId = activityId
    if(!state.budget[state.activityId])Vue.set(state.budget,state.activityId,[])
    if(!state.budgetChanges[state.activityId])Vue.set(state.budgetChanges,state.activityId,[])
  },
  setBudgets(state:IState,data:any){
    Vue.set(state.budget,state.activityId,data.data)
  },
  addBudgetToActivity(state: IState, budgetToAdd: any) {
    let tempId = Math.abs((Math.floor(Math.random() * (10 - +4000))  +10)+(Math.floor(Math.random() * (10 - +4000))  +10)); 
    budgetToAdd.id = tempId
    budgetToAdd.parentActivityId = state.activityId
    state.budget[state.activityId].push(budgetToAdd) 
    state.budgetChanges[state.activityId].push({
      id:tempId,
      action:"CREATE",
      budget:budgetToAdd
    })
  },
  updateNewBudget(state:IState, data:any){
    state.budget[state.activityId].forEach((thisBudget: any,index: any, milestones: any) => {
      let indexOfUpdate = data.data.findIndex((thisUpdate:any) => thisUpdate.data.tempKey == thisBudget.id)
      if(indexOfUpdate>-1)milestones[index] = data.data[indexOfUpdate].data
    })
  },

  updateBudget (state:IState, budgetToUpdate:any){
    //make sure changes to start and end date are saved to the store
    let existingChangeIndex = state.budgetChanges[state.activityId].findIndex((thisBudgetUpdate: any) => thisBudgetUpdate.id == budgetToUpdate.id)
    let existingMilestoneIndex = state.budget[state.activityId].findIndex((thisBudget: any) => thisBudget.id == budgetToUpdate.id)
    if(existingChangeIndex>-1){
      for(let key in budgetToUpdate){
        state.budgetChanges[state.activityId][existingChangeIndex].milestone[key] = budgetToUpdate[key]
        state.budget[state.activityId][existingMilestoneIndex][key] = budgetToUpdate[key]
      }
    }else{
      const budget = state.budget[state.activityId][existingMilestoneIndex]
      if(!budget)return
      state.budgetChanges[state.activityId].push({
        id:budget.id,
        action:"UPDATE",
        milestone: budget
      })
      let indexToUpdate = state.budgetChanges[state.activityId].length -1
      for(let key in budgetToUpdate){
        state.budgetChanges[state.activityId][indexToUpdate].milestone[key] = budgetToUpdate[key]
        state.budget[state.activityId][existingMilestoneIndex][key] = budgetToUpdate[key]
      }
    }
  },

  updateBudgets(state:IState, data:any){
    for(var key in state.budget){
      state.budget[key].forEach((thisBudget: any,index: any, budgets: any) => {
        let indexOfUpdate = data.data.findIndex((thisUpdate:any) => thisUpdate.id == thisBudget.id)
        if(indexOfUpdate>-1)budgets[index] = data.data[indexOfUpdate]
      })
    }
  },

  deleteBudget(state: IState, budgetToDelete: any) {
    let existingChangeIndex = state.budgetChanges[state.activityId].findIndex((thisBudgetUpdate: any) => thisBudgetUpdate.id == budgetToDelete.id)
    let existingMilestoneIndex = state.budget[state.activityId].findIndex((thisBudget: any) => thisBudget.id == budgetToDelete.id);
    
    if(existingChangeIndex>-1){
        if(state.budgetChanges[state.activityId][existingChangeIndex].action=="CREATE"){
            state.budgetChanges[state.activityId].splice(existingChangeIndex,1)
        }else{
            state.budgetChanges[state.activityId][existingChangeIndex].action = "DELETE"
        }
        state.budget[state.activityId].splice(existingMilestoneIndex,1);
    }else{
        state.budgetChanges[state.activityId].push({
            id:budgetToDelete.id,
            action:"DELETE",
        })
        state.budget[state.activityId].splice(existingMilestoneIndex,1);
    }
  },

  updateTempKeyToActual(state:IState,data:any){
    data.forEach((thisTemActualPair:any) => {
      if(thisTemActualPair.tempKey in state.budget){
        Vue.set(state.budget,thisTemActualPair.id,state.budget[thisTemActualPair.tempKey])
        Vue.delete(state.budget,thisTemActualPair.tempKey)
      }

      if(thisTemActualPair.tempKey in state.budgetChanges){
        Vue.set(state.budgetChanges,thisTemActualPair.id,state.budgetChanges[thisTemActualPair.tempKey])
        Vue.delete(state.budgetChanges,thisTemActualPair.tempKey)
      }

      if(thisTemActualPair.tempKey == state.activityId){
        state.activityId = thisTemActualPair.id
      }

    })
  },

  clearBudgetChanges(state:IState,data:any){
    for(var key in state.budgetChanges){
      state.budgetChanges[key] = []
    }
  }

}

const actions = {
  
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}































































import Vue from 'vue'
import Component from 'vue-class-component';

@Component({
    props: {
        headerColumnOne : {
            default: '12',
            type: Number,
        },
        headerColumnTwo : {
            default: '0',
            type: Number,
        },
        placeholder : {
            default: 'Search',
            type: String,
        },
        title : {
            default: 'PAGE TITLE',
            type: String,
            required: true
        }, 
    }
})
export default class Overview extends Vue{
    showNotifications = false
    get sidenav () {
        return this.$store.getters['getSideNavStatus']
    }

    visible: boolean = false

    get activeUser(){
        return this.$store.getters['activeUser']
    }
    get unreadNotifications():Number{
        return this.$store.getters["Notifications/getNumberOfUnreadNotifications"]
    }
    toggleSideNav(){
        this.$store.commit('setSideNav')
    }
    logout(){
          // this.$router.push('/')
        this.$router.go(0)
        this.$store.dispatch("logOut")
    }

    editProfile(){
        let path : object = { path: '/manage-user', query:{id:this.activeUser.id}  }
        this.$router.push(path)
    }
     onNotificationClicked(){
        this.showNotifications=!this.showNotifications
        this.$store.dispatch("Notifications/updateNotificationStatus")
    }
}
















































































































import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class RigsImport extends Vue {
 @Prop({default:false,required:true}) showModal!:boolean;

    name = ""
    rigType=""
    terrain=""
    minimumHPRating=""
    maximumDrillDepth=""
    bopStack=""
    owner=""
    yearBuilt=""
    hookLoadCapacityRating=""
    rotarySwivelMinWorkingpressure=""
    rotarySwivelAPIDeadLoadRating=""
    travelingBlockMinApiWorkingPressure=""
    additionalComment=""
    sheetName = null
    sheetNames:Array<string> =[]
    fileName:string=""
    columnNames:Array<string> =[]
    rigFile = null as any
    fileUploaded=false
    sheetSelected=false
    loading=false
    okBtnTitle="Upload"
    errors:Array<string> =[]

    get validated(){
        if(this.okBtnTitle == "Import Rigs" && (this.terrain == "" || this.name =="" || this.owner ==""|| this.hookLoadCapacityRating =="")){
           return true;
        }
        else if(this.okBtnTitle == "OK" && this.sheetName =="" ){
           return true;
        }
       return false;
    }

    importRigs() {
        this.loading = true
        this.okBtnTitle="Importing Rigs..."
        let model = {
        filePath: this.fileName,
        sheetName: this.sheetName,
        columnNames:{
        name: this.name,
        RigType: this.rigType,
        terrain: this.terrain,
        minimumHPRating: this.minimumHPRating,
        maximumDrillDepth: this.maximumDrillDepth,
        bopStack: this.bopStack,
        owner: this.owner,
        yearBuilt: this.yearBuilt,
        hookLoadCapacityRating: this.hookLoadCapacityRating,
        rotarySwivelMinWorkingpressure: this.rotarySwivelMinWorkingpressure,
        rotarySwivelAPIDeadLoadRating: this.rotarySwivelAPIDeadLoadRating,
        travelingBlockMinApiWorkingPressure: this.travelingBlockMinApiWorkingPressure,
        additionalComment: this.additionalComment
        }
     }
      this.$store.dispatch('rigsModule/createImportedRigs', model).then((res)=>{
          this.$bvModal.hide("rig-import-modal")
          if(res.data.data.errors.length>0){
            this.errors = res.data.data.errors
            this.$bvModal.show("error-list-modal")
          }

      }).catch((err)=>{
          this.loading =false
            this.okBtnTitle="Import Rigs"
      })
    }
    

    getColumnNames(){
        this.okBtnTitle = "Loading..."
        this.loading = true
        this.$store.dispatch('rigsModule/getColumnNames', {fileName:this.fileName,sheetName:this.sheetName}).then((res)=>{
          this.columnNames = res.data.data
          this.sheetSelected = true
          this.okBtnTitle = "Import Rigs"
          this.loading=false
          }).catch(()=>{
             this.loading = false
             this.okBtnTitle = "OK"
         })

    }

    uploadFile(){
        this.loading = true
        this.okBtnTitle = "Uploading..."
         let form = new FormData()
         form.append("file",this.rigFile)
        this.$store.dispatch('rigsModule/uploadFile', form).then((response=>{
            this.sheetNames = response.data.data.sheetNames
            this.fileName = response.data.data.fileName
             this.fileUploaded = true
            this.loading =false
             this.okBtnTitle = "OK"
         })).catch(()=>{
             this.loading = false
             this.okBtnTitle = "Upload"
         })
    }

    btnClicked(bvModalEvt:any){
        bvModalEvt.preventDefault()
        if(this.okBtnTitle == "Upload"){
           this.uploadFile()
        }
        else if(this.okBtnTitle == "OK"){
            this.getColumnNames()
        }
        else if(this.okBtnTitle == "Import Rigs"){
            this.importRigs()
        }
    }
    closeModal(){
      this.$emit("rig-import-modal-closed")
    }

    @Watch('showModal', {immediate: true}) onShowModalChanged(value: boolean, oldValue: boolean) {
       if(this.showModal){
            this.fileUploaded = false
            this.sheetSelected =false
            this.loading =false
            this.okBtnTitle ="Upload"
            this.$bvModal.show("rig-import-modal")
       }
    }
    
}

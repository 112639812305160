import { HTTP } from '@/axios_api/http-common' 
import {Milestone} from '../../types/Milestone'
import Vue from 'vue'

const state = {
  milestones: {},
  milestoneChanges:[],
  activityId:"",
}

interface IState {
    milestones: Record<string,Array<any>>
    activityId:string,
    milestoneChanges:Array<any>
}

function addDays(date:string, daysToAdd:any) {
  var result = null
  if(typeof(date)=="string"){
    let splitedDate = date.split('-')
    result = new Date(Number.parseInt(splitedDate[0]),Number.parseInt(splitedDate[1])-1,Number.parseInt(splitedDate[2]));
  }else{
    result = date
  }
  if(daysToAdd=="") return result
  result.setDate(result.getDate() + Number.parseInt(daysToAdd));
  return result;
}

const getters = {

    getMilestonesForActivity (state: IState) {
      if(!state.activityId)return []
      const activityToReturn = state.milestones[state.activityId]
      if(activityToReturn==undefined)return []
      return activityToReturn
    },

    filteredMilestoneForActivity : (state:IState,getters:any) => (filter:string) => {
      return getters.getMilestonesForActivity.filter((thisMilestone:any) => thisMilestone.name.toLowerCase().match(filter.toLowerCase()))
    },

    getMilestoneChangesCount: (state:IState) => {
      return state.milestoneChanges.length
    },
    
    getMilestoneById:(state:IState) => (id:any) => {
      for(let k in state.milestones){
        let milestones = state.milestones[k]
        if(!milestones) continue
        let milestone = milestones.find((thisMilestone:any) => thisMilestone.id==id)
        if(milestone){
          //correct for parent activity id here
          milestone.parentActivityId = k
          return milestone
        } 
      }
    },

    convertMilestoneToMilestoneModel:(state:IState,getters:any) => (id:any) => {

      let milestoneModel:any = {}
      let milestoneToConvert = getters.getMilestoneById(id)

      milestoneModel.id = milestoneToConvert.id
      milestoneModel.parentActivityId = milestoneToConvert.parentActivityId
      milestoneModel.code = milestoneToConvert.code
      milestoneModel.name = milestoneToConvert.name
      milestoneModel.start = milestoneToConvert.start
      milestoneModel.end = milestoneToConvert.end
      milestoneModel.startDepth = milestoneToConvert.startDepth.value
      milestoneModel.endDepth = milestoneToConvert.endDepth.value
      milestoneModel.dailyOperations = milestoneToConvert.dailyOperations.map((d:any)=>({dayIndex:d.dayIndex,depth:d.depth.value}))
      milestoneModel.description = milestoneToConvert.description

      //handle budget updates here
      if(milestoneToConvert.budgetChanged){
        milestoneModel.budget = {
          budgetTemplateId:milestoneToConvert.budget.budgetTemplateId,
          activityId:milestoneToConvert.budget.activityId,
          budgetLines:milestoneToConvert.budget.budgetLines.map((bl:any) => ({
            lineItemId:bl.lineItemId,
            lineItemValue:bl.lineValue
          }))
        }
      }

      //end of budget update
      return milestoneModel
    }
}

const mutations = {

  setActivityId(state:IState, activityId:any){
    if(state.activityId==activityId)return
    state.activityId = activityId
    if(!state.milestones[state.activityId])Vue.set(state.milestones,state.activityId,[])
  },

  setMilestones(state:IState,data:any){
    Vue.set(state.milestones,state.activityId,data.data)
  },

  setMilestonesForSpecificActivity(state:IState,data:any){
    Vue.set(state.milestones,data.activityId,data.data)
  },

  addMilestoneToActivity(state: IState, milestoneToAdd: any) {
    let tempId = Math.abs((Math.floor(Math.random() * (10 - +4000))  +10)+(Math.floor(Math.random() * (10 - +4000))  +10)); 
    milestoneToAdd.id = tempId
    milestoneToAdd.parentActivityId = state.activityId
    state.milestones[state.activityId].push(milestoneToAdd) 
    state.milestoneChanges.push({
      id:tempId,
      action:"CREATE",
    })
  },

  addMilestoneToSpecificActivity(state: IState, data: any) {
    let tempId = Math.abs((Math.floor(Math.random() * (10 - +4000))  +10)+(Math.floor(Math.random() * (10 - +4000))  +10)); 
    data.milestoneToAdd.id = tempId
    data.milestoneToAdd.parentActivityId = data.activityId
    state.milestones[data.activityId].push(data.milestoneToAdd) 
    state.milestoneChanges.push({
      id:tempId,
      action:"CREATE",
    })
  },

  updateNewMilestones(state:IState, data:any){
    for(let k in state.milestones){
      state.milestones[k].forEach((thisMilestone,index,milestones) => {
        let indexOfUpdate = data.data.findIndex((thisUpdate:any) => thisUpdate.data.tempKey == thisMilestone.id)
        if(indexOfUpdate>-1)milestones[index] = data.data[indexOfUpdate].data
      })
    }
  },

  updateMilestone (state:IState, milestoneToUpdate:any){
    
    //make sure changes to start and end date are saved to the store
    let existingChangeIndex = state.milestoneChanges.findIndex(thisMilestoneUpdate => thisMilestoneUpdate.id == milestoneToUpdate.id)
    let existingMilestoneIndex = state.milestones[state.activityId].findIndex(thisMilestone => thisMilestone.id == milestoneToUpdate.id)
    
    if(existingChangeIndex<0){
      state.milestoneChanges.push({
        id:milestoneToUpdate.id,
        action:"UPDATE",
      })
    }

    for(let key in milestoneToUpdate){
      if(key=="id")continue
      state.milestones[state.activityId][existingMilestoneIndex][key] = milestoneToUpdate[key]
    }
  },

  updateMilestoneoSpecificActivity (state:IState, milestoneToUpdate:any){
    
    //make sure changes to start and end date are saved to the store
    let existingChangeIndex = state.milestoneChanges.findIndex(thisMilestoneUpdate => thisMilestoneUpdate.id == milestoneToUpdate.id)
    let existingMilestoneIndex = state.milestones[milestoneToUpdate.activityId].findIndex(thisMilestone => thisMilestone.id == milestoneToUpdate.id)
    
    if(existingChangeIndex<0){
      state.milestoneChanges.push({
        id:milestoneToUpdate.id,
        action:"UPDATE",
      })
    }

    for(let key in milestoneToUpdate){
      if(key=="id"||key=="activityId")continue
      state.milestones[milestoneToUpdate.activityId][existingMilestoneIndex][key] = milestoneToUpdate[key]
    }
  },

  updateMilestoneBudget (state:IState, budgetUpdate:any){
    //make sure changes to start and end date are saved to the store
    let existingChangeIndex = state.milestoneChanges.findIndex(thisMilestoneUpdate => thisMilestoneUpdate.id == budgetUpdate.id)
    let existingMilestoneIndex = state.milestones[state.activityId].findIndex(thisMilestone => thisMilestone.id == budgetUpdate.id)
  
    state.milestones[state.activityId][existingMilestoneIndex].budgetChanged = true
    let currentBudget = state.milestones[state.activityId][existingMilestoneIndex].budget
    
    if(currentBudget==null){
      currentBudget = {budgetTemplateId:"",budgetLines:[],activityId:budgetUpdate.id}
      Vue.set(state.milestones[state.activityId][existingMilestoneIndex],'budget', currentBudget)
    }

    if(existingChangeIndex<0){
      state.milestoneChanges.push({
        id:budgetUpdate.id,
        action:"UPDATE",
      })
      existingChangeIndex= state.milestoneChanges.length -1
    }

    for(let key in budgetUpdate){
      if(key == "id"||key=="sectionId")continue
      if(key=="lineItem"){
        //Look for the budgetLineIndex
        let budgetLineIndex = currentBudget.budgetLines.findIndex((thisBudgetLine:any) => thisBudgetLine.lineItemId == budgetUpdate.lineItem.lineItemId)
        //where the budget line index is not found, do a push into budget lines
        if(budgetLineIndex<0){
          state.milestones[state.activityId][existingMilestoneIndex].budget.budgetLines.push({lineValue:budgetUpdate.lineItem.lineValue,lineItemId:budgetUpdate.lineItem.lineItemId})
        }else{
          //where the budget line is found, do an update of that particular budget line
          state.milestones[state.activityId][existingMilestoneIndex].budget.budgetLines[budgetLineIndex].lineValue = budgetUpdate.lineItem.lineValue
        }
      }else{
        if(key=="budgetTemplateId"&&state.milestones[state.activityId][existingMilestoneIndex].budget.budgetTemplateId!=budgetUpdate.budgetTemplateId){
          state.milestones[state.activityId][existingMilestoneIndex].budget.budgetTemplateId = budgetUpdate.budgetTemplateId
        }else{
          state.milestones[state.activityId][existingMilestoneIndex].budget[key] = budgetUpdate[key]
        }
      }
    }
  },

  updateMilestones(state:IState, data:any){
    for(var key in state.milestones){
      state.milestones[key].forEach((thisMilestone,index, milestones) => {
        let indexOfUpdate = data.data.findIndex((thisUpdate:any) => thisUpdate.id == thisMilestone.id)
        if(indexOfUpdate>-1)milestones[index] = data.data[indexOfUpdate]
      })
    }
  },

  deleteMilestone(state: IState, milestoneToDelete: any) {
    let existingChangeIndex = state.milestoneChanges.findIndex(thisMilestoneUpdate => thisMilestoneUpdate.id == milestoneToDelete.id)
    let existingMilestoneIndex = state.milestones[state.activityId].findIndex(thisMilestone => thisMilestone.id == milestoneToDelete.id);
    
    if(existingChangeIndex>-1){
        if(state.milestoneChanges[existingChangeIndex].action=="CREATE"){
            state.milestoneChanges.splice(existingChangeIndex,1)
        }else{
            state.milestoneChanges[existingChangeIndex].action = "DELETE"
        }
        state.milestones[state.activityId].splice(existingMilestoneIndex,1);
    }else{
        state.milestoneChanges.push({
            id:milestoneToDelete.id,
            action:"DELETE",
        })
        state.milestones[state.activityId].splice(existingMilestoneIndex,1);
    }
  },

  updateTempKeyToActual(state:IState,data:any){
    data.forEach((thisTemActualPair:any) => {
      if(thisTemActualPair.tempKey in state.milestones){
        Vue.set(state.milestones,thisTemActualPair.id,state.milestones[thisTemActualPair.tempKey])
        Vue.delete(state.milestones,thisTemActualPair.tempKey)
      }

      if(thisTemActualPair.tempKey == state.activityId){
        state.activityId = thisTemActualPair.id
      }

    })
  },

  clearMilestoneChanges(state:IState,data:any){
    state.milestoneChanges = []
  }
  
}

const actions = {
  getMilestonesForActivity ({ commit,state } : { commit: any,state:IState}) {       
    return new Promise((resolve, reject) => {
        HTTP.get('milestones/?activityId='+state.activityId)
        .then((response: any) => {
            commit('setMilestones', {activityId:state.activityId,data:response.data.data} )         
            resolve(response)                    
        })
        .catch((error: any) => {
            reject(error)
        })
    })
  },
  getAndAdjustMilestonesDateForActivity ({ commit,state } : { commit: any,state:IState},data:any) {    

    if(state.milestones[data.activityId]&&state.milestones[data.activityId].length>0){

      return new Promise((resolve,reject) => {
        state.milestones[data.activityId].forEach((milestone:any) => {
          const start = addDays(milestone.start,data.days)
          const end = addDays(milestone.end,data.days)
          commit('updateMilestoneoSpecificActivity',{start,end,id:milestone.id,activityId:data.activityId})
        })
        resolve(null)
      })

    }else{

      return new Promise((resolve, reject) => {

        if(data.hasServerData){

          HTTP.get('milestones/?activityId='+data.activityId)
          .then((response: any) => {
              commit('setMilestonesForSpecificActivity', {activityId:data.activityId,data:response.data.data} )
              if(state.milestones[data.activityId]&&state.milestones[data.activityId].length>0){
                state.milestones[data.activityId].forEach((milestone:any) => {
                  const start = addDays(milestone.start,data.days)
                  const end = addDays(milestone.end,data.days)
                  commit('updateMilestoneoSpecificActivity',{start,end,id:milestone.id,activityId:data.activityId})
                })
              }          
              resolve(response)                    
          })
          .catch((error: any) => {
              reject(error)
          })

        }

      })

    }   
  },
  getAndCreateMilestonesForActivity({ commit,state } : { commit: any,state:IState},data:any){
    commit('setMilestonesForSpecificActivity', {activityId:data.activityId,data:[]} )
    return new Promise((resolve,reject) => {
      if(state.milestones[data.sourceActivityId]&&state.milestones[data.sourceActivityId].length>0){

        state.milestones[data.sourceActivityId].forEach((milestone:any) => {
          const start = addDays(milestone.start,data.days)
          const end = addDays(milestone.end,data.days)

          commit('addMilestoneToSpecificActivity',{activityId:data.activityId,milestoneToAdd:{
            name: milestone.name,
            start: start,
            end: end,
            startDepth:milestone.startDepth,
            endDepth:milestone.endDepth,
            code:milestone.code,
            dailyOperations:milestone.dailyOperations,
            description: milestone.description,
            parentActivityId:data.activityId
          }})

        })

        resolve(null)
      }else{

        if(data.hasServerData){

          HTTP.get('milestones/?activityId='+data.sourceActivityId)
          .then((response: any) => {

            commit('setMilestonesForSpecificActivity', {activityId:data.sourceActivityId,data:response.data.data} )

            if(state.milestones[data.sourceActivityId]&&state.milestones[data.sourceActivityId].length>0){

              state.milestones[data.sourceActivityId].forEach((milestone:any) => {
                const start = addDays(milestone.start,data.days)
                const end = addDays(milestone.end,data.days)
                commit('addMilestoneToSpecificActivity',{activityId:data.activityId,milestoneToAdd:{
                  name: milestone.name,
                  start: start,
                  end: end,
                  startDepth:milestone.startDepth,
                  endDepth:milestone.endDepth,
                  code:milestone.code,
                  dailyOperations:milestone.dailyOperations,
                  description: milestone.description,
                  parentActivityId:data.activityId
                }})
              })

            }          
            resolve(response)                    
          })
          .catch((error: any) => {
            reject(error)
          })
          //end of http call stuffs
        }

      }

    })
    //end of promise object
  },
  createMilestonesForActivity({commit,rootState,rootGetters}:{commit:any,rootState:any,rootGetters:any},data:any){
    
    return new Promise((resolve,reject) => {
        if(data.activities.length>0){
            HTTP.post('milestones/create',data)
            .then((response:any) => {
                response.data.data.forEach((data:any) => {
                  const m = data.data
                  let activity = rootGetters["ActivityModule/getActivityById"](m.parentActivityId)
                  if(activity){
                    let project =  rootGetters["projectModule/getProjectById"](activity.projectId)
                    let projectIndex = rootGetters["projectModule/getProjectIndexById"](activity.projectId)
                    if(projectIndex>-1){
                      let activityIndex = rootState.projectModule.projects[project.sequenceId][projectIndex].activities.findIndex((a:any)=>a.id==m.parentActivityId)
                      if(activityIndex>-1){
                        rootState.projectModule.projects[project.sequenceId][projectIndex].activities[activityIndex].milestones.push({id:m.id,name:m.name})
                      }
                    }
                  }
                });
                commit('updateNewMilestones',{data:response.data.data})
                let tempActualKeyPairs = response.data.data.map((thisMilestone:any) => {return {id:thisMilestone.data.id,tempId:thisMilestone.data.tempKey}})
                resolve({changeType:"CREATE",tempActualKeyPairs})
            })
            .catch((error:any) => {
                reject(error)
            })
        }else{
            resolve([])
        }
    })
  },
  updateMilestonesForActivity({commit}:{commit:any},data:any){
    return new Promise((resolve,reject) => {
        if(data.activities.length>0){
            HTTP.put('milestones/update',data)
            .then((reponse:any) => {
                commit('updateMilestones',{data:reponse.data.data})
                resolve({changeType:"UPDATE"})
            })
            .catch((error:any) => {
                reject(error)
            })
        }else{
            resolve([])
        }
    })
  },
  deleteMilestoneForActivity({commit}:{commit:any},data:any){
    return new Promise((resolve,reject) => {
        if(data.length>0){
            HTTP.post('milestones/delete',data)
            .then((response:any) => {
                resolve(response)
            })
            .catch((error:any) => {
              reject(error)
            })
        }else{
            resolve(null)
        }
    })
  },
  saveChanges({commit,dispatch,state,getters}:{commit:any,dispatch:any,state:IState,getters:any},data:any){
    
    if(data)commit("updateTempKeyToActual",data)
    
    const allCreateOps:Array<any> = state.milestoneChanges.filter(m => m.action=="CREATE").map(thisEntry => getters.convertMilestoneToMilestoneModel(thisEntry.id));
    const allUpdateOps:Array<any> = state.milestoneChanges.filter(m => m.action=="UPDATE").map(thisEntry => getters.convertMilestoneToMilestoneModel(thisEntry.id));
    const allDeleteOps:Array<any> = state.milestoneChanges.filter(m => m.action=="DELETE").map(thisEntry => thisEntry.id);

    return new Promise((resolve,reject) => {
      dispatch("createMilestonesForActivity",{activities:allCreateOps,type:"List"}).then((tempActualKeyPairs:any) => {
          dispatch("updateMilestonesForActivity",{activities:allUpdateOps,type:"List"}).then((updateRes:any) => {
              dispatch("deleteMilestoneForActivity",allDeleteOps).then((deleteRes:any) => {
                  resolve(tempActualKeyPairs)
              })
          })
      })
    })  

  }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
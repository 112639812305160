import { HTTP } from '@/axios_api/http-common'

const state = {
    wells: [],
    well:{
        id: 0,
        name: null,
        status: null,
        project_activities: null,
        well_type: null
    },
    columnNames:[],
    sheetNames:[]
}

interface IState {
    wells: Array<IWell>
}

interface IWell {
    id: number,
    name: string,
    status: any,
    project_activities: any,
    well_type: any
}


const mutations = {
    setWells(state: IState, wells: Array<IWell>) {
        state.wells = wells
    },
    setWell(state: IState, well: IWell){
        state.wells.push(well)
    },
    updateWell(state: IState, well: IWell){
        let indexToUpdate: number = -1
        state.wells.forEach((object,index,deptArray)=>{
            if (well.id === object.id) {
                indexToUpdate = index
            }
        })
        state.wells.splice(indexToUpdate, 1, well)
    },
    deleteWell(state: IState, id: number){
        var indexToDelete: number = -1
        state.wells.forEach((thisWell,index,wellArray)=>{
          if (thisWell.id===id) {
            indexToDelete=index
            return
          }
        })
        state.wells.splice(indexToDelete,1)
    },
}


const getters = {
    
    filteredWells: (state: IState) => (filter: string)=>{
        return state.wells.filter( well => {
            return well.name.toLowerCase().match(filter.toLowerCase())
        })
    },

    getWellById:(state: IState)=>(id: number)=>{
       
        let well = null
      
        for (let index = 0; index < state.wells.length; index++) {
     
             if (state.wells[index].id == id) {
                 well = state.wells[index];
                 break;
             }
            
        }
        return well
    },

    getSortedWellOptions: (state: IState) =>(value_is_object: boolean) =>{
        if(!state.wells) return [];
        var options = JSON.parse(JSON.stringify(state.wells)).sort((a: any, b: any) => {
                    if (a.name < b.name) return -1
                    else if (a.name > b.name) return 1
                    return 0
                }).map((well: IWell) => {
                    return {text: well.name, value: value_is_object === true ? well : well.id}
                })  
        return options;
    },

    getWellsByTerrain:(state:IState) => (terrain:string) => {
        if(!state.wells) return []
        return state.wells.filter((w:any)=>w.terrain == terrain)
    },

}

const actions={
    getWellsAction({ commit }: { commit : any }) {
        return new Promise((resolve: any, reject: any) => {
            HTTP.get('wells').then((response: any) => {                       
              commit('setWells', response.data.data)	
              resolve(response)    
            })
            .catch((error: any) => {
              reject(error)
            })
        })         
    },
    createWellAction ({ commit } : { commit : any}, well_object: IWell) {
        
        return new Promise((resolve, reject) => {
           
            HTTP.post('wells/create', well_object)
            .then((response: any) => {
                commit('setWell',response.data.data)   
                resolve(response.data)
                
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    updateWellAction ({ commit }: { commit : any}, well: IWell) {
        return new Promise((resolve, reject) => {
            HTTP.put('wells/update/' + well.id, well )
            .then((response: any) => {
                commit('updateWell', response.data.data)
                resolve(response)
            })
            .catch((error: any) => {
                reject(error)
            });
        })        
    },
    deleteWellAction ({ commit }: { commit : any }, well_id: number) {
        
        return new Promise((resolve, reject) => {
    
          HTTP.delete('wells/delete/' + well_id)
          .then((response: any) => {
            commit('deleteWell',well_id)
            resolve(response)
          })
          .catch((error: any) => {
            reject(error)
          })
    
        })
    },
    uploadFile ({ commit }: { commit : any }, data: any) {     
        return new Promise((resolve, reject) => {
          HTTP.post('wells/upload', data)
          .then((response: any) => {
            resolve(response)
          })
          .catch((error: any) => {
            reject(error)
          })
    
        })
    },
    getColumnNames ({ commit }: { commit : any }, payload:any) {     
        return new Promise((resolve, reject) => {-
          HTTP.get('wells/colunmNames?fileName='+payload.fileName+'&sheetName='+payload.sheetName)
          .then((response: any) => {
            resolve(response)
          })
          .catch((error: any) => {
            reject(error)
          })
    
        })
    },
    createImportedWells ({ commit }: { commit : any }, model:any) {     
        return new Promise((resolve, reject) => {-
          HTTP.post('wells/createwells',model)
          .then((response: any) => {
            response.data.data.wells.forEach((well:any) => {
                commit('setWell',well)
            });
            resolve(response)
          })
          .catch((error: any) => {
            reject(error)
          })
    
        })
    },
}

const wellsModule={
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}

export default wellsModule
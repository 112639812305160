










































import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class DeviationSurveyImport extends Vue {
 @Prop({default:false,required:true}) showModal!:boolean;
 @Prop({default:-1,required:true}) dailyReportId!:boolean;
    md = ""
    inclination=""
    azimuth=""
    sheetName = null
    sheetNames:Array<string> =[]
    fileName:string=""
    columnNames:Array<string> =[]
    deviationFile = null as any
    fileUploaded=false
    sheetSelected=false
    loading=false
    okBtnTitle="Upload"
    errors:Array<string> =[]

    get validated(){
        if(this.okBtnTitle == "Import Deviation Survey" && (this.md =="" ||  this.inclination=="" || this.azimuth=="" )){
           return true;
        }
        else if(this.okBtnTitle == "OK" && this.sheetName =="" ){
           return true;
        }
       return false;
    }
    importDeviationSurvey() {
        this.loading = true
        this.okBtnTitle="Importing Deviation Survey"
        let model = {
        filePath: this.fileName,
        sheetName: this.sheetName,
        md: this.md,
        inclinationAngle: this.inclination,
        azimuth:this.azimuth,
        dailyReportId:this.dailyReportId,
        }
      this.$store.dispatch('DailyReportModule/importDeviationSurvey', model).then((res)=>{
          this.$bvModal.hide("deviation-survey-import-modal")   
          if(res.data.data.errors.length>0){
            this.errors = res.data.data.errors
            this.$bvModal.show("error-list-modal")
          }
         this.$emit("deviation-imported",res.data.data.deviationSurveys)
      }).catch(()=>{
          this.loading = false
          this.okBtnTitle="Import Deviation Survey"
      })
    }
    

    getColumnNames(){
        this.okBtnTitle = "Loading..."
        this.loading = true
        this.$store.dispatch('DailyReportModule/getColumnNames', {fileName:this.fileName,sheetName:this.sheetName}).then((res)=>{
          this.columnNames = res.data.data
          this.sheetSelected = true
          this.okBtnTitle = "Import Deviation Survey"
          this.loading=false
          }).catch(()=>{
             this.loading = false
             this.okBtnTitle = "OK"
         })

    }

    uploadFile(){
        this.loading = true
        this.okBtnTitle = "Uploading..."
         let form = new FormData()
         form.append("file",this.deviationFile)
        this.$store.dispatch('DailyReportModule/uploadFile', form).then((response=>{
            this.sheetNames = response.data.data.sheetNames
            this.fileName = response.data.data.fileName
             this.fileUploaded = true
            this.loading =false
             this.okBtnTitle = "OK"
         })).catch(()=>{
             this.loading = false
             this.okBtnTitle = "Upload"
         })
    }

    btnClicked(bvModalEvt:any){
        bvModalEvt.preventDefault()
        if(this.okBtnTitle == "Upload"){
           this.uploadFile()
        }
        else if(this.okBtnTitle == "OK"){
            this.getColumnNames()
        }
        else if(this.okBtnTitle == "Import Deviation Survey"){
            this.importDeviationSurvey()
        }
    }
    closeModal(){
      this.$emit("deviation-survey-import-modal-closed")
    }

    @Watch('showModal', {immediate: true}) onShowModalChanged(value: boolean, oldValue: boolean) {
       if(this.showModal){
            this.fileUploaded = false
            this.sheetSelected =false
            this.loading =false
            this.okBtnTitle ="Upload"
            this.$bvModal.show("deviation-survey-import-modal")
       }
    }
    
}

















































import Vue from 'vue'
import Component from 'vue-class-component';

@Component ({
    props: {
        title: {
            required: true,
            default: 'USER',
            type: String
        },
        links: {
            required: false,
            // default: [
            //     {
            //         text: 'User Accounts',
            //         to: '/users'
            //     },
            //     {
            //         text: 'Manage Account',
            //         active: true
            //     }
            // ]
        }
    }
})
export default class FormComponent extends Vue{
    showNotifications = false
    visible: boolean = false
    get sidenav () {
        return this.$store.getters['getSideNavStatus']
    }

    get activeUser(){
        return this.$store.getters['activeUser']
    }
    get unreadNotifications():Number{
        return this.$store.getters["Notifications/getNumberOfUnreadNotifications"]
    }
    
    toggleSideNav(){
        this.$store.commit('setSideNav')
    }

    logout(){
        this.$router.push("/")
        this.$store.dispatch("logOut")
    }

    editProfile(){
        let path : object = { path: '/manage-user', query:{id:this.activeUser.id}  }
        this.$router.push(path)
    }
     onNotificationClicked(){
        this.showNotifications=!this.showNotifications
        this.$store.dispatch("Notifications/updateNotificationStatus")
    }    
}










































import { fas } from '@fortawesome/free-solid-svg-icons';
import Vue from 'vue'
import Component from 'vue-class-component';
import EventBus from './../../eventBus'

@Component
export default class ChangePassword extends Vue{
  // Data
   password = {
     currentPassword:"",
     newPassword:"",
     confirmNewPassword:""
   }
  nextURL: string = ''
  error: any = {
      generalMessage: '',
      errors: []
  }
  activeScreen: string = 'main'

  // methods
  onSubmit() {
    this.activeScreen = 'loading'
    EventBus.$emit('activeScreen', this.activeScreen)
    this.$store.dispatch("usersModule/changePassword",this.password).then((response: any) => {
            this.$router.replace({path:'/login'})
        }).catch((err: any) => {
      this.error.generalMessage = err.response.data.message
      this.activeScreen = "main"
      EventBus.$emit('activeScreen', this.activeScreen)
    });

  }

}

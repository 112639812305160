import Vue from 'vue'
import Component from 'vue-class-component'
@Component
export default class UserProvisioning extends Vue {

    activeUserPermissions=[] as Array<any>;
    activeUserRoles =[ ] as Array<any>;

    created(){
      if(this.$store !== undefined){
        if(this.$store.state['activeUser'] !== null){
            this.activeUserPermissions = this.$store.state['activeUser'].permissions;
            this.activeUserRoles = this.$store.state['activeUser'].roles;
        }
      }
    }

    get userRoles(): any {
        if(this.activeUserRoles !== null){
            return this.activeUserRoles;
        }
        return []
    }
    get userPermissions() {
        if(this.activeUserPermissions !== null){
            return this.activeUserPermissions;
        }
        return []
    }
    get IsUser(): boolean {
        return true;
    }
    get IsSuperAdmin(): boolean {
        if (this.userRoles.includes("superadmin")) {
            return true;
        }
        return false;
    }
    get IsAdmin(): boolean {
        if (this.userRoles.includes("admin")) {
            return true;
        }
        return false;
    }
    get canWriteSequence(): boolean {
        if (this.IsUser && this.userPermissions.includes("sequence")) {
            return true;
        }
        return false;
    }
    get canWriteRig(): boolean {
        if (this.IsAdmin && this.userPermissions.includes("rig")) {
            return true;
        }
        return false;
    }
    get canWriteWell(): boolean {
        if (this.IsAdmin && this.userPermissions.includes("well")) {
            return true;
        }
        return false;
    }
    get canWriteDepartment(): boolean {
        if (this.IsSuperAdmin) {
            return true;
        }
        return false;
    }
    get canWriteBudgetSettings(): boolean {
        if (this.IsAdmin && this.userPermissions.includes("budgetsetting")) {
            return true;
        }
        return false;
    }
    get canEditSettings(): boolean {
        if (this.IsSuperAdmin) {
            return true;
        }
        return false;
    }
    get canWriteProject(): boolean {
        if (this.IsUser && this.userPermissions.includes("wellplanner")) {
            return true;
        }
        return false;
    }
    get canUpdateBudget(): boolean {
        if (this.IsUser && this.userPermissions.includes("budgetentry")) {
            return true;
        }
        return false;
    }
    get canReadBudget(): boolean {
        if (this.IsUser && this.userPermissions.includes("budgetread")) {
            return true;
        }
        return false;
    }
    get canCreateAdmin() {
        if (this.IsSuperAdmin) {
            return true;
        }
        return false;
    }
    get canCreateUser() {
        if (this.IsAdmin) {
            return true;
        }
        return false;
    }
}
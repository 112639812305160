import { HTTP } from '@/axios_api/http-common'

const state = {
    budgetLines: []
}

interface IState {
    budgetLines: any[]
}

const getters = {
    getAllBudgetLines(state:IState) {
        return state.budgetLines;
    }
}

const mutations = {
    saveBudgetLine(state: IState, payload: any) {
        state.budgetLines.push(payload);
    },
    setBudgetLines(state: IState, budgetLines: any[]) {
        state.budgetLines = budgetLines
    },
    updateBudgetLineMutation(state: IState, budgetlineitem: any) {
        var indexToUpdate: number | null = 0
        state.budgetLines.forEach((bItem: any, index: any, budgetLineArray: any) => {
            if (budgetlineitem.id === bItem.id) {
                indexToUpdate = index
            }
        })
        state.budgetLines.splice(indexToUpdate, 1, budgetlineitem)
    },
    deleteBudgetLine(state: IState, id: number){
        for (let index = 0; index < state.budgetLines.length; index++) {
            if(state.budgetLines[index].id == id) {
                state.budgetLines.splice(index,1)
                break;
            }
       }
    },
}

const actions = {
    createBudgetLineItem ({commit}: {commit: any}, payload: any) {
        return new Promise((resolve, reject) => {
            HTTP.post('budgetlineitem/create/', payload)
                .then((response: any) => {
                    commit('saveBudgetLine', response.data.data)
                    resolve(response)
                })
                .catch((error: any) => {
                    reject(error)
                })
        })
    },
    fetchBudgetLines({commit}: {commit:any}) {
        return new Promise((resolve, reject) => {
            HTTP.get('budgetlineitem').then((response: any) => {
                commit('setBudgetLines', response.data.data)
                resolve(response)
            }).catch((err: any) => {
                reject(err)
            })
        })
    },
    fetchBudgetLineById(payload: any){
        return new Promise((resolve, reject) => {
            HTTP.get('budgetlineitem/' + payload.id).then((response: any) => {
                resolve(response)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    updateBudgetLines({commit}: {commit: any}, payload: any) {
        return new Promise((resolve, reject) => {
            HTTP.put('budgetlineitem/update/' + payload.id, {lineItem: payload.lineItem}).then((response: any) => {
                commit('updateBudgetLineMutation', payload)
                resolve(response)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    deleteBudgetLineAction({commit}: {commit: any}, payload: any) {
        return new Promise((resolve, reject) => {
            HTTP.delete('budgetlineitem/delete/'+payload.id).then((response: any) => {
                commit('deleteBudgetLine', payload.id)
                resolve(response)
            }).catch((error: any) => {
                // window.console.log(error)
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}


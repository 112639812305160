




























































































































































import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import BudgetSheet from './BudgetSheet'
import ReportSheet from './ReportSheet'
import ScopeOfWork from './Scope/ScopeOfWork'
import AAR from './AAR/AfterActionReviewForm'
import AAROverview from './AAR/Overview'
import DDROverview from './DailyReport/Overview'
import Preprocesses from './Preprocesses.vue'
import convertUnit from '../../utils/convertUnit.ts'
@Component({
    components:{
        'budget-sheet':BudgetSheet,
        'report-sheet':ReportSheet,
        'scope-of-work':ScopeOfWork,
        'ddr-overview':DDROverview,
        'aar-overview':AAROverview,
        'planning-sheet-overview':Preprocesses,
        'aar':AAR
    },
    filters:{
        budget:(value:any)=>{
            return "K$ "+parseFloat(value).toFixed(2);
        }
    }
})
export default class ActivityChart extends Vue {
    @Prop()
    readOnlyView!:any
    @Prop()
    projectId !:any
    @Prop()
    currentHeight !: any
    editedReport:any={};
    isEdit = false
    activeReviewId:number = -1
    historyMode = false
    filterId : any
    timeArray: Array<any> = [
        {text: 'Year', value: 'YEAR'},
        {text: 'Month', value: 'MONTH'},
        {text: 'Week', value: 'WEEK'},
    ]
    activeView:string = 'gantt'
    timeUnit: string = 'WEEK'
    chartOptions: any = {
        legend: true,
        constraints: {
            casecadeWest: false,
            cascadeEast: true,
            allowInteraction: {checkType:'START_AFTER'},
            boundWest:true,
            boundEast:true,
            allowDrawing:!this.readOnlyView
        }
    }
    sequenceId:number = -1
    defaultWellActivity = {
        id:-1
    }

    start:any = new Date()
    end:any = new Date(this.start.getFullYear()+1,11,31)

    selectedDepts: Array<any> = []
    budgetSheetData:any = {
        activityId:-1,
        budgetTemplateId:-1,
    }

    budgetLumpedSum:boolean = true
    isEditingDailyReport=false;

    get visibleTimeUnits(): Array<any>{
        return [
        {
            timeUnit:"CURRENT",
            options:{
            fill:'yellow',
            textColor:'blue',
            stroke:'white'
            }
        },
        {
            timeUnit: "YEAR",
            options:{
            fill:this.settings[8]?'#'+this.settings[8].value:'black',
            textColor: 'white',
            stroke: 'white'
            }
        },
        {
            timeUnit:"MONTH",
            options: {
            fill:this.settings[9]?'#'+ this.settings[9].value:'black',
            textColor: 'white',
            stroke: 'white'
            }
        },
        {
            timeUnit:"WEEK",
            options: {
            fill:this.settings[10]?'#'+ this.settings[10].value:'black',
            textColor: 'white',
            stroke: 'white'
            }
        },
        ]
    }

    get settings () {
        return this.$store.getters['settingsModule/getSettings']
    }

    get activityOptions():any{
        return this.activities.map((thisActivity:any)=>({value:thisActivity.id,text:thisActivity.wellActivity.name}))
    }

    get budgetTemplateOptions():any{
        return this.$store.getters["budgetTemplateModule/getAllTemplates"].map((thisBudgetTemplate:any)=>({value:thisBudgetTemplate.id,text:thisBudgetTemplate.templateName}))
    }

    get milestones():any{
        return this.$store.getters['MilestoneModule/getMilestonesForActivity']
    }
    //end of budget sheet and report sheet data

    get sidenav():any{
        return this.$store.getters['getSideNavStatus']
    }

    get rightPane():any{
        return this.$store.getters['getPaneStatus']
    }

    get project(){
        return this.$store.getters['projectModule/getProjectById'](this.projectId)
    }

    get activities(){
        return this.$store.getters['ActivityModule/getActivitiesForProject'](this.projectId)
    }

    get departments(){
        return this.sequence.departments?this.sequence.departments:[]
    }

    get deptOptions(){
        let deptOptions = this.sequence.departments?this.sequence.departments.map((d:any) => ({value:d.id,text:d.name})):[]
        this.selectedDepts = deptOptions.map((d:any) => d.value)
        return deptOptions
    }

    get sequence(){
        return this.$store.getters['sequencesModule/getSequence'](this.sequenceId)
    }

    get updateRoute(){
        return this.$store.state.ActivityModule.updateRoute
    }

    get getProjectIndexById(){
        return this.$store.getters["projectModule/getProjectIndexById"](this.projectId)
    }

    get totalBudgetForProject(){
        let totalBudget = 0
        this.activities.forEach((activity:any) => {
            if(activity.budget){
                activity.budget.budgetLines.forEach((budgetLine:any) => {
                    totalBudget+=budgetLine.lineValue
                })
            }
        });
        return totalBudget
    }

    get getAllPlannedMilestonesForProject(){
        let milestoneNames = [] as Array<string>
        this.activities.forEach((activity:any) => {
            activity.milestones.forEach((milestone:any) => {
                milestoneNames.push(milestone.name)
            })
        } )
        return milestoneNames
    }

    get settingswellFluid () {
        return this.$store.getters['settingsModule/getSettingsWell']
    }

    getColorForProject(data: any){
        switch(data.well.wellType){
        case 'Gas':
            return '#'+ this.settings[4].value
        case 'OilGas':
            return '#'+ this.settings[5].value
        case 'Oil':
            return '#'+ this.settings[6].value
        case 'Condensate':
            return '#'+ this.settings[7].value
        default:
            return '808080'
        }
    }

    get currentUnitSystem(){
        return this.$store.getters['UnitsModule/getCurrentUnitSystem']
    }

    openDailyReportEditor(){
        this.activeView = 'report-sheet';
        this.isEditingDailyReport = false;
        this.isEdit = false
    }
    editDailyReport(dailyReport:any){
        this.activeView="report-sheet";
        this.isEditingDailyReport=true;
        this.editedReport=dailyReport;
        this.isEdit= true
        this.editedReport.endDepth=convertUnit(dailyReport.endDepth, this.currentUnitSystem, "m" );
        this.editedReport.startDepth=convertUnit(dailyReport.startDepth, this.currentUnitSystem, "m" );
        this.editedReport.activityLog = dailyReport.activityLog.map((element: any) => {
            if (element) {
                return {
                     id:element.id,
                     from: element.from,
                     to: element.to,
                     hasNpt: element.hasNpt,
                     activityInProjectId: element.activityInProjectId,
                     activityId: element.activityId,
                     activityInProjectName:  this.activities.find((activityInProject:any)=> activityInProject.id == element.activityInProjectId).name,
                     activityName: this.activities.find((activityInProject:any)=> activityInProject.id == element.activityInProjectId)
                                .milestones.find((milestone:any)=> milestone.id == element.activityId).name,
                     description: element.description,
                     depth: convertUnit(element.depth, this.currentUnitSystem, "m" ),
                     nptRef:element.nptRef,
                     npt:{
                     nptDescription: element.npt.nptDescription,
                     nptHours: element.npt.nptHours,
                     nptOperationId: element.npt.nptOperationId,
                     }
                 }
            }
        });
    }

    showWellPlanningChecklist(){
        this.activeView = 'planning-sheet-overview'
    }

    showScopeOfWork(){
        this.activeView = 'scope-of-work'
    }

    showAAR(){
        this.activeView = 'aar-overview'
    }

    showReportSheet(){
        this.activeView = 'ddr-overview'
    }

    showPropertyGrid(){
        if(this.rightPane){
            return this.$store.commit('setRightPaneToFalse')
        }else{
            this.$store.commit('setActivePane', 'activity-pane');
            this.$store.commit('setRightPaneToTrue');
        }
    }

    budgetTemplateIdChanged(){
        if(this.budgetSheetData.activityId!=-1)this.$store.dispatch("ActivityModule/updateActivityBudget",{projectIndex:this.getProjectIndexById,projectId:this.projectId,sequenceId:this.sequenceId,id:this.budgetSheetData.activityId,budgetTemplateId:this.budgetSheetData.budgetTemplateId})
    }

    selectedActivityChanged(){
        
        //if(this.budgetSheetData.activityId=="-1") return 
        this.$store.commit('projectModule/setActiveProject', this.projectId);
        this.$store.commit('projectModule/setActiveSequence', {sequenceId:this.sequenceId,readOnly:this.readOnlyView});
        this.$store.commit('ActivityModule/setActiveActivity', this.budgetSheetData.activityId);
        this.$store.commit('MilestoneModule/setActivityId', this.budgetSheetData.activityId);
        let activity = this.$store.getters["ActivityModule/getActiveActivity"]
        if(activity.budget == null) this.budgetSheetData.budgetTemplateId = "-1"
        else this.budgetSheetData.budgetTemplateId = activity.budget.budgetTemplateId
        if(this.$store.getters['MilestoneModule/getMilestonesForActivity'].length<1){
            this.$store.dispatch("MilestoneModule/getMilestonesForActivity")
        }
    }

    showBudgetSheet(){
        this.activeView = 'budget-sheet'
    }

    getDependency(dependencyId:string){
      if(dependencyId){
        let activity = this.activities.find((thisActivity:any) => thisActivity.id == dependencyId)
        if(activity){
          return {row:activity.departmentId,end:activity.end}
        }else{
          return undefined
        }
      }else{
        return undefined
      }
    }

    getDepartmentName(id:string){
        return this.departments.find((d:any) => d.id == id).name
    }

    //methods
    takeRightPane() {
        return this.$store.commit('setRightPaneToFalse')
    }

    singleClickEvent(activityId: any) {
        this.$store.commit('projectModule/setActiveProject', this.projectId);
        this.$store.commit('projectModule/setActiveSequence', {sequenceId:this.sequenceId,readOnly:this.readOnlyView});
        this.$store.commit('ActivityModule/setActiveActivity', activityId);
        this.$store.commit('MilestoneModule/setActivityId', activityId);
        this.$store.commit('setActivePane', 'activity-pane');
        this.$store.commit('setRightPaneToTrue');
        this.budgetSheetData.activityId = activityId
        let activity = this.$store.getters["ActivityModule/getActiveActivity"]
        if(activity.budget != null) this.budgetSheetData.budgetTemplateId = activity.budget.budgetTemplateId
    }

    drawingStart(data: any){
        let date = new Date(data.startDate)
        let today = new Date()
        if(date.getTime() < today.getTime()){
            if(this.chartOptions.constraints.allowDrawing){
                this.chartOptions.constraints.allowDrawing = false
                this.$bvToast.toast('You are attempting to add an activity in the past. Activity start date must be greater than today.', {
                    title: 'Please Note!!!',
                    autoHideDelay: 10000,
                    appendToast: true,
                    toaster: 'b-toaster-bottom-right',
                    solid: true,
                    variant: 'danger'
                })
            }
        }else{
            this.chartOptions.constraints.allowDrawing = true
        }
    }
    showPrompt = false

    drawingEnd(data:any){
        const start = data.startDate.getFullYear()+"-"+((data.startDate.getMonth()+1)<10?('0'+(data.startDate.getMonth()+1)):data.startDate.getMonth()+1)+"-"+(data.startDate.getDate()<10?('0'+data.startDate.getDate()):data.startDate.getDate())
        const end = data.endDate.getFullYear()+"-"+((data.endDate.getMonth()+1)<10?('0'+(data.endDate.getMonth()+1)):data.endDate.getMonth()+1)+"-"+(data.endDate.getDate()<10?('0'+data.endDate.getDate()):data.endDate.getDate())
        if ((data.startDate.getTime() < new Date(this.project.start).getTime() || data.endDate.getTime() > new Date(this.project.end).getTime()) && data.row == '3' ) {
            this.showPrompt = true            
        } else {
            this.$store.dispatch("ActivityModule/addActivity",{sequenceId:this.sequenceId,projectIndex:this.getProjectIndexById ,newActivity:{
                name:"name"+this.activities.length,
                departmentId:data.row,
                start: start,
                end: end,
                milestones:[],
                wellActivity:this.defaultWellActivity,
                supportTeamsIds:[]
            }})
        }
    }

    convert (date: any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }

    computeDurationForProjectMove(start:string,end:string){
        if(start==""||end=="")return 0
        let startDateMill = (new Date(start)).getTime()
        let endDateMill = (new Date(end)).getTime()
        let diff = endDateMill - startDateMill
        return diff/(1000 * 3600 * 24)
    }

    canMoveActivity(deptId:any,newStart:any,newEnd:any,activityStart:any,activityEnd:any){
    
        let maxMoveRight = this.computeDurationForProjectMove(activityEnd,this.project.end)
        let maxMoveLeft = this.computeDurationForProjectMove(this.project.start,activityStart)
        let actualMoveRight = this.computeDurationForProjectMove(activityEnd,newEnd)
        let actualMoveLeft = this.computeDurationForProjectMove(activityStart,newStart)
        let trackOtherActivities = this.chartOptions.constraints.cascadeEast

        if(actualMoveLeft>0){
            actualMoveLeft = 0
        }else{
            trackOtherActivities = false
        }
        if(actualMoveRight<0){
            actualMoveRight = 0
            trackOtherActivities = false
        }

        
        
        const activities = this.activities.filter((a:any) => a.departmentId == deptId).sort((a1:any,a2:any) => {
            const a1Start = new Date(a1.start).getTime()
            const a2Start = new Date(a2.start).getTime()
            if(a1Start==a2Start) return 0
            if(a1Start<a2Start) return -1
            if(a1Start>a2Start) return 1
        })
        if(activities.length>1&&trackOtherActivities){
            const last = activities[activities.length - 1]
            const first = activities[0]
            maxMoveRight = this.computeDurationForProjectMove(last.end,this.project.end)
            maxMoveLeft = this.computeDurationForProjectMove(this.project.start,first.start)
        }

        if(maxMoveLeft<0)maxMoveLeft=0
        if(maxMoveRight<0)maxMoveRight=0

        window.console.log(actualMoveLeft+','+maxMoveLeft+'--------'+actualMoveRight +','+ maxMoveRight)
        
        return (Math.abs(actualMoveLeft)<=maxMoveLeft)&&(Math.abs(actualMoveRight)<=maxMoveRight)
    }

    barMoveEnded(data:any){
        const activity = this.activities.find((a:any) => a.id == data.id)
        const start = data.startDate.getFullYear()+"-"+((data.startDate.getMonth()+1)<10?('0'+(data.startDate.getMonth()+1)):data.startDate.getMonth()+1)+"-"+(data.startDate.getDate()<10?('0'+data.startDate.getDate()):data.startDate.getDate())
        const end = data.endDate.getFullYear()+"-"+((data.endDate.getMonth()+1)<10?('0'+(data.endDate.getMonth()+1)):data.endDate.getMonth()+1)+"-"+(data.endDate.getDate()<10?('0'+data.endDate.getDate()):data.endDate.getDate())

        //if ((data.endDate.getTime() > new Date(this.project.end).getTime() || data.startDate.getTime() < new Date(this.project.start).getTime()) &&  activity.departmentId== '3') {
        if (!this.canMoveActivity(3,start,end,activity.start,activity.end) &&  activity.departmentId== '3') {
            this.showPrompt = true   
            return
        }
        
        this.$store.dispatch("ActivityModule/updateActivity",{projectIndex:this.getProjectIndexById,projectId:this.projectId,sequenceId:this.sequenceId,id:data.id,start:start,end:end})
    }

    @Watch('sidenav')
    onSideNavChanged(val:boolean){
        setTimeout(() => { 
            ((this.$refs.gantt2) as any).resize()
        }, 400);
    }

    @Watch('rightPane')
    onRigthPaneChanged(val:boolean){
        setTimeout(() => { 
            if(this.activeView=='gantt')((this.$refs.gantt2) as any).resize()
        }, 400);
    }

    mounted(){
        if(!this.project){
            this.$store.dispatch("projectModule/getProject",this.projectId).then((res:any) => {
                if(this.project){
                    this.sequenceId = this.project.sequenceId
                    this.$store.dispatch("sequencesModule/getSequence",this.project.sequenceId)
                }
            })
        }else{
            this.sequenceId = this.project.sequenceId
            let projectStart = new Date(this.project.start)
            let projectEnd = new Date(this.project.end)
            this.start = projectStart.setMonth(projectStart.getMonth()-6)
            this.end = projectEnd.setMonth(projectEnd.getMonth()+6)
        }

        if(this.budgetTemplateOptions.length<1){
            this.$store.dispatch("budgetTemplateModule/fetchBudgetTemplates")
        }
    }

    created(){
        this.filterId = Math.abs((Math.floor(Math.random() * (10 - +4000))  +10)+(Math.floor(Math.random() * (10 - +4000))  +10)).toString()
    }
}



































































































































































import Vue from 'vue'
import Component from 'vue-class-component';
import EventBus from './../../eventBus'
import UsersImport from "@/components/UserImport.vue"

@Component({
    components:{
    "users-import": UsersImport
    }
})
export default class UsersOverview extends Vue{
    //Data
    search: string = ''
    activeScreen: string = 'loading'
    loadingMessage: string = 'Loading Users'
    loadingTitle: string = 'Loading...'
    notificationButtonState: string = "Show Notification Settings"
    successMessage:string = ''
    additionalSuccessMessage = ''
    showUserImportModal=false
    fields = [
            //{key:'photo', label:"Image"},
            {key: 'firstName', sortable: 'true'},
            {key: 'lastName', sortable: 'true'},
            'jobTitle','email',
            {key: 'button', label: "Actions"}
    ]

    accessTypes: Array<object> =[
        {value: 'Full', text: "Full"},
        { value: 'ReadOnly', text: "ReadOnly"}
    ]

    selectedUser: any = {
        id:null,
        firstName: '',
        lastName: '',
        position: '',
        departments: '',
        isLocked:true,
        userName:''
    
    }
    departmentFilter: number = -1
    items: Array<any> = []
    notificationSettings: Array<any> = []
    departments: any
    deletePromptProperty: boolean = false
    error: string  = ''
    loader: boolean = true
    isView: boolean = false


    // Methods
    gotoEdit (id: number) {
        let path : object = { path: '/manage-user', query:{id:id} }
        this.$router.push(path)
    }

    getDepartments() {
        return this.$store.dispatch('departmentModule/getDepartments')
    }

    deleteUser(id: number) {
        return this.$store.dispatch('usersModule/deleteUser', id)
    }

    view(selected: any){
        this.selectedUser = selected
        this.isView = true
    }

    selectUser(selected: any){
        this.selectedUser.name = selected.name;
        this.selectedUser.position = selected.position;
        this.selectedUser.department = selected.department;
    }

    deleteUserPrompt(selected: any){
        this.selectedUser.id = selected.id
        this.selectedUser.firstName = selected.firstName;
        this.selectedUser.lastName = selected.lastName;
        this.selectedUser.position = selected.position;
        this.selectedUser.department = selected.department;

        this.deletePromptProperty = true
    }

    lockUserPrompt(selected: any){
        this.selectedUser.id = selected.id
        this.selectedUser.userName = selected.userName
        this.selectedUser.isLocked = selected.isLocked
        this.selectedUser.firstName = selected.firstName;
        this.selectedUser.lastName = selected.lastName;
        this.$bvModal.show("lock-user-modal")
    }

    lockUser(){
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.loadingTitle = this.selectedUser.isLocked ? 'Unlocking User' :'Locking User'
        this.loadingMessage = this.selectedUser.isLocked ? "Please wait while we unlock this user." : "Please wait while we lock this user."
        if(this.selectedUser.isLocked){    
        this.$store.dispatch('usersModule/unlockUser', this.selectedUser.userName).then((res) => {
            this.activeScreen="successNoBack"
            EventBus.$emit('activeScreen', this.activeScreen)
            this.successMessage = this.selectedUser.firstName + ' ' + this.selectedUser.lastName + ' Unlocked succesfully'
            this.selectedUser.isLocked = false
        },error => {
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.message
        }) 
        }else{
        this.$store.dispatch('usersModule/lockUser', this.selectedUser.userName).then((res) => {
            this.activeScreen="successNoBack"
            EventBus.$emit('activeScreen', this.activeScreen)
            this.successMessage = this.selectedUser.firstName + ' ' + this.selectedUser.lastName + ' Locked succesfully'
            this.additionalSuccessMessage = 'This User will no longer be able to login'
             this.selectedUser.isLocked = true
        },error => {
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.message
        }) 
        }

    }

    doDelete (bvEvt: any) {
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.loadingTitle = 'Deleting User'
        this.loadingMessage = "Please wait while we delete this user."
        this.deleteUser(this.selectedUser.id).then(response => {
            this.activeScreen="successNoBack"
            EventBus.$emit('activeScreen', this.activeScreen)
            this.successMessage = this.selectedUser.firstName + ' ' + this.selectedUser.lastName + ' deleted succesfully'
            this.additionalSuccessMessage = 'This User will no longer be available'
            this.deletePromptProperty = false
        },error => {
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.message
            this.deletePromptProperty = false
        })
    }

    searchValue(e: any){
        this.search = e
    }

    isSuscribedToNotification(userID: any, notificationType: any){
        if(this.notificationSettings[userID]!=undefined){
            return this.notificationSettings[userID].filter((notSet: any) => notSet.notificationType == notificationType).length?{userId:userID,permissionVal:notificationType}:false
        }else{
            return false
        }
    }

    manageNotificationSettings(){
        if(this.notificationButtonState=="Show Notification Settings"){
            this.fields.push(
                {key: 'not1', label: "Timeline change"},
                {key: 'not2', label: "Budget"},
                {key: 'not3', label: "Well Creation"}
            )
            this.notificationButtonState="Hide Notification Settings"
            return
        }

        if(this.notificationButtonState=="Hide Notification Settings"){
            this.fields.splice((this.fields.length-3),3)
            this.notificationButtonState="Show Notification Settings"
            return
        }

        if(this.notificationButtonState=="Save Notification Settings"){
            var settingsToReturn = []
            for(var userId in this.notificationSettings) {
                settingsToReturn.push({userId,suscriptions:this.notificationSettings[userId]})
            }
            this.activeScreen = 'loading'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.manageSuscription(settingsToReturn).then((res: any)=>{
                this.getNotificationSettings()
                this.activeScreen = 'loading'
                EventBus.$emit('activeScreen', this.activeScreen)
            }).catch((err: any) => {
                this.error = err.message
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
            })
        }
    }

    getNotificationSettings(){
        this.fillteredUsers(this.search,this.departmentFilter).forEach((user: any) => {            
            user.suscribedNotifcations.forEach( (thisSuscribedNotification: any) => {                
                if( this.notificationSettings[user.id] == undefined ){                    
                    this.notificationSettings[user.id] = [{notificationType:thisSuscribedNotification,isSuscribed:true}]
                }else{                    
                    this.notificationSettings[user.id].push({notificationType:thisSuscribedNotification,isSuscribed:true})
                }
            })
        });
    }

    permValueChanged(data: any){
        if(this.notificationSettings[data.userId]!=undefined){            
            var notificationSettingToChange = this.notificationSettings[data.userId].find((thisNotificationSetting: any) => thisNotificationSetting.notificationType==data.permissionVal)
            if(notificationSettingToChange!=undefined){                
                notificationSettingToChange.isSuscribed = !notificationSettingToChange.isSuscribed
            }else{                
                this.notificationSettings[data.userId].push({notificationType:data.permissionVal,isSuscribed:true})
            }
        }else{            
            this.notificationSettings[data.userId] = [{notificationType:data.permissionVal,isSuscribed:true}]
        }
        if(this.notificationButtonState=="Hide Notification Settings")this.notificationButtonState = "Save Notification Settings"
    }


    // Computed propertied
    get getDepartmentOptions () {
        return this.$store.getters['departmentModule/getDepartmentOptions']
    }

    get department () {
        return this.$store.getters['departmentModule/getDepartmentById'](this.selectedUser.departmentId)
    }

    get fillteredUsers () {
        return this.$store.getters['usersModule/fillteredUsers']
    }

    get manageSuscription () {
        return this.$store.getters['usersModule/manageSuscription']
    }

    created() {
        if (this.fillteredUsers('', this.departmentFilter).length === 0){
            this.$store.dispatch('usersModule/getUsers').then((res: any) => {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.loader = false
            })
        } else {
            this.activeScreen = 'main'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.loader = false
        }

        if(this.getDepartmentOptions.length===0){
            this.getDepartments()
        }
    }
    
}

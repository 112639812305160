












import Vue from 'vue'
import Component from 'vue-class-component';

@Component ({
    props: {
        // returnButton: {
        //     type: Function,
        //     required: true
        // }
    }
})
export default class ErrorComponent extends Vue{

}







































































































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import UnitConverter from '../../mixins/unitConverter.ts'

@Component({
  mixins: [UnitConverter]
})
export default class General extends Vue {
  @Prop({}) 
  startDepth!:any;
  @Prop({}) 
  last!:any;
  @Prop({}) 
  endDepth!:any;
  @Prop({}) 
  reportDate!:any;
  @Prop({}) 
  startTime!:any;
  @Prop({}) 
  endTime!:any;
  @Prop({}) 
  rkbDepth!:any;
  @Prop({}) 
  dfe!:any;

  // Schedule
  @Prop({})
  plannedRigMoveStartDate!:any
  @Prop({})
  plannedRigMoveEndDate!:any
  @Prop({})
  plannedRigMoveDays!:any
  @Prop({})
  plannedOperationsDays!:any
  @Prop({})
  rigMoveStartDate!:any
  @Prop({})
  rigMoveEndDate!:any
  @Prop({})
  actualRigMoveDays!:any
  @Prop({})
  daysOnLocation!:any
  @Prop({})
  spudDate!:any
  @Prop({})
  spudTime!:any
  @Prop({})
  daysFromSpud!:any
  @Prop({})
  operatingDaysVariance!:any

  private get startDepthInput() {
    return this.startDepth;
  }
  private set startDepthInput(depth:any) {
    this.$emit("update:startDepth", depth);
  }
  private get rkbDepthInput() {
    return this.rkbDepth;
  }
  private set rkbDepthInput(depth:any) {
    this.$emit("update:rkbDepth", depth);
  }
  private get dfeInput() {
    return this.dfe;
  }
  private set dfeInput(depth:any) {
    this.$emit("update:dfe", depth);
  }
  private get endDepthInput() {
    return this.endDepth;
  }
  private set endDepthInput(depth:any) {
    this.$emit("update:endDepth", depth);
  }
   private get reportDateInput() {
    return this.reportDate;
  }
  private set reportDateInput(date:any) {
    this.$emit("update:reportDate", date);
  }
   private get startTimeInput() {
    return this.startTime;
  }
  private set startTimeInput(time:any) {
    this.$emit("update:startTime", time);
  }
  private get endTimeInput() {
    return this.endTime;
  }
  private set endTimeInput(time:any) {
    this.$emit("update:endTime", time);
  }

  // Schedule
  private get plannedRigMoveStartDateInput() {
    return this.plannedRigMoveStartDate;
  }
  private set plannedRigMoveStartDateInput(date:any) {
    this.$emit("update:plannedRigMoveStartDate", date);
  }
  private get actualStartDateInput() {
    return this.rigMoveStartDate;
  }
  private set actualStartDateInput(date:any) {
    this.$emit("update:rigMoveStartDate", date);
  }
  private get plannedEndDateInput() {
    return this.plannedRigMoveEndDate;
  }
  private set plannedEndDateInput(date:any) {
    this.$emit("update:plannedRigMoveEndDate", date);
  }
  private get actualEndDateInput() {
    return this.rigMoveEndDate;
  }
  private set actualEndDateInput(date:any) {
    this.$emit("update:rigMoveEndDate", date);
  }
  private get plannedDaysRigMoveInput() {
    return this.plannedRigMoveDays;
  }
  private set plannedDaysRigMoveInput(date:any) {
    this.$emit("update:plannedRigMoveDays", date);
  }
  private get actualRigMoveDaysInput() {
    return this.actualRigMoveDays;
  }
  private set actualRigMoveDaysInput(date:any) {
    this.$emit("update:actualRigMoveDays", date);
  }
  private get plannedDaysOperationalInput() {
    return this.plannedOperationsDays;
  }
  private set plannedDaysOperationalInput(days:any) {
    this.$emit("update:plannedOperationsDays", days);
  }
  private get operatingDaysVarianceInput() {
    return this.operatingDaysVariance;
  }
  private set operatingDaysVarianceInput(days:any) {
    this.$emit("update:operatingDaysVariance", days);
  }
  private get spudDateInput() {
    return this.spudDate;
  }
  private set spudDateInput(days:any) {
    this.$emit("update:spudDate", days);
  }
  private get spudTimeInput() {
    return this.spudTime;
  }
  private set spudTimeInput(days:any) {
    this.$emit("update:spudTime", days);
  }
  private get daysFromSpudInput() {
    return this.daysFromSpud;
  }
  private set daysFromSpudInput(days:any) {
    this.$emit("update:daysFromSpud", days);
  }
  private get daysOnLocationInput() {
    return this.daysOnLocation;
  }
  private set daysOnLocationInput(days:any) {
    this.$emit("update:daysOnLocation", days);
  }
}












































import Vue from 'vue'
import Component from 'vue-class-component';
//import { BModal } from  'bootstrap-vue';
import EventBus from '../../eventBus'
import { required } from 'vuelidate/lib/validators'
import { Validations } from 'vuelidate-property-decorators';

@Component
export default class BudgetingLines extends Vue {
    [x: string]: any;
    @Validations()
    validations = {
        budgetLine: {
            lineItem: { required }
        }
    }
    private error: string = '';
    private activeScreen: string = 'loading';
    private additionalSuccessMessage: string = '';
    private successMessage: string = '';
    private loadingMessage: string = '';
    private loadingTitle: string = '';
    private budgetLine: any = {
        lineItem: ''
    };
    private search: string = '';
    private title: string = '';
    private deletePromptProperty: boolean = false;
    private activeItem: any = {};
    private isEdit : boolean = false;
    get getFields(){
       if(this.canWriteBudgetSettings){
            return  [ { key: 'lineItem', label: 'Name'},
                      { key: 'numberOfDependentTemplates', label: 'No of Dependent Templates'},
                      // { key: 'numberOfDependentProjects', label: 'No of Dependent Projects'},
                       { key: 'button', label: 'Actions'},
                    ];
        }
         return  [ { key: 'lineItem', label: 'Name'},
                      { key: 'numberOfDependentTemplates', label: 'No of Dependent Templates'},
                      // { key: 'numberOfDependentProjects', label: 'No of Dependent Projects'},
                    ];

    }

    private get getAllBudgetLines () {
        return this.$store.getters['budgetLineModule/getAllBudgetLines'];
    }

    private fetchBudgetLines () {
        return this.$store.dispatch('budgetLineModule/fetchBudgetLines')
    }

    private searchValue (e: any) {
        this.search = e;
    }

    private openForm() {
        this.isEdit = false
        this.budgetLine.lineItem = ''
        this.budgetLine.id = ''
        this.budgetLine.numberOfDependentProjects = 0
        this.budgetLine.numberOfDependentTemplates = 0
        let formModal = this.$refs.budget as any;
        this.title = 'Create New Budget Line';
        formModal.show();
    }

    private deleteBudgetLine() {
        this.activeScreen = 'loading'
        this.loadingMessage = 'Please wait while we delete this budget line'
        this.loadingTitle = 'Deleting...'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.$store.dispatch('budgetLineModule/deleteBudgetLineAction', this.activeItem).then(response => {
            this.activeScreen = 'successNoBack'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.additionalSuccessMessage = 'This budgetline will no longer be available'
            this.successMessage = this.activeItem.lineItem + ' deleted succesfully'
            this.deletePromptProperty = false
        }, error => {          
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.response.data.message
            this.deletePromptProperty = false
        })
    }

    save() {
        this.loadingMessage = 'Please wait while we save this budget line item';
        this.loadingTitle = 'Saving budget line item';
        this.$store.dispatch('budgetLineModule/createBudgetLineItem', this.budgetLine)
        .then((response: any) => {
            this.activeScreen = 'successNoBack';
            EventBus.$emit('activeScreen', this.activeScreen);
            this.additionalSuccessMessage = '';
            this.$v.$reset()
            this.successMessage = 'Successfully Created '+ this.budgetLine.lineItem;
        }). catch((error: any) => {
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.response.data.message
        })
    }

    updateBudgetLine() {
        this.$store.dispatch('budgetLineModule/updateBudgetLines', {...this.budgetLine})
        .then((response: any) => {
            this.loadingMessage = 'Please wait while we update this budget line item';
            this.loadingTitle = 'Updating budget line item';
            this.activeScreen = 'successNoBack';
            EventBus.$emit('activeScreen', this.activeScreen);
            this.additionalSuccessMessage = '';
            this.successMessage = 'Budget Line Updated Successfully';
        }).catch((error:any) => {
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.response.data.message
        })
    }

    private saveBudgetLine () {
        this.activeScreen = 'loading';
        EventBus.$emit('activeScreen', this.activeScreen);
        if (this.isEdit === false ){
            this.save()
        } else {
            this.updateBudgetLine()
        }        
    }

    private update(data: any) {
        
        this.isEdit = true;
        this.budgetLine.id = data.id;
        this.budgetLine.lineItem = data.lineItem
        this.budgetLine.numberOfDependentProjects = data.numberOfDependentProjects
        this.budgetLine.numberOfDependentTemplates = data.numberOfDependentTemplates

        let formModal = this.$refs.budget as any;
        this.title = 'Update Budget Line';
        formModal.show();
    }

    private promptDelete(e: any) {
        this.activeItem = e;
        this.deletePromptProperty = true;
    }

    created () {
        this.loadingMessage = 'Loading Budget Lines';
        this.loadingTitle = 'Loading...';
        if (this.getAllBudgetLines.length == 0) {
            this.fetchBudgetLines().then((res: any) => {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
            })
        } else {
            this.activeScreen = 'main'
            EventBus.$emit('activeScreen', this.activeScreen)
        }
    }
}

const Unit = class {
    public name: string
    public symbol: string
    public quantity: string
    public isSI: boolean
    public isField: boolean
    private a: number
    private b: number
    private c: number
    private d: number

    constructor(name: string, quantity: string, symbol: string, a: number, b: number, c: number, d: number, isSI: boolean, isField:boolean) {
        this.name = name;
        this.symbol = symbol
        this.quantity = quantity
        this.isSI = isSI
        this.isField = isField
        this.a = a
        this.b = b
        this.c = c
        this.d = d
    }

    getSymbol(): string {
        return this.symbol;
    }

    public toBase(value: number): number {
        let baseValue: number = (this.a * value + this.b) / (this.c * value + this.d);
        return baseValue;
    }

    public fromBase(baseValue: any): any {
        let value: number = (this.b - this.d * baseValue) / (this.c * baseValue - this.a);
        return value;
    }
}

export default Unit


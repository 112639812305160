import { HTTP } from '@/axios_api/http-common' 
import {Rig} from '../../types/Rig'
import Vue from 'vue'

const state={
    rigs:[]
}

interface IState {
    rigs: Array<Rig>
}

const mutations={
    setRigs(state: IState, rigs: Array<Rig>){
        state.rigs = rigs;
    },
    addRig(state: IState, rig: Rig){
        state.rigs.push(rig)
    },
    addRigContract(state:IState,rigContract:any){
        let indexToUpdate = state.rigs.findIndex(r=> r.id==rigContract.rigId)
        
        if(rigContract.isActive){
            var currentActiveContractIndex = state.rigs[indexToUpdate].rigContracts.findIndex(rc=> rc.isActive)
            if(currentActiveContractIndex>-1){
                state.rigs[indexToUpdate].rigContracts[currentActiveContractIndex].isActive = false
            }
        }

        if(indexToUpdate>-1)state.rigs[indexToUpdate].rigContracts.push(rigContract)
    },
    updateRig(state: any, rig: any) {
        var indexToUpdate = null
        state.rigs.forEach((thisRig: any, index: any, rigArray: any) => {
            if (rig.id === thisRig.id) {
                indexToUpdate = index
            }
        })
        state.rigs.splice(indexToUpdate, 1, rig)
    },
    updateRigContract(state:IState,rigContract:any){
        let rigIndexToUpdate = state.rigs.findIndex(r=> r.id==rigContract.rigId)
        let contractIndexToUpdate = state.rigs[rigIndexToUpdate].rigContracts.findIndex(rc=>rc.id==rigContract.id)
        if(rigContract.isActive){
            var currentActiveContractIndex = state.rigs[rigIndexToUpdate].rigContracts.findIndex(rc=> rc.isActive&&(rc.id!=rigContract.id))
            if(currentActiveContractIndex>-1){
                state.rigs[rigIndexToUpdate].rigContracts[currentActiveContractIndex].isActive = false
            }
        }
        state.rigs[rigIndexToUpdate].rigContracts.splice(contractIndexToUpdate,1,rigContract)
    },
    deleteRig(state: IState, id: number){
        for(let index = 0; index < state.rigs.length; index++){
            if (state.rigs[index].id === id){
                state.rigs.splice(index, 1)
                break;
            } 
        }
    },
    deleteRigContract(state:IState,data:any){
        let rigIndexToUpdate = state.rigs.findIndex(r=> r.id==data.rigId)
        let contractIndexToUpdate = state.rigs[rigIndexToUpdate].rigContracts.findIndex(rc=>rc.id==data.id)
        state.rigs[rigIndexToUpdate].rigContracts.splice(contractIndexToUpdate,1)
    },
    activateRigContract(state:IState,data:any){
        let rigIndexToUpdate = state.rigs.findIndex(r=> r.id==data.rigId)
        var currentActiveContractIndex = state.rigs[rigIndexToUpdate].rigContracts.findIndex(rc=> rc.isActive&&(rc.id!=data.id))
        if(currentActiveContractIndex>-1){
            state.rigs[rigIndexToUpdate].rigContracts[currentActiveContractIndex].isActive = false
        }
        let contractIndexToUpdate = state.rigs[rigIndexToUpdate].rigContracts.findIndex(rc=>rc.id==data.id)
        state.rigs[rigIndexToUpdate].rigContracts[contractIndexToUpdate].isActive = true
    }
}

const getters={
    getRigById: (state: IState) => (id: number) => {
        let rig = null
        for (let index = 0; index < state.rigs.length; index++) {
            if (state.rigs[index].id == id) {
                rig = state.rigs[index];
                break;
            }
        }
        return rig
    },

    filteredRigs: (state: IState) => (filter: string) => {
        if(!state.rigs)return [];
        return state.rigs.filter((rig: any) => {
            return rig.name.toLowerCase().match(filter.toLowerCase())
        });
    },

    getSortedRigsOptions: (state: IState) => {
        if(!state.rigs) return [];
        return JSON.parse(JSON.stringify(state.rigs)).sort((a: any, b: any) => {
                    if (a.name < b.name) return -1
                    else if (a.name > b.name) return 1
                    return 0
                }).map((rig: any) => {
                    return {text: rig.name, value:rig.id}
                })      
    },
    
    getAllrigs:(state: IState) => {
        return JSON.parse(JSON.stringify(state.rigs)).sort((a: any, b: any) => {
            if (a.terrain < b.terrain) return -1
            else if (a.terrain > b.terrain) return 1
            return 0
        });
    }
}

const actions={
    getRigs({ commit } : { commit: any}) {
        return new Promise((resolve, reject) => {
            HTTP.get('rigs')
            .then((response: any) => {
                commit('setRigs', response.data.data)
                resolve(response)
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    },
    createRig({ commit }: {commit: any}, rig_object: any) {
        return new Promise((resolve, reject) => {
            HTTP.post('rigs/create', rig_object)
                .then((response: any) => {
                    commit('addRig', response.data.data)
                    resolve(response)
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    },
    updateRigAction({ commit }: {commit: any}, rig: any) {
        return new Promise((resolve, reject) => {
            HTTP.put('rigs/update/' + rig.id, rig.rig)
                .then((response: any) => {
                    commit('updateRig', response.data.data)
                    resolve(response)
                })
                .catch((error: any) => {
                    reject(error)
                });
        })
    },
    deleteRigAction({ commit } : {commit: any}, id: number) {
        return new Promise((resolve, reject) => {
            HTTP.delete('rigs/delete/' + id)
                .then((response: any) => {
                    commit('deleteRig', id)
                    resolve(response)
                })
                .catch((error: any) => {
                    reject(error)
                })
        })
    },
    createRigContract({commit}:{commit:any},rigContract:any){
        return new Promise((resolve,reject)=>{
            HTTP.post('rigs/contract/create',rigContract).then((response:any) => {
                commit('addRigContract',response.data.data) 
                resolve(response);
            })
        })
    },
    updateRigContract({commit}:{commit:any},rigContract:any){
        return new Promise((resolve,reject)=>{
            HTTP.put('rigs/contract/update/'+rigContract.id,rigContract)
            .then((response:any) => {
                commit('updateRigContract', response.data.data)
                resolve(response)
            })
            .catch((error:any)=>{
                reject(error)
            })
        })
    },
    deleteRigContract({ commit } : {commit: any}, data: any) {
        return new Promise((resolve, reject) => {
            HTTP.delete('rigs/contract/delete/' + data.id)
                .then((response: any) => {
                    commit('deleteRigContract', data)
                    resolve(response)
                })
                .catch((error: any) => {
                    reject(error)
                })
        })
    },
    activateRigContract({ commit } : {commit: any}, data: any) {
        return new Promise((resolve, reject) => {
            HTTP.patch('rigs/contract/activate/' + data.id)
                .then((response: any) => {
                    commit('activateRigContract', data)
                    resolve(response)
                })
                .catch((error: any) => {
                    reject(error)
                })
        })
    },
    uploadFile ({ commit }: { commit : any }, data: any) {     
        return new Promise((resolve, reject) => {
          HTTP.post('rigs/upload', data)
          .then((response: any) => {
            resolve(response)
          })
          .catch((error: any) => {
            reject(error)
          })
    
        })
    },
    getColumnNames ({ commit }: { commit : any }, payload:any) {     
        return new Promise((resolve, reject) => {-
          HTTP.get('rigs/colunmNames?fileName='+payload.fileName+'&sheetName='+payload.sheetName)
          .then((response: any) => {
            resolve(response)
          })
          .catch((error: any) => {
            reject(error)
          })
    
        })
    },
    createImportedRigs ({ commit }: { commit : any }, model:any) {     
        return new Promise((resolve, reject) => {-
          HTTP.post('rigs/createRigs',model)
          .then((response: any) => {
            response.data.data.rigs.forEach((rig:any) => {
                commit('addRig',rig)
            });
            resolve(response)
          })
          .catch((error: any) => {
            reject(error)
          })
    
        })
    },


}

const rigsModule={
    namespaced:true,
    state,
    mutations,
    getters,
    actions
}


export default rigsModule
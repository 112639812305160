import { HTTP } from '@/axios_api/http-common'

const state = {
    budgetTemplates : []
}

interface IState {
    budgetTemplates: any[]
}

const getters = {
    getAllTemplates (state: IState) {
        return state.budgetTemplates
    }
}

const mutations = {
    createBudgetTemplate(state: IState, payload: any) {
        state.budgetTemplates.push(payload);
    },
    editBudgetTemplate(state:IState, update: any) {
        for (let index = 0; index < state.budgetTemplates.length; index++) {
            if(state.budgetTemplates[index].id == update.id){
                state.budgetTemplates[index] = update;
                break;
            }
        }
    },
    setBudgetTemplate(state: IState,  budgetTemplates: any) {
        state.budgetTemplates = budgetTemplates
    },
    removeBudgetTemplate(state: IState, payload: any) {
        var _exIndex = state.budgetTemplates.indexOf(payload);
        if(_exIndex > -1)
            state.budgetTemplates.splice(_exIndex,1);
    },
}

const actions = {
    fetchBudgetTemplates({commit}: {commit: any}) {
        return new Promise((resolve, reject) => {
            HTTP.get('budgettemplate').then((response: any) => {
                commit('setBudgetTemplate', response.data.data )
                resolve(response)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    createNewBudgetTemplate({commit}: {commit: any}, payload: any) {
        return new Promise((resolve, reject) => {
            HTTP.post('budgettemplate/create', payload).then((response: any) => {
                commit('createBudgetTemplate', response.data.data)
                resolve(response)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    updateBudgetTemplate({commit} : {commit: any}, payload: any) {
        return new Promise((resolve, reject) => {
            HTTP.put('budgettemplate/update/' + payload.id, payload).then((response: any) => {
                window.console.dir(response.data.data)
                commit('editBudgetTemplate', response.data.data)
                resolve(response)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    deleteBudgetTemplate({commit}:{commit: any}, payload: any) { 
        return new Promise ((resolve, reject) => {
            HTTP.delete('budgettemplate/delete/'+ payload.id).then((response: any) => {
                commit('removeBudgetTemplate', payload)
                resolve(response)
            }).catch((error: any) => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
import { HTTP } from '@/axios_api/http-common' 
import { use } from 'vue/types/umd'

const state = {
  users: [],
}

interface IState {
  users: Array <any>
}

const mutations = {
  setUsers(state: IState, users: Array<any>) {
    state.users = users
  },
  addUser(state: IState,userToAdd: any){
    state.users.push(userToAdd)
  },
  updateUser(state: any, userToUpdate: any){
    let indexToUpdate: number | null = null
    state.users.forEach((thisUser: any,index: any,userArray: any)=>{
      if(thisUser.id===userToUpdate.id){
        indexToUpdate=index
      }
    })
    state.users.splice(indexToUpdate,1,userToUpdate)
  },
  deleteUser(state: any,id: any){
    let indexToDelete: number | null =null
    state.users.forEach((thisUser: any,index: any,userArray: any)=>{
      if(thisUser.id===id){
        indexToDelete=index
        return
      }
    })
    state.users.splice(indexToDelete,1)
  },
  manageNotificationSettings(state: IState,notificationSettings: any){
    notificationSettings.forEach((thisNotSetting: any) => {
      var userToUpdate = state.users.find((thisUser: any) => thisUser.id == thisNotSetting.userId)
      thisNotSetting.suscriptions.forEach( (thisSuscription: any) => {
        if(thisSuscription.isSuscribed&&!userToUpdate.suscribedNotifcations.includes(thisSuscription.notificationType)){
          userToUpdate.suscribedNotifcations.push(thisSuscription.notificationType)
        }
        if(!thisSuscription.isSuscribed&&userToUpdate.suscribedNotifcations.includes(thisSuscription.notificationType)){
          let indexToRemove = userToUpdate.suscribedNotifcations.indexOf(thisSuscription.notificationType)
          userToUpdate.suscribedNotifcations.splice(indexToRemove,1)
        }
      })
    })
  }


}

const getters = {
  fillteredUsers: (state: any) =>(sfilter: any, dept: any)=>{
    return state.users.filter((user: any) => {
      var fullName=user.firstName+" "+user.lastName
      return fullName.toLowerCase().match(sfilter.toLowerCase())&&(user.departmentId===dept||dept===-1)
    })
  },
  
  getUserById:(state: any)=>(id: any)=>{
    var userToReturn=null
    state.users.forEach((thisUser: any,index: any,userArray: any)=>{
      if(thisUser.id==id){
        userToReturn=thisUser
      }
    })
    return userToReturn
  }
  
}

const actions = {

  getUsers({ commit }:  {commit: any}) {
    return new Promise((resolve, reject) => {
      HTTP.get('users').then((response: any) => {                         
        commit('setUsers', response.data.data)	
        resolve(response)    
      })
      .catch((error: any) => {
        reject(error)
      })
    })         
  },

  createUser({commit} : {commit: any},userToCreate: any){
    return new Promise((resolve,reject)=>{
      HTTP.post('users/create',userToCreate).then((response: any)=>{
        commit('addUser',response.data.data)
        resolve(response)
      }).catch((error: any)=>{
        reject(error)
       
      })
    })
  },

  updateUserPermissions({ commit } : {commit: any},permissions: any,){
    return new Promise((resolve, reject) => {
     HTTP.post('users/userpermissions',permissions).then((response:any) => {
          resolve(response);
      }, (error:any) => {
          reject(error);
      })
  })
  },

  getUserRoles({ commit } : {commit: any}, userId: any){
    return new Promise((resolve, reject) => {
      HTTP.get('users/userroles/?userId='+userId).then((response:any) => {
          resolve(response);
      }, (error:any) => {
          reject(error);
      })
  })
},

getUserPermissions({ commit } : {commit: any}, userId: any){
  return new Promise((resolve, reject) => {
    HTTP.get('users/userpermissions/?userId='+userId).then((response:any) => {
        resolve(response);
    }, (error:any) => {
        reject(error);
    })
})
},
updateUserRoles({ commit } : {commit: any},roles: any,){
  return new Promise((resolve, reject) => {
    HTTP.post('users/userroles',roles).then((response:any) => {
        resolve(response);
    }, (error:any) => {
        reject(error);
    })
})
},
updateUser ({ commit } : {commit: any}, data: any) {
  return new Promise((resolve, reject) => {
      HTTP.put('users/update/'+ data.id, data.user )
      .then((response: any) => {
          commit('updateUser',response.data.data)
          resolve(response)
      })
      .catch((error: any) => {
          reject(error)
      });
  })        
},
changePassword({ commit }: { commit: any},data:any){
  return new Promise((resolve, reject) => {
    let token = localStorage.dsodsessiontoken 
    HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + token 
    HTTP.put('users/changepassword',data).then((response:any) => {
      resolve()
    }).catch( (error: any) => {
      reject(error)
    })
  })
}, 

  deleteUser ({ commit } : {commit: any}, id: any) {
    return new Promise((resolve, reject) => {
        HTTP.delete('users/delete/' +  id)
        .then((response: any) => {         
            commit('deleteUser', id)
            resolve(response)
        })
        .catch((error: any) => {
            reject(error)
        })
    })      
  },

  manageSuscription({commit}: {commit: any}, notificationSettings: any){
    return new Promise((resolve,reject) => {
      HTTP.post('notifications/managesuscription',notificationSettings)
      .then((response: any) => {
        commit('manageNotificationSettings',notificationSettings)
        resolve(response)
      })
      .catch((error: any) => {
        reject(error)
      })
    })

  },
  uploadFile ({ commit }: { commit : any }, data: any) {     
    return new Promise((resolve, reject) => {
      HTTP.post('users/upload', data)
      .then((response: any) => {
        resolve(response)
      })
      .catch((error: any) => {
        reject(error)
      })

    })
},
getColumnNames ({ commit }: { commit : any }, payload:any) {     
    return new Promise((resolve, reject) => {-
      HTTP.get('users/colunmNames?fileName='+payload.fileName+'&sheetName='+payload.sheetName)
      .then((response: any) => {
        resolve(response)
      })
      .catch((error: any) => {
        reject(error)
      })

    })
},
createImportedUsers ({ commit }: { commit : any }, model:any) {     
    return new Promise((resolve, reject) => {-
      HTTP.post('users/createUsers',model)
      .then((response: any) => {
        response.data.data.users.forEach((user:any) => {
            commit('addUser',user)
        });
        resolve(response)
      })
      .catch((error: any) => {
        reject(error)
      })

    })
},
generatePassword ({ commit }: { commit : any }, userName:any) {     
  return new Promise((resolve, reject) => {-
    HTTP.put('users/lockUser?userName='+userName)
    .then((response: any) => {
      resolve(response)
    })
    .catch((error: any) => {
      reject(error)
    })

  })
},
lockUser ({ commit }: { commit : any }, userName:any) {     
  return new Promise((resolve, reject) => {-
    HTTP.put('users/lockuser?userName='+userName)
    .then((response: any) => {
      commit('updateUser',response.data.data)
      resolve(response)
    })
    .catch((error: any) => {
      reject(error)
    })

  })
},
unlockUser ({ commit }: { commit : any }, userName:any) {     
  return new Promise((resolve, reject) => {-
    HTTP.put('users/unlockUser?userName='+userName)
    .then((response: any) => {
      commit('updateUser',response.data.data)
      resolve(response)
    })
    .catch((error: any) => {
      reject(error)
    })

  })
},
  
}

const usersModule = {
  namespaced:true,
  state,
  mutations,
  getters,
  actions
}

export default usersModule;

import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginComponent from '../views/authentication/Login.vue'
import store from '@/store'
import { HTTP } from '@/axios_api/http-common' 
import Dashboard from '../views/Layout/DashboardContainer.vue'
import UsersOverview from '../views/Users/overview.vue'
import DepartmentsOverview from '../views/Departments/overview.vue'
import WellsOverview from '../views/Wells/overview.vue'
import RigsOverview from '../views/Rigs/overview.vue'
import DependenciesOverview from '../views/Dependencies/overview.vue'
import WellActivitiesOverview from '../views/Operations/WellActivities/overview.vue'
import FundingOverview from '../views/Funding/overview.vue'
import ManageUserComponent from '../views/Users/manageUser.vue'
import ManageDepartmentComponent from '../views/Departments/manageDepartments.vue'
import ManageRigComponent from '../views/Rigs/manageRigs.vue'
import ManageWellComponent from '../views/Wells/manageWells.vue'
import ManageDependencyComponent from '../views/Dependencies/manageDependency.vue'
import ManageActivityComponent from '../views/Operations/WellActivities/manageWellActivities.vue'
import ManageActivityTemplateComponent from '../views/Operations/WellActivities/manageWellActivityTemplate.vue'
import ManageFundingComponent from '../views/Funding/manageFunding.vue'
import SequenceCatalogComponent from '../views/SequenceCatalog/sequenceCatalogPage.vue'
import SequenceMain from '../views/Chart/SequenceMain.vue'
import ProfileComponent from '../views/Profile/overview.vue'
import BudgetingLinesComponent from '../views/Budgeting/budgetingLines.vue'
import BudgetTemplateComponent from '../views/Budgeting/budgetingTemplate.vue'
import NPTOverviewComponent from '../views/Operations/npt/overview.vue'
import ManageNPTComponent from '../views/Operations/npt/manageNpt.vue'
import ColorSettings from '../views/ColorSettings/overview.vue'
import UnitSettings from '../views/units/UnitSettings'
import Operations from '../views/Operations/operations.vue'
import Analytics from '../views/Analytics/Analytics.vue'
import ApplicationLog from '../views/ApplicationLog/applicationLog.vue'
import ChangePassword from '../views/authentication/ChangePassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginComponent
  },
  {
    path: '/login',
    name: 'login',
    component: LoginComponent
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: ChangePassword
  },
  {
    path: '/home',
    component: Dashboard,
    meta: { requiresAuth: true},
    children: [
      {
        path: '/users',
        component: UsersOverview
      },
      {
        path: '/application-log',
        component: ApplicationLog
      },
      {
        path: '/manage-user',
        component: ManageUserComponent
      },
      {
        path: '/departments',
        component: DepartmentsOverview
      },
      {
        path: '/manage-department',
        component: ManageDepartmentComponent
      },
      {
        path: '/rigs',
        component: RigsOverview
      },
      {
        path: '/manage-rig',
        component: ManageRigComponent
      },
      {
        path: '/wells',
        component: WellsOverview
      },
      {
        path: '/manage-well',
        component: ManageWellComponent
      },
      {
        path: '/sequences',
        component: SequenceCatalogComponent
      },
      {
        path:'/analytics',
        component:Analytics
      },
      {
        path: '/dependencies',
        component: DependenciesOverview
      },
      {
        path: '/manage-dependency',
        component: ManageDependencyComponent
      },
      // {
      //   path: '/well-activities',
      //   component: WellActivitiesOverview
      // },
      {
        path: '/operations',
        component: Operations
      },
      {
        path: '/manage-well-activity',
        component: ManageActivityComponent
      },
      {
        path: '/manage-well-activity-template',
        component: ManageActivityTemplateComponent
      },
      {
        path: '/fundings',
        component: FundingOverview
      },
      {
        path: '/manage-funding',
        component: ManageFundingComponent
      },
      {
        path: '/sequence/:sequenceId',
        component: SequenceMain,
        props: true
        /*
        children:[
          {
            path:"sequence/:sequenceId",
            component:ProjectChart,
            props: true
          },
          {
            path:"activities/:projectId",
            component:ActivityChart,
            props: true
          }
        ]
        */
      },
      {
        path: '/profile',
        component: ProfileComponent
      },
      {
        path: '/budgeting-lines',
        component: BudgetingLinesComponent
      },
      {
        path: '/budget-templates',
        component: BudgetTemplateComponent
      },
      // {
      //   path: '/npt-operations',
      //   component: NPTOverviewComponent
      // },
      {
        path: '/manage-npt-operations',
        component: ManageNPTComponent
      },
      {
        path: '/chart-settings',
        component: ColorSettings
      },
      {
        path:'/unit-settings',
        component:UnitSettings
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    let activeUser: any = store.state.activeUser;
    if(activeUser==null)
    {
      if (localStorage.dsodsessiontoken&&localStorage.dsodsessionid) {
        HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.dsodsessiontoken
        HTTP.get("users/"+localStorage.dsodsessionid).then((response: any)=>{
          let returnedUser = response.data.data;
          store.commit('setActiveUser', returnedUser)
          next()
        }).catch((e: any)=>{
          next({path:'/login',query:{nextUrl: to.fullPath}})
        })
      }else{
        next({path:'/login',query:{nextUrl: to.fullPath}})
      }
    }else{
      // if(localStorage.dsodsessionid=="" && localStorage.dsodsessiontoken==""){
      //   next({path:"/"})
      // }
      if(to.matched.some(routeRecord=> typeof routeRecord.meta.requiresRole!=='undefined')){
        to.matched.forEach((thisRecord,index,allRecord)=>{  
          if(to.fullPath.split("/?")[0]===thisRecord.path){ 
            if(activeUser.roles.indexOf(thisRecord.meta.requiresRole)>-1){ 
              next()
            }else{
              next({path:"/unauthorized"})
            }
          }
        })  
      }
      else{
        next()
      }
    }
  } else {
    next()
  }

})


export default router

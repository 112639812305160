





































































































































































































































































































































































































import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import printer from '@/print'
import ProjectHeatMap from './Modals/ProjectHeatMap.vue'
import SwapStates from '../../utils/SwapState.js'
import { createPopper } from '@popperjs/core';
import convertUnit from '@/utils/convertUnit.ts'
import SequencePrinter from '@/mixins/SequencePrinter.ts'

let popperInstance = null as any;
let tooltip = null as any

@Component({
  components: {
   'heat-map' :ProjectHeatMap
  },
  filters:{
    budget:(value:any)=>{
      return "K$ "+parseFloat(value).toFixed(2);
    }
  },
  mixins:[SequencePrinter]
})
export default class Chart extends Vue {
  convertUnits(value: any, typex:any, unit: any) {
    return convertUnit(value, typex, unit)
  }

  printStatus: any = {
    all: false,
    number: 1
  }

  @Prop()
  readOnlyView!:any

  @Prop()
  sequenceId !: any

  @Prop()
  currentHeight !: any 

  @Prop()
  currentWidth !:any

  showMailerForm = false

  isWBS = false

  viewMode:string = "rig"

  showFundingMechnism = true
  showWBS = false
  historyMode = false
  showHeatMap=false
  filterId : any
  showDeleteModal=false
  lastStartDate:any=null
  activeHeatMapFile = null;

  tooltipFocused:boolean = false

  toolTipLostFocus(){
    this.tooltipFocused = false;
    this.destroyTooltip()
  }

  allowCascadeStateBackup:string = ""
  starts:any = new Date(this.sequenceObject.startYear, 0, 1)
  ends:any = new Date(this.sequenceObject.endYear, 11, 31)
  set start (year:any) {
    this.starts = new Date(this.sequenceObject.startYear, 0, 1)
  }
  get start () {
    return this.starts
  }
  set end (year: any) {
    this.ends = new Date(this.sequenceObject.endYear, 11, 31)
  }
  get end () {
    return this.ends
  }
  get printRules () {
    const  numberOfYears = this.sequenceObject.endYear - this.sequenceObject.startYear + 1
    if (!this.printStatus.all && (this.printStatus.number == 0 || this.printStatus.number > numberOfYears)) return true
    return false
  }

  get applyClass () {
    return (result: any) => {
          if(result.preprocessValue){
              if(this.checkGreenCodeTask(result)){       
                return {background: '#2eaa3e'};
              }
              if(this.checkGrayCodeTask(result)){
                return {background: '#6C757D'};
              }
              if(this.checkYellowCodeTask(result)){
                return {background: '#FFC107'};
              }
              if(this.checkRedCodeTask(result)){
                return {background: '#DC3545'};
              }
          }
      }
  }

  get activeUser() {
    return this.$store.getters['activeUser']
  }

  get messageBody():string{
    let message = "\nSEPAL DIAP NOTIFICATION,\n\n"+this.activeUser.firstName+' '+this.activeUser.lastName+
                        ' sent a file.'
    return message
  }

  timeArray: Array<any> = [
    {text: 'Year', value: 'YEAR'},
    {text: 'Month', value: 'MONTH'},
    {text: 'Week', value: 'WEEK'},
  ]

  projectSheetView: boolean = false
  
  timeUnit: string = 'WEEK'
  chartOptions: any = {
    legend: true,
    constraints: {
      casecadeWest: false,
      cascadeEast: true,
      allowInteraction: this.readOnlyView?false:{checkType:'START_AFTER'},
      boundWest:true,
      boundEast:true,
      allowDrawing:!this.readOnlyView
    }
  }

  defaultWell: any = {
    name:"Not Yet Selected",
    id:0,
    wellType: 'DEFAULT'
  }

  defaultRig:any = {
    name:"Not Yet Selected",
    id:0,
    terrain: 'DEFAULT' 
  }

  rigs: Array<any> = []
  selected: Array<any> = []
  selectedWells: Array<any> = []
  sortedRigs: Array<any> = []
  sortedWells:Array<any> = []
  checkRigs: Array<any> = []
  checkWells: Array<any> = []

  hoveredBar:any=null

  info:any = {
    id: '',
    cost:"",
    class:"",
    name:"",
    well:{},
    projectCopyState:"",
    funding:{},
    Objective:"",
    moveInProgress:false,
    incomingResourceId:"",
    incommingStart:"",
    resourceName:"",
    resourceId:"",
    rigTerrain:"",
    projectCopyErrorMessage:"",
    cloneInProgress:false,
    row:{},
    preProcesses:[] as Array<any>,
    deptDataIntegrity:[] as Array<any>,
    activities:[] as Array<any>
  } 

  sendHeatMapInMail(data:any){
    this.showHeatMap = false
    this.showMailerForm = true
    window.console.log(data)
    this.activeHeatMapFile = data.file

  }

  addDays(date:string, daysToAdd:any) {
    var result = null
    if(typeof(date)=="string"){
      let splitedDate = date.split('-')
      result = new Date(Number.parseInt(splitedDate[0]),Number.parseInt(splitedDate[1])-1,Number.parseInt(splitedDate[2]));
    }else{
      result = date
    }
    if(daysToAdd=="") return result
    result.setDate(result.getDate() + Number.parseInt(daysToAdd));
    return result;
  }

  startProjectMove(){
    this.info.moveInProgress = true
    this.$bvToast.toast('Project move ready.', {
      title: 'Please Note!!!',
      autoHideDelay: 5000,
      appendToast: true,
      toaster: 'b-toaster-bottom-right',
      solid: true,
      variant: 'success'
    })
    this.destroyTooltip()
  }

  startProjectClone(){
    this.info.cloneInProgress = true
    this.$bvToast.toast('Project clone ready.', {
      title: 'Please Note!!!',
      autoHideDelay: 5000,
      appendToast: true,
      toaster: 'b-toaster-bottom-right',
      solid: true,
      variant: 'success'
    })
    this.destroyTooltip()
  }

  doProjectMoveCancel(){
    this.info.moveInProgress = false
    this.$bvModal.hide("project-move-modal")
  }

  doProjectCloneCancel(){
    this.info.cloneInProgress = false
    this.$bvModal.hide("project-clone-modal")
  }

  doProjectMove(){
    this.info.moveInProgress = false
    let projectToMove = this.projects.find((p:any) => p.id == this.info.id)
    
    if(this.info.resourceId!=this.info.incomingResourceId){
      if(this.info.resourceType=="RIG"){
        let rigToSwapTo = this.sequenceObject.rigs.find((r:any) => this.info.incomingResourceId == r.id) 
        this.$store.commit("projectModule/updateProject",{sequenceId:this.sequenceObject.id,id:this.info.id,rig:rigToSwapTo})
      }else{
        let riglessBucketToSwapTo = this.sequenceObject.riglessBuckets.find((r:any) => this.info.incomingResourceId == r.id) 
        this.$store.commit("projectModule/updateProject",{sequenceId:this.sequenceObject.id,id:this.info.id,riglessBucket:riglessBucketToSwapTo})
      }
    }
    let newEndDate:any = this.addDays(this.info.incommingStart,this.computeDuration(projectToMove.start,projectToMove.end));
    newEndDate = newEndDate.getFullYear()+"-"+((newEndDate.getMonth()+1)<10?('0'+(newEndDate.getMonth()+1)):newEndDate.getMonth()+1)+"-"+(newEndDate.getDate()<10?('0'+newEndDate.getDate()):newEndDate.getDate())
    this.chartOptions.constraints.cascadeEast = false;
    this.$nextTick(()=>{
      this.$store.commit("projectModule/updateProject",{sequenceId:this.sequenceObject.id,id:this.info.id,start:this.info.incommingStart,end:newEndDate})
      this.chartOptions.constraints.cascadeEast = false;
      this.$bvModal.hide("project-move-modal")
    })
    let days = this.getDateDifferenceInDays(this.lastStartDate,this.info.incommingStart)
    this.adjustActivities(projectToMove.id,days);
  }

  get currentSystem(){
    return this.$store.getters['UnitsModule/getCurrentUnitSystem']
  }
  
  doProjectClone(){
    this.info.cloneInProgress = false
    let projectToClone = this.projects.find((p:any) => p.id == this.info.id)
    let daysDifference = this.getDateDifferenceInDays(projectToClone.start,this.info.incommingStart)
    let newEndDate:any = this.addDays(this.info.incommingStart,this.computeDuration(projectToClone.start,projectToClone.end));
    this.drawingEnd({
      startDate:new Date(this.info.incommingStart),
      endDate:newEndDate,
      row:this.info.row
    })
    const createdProject = this.projects[this.projects.length-1]
    projectToClone.activities.forEach((a:any) => {
      this.$store.dispatch("ActivityModule/addActivity",{sequenceId:this.sequenceId,projectIndex:this.projects.length-1 ,newActivity:{
        name:a.name,
        departmentId:a.departmentId,
        start: this.addDays(a.start,daysDifference),
        end: this.addDays(a.end,daysDifference),
        milestones:a.milestones.map((m:any) => ({id:m.id,name:m.name})),
        wellActivity:a.wellActivity,
        supportTeamsIds:a.supportTeamsIds,
      }})
      const createdActivity = this.projects[this.projects.length-1].activities[createdProject.activities.length-1]
      this.$store.dispatch("MilestoneModule/getAndCreateMilestonesForActivity",{activityId:createdActivity.id,sourceActivityId:a.id,days:daysDifference,hasServerData:(a.milestones.length>0)})
      
      if(a.budget&&a.budget!=null){
        this.$store.dispatch("ActivityModule/updateActivityBudget",{projectIndex:this.projects.length-1,projectId:projectToClone.id,sequenceId:this.sequenceId,id:createdActivity.id,budgetTemplateId:a.budget.budgetTemplateId})
        a.budget.budgetLines.forEach((bl:any) => {
          this.$store.dispatch('ActivityModule/updateActivityBudget',{sequenceId:this.sequenceId,projectIndex:this.projects.length-1,id:createdActivity.id,sectionId:"",lineItem:{lineValue:bl.lineValue,lineItemId:bl.lineItemId}})
        });
      }
      
    });
    this.$bvModal.hide('project-clone-modal')
  }

  checkGreenCodeTask(preprocess:any):boolean{
      let results = ["Yes","COMPLETED","100"]
      if((preprocess.maxValue == 0 && preprocess.minValue==0 && results.includes(preprocess.preprocessValue))||
          (preprocess.maxValue>preprocess.minValue && preprocess.maxValue== preprocess.preprocessValue)){
              return true
      }
      return false
  }

  checkRedCodeTask(preprocess:any):boolean{
      let results = ["No","AT_RISK","0"]
      if((preprocess.maxValue == 0 && preprocess.minValue==0 && results.includes(preprocess.preprocessValue))||
          (preprocess.maxValue>preprocess.minValue &&  preprocess.preprocessValue<=preprocess.minValue)){
              return true
      }
      return false
  }

  checkYellowCodeTask(preprocess:any):boolean{
      let results = ["IN PROGRESS"]
      if((preprocess.maxValue == 0 && preprocess.minValue==0 && (preprocess.preprocessValue== "IN PROGRESS" || (preprocess.preprocessValue<100 &&preprocess.preprocessValue>0))) ||
          (preprocess.maxValue>preprocess.minValue && preprocess.maxValue > preprocess.preprocessValue && preprocess.minValue < preprocess.preprocessValue )){
              return true
      }
      return false
  }

  checkGrayCodeTask(preprocess:any):boolean{
      if(preprocess.preprocessValue == "SUSPENDED"){
          return true
      }
      return false
  }

  destroyTooltip(){
    tooltip.removeAttribute('data-show');
    if (popperInstance) {
        popperInstance.destroy();
        popperInstance = null;
    }
  }
   deleteAllProjects() { 
     this.$store.commit("setRightPaneToFalse")
     let tempList = this.projects.slice()
     for (let index = 0; index < tempList.length; index++) {
       this.$store.commit('projectModule/deleteProject', tempList[index])     
     }
    }
  getPlanBudgetForProject(activities:Array<any>){
    let totalBudget = 0
    activities.forEach((activity:any) => {
      if(activity.budget){
        activity.budget.budgetLines.forEach((budgetLine:any) => {
          totalBudget+=budgetLine.lineValue
        })
      }
    });
    return totalBudget
  }
  barMouseEnter(data:any){
    if(this.info.moveInProgress||this.info.cloneInProgress) return
    let hoveredProject = this.projects.find((p:any) => p.id == data.id)
    this.lastStartDate =hoveredProject.start;
    this.info.cost = this.getPlanBudgetForProject(hoveredProject.activities)
    this.info.name = hoveredProject.name
    this.info.funding = hoveredProject.funding
    this.info.start = hoveredProject.start
    this.info.objective = hoveredProject.objective
    this.info.class = hoveredProject.projectType
    this.info.preProcesses = hoveredProject.preProcesses?hoveredProject.preProcesses:[]
    this.info.id = hoveredProject.id
    this.info.well = hoveredProject.well
    this.info.activities = hoveredProject.activities

    if(hoveredProject.rig!=null){
      this.info.resourceId = hoveredProject.rig.id
      this.info.resourceName = hoveredProject.rig.name
      this.info.resourceType = "RIG"
      this.info.rigTerrain = hoveredProject.rig.terrain
    }else{
      this.info.resourceId = hoveredProject.riglessBucket.id
      this.info.resourceName = hoveredProject.riglessBucket.alias
      this.info.resourceType = "RIGLESS"
    }
    
    
    const bar = (this.$refs[data.id] as any).$el 
    tooltip.setAttribute('data-show', '');
    popperInstance = createPopper(bar, tooltip, {
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    });
  }
  barMouseLeave(){
    setTimeout(()=>{
      if(!this.tooltipFocused)this.destroyTooltip()
    },100)
  }
  computeStroke(funding:any){
    if(funding==null){
      return {color:'',type:''}
    }else{
      return {color:'#'+funding.lineColor,type:funding.lineStyle.toLowerCase()}
    }
  }
  computeDuration(start:string,end:string){
    if(start==""||end=="")return 0
    let startDateMill = (new Date(start)).getTime()
    let endDateMill = (new Date(end)).getTime()
    let diff = endDateMill - startDateMill
    if(diff<1)return 0
    return Math.round(diff/(1000 * 3600 * 24))
  }
  computeSections(projectToSection:any){
    let visibleActivityIds:Array<any> = []
    if(projectToSection.activities&&projectToSection.activities.length>0){
      let sections:Array<any> = []
      let totalDays = this.computeDuration(projectToSection.start,projectToSection.end)
      projectToSection.activities.slice().sort((a1:any,a2:any) => {
        const a1Start = new Date(a1.start).getTime()
        const a2Start = new Date(a2.start).getTime()
        if(a1Start==a2Start) return 0
        if(a1Start<a2Start) return -1
        if(a1Start>a2Start) return 1
      }).filter((a:any) => a.departmentId == 3).forEach((a:any) => {
        visibleActivityIds.push(a.wellActivity.id)
        let sectionDays = Math.round((this.computeDuration(a.start,a.end)/totalDays)*100)
        sections.push({color:'#'+a.wellActivity.color,width:sectionDays})
      })
      this.$emit('visible-activities-changed',visibleActivityIds)
      return sections
    }else{
      return []
    }
  }
  computePrintSections(projectToSection:any){
    let visibleActivityIds:Array<any> = []
    if(projectToSection.activities&&projectToSection.activities.length>0){
      let sections:Array<any> = []
      let totalDays = this.computeDuration(projectToSection.start,projectToSection.end)
      let activitiesToProcess:Array<any> = []
      projectToSection.activities.filter((a:any) => a.departmentId == 3).forEach((a:any) => {
        const activityStartDate = new Date(a.start)
        const activityEndDate = new Date(a.end)
        const projectStartDate = new Date(projectToSection.start)
        const projectEndDate = new Date(projectToSection.end)
        if(activityStartDate.getTime()<=projectEndDate.getTime()&&activityEndDate.getTime()>projectStartDate.getTime()){
          activitiesToProcess.push({...a,start:activityStartDate.getTime()<projectStartDate.getTime()?projectStartDate:activityStartDate,end:activityEndDate.getTime()>projectEndDate.getTime()?projectEndDate:activityEndDate})
        }
      })
      
      activitiesToProcess.sort((a1:any, a2:any) => {
        const a1Start = new Date(a1.start).getTime()
        const a2Start = new Date(a2.start).getTime()
        return a1Start - a2Start
      }).forEach((a:any) => {
        visibleActivityIds.push(a.wellActivity.id)
        let sectionDays = this.computeDuration(a.start,a.end)
        sections.push({color:'#'+a.wellActivity.color,width:sectionDays,activityName:a.wellActivity.name})
      })
      this.$emit('visible-activities-changed',visibleActivityIds)
      return sections
    }else{
      return []
    }
  }
  get_report_projects() {
    let allprojects = this.projects
    var crossing_projects = allprojects.filter((x: any) => new Date(x._start).getFullYear() != new Date(x._end).getFullYear())
    crossing_projects.map((x: any) => x.is_crossing = true)
    allprojects.push(...crossing_projects);
    return allprojects;
  }
  getProjectsForRigOrRigless(rigId: any, projects: any){
    return projects.filter((project:any) => {
        if(project.isRigless)return project.riglessBucket.id == rigId
        else return project.rig.id == rigId 
        //return (!project.isRigless && project.rig.id == rigId)||(project.riglessBucket.id == rigId)
    })
  }
  getProjectsForYear(consideredDate: any) {
      var maxDateFromYear = new Date(consideredDate.getFullYear() + 1, consideredDate.getMonth(), 1)
      return this.projects.filter((project:any) => {
          let actualProjectStart = new Date(project.start)
          return (actualProjectStart < maxDateFromYear) && ((new Date(project.end)) > consideredDate)
      })
  }
  getProjectsBetweenYears(minDate: any, maxDate: any) {
    const maxDateFromYear = new Date(maxDate.getFullYear(), maxDate.getMonth(), 31)
    return this.projects.filter((project: any) => {
      let actualProjectStart = new Date(project.start)
      return (actualProjectStart < maxDateFromYear) && ((new Date(project.end)) > minDate)
    })
  }
  get visibleTimeUnits(): Array<any>{
     return [
      {
        timeUnit:"CURRENT",
        options:{
          fill:'yellow',
          textColor:'blue',
          stroke:'white'
        }
      },
      {
        timeUnit: "YEAR",
        options:{
          fill:this.settings[8]?'#'+this.settings[8].value:'black',
          textColor: 'white',
          stroke: 'white'
        }
      },
      {
        timeUnit:"MONTH",
        options: {
          fill:this.settings[9]?'#'+ this.settings[9].value:'black',
          textColor: 'white',
          stroke: 'white'
        }
      },
      {
        timeUnit:"WEEK",
        options: {
          fill:this.settings[10]?'#'+ this.settings[10].value:'black',
          textColor: 'white',
          stroke: 'white'
        }
      },
    ]
  }
  get extractor(){
    if(this.viewMode=='rig')return (p:any)=>p.isRigless?p.riglessBucket:p.rig
    return (p:any)=>p.well
  }
  get filteredRigs(){
    if(this.viewMode=="well")return undefined
    let rigsToReturn: Array<any> = []
    this.selected.forEach((thisCheckedRig,indexo,thisArray) =>{
      let valToUse = thisCheckedRig<=indexo?thisCheckedRig:indexo
      rigsToReturn.splice(valToUse,0,this.viewMode=='rig'?this.sortedRigs[thisCheckedRig]:this.sortedWells[thisCheckedRig])
    })
    return rigsToReturn;
  }
  get projects(){
    return this.$store.getters['projectModule/getProjectsForSequence'](this.sequenceId)
  }
  get getProjectChangesValidity(){
    return this.$store.getters['projectModule/getProjectChangesValidity']
  }
  get sidenav():any{
    return this.$store.getters['getSideNavStatus']
  }
  get rightPane():any{
    return this.$store.getters['getPaneStatus']
  }
  get sequenceObject() {
    return this.$store.getters['sequencesModule/getSequence'](this.sequenceId)
  }
  get rigById () {
    return this.$store.getters['rigsModule/getRigById']
  }
  get getAllrigs () {
    return this.$store.getters['rigsModule/getAllrigs']
  }
  get getDepartmentOptions () {
    return this.$store.getters['departmentsModule/getDepartmentOptions']
  }
  get settings () {
    return this.$store.getters['settingsModule/getSettings']
  }

  get annotationSettings () {
    return this.settings.filter((x:any)=> x.value == "ANNOTATION")
  }

  computedAnnotationSettings (project:any) {
    return this.annotationSettings.map((x:any) => {
      const settingData = x.meta.split("/")
      const settingsObject = {id:"",backgroundColor:"", textColor:"",comp:"", start:"", end:"", yOffset:0,xOffset:0, inverted:false, text:"", showSetting:true, fill:""}
      const options = settingData[1]
      const states = this.convertStateStates(settingData[2],settingData[4],settingData[5])
      const presentation = settingData[3]

      settingsObject.id = x.id
      settingsObject.comp = this.convertPresentationToGantComponent(settingData[3])
      if(presentation=="TRIANGLE_INVERTED"){
        settingsObject.inverted == true
      }
      
      switch(x.key){
        case "TOP_LEFT_ANNOTATION":
          settingsObject.yOffset =-(0.3*95)
          settingsObject.start = project.start
          break;
        case "TOP_RIGHT_ANNOTATION":
          settingsObject.yOffset = -(0.3*95)
          settingsObject.start = project.end
          if(presentation=='RECTANGLE') settingsObject.xOffset = 20
          if(presentation=='TRIANGLE')
          {
            settingsObject.xOffset = 2.5
            settingsObject.yOffset = -(0.28*95)
          }
          break;
        case "BOTTOM_LEFT_ANNOTATION":
          settingsObject.yOffset = 0.3*95
          settingsObject.start = project.start
          break;
        case "BOTTOM_RIGHT_ANNOTATION": 
          settingsObject.yOffset = 0.3*95
          settingsObject.start = project.end
          if(presentation=='RECTANGLE') settingsObject.xOffset = 20
          if(presentation=='TRIANGLE') settingsObject.xOffset = 2
        break;
      }

      switch(settingData[0]){
        case "ESTIMATED_PRODUCTION":
          if(project.estimatedProduction == "")settingsObject.showSetting = false
          else{
            settingsObject.text = project.estimatedProduction+'bbl/day'
            settingsObject.fill = 'red' //update here later to use color to show different things
          }
          break;
        case "BUDGET":
            settingsObject.text = 'K$'+this.getPlanBudgetForProject(project.activities)
            settingsObject.fill = 'green' //update here for settings on conditionals and approval
          break;
        case "PLANNING_ITEM":
          if(!project.preProcesses){
            settingsObject.showSetting = false
            return 
          }
          var planningItemInstance = project.preProcesses.find((p:any) => p.name == options)
          if(planningItemInstance){
           var stateToProcess = states.find((s:any) => s.state == planningItemInstance.preprocessValue)
           if(stateToProcess) settingsObject.fill = stateToProcess.background
           else settingsObject.fill = 'grey'
          }else{
            settingsObject.showSetting = false
          }
          break;
        case "PROJECT_STATE":
          if(project.preProcesses.length == 0) settingsObject.fill = 'grey'
          else {
            var allBooleanPreProcesses = project.preProcesses.filter((p:any) => p.valueType == 'BOOLEAN')
            var allRangeValues = project.preProcesses.filter((p:any) => p.valueType == 'RANGE')
            var allPercentageValues = project.preProcesses.filter((p:any) => p.valueType == 'PERCENTAGE')
            var allStatusValues = project.preProcesses.filter((p:any) => p.valueType == 'STATUS')

            if(
              allBooleanPreProcesses.every((bp:any) => bp.preprocessValue == 'Yes') && 
              allRangeValues.every((rv:any) => rv.maxValue==rv.preprocessValue) &&
              allPercentageValues.every((pv:any) => pv.preprocessValue == 100) &&
              allStatusValues.every((sv:any) => sv.preprocessValue == 'COMPLETED') 
              ){
                settingsObject.fill = 'green'
            }else{
              settingsObject.fill = 'red'
            }
          }
          break;
      }


      return settingsObject
    })
  }
  
  
  get totalBudgetForProject(){
    let totalBudget = 0
    this.projects.forEach((project:any) => {
      project.activities.forEach((activity:any) => {
        if(activity.budget){
          activity.budget.budgetLines.forEach((budgetLine:any) => {
            totalBudget+=budgetLine.lineValue
          })
        }
      });
    })
    return totalBudget
  }

  convertPositionToReadablePosition(passedPosition:string){
    switch(passedPosition){
      case "TOP_LEFT_ANNOTATION": return "Top Left Annotation"
      case "TOP_RIGHT_ANNOTATION": return "Top Right Annotation"
      case "BOTTOM_LEFT_ANNOTATION": return "Bottom Left Annotation"
      case "BOTTOM_RIGHT_ANNOTATION": return "Budget"
    }
    return ""
  }

  convertStateStates(state:string, stateBackgrounds: string, stateTextColors:string){
    if(state.includes("*")){
      const statesToReturn:Array<any> = []
      const states = state.split("*")
      const stateBackgroundsData = stateBackgrounds.split("*")
      const stateTextColorsData =  stateTextColors.split("*")
      states.forEach((s,i) => {
        statesToReturn.push({
          state:s,
          background:stateBackgroundsData.length>i?stateBackgroundsData[i]:"white",
          textColor:stateTextColorsData.length>i?stateTextColorsData[i]:"black"
        })
      })
      return statesToReturn
    }else{
      return [{state,background:stateBackgrounds,textColor:stateTextColors}]
    }
  }

  convertPresentationToGantComponent(presentation: string){
    switch(presentation){
      case "RECTANGLE": return 'bar'
      case "CIRCLE": return 'gantt-circle'
      case "TRIANGLE":  return 'gantt-triangle'
      case "TRIANGLE-INVERSED": return 'gantt-triangle'
      default: return ""
    }
  }

  takeRightPane(data: any) {
    if(this.info.moveInProgress || this.info.cloneInProgress){
      this.info.row = data.row
      if(this.info.resourceType==data.row.resourceType){
        this.info.incommingStart =  data.start.getFullYear()+"-"+((data.start.getMonth()+1)<10?('0'+(data.start.getMonth()+1)):data.start.getMonth()+1)+"-"+(data.start.getDate()<10?('0'+data.start.getDate()):data.start.getDate()) 
        this.info.incomingResourceId = data.row.id
        
        if(this.info.resourceType=="RIG"){
          if(this.info.rigTerrain==data.row.terrain){
            this.info.incommingResourceName = data.row.name
            this.info.projectCopyState = "PASTE_SUCCESS"
          }else{
            this.info.projectCopyState = "ERROR"
            this.info.projectCopyErrorMessage = "Source Terrain and destination terrain do match, please check your paste area and try again"
          }
        }else{
          this.info.incommingResourceName = data.row.alias
          this.info.projectCopyState = "PASTE_SUCCESS"
        }

        if (this.info.moveInProgress) {
          this.$bvModal.show("project-move-modal")
        } else {
          this.$bvModal.show("project-clone-modal")
        }
      }else{
        this.info.projectCopyState = "ERROR"
        this.info.projectCopyErrorMessage = "Source Terrain and destination terrain do match, please check your paste area and try again"
        this.$bvModal.show("project-move-modal")
      }
    } else{
       return this.$store.commit('setRightPaneToFalse')
    }
  }
  timeLabelClick () {
    this.$store.commit('setActivePane', 'time-units-component');
    this.$store.commit('setRightPaneToTrue');
  }
  getRigs () {
    return this.$store.dispatch('rigsModule/getRigs')
  }
  drawingStart(data: any){
    if(this.readOnlyView)return
    let date = new Date(data.startDate)
    let today = new Date()
   
    if((date < today)&&(!this.historyMode)){
      if(this.chartOptions.constraints.allowDrawing){
        this.chartOptions.constraints.allowDrawing = false
        this.$bvToast.toast('You are attempting to draw a project in the past. Project start date must be greater than today.', {
          title: 'Please Note!!!',
          autoHideDelay: 10000,
          appendToast: true,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          variant: 'danger'
        })
      }
    }else{
      this.chartOptions.constraints.allowDrawing = true
    }
  }
  drawingEnd(data: any){
    let isRigless = false 
    var riglessBucket = null;
    var rig = null;
    var well = null;
    var name = "Project "+this.projects.length;
    let projectType = "DRILLING"

    if(this.viewMode=='rig'){
      isRigless = data.row.resourceType=="RIGLESS"
      if(isRigless){
        riglessBucket = data.row
        projectType = "INTERVENTION"
      }else{
        rig = data.row
      }
      well = this.defaultWell
    }else{
      well = data.row
      name = data.row.name
      rig = this.defaultRig
    }

    this.$store.commit("projectModule/addProject",{
      name,
      rig,
      riglessBucket,
      projectType,
      start:data.startDate.getFullYear()+"-"+((data.startDate.getMonth()+1)<10?('0'+(data.startDate.getMonth()+1)):data.startDate.getMonth()+1)+"-"+(data.startDate.getDate()<10?('0'+data.startDate.getDate()):data.startDate.getDate()),
      end:data.endDate.getFullYear()+"-"+((data.endDate.getMonth()+1)<10?('0'+(data.endDate.getMonth()+1)):data.endDate.getMonth()+1)+"-"+(data.endDate.getDate()<10?('0'+data.endDate.getDate()):data.endDate.getDate()),
      well,
      activities:[],
      funding:null,
      sequenceId: this.sequenceId,
      isRigless,
      textColor:"white"
    })
  }
  get projectChanges () {
    return this.$store.getters['projectModule/getProjectChanges']
  }
  barMoveEnded(data:any){
    let projectToMove = this.projects.find((p:any) => p.id == data.id)
    let days = this.getDateDifferenceInDays(projectToMove.start,data.startDate)
    this.adjustActivities(data.id,days+1);
    data.startDate = data.startDate.getFullYear()+"-"+((data.startDate.getMonth()+1)<10?('0'+(data.startDate.getMonth()+1)):data.startDate.getMonth()+1)+"-"+(data.startDate.getDate()<10?('0'+data.startDate.getDate()):data.startDate.getDate())
    data.endDate = data.endDate.getFullYear()+"-"+((data.endDate.getMonth()+1)<10?('0'+(data.endDate.getMonth()+1)):data.endDate.getMonth()+1)+"-"+(data.endDate.getDate()<10?('0'+data.endDate.getDate()):data.endDate.getDate())
    this.$store.commit("projectModule/updateProject",{sequenceId:this.sequenceId,id:data.id,start:data.startDate,end:data.endDate})
  }
  getProjectIndexById(id:any){
    return this.$store.getters["projectModule/getProjectIndexById"](id)
  }
  adjustActivities(id:any,days:any){
    let activities = this.$store.getters['ActivityModule/getActivitiesForProject'](id);
    activities.forEach((activity:any) => {
      activity.start=this.addDays(activity.start,days)
      activity.end=this.addDays(activity.end,days)
      this.adjustMilestones(activity.id,days,(activity.milestones.length>0))
      this.$store.dispatch("ActivityModule/updateActivity",{projectIndex:this.getProjectIndexById(id),projectId:id,sequenceId:this.sequenceId,id:activity.id,start:activity.start,end:activity.end})
    });
  }
  adjustMilestones(activityId:any,days:any,hasServerData:boolean){
    this.$store.dispatch("MilestoneModule/getAndAdjustMilestonesDateForActivity",{activityId:activityId,days:days,hasServerData})
  }
  getDateDifferenceInDays(startDate:any,endDate:any){
    if(typeof(startDate)=='string'){
      return Math.floor((new Date(endDate).getTime()-new Date(startDate).getTime() )/(1000*3600*24))
    }else{
      return Math.floor((endDate.getTime()-startDate.getTime())/(1000*3600*24))
    }
  }
  changeTimeUnit(input: string) {
    this.timeUnit = input
  }
  doubleClick(proj: any) {
    this.destroyTooltip()
    this.$emit('change-active-view', {view:'activities', projectId:proj.id})
    // this.$emit('open-activity',proj.id)
  }
  clickEvent(projId: any) {
    this.$store.commit('projectModule/setActiveProject', projId);
    this.$store.commit('projectModule/setActiveSequence', {sequenceId:this.sequenceId,readOnly:this.readOnlyView,viewMode:this.viewMode});
    this.$store.commit('setActivePane', 'info-cost');
    this.$store.commit('setRightPaneToTrue');
  }

  getColorForProject(data: any){
    switch(data.well.wellType){
      case 'Gas':
        return this.settings[4].value?'#'+ this.settings[4].value:'#000'
      case 'OilGas':
        return this.settings[5].value?'#'+ this.settings[5].value:'#000'
      case 'Oil':
        return this.settings[6].value?'#'+ this.settings[6].value: '#000'
      case 'Condensate':
        return this.settings[7].value?'#'+ this.settings[7].value:'#000'
      default:
        return '#808080'
    }
  }

  getColorForRig(data: any){
    switch(data.terrain){
      case 'Land':
        return this.settings[1].value?'#'+ this.settings[1].value:'#000'
      case 'Swamp':
        return this.settings[2].value?'#'+ this.settings[2].value:'#000'
      case 'Offshore':
        return this.settings[3].value?'#'+ this.settings[3].value:'#000'
    }
  }

  setupRigOptions(){
    let allLand = this.sequenceObject.rigs.filter((r:any) => r.terrain == "Land")
    let allSwamp = this.sequenceObject.rigs.filter((r:any) => r.terrain == "Swamp")
    let allOffshore = this.sequenceObject.rigs.filter((r:any) => r.terrain == "Offshore")
    let allAmphibious = this.sequenceObject.rigs.filter((r:any) => r.terrain == "Amphibious")
    let allRigless = this.sequenceObject.riglessBuckets

    this.sortedRigs = [...allLand,...allSwamp,...allOffshore,...allAmphibious,...allRigless]

    this.checkRigs = this.sortedRigs.map((thisRig:any,index:any) => {
      this.selected.push(index)
      if(thisRig.resourceType=='RIG'){
        return {text:thisRig.name,value:index}
      }else{
        return {text:thisRig.alias,value:index}
      }
      
    })
  }

  setupWellOptions(){
    let allLandWells = this.$store.getters["wellsModule/getWellsByTerrain"]("Land")
    let allSwampWells = this.$store.getters["wellsModule/getWellsByTerrain"]("Swamp")
    let allOffshoreWells = this.$store.getters["wellsModule/getWellsByTerrain"]("Offshore")

    this.sortedWells = [...allLandWells, ...allSwampWells, ...allOffshoreWells]

    this.checkWells = this.sortedWells.map((thisWell:any,index:any) => {
      this.selectedWells.push(index)
      return {text:thisWell.name,value:index}
    })
  }

  showRigProperties(obj: any){
    this.$store.commit('setActivePane', 'rig-info');
    this.$store.commit('setRightPaneToTrue');
    this.$store.commit('setCurrentRig', this.$store.getters['rigsModule/getRigById'](obj.id));
  }

  showRigLessProperties(obj:any){
    this.$store.commit('setActivePane', 'rigless-info');
    this.$store.commit('setRightPaneToTrue');
    this.$store.commit('setCurrentRiglessBucket', obj);
  }

  convertToInches (val: number) {
      return val / 96;
  }

  get selectedSequence(){
    return this.$store.getters['projectModule/getSelectedSequence']
  }

  get swapState(){
    return this.$store.state.projectModule.swapState
  }

  get dynamicStart(){
    return this.$store.getters['getDynamicStartDate']
  }

  get dynamicEnd(){
    return this.$store.getters['getDynamicEndDate']
  }

  @Watch("swapState")
  onSwapStateChanged(newVal:string){
    if(this.selectedSequence.Id==this.sequenceId){
      switch(newVal){
        case SwapStates.MOVING_INTO_POSITIONS:
          if(this.chartOptions.constraints.cascadeEast){
            this.allowCascadeStateBackup = "SWITCHED"
            this.chartOptions.constraints.cascadeEast = false
          }
         
        break;
        case SwapStates.SETTING_ACTUAL_PERIOD:
          this.chartOptions.constraints.cascadeEast = true;
        break;
        case SwapStates.SWAP_COMPLETED:
          if(this.allowCascadeStateBackup == "SWITCHED"){
            this.$nextTick(() => this.chartOptions.constraints.cascadeEast = true)
          }
        break;
      }
    }
  }

  //watchers
  @Watch("dynamicStart")
  onDynamicStartChanged(newValue:any, oldValue: any){
    if(this.selectedSequence.Id==this.sequenceId){
      this.starts = new Date(newValue)
    }
  }

  @Watch("dynamicEnd")
  onDynamicEndChanged(newValue:any, oldValue: any){
      if(this.selectedSequence.Id==this.sequenceId)
      { this.ends = new Date(newValue) }
  }

  @Watch("currentHeight")
  currentHieghtWatcher(newVal:any){
    ((this.$refs.gantt) as any).resize()
  }

  @Watch("currentWidth")
  currentWidthWatcher(newVal:any){
    ((this.$refs.gantt) as any).resize()  
  }

  @Watch("rightPane")
  onRightPaneChanged(){
    setTimeout(() => { 
      ((this.$refs.gantt)as any).resize()
    }, 400);
  }

  created(){
    this.$store.commit('setDynamicEnd', new Date(this.sequenceObject.endYear, 11, 31))
    this.filterId = Math.abs((Math.floor(Math.random() * (10 - +4000))  +10)+(Math.floor(Math.random() * (10 - +4000))  +10)).toString()
    this.$store.commit('projectModule/setActiveSequence', {sequenceId:this.sequenceId,readOnly:this.readOnlyView,viewMode:this.viewMode});
  }
  
  mounted(){

    tooltip = this.$refs.tooltip as any
    
    if((this.$store.getters['rigsModule/getAllrigs']).length <= 0) {
      this.$store.dispatch('rigsModule/getRigs')
    }

    if(this.$store.state.wellsModule.wells.length <= 0) {
      this.$store.dispatch('wellsModule/getWellsAction')
    }

    if(Object.entries(this.sequenceObject).length === 0){
      this.$store.dispatch("sequencesModule/fetchSequences",this.sequenceId).then(res => {
        this.setupRigOptions()
        this.setupWellOptions()
        this.starts = new Date(res.data.data[0].startYear, 0, 1)
        this.ends = new Date(res.data.data[0].endYear, 11, 31)
      })
    }else{
      this.setupRigOptions()
      this.setupWellOptions()
      this.starts = new Date(this.sequenceObject.startYear, 0, 1)
      this.ends = new Date(this.sequenceObject.endYear, 11, 31)
    }
          
    if(this.projects.length<1){
      this.$store.dispatch("projectModule/getProjectsForSequence",this.sequenceId)
    }

    this.$emit('show-funding',this.showFundingMechnism)
 }

 getVisibleActivites(){
   const retrivedActivies:Array<any> = []
   this.projects.filter((p:any) => p.activities.length>0).forEach((p:any) => {
     const activities = p.activities?p.activities:[]
     activities.filter((a:any) => a.departmentId == 3).forEach((a:any) => {
       if( !retrivedActivies.some((ra:any) =>ra.id == a.wellActivity.id )){
         retrivedActivies.push({id:a.wellActivity.id,fill:a.wellActivity.color,color:a.wellActivity.textColor==='FFFF'?'FFFFFF':a.wellActivity.textColor,name:a.wellActivity.name})
       }
     });
   });
   return retrivedActivies
 }
 
 print(){
   return printer(this.isWBS, this.sequenceObject, this.settings, this.$store.getters['getDynamicStartDate'], this.sequenceObject.startYear, this.sequenceObject.endYear, this.getProjectsBetweenYears, this.getProjectsForRigOrRigless, this.computeDuration,this.getVisibleActivites(), this.printStatus) 
 }

 updated(){
    setTimeout(() => { 
      ((this.$refs.gantt)as any).resize()
    }, 500);
 }
}

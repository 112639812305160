










































































































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { HTTP } from "@/axios_api/http-common";
import DailyReportViewer from "./DailyReportViewer";
@Component ({
  components: {
    "report-viewer": DailyReportViewer
  },    
  filters: {
    prettyttime(dateTime: any) {
      let passedDate = new Date(Date.parse(dateTime));
      return (
        (passedDate.getHours() < 10
          ? "0" + (passedDate.getHours()+1)
          : (passedDate.getHours()+1)) +
        ":" +
        (passedDate.getMinutes() < 10
          ? "0" + passedDate.getMinutes()
          : passedDate.getMinutes())
      );
    },
  },
})
export default class OverviewDDR extends Vue {
  @Prop({})
  projectId !: any
  sendingSelected: any[] = []
  searchSelected: string = ""
  searchAll: string = ""
  fields: any[] = [
    { key: "id", label: "Report No." },
    { key: "reportDate", label: "Report Date" },
    { key: "reportUploadDate", label: "Upload Date" },
    { key: "startTime", label: "Time Start", },
    { key: "endTime", label: "Time End" },
    { key: "manHours", label: "Man Hours" },
    { key: "startDepth", label: this.$store.state.UnitsModule.currentUnitSystem == 'si' ? 'Start Depth(m)': 'Start Depth(ft)' },
    { key: "endDepth", label: this.$store.state.UnitsModule.currentUnitSystem == 'si' ? 'End Depth(m)': 'End Depth(ft)'  },
    { key: "actions", label: "Actions" },
  ]
  selectedDailyReport: any = {}
  selectedReportId: number = 0
  send: boolean = false
  emails: any[] = []
  selectedEmails: any[] = []
  showModal: boolean = false
  getTimeDifferenceInHours(startDateTime: string, endDateTime: string) {
    let passedStartDate = new Date(Date.parse(startDateTime)).getTime();
    let passedEndDate = new Date(Date.parse(endDateTime)).getTime();
    if(passedEndDate<= passedStartDate){
      passedEndDate = passedEndDate+(24*3600*1000);
    }
    return Math.round(Math.abs(passedEndDate - passedStartDate) / (1000 * 3600));
  }
  viewDailyReporClicked(id: any) {
    this.selectedDailyReport = this.ddrs.find((d) => d.id == id);
    this.showModal = true
    // this.$bvModal.show("reportviewermodal");
  }
  printDailyReport(id: any) {
    if (id.length > 0) {
      HTTP.get("ddr/ExportDocument?id="+id, { responseType: "blob" })
        .then((res: any) => {
          var blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          const downloadUrl = window.URL.createObjectURL(blob);
          link.href = downloadUrl;
          //link.download = res.headers.filename
          link.setAttribute("download", res.headers.filename + ".xlsx"); //any other extension
          document.body.appendChild(link);
          link.click();
         // link.remove();
        })
        .catch((error: any) => {});
    } else {
      alert("invalid id");
    }
  }
  OpenEmailList(selectedReportId: number) {
    this.send = true;
    this.selectedReportId = selectedReportId;
  }
  emailDailyReport() {
    let emailsToSendTo = this.sendingSelected.map((x: any) => {
      return x.userEmail;
    });
    let recipientIds = this.selectedEmails.map((x: any) => {
      return x.id;
    });
    this.$bvToast.toast('Please wait while this daily report is being delivered to the specified emails.', {
      title: 'Loading',
      autoHideDelay: 3000,
      appendToast: true,
      toaster: 'b-toaster-bottom-right',
      solid: true,
      variant: 'dark'
    })
    HTTP.post("ddr/distribute", {
      reportId: this.selectedReportId,
      recipients: emailsToSendTo,
    }).then((res:any) => {
      this.$bvToast.toast('This Daily Report has been delivered to the corresponding email addresses.', {
          title: 'Successful!!!',
          autoHideDelay: 10000,
          appendToast: true,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          variant: 'success'
        })
    }).catch((err: any) => {
          this.$bvToast.toast('This daily report failed to deliver.', {
          title: 'Failed!!!',
          autoHideDelay: 10000,
          appendToast: true,
          toaster: 'b-toaster-bottom-right',
          solid: true,
          variant: 'danger'
        })
      });
    HTTP.post("reportrecipient/upsert", {
      projectId: this.projectId,
      RecipientIds: recipientIds,
    });
  }
  getUsers() {
    return this.$store.dispatch("usersModule/getUsers");
  }
  fetchReportRecipients() {
    return this.$store.dispatch(
      "DailyReportModule/getReportRecipient",
      this.projectId
    );
  }
  addUser(user: any) {
    let ind: any = this.emails.findIndex((x: any) => x.id === user.id);
    this.selectedEmails.push({
      id: this.emails[ind].id,
      userEmail: this.emails[ind].userEmail,
    });
    this.sendingSelected.push({
      id: this.emails[ind].id,
      userEmail: this.emails[ind].userEmail,
    });
    this.emails.splice(ind, 1);
  }
  removeUser(user: any) {
    let ind: any = this.selectedEmails.findIndex(
      (x: any) => x.id === user.id
    );
    let inds: any = this.sendingSelected.findIndex(
      (x: any) => x.id === user.id
    );
    this.emails.push({
      id: this.selectedEmails[ind].id,
      userEmail: this.selectedEmails[ind].userEmail,
    });
    this.selectedEmails.splice(ind, 1);
    this.sendingSelected.splice(inds, 1);
  }
  EditDailyReport(id:number) {
    const report = this.ddrs.find((d) => d.id == id);
    this.$emit("editingReportChanged",report);
  }
  ShowEditIcon(id:number):boolean{
    const report = this.ddrs.find((d) => d.id == id);
    var reportDate:any = new Date(report.reportUploadDate).getDate();
    var presentDate:any = new Date(Date.now()).getDate();
    const time = presentDate - reportDate;
    if(time==0){
      return true;
    }
    return false
  }
  get currentUnitSystem(){
      return this.$store.getters['UnitsModule/getCurrentUnitSystem']
  }
  get ddrs(): Array<any> {
    return this.$store.getters["DailyReportModule/getReportsForProject"](
      this.projectId
    );
  }
  get selectedProject(): any {
    return this.$store.getters["projectModule/getProjectById"](
      this.projectId
    );
  }
  get allUsers(): any {
    return this.$store.getters["usersModule/fillteredUsers"]("", -1);
  }
  get getSelectedUsers(): any {
    return this.$store.getters["DailyReportModule/getReportRecipient"];
  }
  get filterSelected(): any {
    return this.selectedEmails.filter((x: any) => {
      return x.userEmail
        .toLowerCase()
        .match(this.searchSelected.toLowerCase());
    });
  }
  get filterAll(): any {
    return this.emails.filter((x: any) => {
      return x.userEmail.toLowerCase().match(this.searchAll.toLowerCase());
    });
  }
  mounted() {
    if (this.ddrs.length < 1) {
        this.$store.dispatch(
          "DailyReportModule/getReportsForProject",
          this.projectId
        ).then(res=> {
        });
      }
      if (this.getSelectedUsers.length < 1) {
        this.fetchReportRecipients().then((res: any) => {
          this.selectedEmails = res.data.data;
          this.selectedEmails = this.selectedEmails.map((x: any) => {
            return {
              id: x.userId,
              userEmail: x.userEmail,
            };
          });
          this.sendingSelected = this.selectedEmails.map((x: any) => {
            return {
              id: x.id,
              userEmail: x.userEmail,
            };
          });
        });
      }
      if (this.allUsers.length < 1) {
        this.getUsers().then((res: any) => {
          res.data.data.forEach((el: any) => {
            if (this.selectedEmails.findIndex((x: any) => x.id == el.id) < 0) {
              this.emails.push({
                id: el.id,
                userEmail: el.email,
              });
            }
          });
        });
      } else {
        this.allUsers.forEach((el: any) => {
          if (this.selectedEmails.findIndex((x: any) => x.id == el.id) < 0) {
            this.emails.push({
              id: el.id,
              userEmail: el.email,
            });
          }
        });
      }
  }
}

<template>
    <div @mouseenter="isFocused=true" @mouseleave="isFocused=false" @mousedown="onMouseDown" class="analytics-card border rounded" :class="{'on-top':(this.selectedCard.startsWith(this.$vnode.key)||isFocused)}" :style="{top:top+'px',left:left+'px',height:height+'px',width:width+'px', background: color}">
        <template v-if="isFocused">
            <span class="top-left-ardoner ardoner-style" @mousedown="topLeftArdonerMouseDown"/>
            <span class="top-right-ardoner ardoner-style" @mousedown="topRightArdonerMouseDown"/>
            <span class="bottom-left-ardoner ardoner-style" @mousedown="bottomLeftArdonerMouseDown"/>
            <span class="bottom-right-ardoner ardoner-style" @mousedown="bottomRightArdonerMouseDown"/>
        </template>
        <div>
            <!-- <pre style="color:white">{{recomputedDataset}}</pre> -->
            <table v-if="dataSet.length > 0">
                <tr>
                    <th v-for="(value, index) in dataSet" :key="index">{{value.columnName}}</th>
                </tr>
                <tr v-for="(value, index) in recomputedDataset" :key="index+'d'">
                    <td>{{value.name}}</td>
                    <td>{{value.plan}}</td>
                    <td>{{value.actual}}</td>
                </tr>                 
            </table>
        </div>
    </div>
</template>

<script>
import {HTTP} from "../../axios_api/http-common"
export default {
    props:{
        kpi:{
            type:Object,
            default:() => ({})
        },
        position:{
            type:Object,
            default:()=>({top:'30px',left:'30px'})
        },
        selectedCard:{
            type:String,
            default:''
        },
        widthProp: {
            type: Number,
            default: 650
        },
        heightProp: {
            type: Number,
            default: 325
        },
        topProp: {
            type: Number,
            default: 0
        },
        leftProp: {
            type: Number,
            default: 0
        },
        colorProp: {
            type: String,
            default: '#343A40'
        },
        dataSet: {
            type: Array,
            default: ()=>[]
        },
    },
    data(){
        return{
            contextMenu: false,
            colorArray: ['#99FF99', '#B34D4D','#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A', '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC','#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC', '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399','#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680', '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933','#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3', '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF','#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6', '#E6B333', '#3366E6', '#999966'],
            top: this.topProp,
            color: this.colorProp,
            left: this.leftProp,
            height: this.heightProp,
            width: this.widthProp,
            mouseDownPositionY:0,
            mouseDownPositionX:0,
            activeHandler:null,
            isFocused:false,
            dataLoaded:false,
            activeChartType:'',
            selectedProjects:[],
            selectedActivities:[],
            selectedMilestones:[],
            atPositionChanged: false,
        }
    },
    computed:{
        recomputedDataset() {
            let newDataset = []
            if (this.dataSet.length > 0) {
                for (let i = 0; i < this.dataSet[1].values.length; i++){
                    let temp = { name: this.dataSet[0].values[i] ,plan: this.dataSet[1].values[i], actual: this.dataSet[2].values[i]}
                    newDataset.push(temp)
                }
                return newDataset
            } else {
                return newDataset
            }
        }
    },
    methods:{
        getConditionalValue(targetName,conditionalProperties){
            let conditionValues = ""
            let conditionalPropertiesArray = conditionalProperties.split(',')
            conditionalPropertiesArray.forEach(thisConditionalProperty => {
                switch(targetName){
                    case "BaseActivity":
                        if(conditionValues.length<1){
                            conditionValues = conditionValues+(this.selectedActivities[0]!=undefined?this.selectedActivities[0].id:-1)
                        }else{
                            conditionValues = ","+conditionValues+(this.selectedActivities[0]!=undefined?this.selectedActivities[0].id:-1)
                        }
                    break
                }
            })
            return conditionValues;
        },
        extractQueryData(){
            return this.kpi.kpiTargets.map(thisKPITarget => ({
                targetName:thisKPITarget.targetName ,
                targetProperties:thisKPITarget.targetProperties,
                conditionTypes:thisKPITarget.conditionTypes,
                conditionalProperties:thisKPITarget.conditionalProperties,
                conditionValues:this.getConditionalValue(thisKPITarget.targetName,thisKPITarget.conditionalProperties)
            }))
        },
        randomInt(min, max) {
            return min + Math.floor((max - min) * Math.random());
        },
        persistMouseDownPosition(data){
            this.mouseDownPositionY = data.y
            this.mouseDownPositionX = data.x
        },
        onMouseDown(data){
            data.stopPropagation()
            data.preventDefault()
            this.persistMouseDownPosition(data)
            this.activeHandler = this.$watch('position',this.handleMove)
            this.$emit('cardselected',this.$vnode.key)
        },
        topLeftArdonerMouseDown(data){
            data.stopPropagation()
            this.persistMouseDownPosition(data)
            this.activeHandler = this.$watch('position',this.handleTopLeftArdoner)
            this.$emit('cardselected',this.$vnode.key+'top-left-ardoner')
        },
        topRightArdonerMouseDown(data){
            data.stopPropagation()
            this.persistMouseDownPosition(data)
            this.activeHandler = this.$watch('position',this.handleTopRightArdoner)
            this.$emit('cardselected',this.$vnode.key+'top-right-ardoner')
        },
        bottomLeftArdonerMouseDown(data){
            data.stopPropagation()
            this.persistMouseDownPosition(data)
            this.activeHandler = this.$watch('position',this.handleBottomLeftArdoner)
            this.$emit('cardselected',this.$vnode.key+'bottom-left-ardoner')
        },
        bottomRightArdonerMouseDown(data){
            data.stopPropagation()
            this.persistMouseDownPosition(data)
            this.activeHandler = this.$watch('position',this.handleBottomRightArdoner)
            this.$emit('cardselected',this.$vnode.key+'bottom-right-ardoner')
        },
        
        handleMove(){
            this.top = this.top+(this.position.top-this.mouseDownPositionY)
            this.left = this.left+(this.position.left-this.mouseDownPositionX)
            this.mouseDownPositionY = this.position.top
            this.mouseDownPositionX = this.position.left
            if (!this.atPositionChanged) {
                this.atPositionChanged = true
            }
        },

        handleBottomLeftArdoner(){
            this.height = this.height+(this.position.top-this.mouseDownPositionY)
            this.width = this.width-(this.position.left-this.mouseDownPositionX)
            this.left = this.left+(this.position.left-this.mouseDownPositionX)
            this.mouseDownPositionY = this.position.top
            this.mouseDownPositionX = this.position.left
            if (!this.atPositionChanged) {
                this.atPositionChanged = true
            }
        },

        handleBottomRightArdoner(){
            this.height = this.height+(this.position.top-this.mouseDownPositionY)
            this.width = this.width+(this.position.left-this.mouseDownPositionX)
            this.mouseDownPositionY = this.position.top
            this.mouseDownPositionX = this.position.left
            if (!this.atPositionChanged) {
                this.atPositionChanged = true
            }
        },

        handleTopLeftArdoner(){
            this.height = this.height-(this.position.top-this.mouseDownPositionY)
            this.width = this.width-(this.position.left-this.mouseDownPositionX)
            this.left = this.left+(this.position.left-this.mouseDownPositionX)
            this.top = this.top+(this.position.top-this.mouseDownPositionY)
            this.mouseDownPositionY = this.position.top
            this.mouseDownPositionX = this.position.left
            if (!this.atPositionChanged) {
                this.atPositionChanged = true
            }
        },
    },
    watch:{
        selectedCard(newVAL,oldVal){
            if(this.activeHandler!=null&&(!this.selectedCard.startsWith(this.$vnode.key))){
                this.activeHandler()
                if(this.atPositionChanged){
                    this.$emit('positionChanged', {kpiId: this.kpi.id, x:this.left, y: this.top, height: this.height, width: this.width})
                    this.atPositionChanged = false
                }
                this.activeHandler = null
            }
        },
    },
    mounted(){
        // if (this.dataSet.length > 0) {
        //     for (let i = 0; i < this.dataSet[1].length; i++){
        //         let temp = { val: this.dataSet[1][i], vals : arrays[2][i]}
        //         this.newDataset.push(temp)
        //     }
        //     window.console.log(this.newDataset)
        // }
    }
}
</script>

<style scoped>
    .analytics-card{
        position: absolute;
        min-height: 20px;
        min-width: 20px;
    }

    .analytics-card:active{
        cursor:move;
    }

    .ardoner-style{
        position:absolute;
        background:red;
        min-width:10px;
        min-height:10px;
    }

    .bottom-right-ardoner{
        bottom:-5px;
        right:-5px;
        cursor:nwse-resize
    }

    .bottom-left-ardoner{
        bottom:-5px;
        left:-5px;
        cursor:nesw-resize
    }

    .top-left-ardoner{
        top:-5px;
        left:-5px;
        cursor:nwse-resize
    }

    .top-right-ardoner{
        top:-5px;
        right:-5px;
        cursor:nesw-resize
    }

    .on-top{
        z-index: 1000;
    }

    .chart-container{
        position: relative;
        margin: auto;
    }

    .right-bar{
        position: absolute;
        right:0;
        top: 0;
        height: 100%;
        min-height: 100%;
        overflow-y: auto;
        width: 0;
        background: white;
        transition: 0.3s;
    }

    .right-bar-visible{
        width: 250px;
        transition: 0.3s;
    }

    .right-bar-margin{
       margin-right: 255px!important;
       transition: 0.3s;
    }

    .context-ul {
        list-style:none;
        padding:0;
        display:block;
        max-width:150px;
        box-shadow: 1px 2px 20px rgb(20, 20, 20);
        background:white;
        border-radius: 2px;
    }

    .context-ul li {
        padding: 5px;
        border-bottom: 1px solid #ccc;
    }

    table {
        border-collapse: collapse;
        color: white;
        width: 90%;
        margin: 0 auto;
    }
    tr {
        text-align: left;
        padding: 20px;
        color: white;
    }
    tr:hover {
        background-color: rgb(73, 72, 72);
    }
    td {
        border-bottom: 1px solid white;
        padding: 5px;
        padding-top: 15px;
    }
    th {
        height: 50px;
        border-bottom: 1px solid white;
        padding: 10px;
        padding-top: 10px;
    }
</style>

















































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class PersonnelContact extends Vue {
  
  @Prop({}) 
  circulation!:any;
  @Prop({}) 
  rIH!:any;
  @Prop({})
  pOOH!:any;
  @Prop({})
  drilling!:any;
  @Prop({}) 
  cementing!:any;
  @Prop({}) 
  tCP!:any;
  @Prop({}) 
  others!:any;

  private get circulationInput() {
    return this.circulation;
  }
  private set circulationInput(value: any) {
    this.$emit("update:circulation", value);
  }
   private get rIHInput() {
    return this.rIH;
  }
  private set rIHInput(value: any) {
    this.$emit("update:rIH", value);
  }
   private get pOOHInput() {
    return this.pOOH;
  }
  private set pOOHInput(value: any) {
    this.$emit("update:pOOH", value);
  }
   private get drillingInput() {
    return this.drilling;
  }
  private set drillingInput(value: any) {
    this.$emit("update:drilling", value);
  }
  private get cementingInput() {
    return this.cementing;
  }
  private set cementingInput(value: any) {
    this.$emit("update:cementing", value);
  }
   private get tCPInput() {
    return this.tCP;
  }
  private set tCPInput(value: any) {
    this.$emit("update:tCP", value);
  }
   private get othersInput() {
    return this.others;
  }
  private set othersInput(value: any) {
    this.$emit("update:others", value);
  }
}

import { HTTP } from '@/axios_api/http-common' 
import {Activity} from '../../types/Activity'
import store from "@/store/index"
import Vue from 'vue'

const state = {
  activityChanges:[],
  updateRoute:false,
  activeActivityId:""
}

interface IState {
    activityChanges:Array<any>,
    updateRoute:boolean,
    activeActivityId:string
}

const getters = {

  getActivitiesForProject : (state: IState,getters:any,rootState:any,rootGetters:any) => (projectId:string) => {
    return rootGetters["projectModule/getProjectById"](projectId).activities
  },

  getActivityValidityState:(state: IState,getters:any)=>{
    // check if well in activity have been selected
    let validityState = true;
    state.activityChanges.forEach((activityChange:any) => {
        if(activityChange.action=="CREATE"||activityChange.action=="UPDATE"){
          let activity = getters.getActivityById(activityChange.id)
          if(activity !== undefined){
            if(activity.wellActivity.id == -1 || activity.supportTeamsIds.length ==0){
              return validityState = false;
            }
          }
         }
    });
    return validityState;
  },

  getActivityById: (state: IState,getters:any,rootState:any,rootGetters:any) => (activityId:string) => {
    for(let key in rootState.projectModule.projects){
      for(let i=0;i<rootState.projectModule.projects[key].length;i++){
        let currentActivity = rootState.projectModule.projects[key][i].activities.find((a:any) => a.id == activityId)
        if(currentActivity){
          currentActivity.sequenceId = key
          currentActivity.projectId = rootState.projectModule.projects[key][i].id
          return currentActivity
        }
      }
    }
    return undefined
  },

  getActivityChangesCount: (state:IState) => {
    return state.activityChanges.length
  },

  convertActivityToActivityModel: (state: IState,getters:any) => (activityToConvertId:any) => {
    let activityToConvert = getters.getActivityById(activityToConvertId)
    let activityModel:any = {}
    activityModel.id = activityToConvert.id
    activityModel.sequenceId = activityToConvert.sequenceId
    activityModel.projectId = activityToConvert.projectId
    activityModel.name = activityToConvert.name
    activityModel.start = activityToConvert.start
    activityModel.end = activityToConvert.end
    activityModel.departmentId = activityToConvert.departmentId
    activityModel.wellActivityId = activityToConvert.wellActivity?activityToConvert.wellActivity.id:-1
    activityModel.budgetId = activityToConvert.Budget?activityToConvert.Budget.id:-1
    activityModel.activityDependencyId = activityToConvert.activityDependencyId?activityToConvert.activityDependencyId:-1
    activityModel.supportTeamsIds=activityToConvert.supportTeamsIds;
    window.console.log(activityModel)
    //handle budget updates here
    if(activityToConvert.budgetChanged){
      activityModel.budget = {
        budgetTemplateId:activityToConvert.budget.budgetTemplateId,
       // activityId:activityToConvert.budget.activityId,
       activityId:activityToConvert.activityId,
        budgetLines:activityToConvert.budget.budgetLines.map((bl:any) => ({
          lineItemId:bl.lineItemId,
          lineItemValue:bl.lineValue
        }))
      }
    }
    //end of budget update
    return activityModel

  },

  getActiveActivity:(state: IState,getters:any) => {
    return getters.getActivityById(state.activeActivityId)
  }

}

const mutations = {

  addActivity(state:IState,activityToAddId:any){ 
    if(store.getters.activeUser.permissions.includes("wellplanner")){
      state.activityChanges.push({
        id:activityToAddId,
        action:"CREATE"
      })
     }
  },

  updateActivity (state:IState, activityUpdateId:any) {
    //record the change that just happened
    let existingChangeIndex = state.activityChanges.findIndex(thisActivityUpdate => thisActivityUpdate.id == activityUpdateId)
    if(existingChangeIndex<0){
      if(store.getters.activeUser.permissions.includes("wellplanner")){
        state.activityChanges.push({
          id:activityUpdateId,
          action:"UPDATE",
        })
       }
    }
  },

  deleteActivity(state: IState, activityToDeleteId: any) {
    let existingChangeIndex = state.activityChanges.findIndex(thisActivityUpdate => thisActivityUpdate.id == activityToDeleteId)
    
    if(existingChangeIndex>-1){
        if(state.activityChanges[existingChangeIndex].action=="CREATE"){
            state.activityChanges.splice(existingChangeIndex,1)
        }else{
            state.activityChanges[existingChangeIndex].action = "DELETE"
        }
    }    
    else{
      if(store.getters.activeUser.permissions.includes("wellplanner")){
         state.activityChanges.push({
           id:activityToDeleteId,
           action:"DELETE",
       })
        }
     }
   },

  setActiveActivity(state:IState,activityId:string){
    state.activeActivityId = activityId
  },

  resetUpdateRouter(state:IState){
    state.updateRoute = false
  },

  clearActivityChanges(state:IState,data:any){
    state.activityChanges = []
  }

}
const actions = {

  //persisting view create operation
  addActivity({commit,state,rootState}:{commit:any, state:IState,rootState:any},payload:any){
    let tempId = Math.abs((Math.floor(Math.random() * (10 - +4000))  +10)+(Math.floor(Math.random() * (10 - +4000))  +10)); 
    payload.newActivity.id = tempId
    rootState.projectModule.projects[payload.sequenceId][payload.projectIndex].activities.push(payload.newActivity)
    commit('addActivity',tempId)
  },

  //persisting view update operation
  updateActivity({commit,state,rootState}:{commit:any, state:IState,rootState:any},activityUpdate:any){
    let existsingActivityIndex = rootState.projectModule.projects[activityUpdate.sequenceId][activityUpdate.projectIndex].activities.findIndex((thisActivity :any)=> thisActivity.id == activityUpdate.id)
    for(let key in activityUpdate){
      if(key == "id"||key=="sequenceId"||key=="projectIndex")continue
      rootState.projectModule.projects[activityUpdate.sequenceId][activityUpdate.projectIndex].activities[existsingActivityIndex][key] = activityUpdate[key]
    }
    commit("updateActivity",activityUpdate.id)
  },

  //persisting view delete
  deleteActivity({commit,state,rootState}:{commit:any, state:IState,rootState:any},payload:any){
    let existsingActivityIndex = rootState.projectModule.projects[payload.sequenceId][payload.projectIndex].activities.findIndex((thisActivity :any)=> thisActivity.id == payload.id)
    rootState.projectModule.projects[payload.sequenceId][payload.projectIndex].activities.splice(existsingActivityIndex,1);
    commit("deleteActivity",payload.id)
  },

  updateActivityBudget({commit,state,rootState}:{commit:any, state:IState,rootState:any},budgetUpdate:any){
    let existsingActivityIndex = rootState.projectModule.projects[budgetUpdate.sequenceId][budgetUpdate.projectIndex].activities.findIndex((thisActivity :any)=> thisActivity.id == budgetUpdate.id)
    rootState.projectModule.projects[budgetUpdate.sequenceId][budgetUpdate.projectIndex].activities[existsingActivityIndex].budgetChanged = true
    let currentBudget = rootState.projectModule.projects[budgetUpdate.sequenceId][budgetUpdate.projectIndex].activities[existsingActivityIndex].budget
    
    if(currentBudget==null){
      currentBudget = {budgetTemplateId:"",budgetLines:[],activityId:budgetUpdate.id}
      Vue.set(rootState.projectModule.projects[budgetUpdate.sequenceId][budgetUpdate.projectIndex].activities[existsingActivityIndex],'budget',currentBudget)
    }
    
    for(let key in budgetUpdate){
      //skip the other keys that came with the budget update object
      if(key == "id"||key=="sequenceId"||key=="projectIndex"||key=="sectionId")continue
      //treat budget line items here, 
      if(key=="lineItem"){
        //Look for the budgetLineIndex
        let budgetLineIndex = currentBudget.budgetLines.findIndex((thisBudgetLine:any) => thisBudgetLine.lineItemId == budgetUpdate.lineItem.lineItemId)
        //where the budget lineitem is not found, do a push into budget lines
        if(budgetLineIndex<0){
          rootState.projectModule.projects[budgetUpdate.sequenceId][budgetUpdate.projectIndex].activities[existsingActivityIndex].budget.budgetLines.push({lineValue:budgetUpdate.lineItem.lineValue,lineItemId:budgetUpdate.lineItem.lineItemId})
        }else{
          //where the budget line is found, do an update of that particular budget line
          rootState.projectModule.projects[budgetUpdate.sequenceId][budgetUpdate.projectIndex].activities[existsingActivityIndex].budget.budgetLines[budgetLineIndex].lineValue = budgetUpdate.lineItem.lineValue
        }
      }else{
        //Handle NON budget line updates here
        rootState.projectModule.projects[budgetUpdate.sequenceId][budgetUpdate.projectIndex].activities[existsingActivityIndex].budget[key] = budgetUpdate[key]
      }
    }
    if(store.getters.activeUser.permissions.includes("budgetentry")){
      commit("updateActivity",budgetUpdate.id)
     }
  },

  //post server operations
  updateNewActivities({commit,state,rootState}:{commit:any, state:IState,rootState:any},payload:any){
    rootState.projectModule.projects[payload.sequenceId].forEach((thisProject:any,projectIndex:number) => {
      thisProject.activities.forEach((thisActivity:any,index:number,activities:Array<any>) => {
        let indexOfUpdate = payload.data.findIndex((thisUpdate:any) => thisUpdate.data.tempKey == thisActivity.id)
        if(indexOfUpdate>-1)Vue.set(rootState.projectModule.projects[payload.sequenceId][projectIndex].activities,index,payload.data[indexOfUpdate].data)
      })
    })
  },

  updateActivities({commit,state,rootState}:{commit:any, state:IState,rootState:any},payload:any){
    rootState.projectModule.projects[payload.sequenceId].forEach((thisProject:any,projectIndex:any) => {
      thisProject.activities.forEach((thisActivity:any,index:number,activities:Array<any>) => {
        let indexOfUpdate = payload.data.findIndex((thisUpdate:any) => thisUpdate.data.id == thisActivity.id)
        if(indexOfUpdate>-1)Vue.set(rootState.projectModule.projects[payload.sequenceId][projectIndex].activities,index,payload.data[indexOfUpdate].data)
      })
    })
  },

  createActivitiesOnServer({dispatch,commit}:{dispatch:any,commit:any},data:any){
    return new Promise((resolve,reject) => {
        if(data.activities.length>0){
            HTTP.post('activities/create',data)
            .then((response:any) => {
                dispatch('updateNewActivities',{sequenceId:data.sequenceId,data:response.data.data})
                let tempActualKeyPairs = response.data.data.map((thisActivity:any) => {return {id:thisActivity.data.id,tempKey:thisActivity.data.tempKey}})
                resolve(tempActualKeyPairs)
            })
            .catch((error:any) => {
                reject(error)
            })
        }else{
            resolve([])
        }
    })
  },

  updateActivitiesOnServer({dispatch,commit}:{dispatch:any,commit:any},data:any){
    return new Promise((resolve,reject) => {
        if(data.activities.length>0){
            HTTP.put('activities/update',data)
            .then((reponse:any) => {
                dispatch('updateActivities',{sequenceId:data.sequenceId,projectId:data.projectId,data:reponse.data.data})
                resolve()
            })
            .catch((error:any) => {
                reject(error)
            })
        }else{
            resolve([])
        }
    })
  },
  
  deleteActivitiesOnServer({commit}:{commit:any},data:any){
    return new Promise((resolve,reject) => {
        if(data.length>0){
            HTTP.post('activities/delete',data)
            .then((response:any) => {
                resolve(response)
            })
            .catch((error:any) => {
                reject(error)
            })
        }else{
            resolve()
        }
    })
  },

  //server operations initilizer
  saveChanges({commit,dispatch,state,getters}:{commit:any,dispatch:any,state:IState,getters:any}){

    let creatOps:Array<any> = state.activityChanges.filter(thisEntry => thisEntry.action == "CREATE").map(thisEntry =>  getters.convertActivityToActivityModel(thisEntry.id))
    let updateOps:Array<any> = state.activityChanges.filter(thisEntry => thisEntry.action == "UPDATE").map(thisEntry => getters.convertActivityToActivityModel(thisEntry.id))
    let deleteOps:Array<any> = state.activityChanges.filter(thisEntry => thisEntry.action == "DELETE").map(thisEntry => thisEntry.id)

    let sequenceId = ""
    if(creatOps.length>0){
      sequenceId = creatOps[0].sequenceId
    }else{
      if(updateOps.length>0)sequenceId = updateOps[0].sequenceId
    }

    return new Promise((resolve,reject) => {
      dispatch("createActivitiesOnServer",{sequenceId,activities:creatOps,type:"List"}).then((tempActualKeyPairs:any) => {
          dispatch("updateActivitiesOnServer",{sequenceId,activities:updateOps,type:"List"}).then((updateRes:any) => {
              dispatch("deleteActivitiesOnServer",deleteOps).then((deleteRes:any) => {
                  resolve(tempActualKeyPairs)
              })
          })
      })
    })
    
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}





























































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import UnitConverter from '../../mixins/unitConverter.ts'

@Component({
  mixins: [UnitConverter]
})
export default class DrillingParameters extends Vue {
  @Prop({}) 
  torque!:number;
  @Prop({}) 
  depth!:number;
  @Prop({}) 
  puWeight!:number;
  @Prop({}) 
  soWeight!:number;
  @Prop({}) 
  maxOPull!:number;
  @Prop({}) 
  rotatingWeight!:number;

  private get torqueInput() {
    return this.torque;
  }
  private set torqueInput(value:any) {
    this.$emit("update:torque", value);
  }
   private get depthInput() {
    return this.depth;
  }
  private set depthInput(value: any) {
    this.$emit("update:depth", value);
  }
   private get PuWeightInput() {
    return this.puWeight;
  }
  private set PuWeightInput(value: any) {
    this.$emit("update:puWeight", value);
  }
   private get SoWeightInput() {
    return this.soWeight;
  }
  private set SoWeightInput(value: any) {
    this.$emit("update:soWeight", value);
  }
  private get maxOPullInput() {
    return this.maxOPull;
  }
  private set maxOPullInput(value: any) {
    this.$emit("update:maxOPull", value);
  }
  private get rotatingWeightInput() {
    return this.rotatingWeight;
  }
  private set rotatingWeightInput(value: any) {
    this.$emit("update:rotatingWeight", value);
  }
}

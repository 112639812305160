


































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import UnitConverter from '../../mixins/unitConverter.ts'

@Component({
  mixins: [UnitConverter]
})
export default class AAFEsummary extends Vue {
  @Prop({}) 
  code!:any;
  @Prop({}) 
  supplementaryCode!:any;
  @Prop({}) 
  amount!:any;
  @Prop({}) 
  supplementaryAmount!:any;
  @Prop({}) 
  amountOrBudgetRatio!:any;
  @Prop({}) 
  supplementaryAmountOrBudgetRatio!:any;
  @Prop({}) 
  dailyCost!:any;
  @Prop({}) 
  cummulativeCost!:any;
  @Prop({}) 
  percentage!:any;
  @Prop({}) 
  suppPercentage!:any;
  @Prop({default: false})
  status!:any

  amountSec= this.amount
  supplementaryAmountSec = this.supplementaryAmount
  amountOrBudgetRatioSec = this.amountOrBudgetRatio
  supplementaryAmountOrBudgetRatioSec = this.supplementaryAmountOrBudgetRatio

  private intialize () {
    this.amountInput = 0
    this.supplementaryAmountInput = 0
    this.amountOrBudgetRatioInput = 0
    this.supplementaryAmountOrBudgetRatioInput = 0
  }
  private get codeInput() {
    return this.code;
  }
  private set codeInput(depth:any) {
    this.$emit("update:code", depth);
  }
   private get supplementaryCodeInput() {
    return this.supplementaryCode;
  }
  private set supplementaryCodeInput(date:any) {
    this.$emit("update:supplementaryCode", date);
  }
   private get amountInput() {
    return this.amount;
  }
  private set amountInput(date:any) {
    this.$emit("update:amount", date);
  }
   private get supplementaryAmountInput() {
    return this.supplementaryAmount;
  }
  private set supplementaryAmountInput(date:any) {
    this.$emit("update:supplementaryAmount", date);
  }
   private get amountOrBudgetRatioInput() {
    return this.amountOrBudgetRatio;
  }
  private set amountOrBudgetRatioInput(depth:any) {
    this.$emit("update:amountOrBudgetRatio", depth);
  }
   private get supplementaryAmountOrBudgetRatioInput() {
    return this.supplementaryAmountOrBudgetRatio;
  }
  private set supplementaryAmountOrBudgetRatioInput(time:any) {
    this.$emit("update:supplementaryAmountOrBudgetRatio", time);
  }
  private get dailyCostInput() {
    return this.dailyCost;
  }
  private set dailyCostInput(time:any) {
    this.$emit("update:dailyCost", time);
  }
  private get cummulativeCostInput() {
    return this.cummulativeCost;
  }
  private set cummulativeCostInput(time:any) {
    this.$emit("update:cummulativeCost", time);
  }
  private get percentageInput() {
    return this.percentage;
  }
  private set percentageInput(time:any) {
    this.$emit("update:percentage", time);
  }
  private get suppPercentageInput() {
    return this.suppPercentage;
  }
  private set suppPercentageInput(time:any) {
    this.$emit("update:suppPercentage", time);
  }
  private get statusInput() {
    return this.status;
  }
  private set statusInput(time:any) {
    this.$emit("update:status", time);
  }
}













































































































































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import baseForm from '../FormComponents/baseFormComponent.vue'
import { required, numeric, between } from 'vuelidate/lib/validators'
import { Validations } from 'vuelidate-property-decorators'
import unitConverter from '../../mixins/unitConverter.ts'

@Component({
    components: {
        baseForm,
    },
    mixins: [ unitConverter]
})
export default class MilestoneComponent extends Vue {
    @Validations()
    validations = {
        milestone: {
            name: {required},
            code: {required},
            start: {required},
            end: {required},
            startDepth: {required, numeric},
            endDepth: {required, numeric},
        }
    }
    
    @Prop()
    activities !:Array<any>

    @Prop()
    projectName !:string

    @Prop()
    milestoneId !:string

    dailyOperationFields:Array<any> = [
        {
            key:"dayIndex",
            label:"S/N"
        },
        {
            key:"date",
            label:"Date"
        },
        {
            key:"depth",
            label:"Depth (m)"
        }
    ]

    milestone: any = {
        id:'',
        name: '',
        code:'',
        description: '',
        start: null,
        end: null,
        startDepth:0,
        endDepth:0,
        parentActivityId:"",
        dailyOperations:[]
    }

    searchValue: string = ''
    activityId : any = ''
    isUpdate:boolean = false
    activeView:number = 1
    formErrors: string[] = []

    get compareAllDepths() {
        let result = false
        this.milestone.dailyOperations.forEach((operations: any) => {
            if (operations.depth <= parseInt(this.milestone.endDepth)) {
                result = false
            } else {
                result = true
            }
        });
        return result
    }

    search(e: any) {
        this.searchValue = e;
    }

    checkErrors() {
        this.formErrors = []

        if(this.milestone.name.length < 1) {
            this.formErrors.push('Milestone Name is Required')
        }
        if(this.milestone.code.length < 1) {
            this.formErrors.push('Milestone Code is Required')
        }
        if(!this.milestone.start) {
            this.formErrors.push('Milestone Start Date is Required')
        }
        if(!this.milestone.end) {
            this.formErrors.push('Milestone End Date is Required')
        }
        if(parseInt(this.milestone.startDepth) > parseInt(this.milestone.endDepth) && this.activeView != 1) {
            this.formErrors.push('Start Depth Can\'t be greater the end depth')
        }
    }

    gotoPrevious(){
        if(this.activeView>1){
            // this.checkErrors()
            this.activeView--
        }
    }

    gotoNext(){
        if(this.activeView<2){
            this.checkErrors()
            this.activeView++
        }else{
            if(!this.isUpdate){
                this.addMilestone()   
            }else{
                this.updateMilestone()
            }
        }
    }

    get presentActivity () {
        return this.$store.getters['ActivityModule/getActiveActivity']
    }

    get activitiesOptions () {
        return this.activities.map((data: any) => {
            return {
                value: data.id,
                text: data.wellActivity.name
            }
        })
    }
    
    get isButtonDisabled(): boolean {
        if(this.formErrors.length > 0) {
            return true
        } else {
            return false
        }
    }

    get milestones () {
        return this.$store.getters['MilestoneModule/filteredMilestoneForActivity'](this.searchValue)
    }


    get startDatePickerMin(){
        if(this.presentActivity){
            return this.presentActivity.start
        }else{
            return new Date()
        }
    }

    get datePickerMax(){
        if(this.presentActivity){
            return this.presentActivity.end
        }else{
            return new Date()
        }
    }

    get endDatePickerMin(){
        if(this.presentActivity){
            return this.presentActivity.start
        }else{
            return new Date()
        }
    }

    computeDuration(start:string,end:string){
        if(start==""||end=="")return 0
        let startDateMill = (new Date(start)).getTime()
        let endDateMill = (new Date(end)).getTime()
        let diff = endDateMill - startDateMill
        return Math.round(diff/(1000 * 3600 * 24))+1
    }

    @Watch("milestone.start")
    onStartDateChanged(){
        let numberOfDays = this.computeDuration(this.milestone.start,this.milestone.end)
        if(numberOfDays>0){
            let newData = []
            for(let i=0;i<numberOfDays;i++){
                let thisDate = new Date(this.milestone.start)
                thisDate.setDate(thisDate.getDate()+i)
                let thisDateString = thisDate.getMonth()+"-"+thisDate.getDate()+"-"+thisDate.getFullYear()
                let existingDateIndex = this.milestone.dailyOperations.findIndex((thisOp:any) => thisOp.dayIndex == i)
                if(existingDateIndex>-1){
                    newData.push({
                        dayIndex :i,
                        date:thisDateString,
                        depth:this.milestone.dailyOperations[existingDateIndex].depth
                    })
                }else{
                    newData.push({
                        dayIndex :i,
                        date:thisDateString,
                        depth:0
                    })
                }
            }
            this.milestone.dailyOperations = newData
        }else{
            this.milestone.dailyOperations = []
        }
    }

    @Watch("milestone.end")
    onEndDateChanged(){
        let numberOfDays = this.computeDuration(this.milestone.start,this.milestone.end)
        if(numberOfDays>0){
            let newData = []
            for(let i=0;i<numberOfDays;i++){
                let thisDate = new Date(this.milestone.start)
                thisDate.setDate(thisDate.getDate()+i)
                let thisDateString = (thisDate.getMonth()+1)+"-"+thisDate.getDate()+"-"+thisDate.getFullYear()
                let existingDateIndex = this.milestone.dailyOperations.findIndex((thisOp:any) => thisOp.dayIndex == i)
                if(existingDateIndex>-1){
                    newData.push({
                        dayIndex :i,
                        date:thisDateString,
                        depth:this.milestone.dailyOperations[existingDateIndex].depth
                    })
                }else{
                    newData.push({
                        dayIndex :i,
                        date:thisDateString,
                        depth:this.milestone.startDepth
                    })
                }
            }
            this.milestone.dailyOperations = newData
        }else{
            this.milestone.dailyOperations = []
        }
    }

    reInitMilestone(){
        this.milestone.id = ''
        this.milestone.name = ''
        this.milestone.description = ''
        this.milestone.start = null
        this.milestone.end = null
        this.milestone.parentActivityId = ""
        this.milestone.code = ""
        this.milestone.dailyOperations = []
        this.milestone.startDepth = 0
        this.milestone.endDepth = 0
        this.activeView = 1
        this.$v.$reset()
    }

    addMilestone () {
        let milestoneToAdd = {
            name: this.milestone.name,
            start: this.milestone.start,
            end: this.milestone.end,
            startDepth:{value:this.milestone.startDepth,uom:(this as any).currentSystem=='si'?'m':'ft'},
            endDepth:{value:this.milestone.endDepth,uom:(this as any).currentSystem=='si'?'m':'ft'},
            code:this.milestone.code,
            dailyOperations:this.milestone.dailyOperations.map((d:any)=>({dayIndex:d.dayIndex,depth:{value:d.depth,uom:(this as any).currentSystem=='si'?'m':'ft'}})),
            description: this.milestone.description,
            parentActivityId:this.presentActivity.id
        }
        
        this.$store.commit('MilestoneModule/addMilestoneToActivity',milestoneToAdd)
        this.reInitMilestone()
    }

    updateMilestone(){
        let milestoneToUpdate = {
            id:this.milestone.id,
            name: this.milestone.name,
            start: this.milestone.start,
            end: this.milestone.end,
            startDepth:{value:this.milestone.startDepth,uom:(this as any).currentSystem=='si'?'m':'ft'},
            endDepth:{value:this.milestone.endDepth,uom:(this as any).currentSystem=='si'?'m':'ft'},
            code:this.milestone.code,
            dailyOperations:this.milestone.dailyOperations.map((d:any)=>({dayIndex:d.dayIndex,depth:{value:d.depth,uom:(this as any).currentSystem=='si'?'m':'ft'}})),
            description: this.milestone.description,
            parentActivityId:this.presentActivity.id
        }
        this.$store.commit('MilestoneModule/updateMilestone',milestoneToUpdate)
        this.reInitMilestone();
        this.isUpdate = false
    }

    editItemClicked(data:any){
        this.milestone.dailyOperations = data.dailyOperations?data.dailyOperations.map((d:any) => ({dayIndex:d.dayIndex,depth:d.depth.value})):[]
        this.milestone.id = data.id
        this.milestone.name = data.name
        this.milestone.start = data.start,
        this.milestone.end = data.end,
        this.milestone.description = data.description
        this.milestone.parentActivityId = this.presentActivity.id
        this.milestone.code = data.code
        this.milestone.startDepth = data.startDepth.value
        this.milestone.endDepth = data.endDepth.value
        this.isUpdate = true
        if(this.isUpdate) {
            this.formErrors = []
        }
        // this.checkErrors()
    }

    cancel () {
        this.milestone.id = ''
        this.milestone.name = ''
        this.milestone.start = null,
        this.milestone.end = null,
        this.milestone.description = ''
        this.milestone.parentActivityId = ''
        this.isUpdate = !this.isUpdate
    }

    deleteItemClicked(data:any){
        this.$store.commit('MilestoneModule/deleteMilestone', data)
        this.reInitMilestone()
    }

    onSelectedActivityChanged(data:any) {
         this.$store.commit("MilestoneModule/setActivityId", this.activityId)
         if(this.milestones.length<1)this.$store.dispatch("MilestoneModule/getMilestonesForActivity")
    }
    
    mounted() {
        this.activityId = this.presentActivity.id
        this.checkErrors()
        if (this.milestoneId) {
            const mile = this.milestones.find((x: any) => x.id === this.milestoneId)
            this.milestone.dailyOperations = mile.dailyOperations?mile.dailyOperations.map((d:any) => ({dayIndex:d.dayIndex,depth:d.depth.value})):[]
            this.milestone.id = mile.id
            this.milestone.name = mile.name
            this.milestone.start = mile.start,
            this.milestone.end = mile.end,
            this.milestone.description = mile.description
            this.milestone.parentActivityId = this.presentActivity.id
            this.milestone.code = mile.code
            this.milestone.startDepth = mile.startDepth.value
            this.milestone.endDepth = mile.endDepth.value
            this.checkErrors()
        }
    }
}



































import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export default class Profile extends Vue {
    activeScreen: string = 'main'
    error: any = {
        generalMessage: '',
        errors: []
    }
    // activeUser : any = this.$store.state.activeUser
    loadingMessage: string = ''
    loadingTitle: string = ''
    showPreview: boolean = false
    uploadedPhoto: any
    user: any = {
        firstName: '',
        lastName: '',
        jobTitle: ''
    }
    links: Array<object> = [
        {
            text: 'User Accounts',
            to: '/users'
        },
        {
            text: 'Edit My Profile',
            active: true
        }
    ]


    fileUploaded(e: any){
        var context = this
        var reader  = new FileReader();
        reader.addEventListener("load", () => {
                context.showPreview = true;
                context.uploadedPhoto = reader.result
        }, false);
        if(e.target.files[0]){
            reader.readAsDataURL(e.target.files[0]);
            this.user.photo = e.target.files[0];
        }
    }



    created() {
        window.console.log(this.$store.state.activeUser)   
    }
}
















































































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import project from '@/store/modules/project';
import ScopeOfWork from '../scopeOfWork'
import SwapStates from '../../utils/SwapState.js'

@Component({
    components:{
        'scope-of-work':ScopeOfWork
    }
})
export default class InformationCost extends Vue {

    private projectModel:any = {name:"",id:"",start:"",end:"",well:"",funding:"",duration:0,projectType:"",textColor:""}
    private view: string = 'General';
    private wellProperties: boolean = false;
    private deletePromptProperty: boolean = false;
    private wells: any[] = []
    private fundingMechanism: any[] = []
    private endDateChangedByDuration:boolean = false
    private showScopeOfWork:boolean = false
    private projectToSwapTo = null


    get project() {
        return this.$store.getters['projectModule/getSelectedProject']
    }

    get allowSwap(){
        if(this.project)return new Date(this.project.start).getTime()>new Date().getTime()
        return false
    }

    get swapableProjects(){
        if(!this.project) return []
        if(this.project.isRigless){
            return this.$store.getters['projectModule/getProjectsForSequence'](this.selectedSequence.Id).filter((p:any) => {
                return (p.isRigless)&&
                        (p.id!=this.project.id)&&
                        (new Date(p.start).getTime()>new Date().getTime())
            }).map((p:any) => {
                return {text:p.name,value:p.id}
            })
        }else{
            return this.$store.getters['projectModule/getProjectsForSequence'](this.selectedSequence.Id).filter((p:any) => {
                return (
                        (!p.isRigless)&&
                        ((p.rig.id == this.project.rig.id)||(p.rig.terrain == this.project.rig.terrain))&&
                        (p.id!=this.project.id)&&
                        (new Date(p.start).getTime()>new Date().getTime())
                )
            }).map((p:any) => {
                return {text:p.name,value:p.id}
            })
        }
    }

    get selectedSequence() {
        return this.$store.getters['projectModule/getSelectedSequence']
    }

    get filteredWells () {
        return this.$store.getters['wellsModule/filteredWells']
    }

    get wellOptions () {
        return this.filteredWells('').map((data: any) => {
            return {text: data.name, value: data.id}
        })
    }

    get rigOptions(){
        let rigOptionsToReturn:Array<any> = []
        this.$store.state.rigsModule.rigs.forEach((data:any) => {
             rigOptionsToReturn.push({text:data.name, value:data.id, type:"rig"})
        })
        this.$store.getters["sequencesModule/getRiglessBucketsForSequence"](this.selectedSequence.Id).forEach((data:any) => rigOptionsToReturn.push({text:data.alias, value:data.id, type:"rigless"}) )
        return rigOptionsToReturn
    }

    get getFundings () {
        return this.$store.getters['fundingsModule/getFundings']
    }

    get fundingOptions () {
        return this.getFundings.map((data: any) => {
            return {text: data.name, value: data.id}
        })
    }

    gotoTaskMasterLayer(){
        // window.console.log("Go to task master layer clicked")
    }

    doDelete() {           
        this.$store.commit("setRightPaneToFalse")
        this.$store.commit('projectModule/deleteProject', this.project)
    }

    otherTabFunction (tabName: string) {
        this.view = tabName
    }

    addDays(date:string, daysToAdd:any) {
        var result = null
        if(typeof(date)=="string"){
            let splitedDate = date.split('-')
            result = new Date(Number.parseInt(splitedDate[0]),Number.parseInt(splitedDate[1])-1,Number.parseInt(splitedDate[2]));
        }else{
            result = date
        }
        if(daysToAdd=="") return result
        result.setDate(result.getDate() + Number.parseInt(daysToAdd));
        return result;
    }

    computeDuration(start:string,end:string){
        if(start==""||end=="")return 0
        let startDateMill = (new Date(start)).getTime()
        let endDateMill = (new Date(end)).getTime()
        let diff = endDateMill - startDateMill
        if(diff<1)return 1
        return Math.round(diff/(1000 * 3600 * 24))
    }

    getWellById(id:any){
        return this.filteredWells('').find((thisWell:any) => thisWell.id==id)
    }

    getRigById(id:any){
        return this.$store.state.rigsModule.rigs.find((thisRig:any) => thisRig.id==id)
    }

    getRiglessBucketById(id:any){
        return this.$store.getters["sequencesModule/getRiglessBucketsForSequence"](this.selectedSequence.Id).find((thisRiglessBucket:any) => thisRiglessBucket.id==id)
    }

    getFundingById(id:any){
        return this.getFundings.find((thisFunding:any) => thisFunding.id == id)
    }

    closePropertyGrid(){
        this.$store.commit("setRightPaneToFalse")
    }

    onProjectSwaped(swappedProjectId:any){
        let projectToSwapWith = this.$store.getters["projectModule/getProjectById"](swappedProjectId)
        if(projectToSwapWith){
            let projectToSwapWithStart = projectToSwapWith.start
            let projectToSwapWithEnd = projectToSwapWith.end
            
            let projectToSwapStart = this.project.start
            let projectToSwapEnd = this.project.end

            let newProjectToSwapEnd:any = this.addDays(projectToSwapWithStart,this.computeDuration(projectToSwapStart,projectToSwapEnd))
            let newProjectToSwapWithEnd:any = this.addDays(projectToSwapStart,this.computeDuration(projectToSwapWithStart,projectToSwapWithEnd))

            let newProjectToSwapEndMonth = newProjectToSwapEnd.getMonth()+1
            let newProjectToSwapEndDate = newProjectToSwapEnd.getDate()
            if(newProjectToSwapEndMonth<10)newProjectToSwapEndMonth = "0"+newProjectToSwapEndMonth
            if(newProjectToSwapEndDate<10)newProjectToSwapEndDate = "0"+newProjectToSwapEndDate
            newProjectToSwapEnd = newProjectToSwapEnd.getFullYear()+"-"+newProjectToSwapEndMonth+"-"+newProjectToSwapEndDate
            
            let newProjectToSwapWithEndMonth = newProjectToSwapWithEnd.getMonth()+1
            let newProjectToSwapWithEndDate = newProjectToSwapWithEnd.getDate()
            if(newProjectToSwapWithEndMonth<10)newProjectToSwapWithEndMonth = "0"+newProjectToSwapWithEndMonth
            if(newProjectToSwapWithEndDate<10)newProjectToSwapWithEndDate = "0"+newProjectToSwapWithEndDate
            newProjectToSwapWithEnd = newProjectToSwapWithEnd.getFullYear()+"-"+newProjectToSwapWithEndMonth+"-"+newProjectToSwapWithEndDate
            
            this.$store.commit("projectModule/setSwapState",SwapStates.MOVING_INTO_POSITIONS)
            if(!this.project.isRigless){
                if(!this.project.rig.id != projectToSwapWith.rig.id){
                    let sequence = this.$store.getters["sequencesModule/getSequence"](this.selectedSequence.Id)
                    let rigToSwapTo = sequence.rigs.find((r:any) => projectToSwapWith.rig.id == r.id) 
                    let rigToSwapFrom = sequence.rigs.find((r:any) => this.project.rig.id == r.id)
                
                    this.$store.commit("projectModule/updateProject",{sequenceId:this.selectedSequence.Id,id:this.projectModel.id,rig:rigToSwapTo})
                    this.$store.commit("projectModule/updateProject",{sequenceId:this.selectedSequence.Id,id:projectToSwapWith.id,rig:rigToSwapFrom})
                }
            }else{
                if(this.project.riglessBucket.id != projectToSwapWith.riglessBucket.id){
                    let sequence = this.$store.getters["sequencesModule/getSequence"](this.selectedSequence.Id)
                    let riglessBucketToSwapTo = sequence.riglessBuckets.find((r:any) => projectToSwapWith.riglessBucket.id == r.id) 
                    let riglessBucketToSwapFrom = sequence.riglessBuckets.find((r:any) => this.project.riglessBucket.id == r.id)
                
                    this.$store.commit("projectModule/updateProject",{sequenceId:this.selectedSequence.Id,id:this.projectModel.id,riglessBucket:riglessBucketToSwapTo})
                    this.$store.commit("projectModule/updateProject",{sequenceId:this.selectedSequence.Id,id:projectToSwapWith.id,riglessBucket:riglessBucketToSwapFrom})
                }
            }
            
            Vue.nextTick(() => {
                this.$store.commit("projectModule/updateProject",{sequenceId:this.selectedSequence.Id,id:this.projectModel.id,start:projectToSwapWithStart,end:newProjectToSwapEnd})
                this.$store.commit("projectModule/updateProject",{sequenceId:this.selectedSequence.Id,id:projectToSwapWith.id,start:projectToSwapStart,end:newProjectToSwapWithEnd})
                this.$store.commit("projectModule/setSwapState",SwapStates.SWAP_COMPLETED)
                this.projectToSwapTo = null
            })
        }
    }

    @Watch("project",{immediate: true,deep:true})
    onProjectChange(){
        if(this.project){
            this.projectModel.name = this.project.name
            this.projectModel.id = this.project.id
            this.projectModel.start = this.project.start
            this.projectModel.end = this.project.end
            this.projectModel.well = this.project.well.id
            this.projectModel.duration = this.computeDuration(this.project.start,this.project.end)
            this.projectModel.projectType = this.project.projectType
            this.projectModel.textColor = this.project.textColor
            if(this.project.isRigless){
                this.projectModel.riglessBucket = this.project.riglessBucket.id
            }else{
                this.projectModel.rig = this.project.rig.id
            }
            if(this.project.funding) {
                this.projectModel.funding = this.project.funding.id
            }else{
                this.projectModel.funding = null
            }
        }
    }

    openScopeOfWorkModal(){
        this.showScopeOfWork = true
    }

    OnNamePropertyChanged(){
        this.$store.commit('projectModule/updateProject',{sequenceId:this.selectedSequence.Id,id:this.projectModel.id,name:this.projectModel.name})
    }

    OnDurationPropertyChanged(newVal:number){
        let newEndDate = this.addDays(this.projectModel.start,this.projectModel.duration)
        this.projectModel.end = newEndDate
        this.OnEndDateChanged()
    }         

    OnStartDateChanged(){
        this.$store.commit('projectModule/updateProject',{sequenceId:this.selectedSequence.Id,id:this.projectModel.id,start:this.projectModel.start})
        this.projectModel.duration = this.computeDuration(this.projectModel.start,this.projectModel.end)
    }

    OnEndDateChanged(){
        this.$store.commit('projectModule/updateProject',{sequenceId:this.selectedSequence.Id,id:this.projectModel.id,end:this.projectModel.end})
        this.projectModel.duration = this.computeDuration(this.projectModel.start,this.projectModel.end)
    }

    OnWellChanged(){
        this.$store.commit('projectModule/updateProject',{sequenceId:this.selectedSequence.Id,id:this.projectModel.id, well:this.getWellById(this.projectModel.well)})
        this.$store.commit('projectModule/updateProject',{sequenceId:this.selectedSequence.Id,id:this.projectModel.id,name: this.project.well.name})
    }

    OnRigChanged(){
        let rig = this.rigOptions.find(r=>r.value==this.projectModel.rig)
        // window.console.log(JSON.stringify(rig,null,4))
        if(rig.type=='rigless'){
            this.$store.commit('projectModule/updateProject',{sequenceId:this.selectedSequence.Id,id:this.projectModel.id, riglessBucket:this.getRiglessBucketById(rig.value), isRigless:true})
        }else{
            this.$store.commit('projectModule/updateProject',{sequenceId:this.selectedSequence.Id,id:this.projectModel.id, rig:this.getRigById(rig.value)})
        }
    }

    OnFundingChanged(){
        this.$store.commit('projectModule/updateProject',{sequenceId:this.selectedSequence.Id,id:this.projectModel.id,funding:this.getFundingById(this.projectModel.funding)})
    }

    onProjectTypeChanged(){
        this.$store.commit('projectModule/updateProject',{sequenceId:this.selectedSequence.Id,id:this.projectModel.id,projectType:this.projectModel.projectType})
    }

    mounted () {
        if (this.filteredWells('').length === 0) {
            this.$store.dispatch('wellsModule/getWellsAction')
        }
        if (this.getFundings.length === 0) {
            this.$store.dispatch('fundingsModule/fetchFundings')
        }
    }
}








































































import {Vue, Component} from 'vue-property-decorator'

@Component
export default class ApplicationLog extends Vue {
    loadingMessage: string = 'Loading Application Logs'
    loadingTitle: string = 'Loading...'
    additionalSuccessMessage: string = ''
    successMessage: string = ''
    selcetedModel = null
    selcetedDate = null
    selcetedUser = null
    search = ''
    showNotifications = false

    get filteredTable() {
        return this.logs.filter((l: any) => (l.fullName == this.selcetedUser||this.selcetedUser==null)&&(l.modelChanged === this.selcetedModel||this.selcetedModel==null)&&(l.date===this.selcetedDate||this.selcetedDate==null) )
    }

    get model () {
        let models = [
            {text: 'All Models', value: null}
        ]
        if (this.logs && this.logs.length !== 0) {
            this.logs.forEach((element: any) => {
                const mode = models.findIndex((x: any) => x.value === element.modelChanged)
                if (mode > -1) {
                    return false
                } else {
                    models.push({text:element.modelChanged, value: element.modelChanged})
                }
            });
        }
        return models
    }
    get unreadNotifications():Number{
        return this.$store.getters["Notifications/getNumberOfUnreadNotifications"]
    }

    get dates () {
        let dates = [
            {text: 'All Dates', value: null}
        ]
        if (this.logs && this.logs.length !== 0) {
            this.logs.forEach((element: any) => {
                const mode = dates.findIndex(x => x.value === element.date)
                if (mode > -1) {
                    return false
                } else {
                    dates.push({text:element.date, value: element.date})
                }
            });
        }
        return dates
    }

    get users () {
        let users = [
            {text: 'All Users', value: null}
        ]
        if (this.logs && this.logs.length !== 0) {
            this.logs.forEach((element: any) => {
                const mode = users.findIndex(x => x.value === element.fullName)
                if (mode > -1) {
                    return false
                } else {
                    users.push({text:element.fullName, value: element.fullName})
                }
            });
        }
        return users
    }

    logFields = [
        { key: 'index', label: 'S/N' },
        { key: 'fullName', label: 'User' },
        { key: 'modelChanged', label: 'Model Changed' },
        { key: 'description', label: 'Description' },
        { key: 'date', label: 'Event Date' },
        { key: 'time', label: 'Event Time' },
    ]

    get logs () {
        return this.$store.getters['ApplicationLogModule/getLogs']
    }

    get sidenav () {
        return this.$store.getters['getSideNavStatus']
    }

    visible: boolean = false

    get activeUser(){
        return this.$store.getters['activeUser']
    }

    help(){
        this.$router.push("/help")
    }
    
    toggleSideNav(){
        this.$store.commit('setSideNav')
    }

    logout(){
        this.$router.push("/")
        this.$store.dispatch("logOut")
    }

    editProfile(){
        let path : object = { path: '/manage-user', query:{id:this.activeUser.id}  }
        this.$router.push(path)
    }

    fetchLogs() {
        return this.$store.dispatch('ApplicationLogModule/getApplicationLogs')
    }
     onNotificationClicked(){
        this.showNotifications=!this.showNotifications
        this.$store.dispatch("Notifications/updateNotificationStatus")
    }
    mounted () {
        this.fetchLogs()
    }
}

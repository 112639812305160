
























import Vue from "vue"
export default Vue.extend({
    name:"AAROverview",
    props:["projectId"],
    data(){
        return {
            fields:[
               "projectName", "activityName","reasonForDeviationFromPlannedDays","reasonForDeviationFromPlannedActivities","correctiveActions"
            ]
        }
    },
    computed:{
        aars():Array<any>{
            return this.$store.getters["AAR/getAARsForProject"](this.projectId)
        }
    },
    mounted(){
        if(this.aars.length<1){
            this.$store.dispatch("AAR/getAARsForProject",this.projectId)
        }
    }
})

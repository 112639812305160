





































































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import UnitConverter from '../../mixins/unitConverter.ts'

@Component({
  mixins: [UnitConverter]
})
export default class Bits extends Vue {
  @Prop({}) 
  bha!:number;
  @Prop({}) 
  bitSize!:number;
  @Prop({}) 
  modelNo!:string;
  @Prop({}) 
  bitType!:string;
  @Prop({}) 
  iadcNo!:number;
  @Prop({}) 
  serialNo!:number;
  @Prop({}) 
  bitHours!:number;
  @Prop({}) 
  bitNozzless!:number;
  @Prop({}) 
  tfa!:number;
  @Prop({}) 
  impact!:number;
  @Prop({}) 
  jetVelocity!:number;
  @Prop({}) 
  rop!:number;
  @Prop({}) 
  rpm!:number;
  @Prop({}) 
  averageCummulative!:number;
  @Prop({}) 
  wob!:number;
  @Prop({}) 
  bitRevolution!:number;
  @Prop({}) 
  bitRotationHours!:number;
  @Prop({}) 
  footage!:number;

  private get BHAInput() {
    return this.bha;
  }
  private set BHAInput(value:any) {
    this.$emit("update:bha", value);
  }
   private get bitSizeInput() {
    return this.bitSize;
  }
  private set bitSizeInput(value: any) {
    this.$emit("update:bitSize", value);
  }
   private get modelNoInput() {
    return this.modelNo;
  }
  private set modelNoInput(value: any) {
    this.$emit("update:modelNo", value);
  }
   private get bitTypeInput() {
    return this.bitType;
  }
  private set bitTypeInput(value: any) {
    this.$emit("update:bitType", value);
  }
  private get IADCNoInput() {
    return this.iadcNo;
  }
  private set IADCNoInput(value: any) {
    this.$emit("update:iadcNo", value);
  }
   private get serialNoInput() {
    return this.serialNo;
  }
  private set serialNoInput(value: any) {
    this.$emit("update:serialNo", value);
  }
   private get bitHoursInput() {
    return this.bitHours;
  }
  private set bitHoursInput(value: any) {
    this.$emit("update:bitHours", value);
  }
   private get bitNozzlessInput() {
    return this.bitNozzless;
  }
  private set bitNozzlessInput(value: any) {
    this.$emit("update:bitNozzless", value);
  }
     private get TFAInput() {
    return this.tfa;
  }
  private set TFAInput(value: any) {
    this.$emit("update:tfa", value);
  }
     private get impactInput() {
    return this.impact;
  }
  private set impactInput(value: any) {
    this.$emit("update:impact", value);
  }
     private get jetVelocityInput() {
    return this.jetVelocity;
  }
  private set jetVelocityInput(value: any) {
    this.$emit("update:jetVelocity", value);
  }
     private get ROPInput() {
    return this.rop;
  }
  private set ROPInput(value: any) {
    this.$emit("update:rop", value);
  }
  private get RPMInput() {
    return this.rpm;
  }
  private set RPMInput(value: any) {
    this.$emit("update:rpm", value);
  }
  private get averageCummulativeInput() {
    return this.averageCummulative;
  }
  private set averageCummulativeInput(value: any) {
    this.$emit("update:averageCummulative", value);
  }
  private get footageInput() {
    return this.footage;
  }
  private set footageInput(value: any) {
    this.$emit("update:footage", value);
  }
  private get wobInput() {
    return this.wob;
  }
  private set wobInput(value: any) {
    this.$emit("update:wob", value);
  }
  private get bitRevolutionInput() {
    return this.bitRevolution;
  }
  private set bitRevolutionInput(value: any) {
    this.$emit("update:bitRevolution", value);
  }
  private get bitRotationHoursInput() {
    return this.bitRotationHours;
  }
  private set bitRotationHoursInput(value: any) {
    this.$emit("update:bitRotationHours", value);
  }
}






















































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class Safety extends Vue {
   @Prop({})  lastBOPDrill!:any;
   @Prop({})  nextBOPTest!:any;
   @Prop({})  lastSafetyMeeting!:any;
   @Prop({})  lastFireDrill!:any;
   @Prop({})  lastBOPTest!:any;
   @Prop({})  lastCsgPres!:any;

  
  private get lastFireDrillInput() {
    return this.lastFireDrill;
  }
  private set lastFireDrillInput(date:any) {
    this.$emit("update:lastFireDrill", date);
  }
  private get lastBOPDrillInput() {
    return this.lastBOPDrill;
  }
  private set lastBOPDrillInput(date:any) {
    this.$emit("update:lastBOPDrill", date);
  }
   private get nextBOPTestInput() {
    return this.nextBOPTest;
  }
  private set nextBOPTestInput(date:any) {
    this.$emit("update:nextBOPTest", date);
  }
   private get lastSafetyMeetingInput() {
    return this.lastSafetyMeeting;
  }
  private set lastSafetyMeetingInput(date:any) {
    this.$emit("update:lastSafetyMeeting", date);
  }
  private get lastBOPTestInput() {
    return this.lastBOPTest;
  }
  private set lastBOPTestInput(date:any) {
    this.$emit("update:lastBOPTest", date);
  }
  private get lastCsgPresInput() {
    return this.lastCsgPres;
  }
  private set lastCsgPresInput(date:any) {
    this.$emit("update:lastCsgPres", date);
  }
}










































































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import EventBus from './../../eventBus'
import { HTTP } from '@/axios_api/http-common'
import { required,minLength,numeric } from "vuelidate/lib/validators";
import { Validations } from 'vuelidate-property-decorators';
import UnitConverter from '../../mixins/unitConverter.ts'
import convertUnit from '../../utils/convertUnit.ts'

@Component({
    mixins: [UnitConverter]
})
export default class ManageRig extends Vue {
    activeScreen: string = 'main'
    contractForm: boolean = false
    loadingMessage: string = 'Please wait while we create your new rig'
    loadingTitle: string = 'Saving...'
    additionalSuccessMessage: string = 'You can now use the newly created rig'
    successMessage: string = 'New Rig Created Successfully'
    links: Array<object> = [
        {
            text: 'Rigs',
            to: '/rigs'
        },
        {
            text: 'Manage Rigs',
            active: true
        }
    ]
    @Validations()
    validations = {
        rig: {
            name: {required, minLength: minLength(3)}, 
            owner:{required, minLength: minLength(3)},
            // type: {required},
            terrain: {required},
            minimumHPRating: {required},
            minimumDrillingDepth: {required},
            hookLoadCapacityRating: {required},
            rotarySwivelMinWorkingpressure: {required},
            rotarySwivelAPIDeadLoadRating: {required},
            travelingBlockMinApiWorkingPressure: {required},
        }
    }
    errorMessage: boolean = false
    isCreate: boolean = true
    error: any = {
        generalMessage: '',
        errors: []
    }
    uploadedPhoto: any
    showPreview: boolean = false
    bopFields: Array<any> =[
        {key: 'sn', label: 'S/N'},
        // {key: 'type',label: 'Type'},
        {key: 'size',label: 'Size'},
        {key: 'rating', label: 'Rating'},
        {key: 'button', label: 'Action'}
    ]
    bopTypes: Array<any> = [
        {text: 'annular preventer', value: 'annular preventer'},
        {text: 'shear ram', value: 'shear ram'},
        {text: 'blind ram', value: 'blind ram'},
        {text: 'Pipe ram', value: 'pipe ram'}
    ]
    rigTypes: Array<any> = [
        {text: 'Pulling Unit', value: 'Pulling_Unit'},
        {text: 'Workover', value: 'WorkOver'},
        {text: 'Drilling', value: 'Drilling'},
        {text: 'Sidetrack', value: 'SIDETRACK'},
        {text: 'Others', value: 'OTHERS'}
    ]
    terrains: Array<any> =[
        {text: 'Land', value: 'Land'},
        {text: 'Swamp', value: 'Swamp'},
        {text: 'Offshore',value: 'Offshore'},
        {text: 'Amphibious',value: 'Amphibious'}
    ]
    selected: any = null
    types: Array<any> = ['one', 'two', 'three', 'four', 'five']
    stats: Array<any> = ['Active', 'Not active', 'Ongoing']
    rig: any ={
        id:null,

        name: '', 
        owner: '',
        yearBuilt: new Date(), 

        // type: null, 
        minimumHPRating: 0,
        minimumDrillingDepth: 0, 

        terrain: null,
        hookLoadCapacityRating: 0,

        rotarySwivelMinWorkingpressure: 0,
        rotarySwivelAPIDeadLoadRating: 0,
        travelingBlockMinApiWorkingPressure: 0,
        
        additionalComment: '',
        bopStack:[]
        
    }
    bop: any = {
        size: 0,
        type: null,
        rating: 0
    }
    view: string = 'contract'
    contract: any = {
        rigId:"",
        startDate: this.formatDate(new Date()),
        endDate: this.formatDate(new Date()),
        contractNumber: 0,
        rigContractType:"DAY_RATE",
        isActive: false,
    }
    contractFields: Array<any> = [
        {key: 'number', label: 'S/N'},
        {key: 'startDate', label: 'Start Date'},
        {key: 'endDate', label: 'End Date'},
        {key: 'contractNumber', label: 'Certificate No.'},
        {key: 'active', label: 'Active Contract'},
        {key: 'actions', label: 'Actions'},
    ]
    
    loading: boolean = false
    status: string = 'create'
    formError: string[] = []

    get isButtonDisabled ():boolean {
        if(this.formError.length > 0 || this.$v.rig.$invalid) {
            return true
        } else {
            return false
        }
    }

    // METHODS
    otherTabFunction (tabName: string) {
        this.view = tabName
    }

    checkErrors() {
        this.formError = []

        if (this.rig.name.length < 1) {
            this.formError.push("Rig Name is required")
        }
        if (this.rig.owner.length < 1) {
            this.formError.push("Rig Owner is required")
        }
        // if (!this.checkRigType) {
        //     this.formError.push("Rig Type is required")
        // }
        if (!this.checkTerrain) {
            this.formError.push("Rig Terrain is required")
        }
        if (!this.rig.hookLoadCapacityRating) {
            this.formError.push("Hook Load Capacity Rating is required")
        }
    }

    get checkTerrain (): boolean {
        if (!this.rig.terrain) {
            return false
        } else {
            return true
        }
    }

    addNewContract () {
        this.contractForm = true
        this.status = 'create'
        this.contract = {
            startDate: this.formatDate(new Date()),
            endDate: this.formatDate(new Date()),
            contractNumber: 0,
            isActive: true,
            rigContractType:"DAY_RATE",
        }
    }

    changeIsActiveAction (state:string,id:any) {
        switch(state){
            case "CONFIRM":
                this.contract.id = id
                this.$bvModal.show('activatemodal')
            break;
            case "activate":
                this.loading = true
                this.$store.dispatch("rigsModule/activateRigContract",{id:this.contract.id,rigId:this.rig.id}).then(()=>{
                    this.loading = false
                    this.reInitRigContract()
                    this.$bvModal.hide('activatemodal')
                })
            break;
        }
    }

    deleteRigContractAction (state:string,id:any) {
        switch(state){
            case "CONFIRM":
                this.contract.id = id
                this.$bvModal.show('deletemodal')
            break;
            case "delete":
                this.loading = true
                this.$store.dispatch("rigsModule/deleteRigContract",{id:this.contract.id,rigId:this.rig.id}).then(()=>{
                    this.loading = false
                    this.reInitRigContract()
                    this.$bvModal.hide('deletemodal')
                })
            break;
        }
    }

    formatDate(date:any) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;

        return [year, month, day].join('-');
    }

    hideContractModal () {
        this.contractForm = false
    }

    editRigContract(data:any) {
        this.contract.id = data.id
        this.contract.startDate = data.startDate
        this.contract.endDate = data.endDate
        this.contract.isActive = data.isActive
        this.contract.contractNumber = data.contractNumber
        this.contract.rigContractType = data.rigContractType
        this.contract.rigId = data.rigId
        this.status = 'update'
        this.contractForm = true
    }

    reInitRigContract(){
        this.contract = {
            startDate: this.formatDate(new Date()),
            endDate: this.formatDate(new Date()),
            contractNumber: 0,
            isActive: false,
        }
    }

    saveContract () {
        this.loading = true
        if (this.status === 'create') {
            this.$store.dispatch("rigsModule/createRigContract",this.contract).then(()=>{
                this.reInitRigContract()
                this.loading = false   
                this.contractForm = false
            }).catch(() =>  {
                this.loading = false   
                this.contractForm = false
            })
            this.contract.rigId = this.rig.id
        } else {
            this.$store.dispatch("rigsModule/updateRigContract",this.contract).then(()=>{
                this.reInitRigContract()
                this.loading = false   
                this.contractForm = false
            }).catch(()=>{
                this.loading = false   
                this.contractForm = false
            })
        }
    }

    fileUploaded(e: any) {
        let file = e.target.files[0]
        this.rig.photo = file
        let reader = new FileReader()
        reader.readAsDataURL(e.target.files[0]) 
        this.showPreview = true;
        reader.onload = (e: any) => {
            const selectedImage = this.$refs.selectedImage as any
            selectedImage.setAttribute("src", e.target.result)
            this.uploadedPhoto = e.target.result
        }
    }

    removePhoto(e: any){
        this.rig.photo = undefined;
        this.showPreview = false
        const selectedImage = this.$refs.selectedImage as any
        selectedImage.setAttribute("src", '')
        this.uploadedPhoto = undefined
    }

    outGoingDataTransformation (formData: any) {
        formData.append('Name', this.rig.name)
        // formData.append('RigType', this.rig.type)
        formData.append('Terrain', this.rig.terrain)

        formData.append('MinimumHPRating', this.rig.minimumHPRating)
        formData.append('MaximumDrillDepth', this.rig.minimumDrillingDepth)
        formData.append('Photo', this.rig.photo)

        formData.append('Owner', this.rig.owner)
        formData.append('YearBuilt', this.rig.yearBuilt.toISOString())

        formData.append('HookLoadCapacityRating', this.rig.hookLoadCapacityRating)
        formData.append('RotarySwivelMinWorkingpressure', this.rig.rotarySwivelMinWorkingpressure)
        formData.append('RotarySwivelAPIDeadLoadRating', this.rig.rotarySwivelAPIDeadLoadRating)
        formData.append('TravelingBlockMinApiWorkingPressure', this.rig.travelingBlockMinApiWorkingPressure)
        
        formData.append('AdditionalComment', this.rig.additionalComment)
    }

    incomingDataTransformation(rig: any){            
        this.rig.name = rig.name
        this.rig.owner = rig.owner
        this.rig.yearBuilt=new Date(rig.yearBuilt)

        // this.rig.type = rig.rigType
        //this.rig.minimumHPRating= convertUnit(rig.minimumHPRating.value, this.currentUnitSystem, rig.minimumHPRating.uom)
        this.rig.minimumHPRating= rig.minimumHPRating.value
        this.rig.minimumDrillingDepth = convertUnit(rig.minimumDrillDepth.value, this.currentUnitSystem, rig.minimumDrillDepth.uom)

        this.rig.terrain=rig.terrain
        //this.rig.hookLoadCapacityRating= convertUnit(rig.hookLoadCapacityRating.value, this.currentUnitSystem, rig.hookLoadCapacityRating.uom)
        this.rig.hookLoadCapacityRating= rig.hookLoadCapacityRating.value

        this.rig.rotarySwivelMinWorkingpressure= convertUnit(rig.rotarySwivelMinWorkingpressure.value, this.currentUnitSystem, rig.rotarySwivelMinWorkingpressure.uom)
        //this.rig.rotarySwivelAPIDeadLoadRating= convertUnit(rig.rotarySwivelAPIDeadLoadRating.value, this.currentUnitSystem, rig.rotarySwivelAPIDeadLoadRating.uom)
        this.rig.rotarySwivelAPIDeadLoadRating= rig.rotarySwivelAPIDeadLoadRating.value
        this.rig.travelingBlockMinApiWorkingPressure= convertUnit(rig.travelingBlockMinApiWorkingPressure.value, this.currentUnitSystem, rig.travelingBlockMinApiWorkingPressure.uom)

        this.rig.additionalComment=rig.additionalComment

        this.rig.id=rig.id

        this.rig.rigContracts = rig.rigContracts

        if(rig.photo){
            this.uploadedPhoto = "data:image/png;base64," + rig.photo
            this.rig.photo = null
            this.showPreview = true
        }
    }
    // incomingDataTransformation(rig: any){            
    //     this.rig.name = rig.name
    //     this.rig.owner = rig.owner
    //     this.rig.yearBuilt=new Date(rig.yearBuilt)

    //     // this.rig.type = rig.rigType
    //     this.rig.minimumHPRating= convertUnit(rig.minimumHPRating.value, this.currentUnitSystem, rig.minimumHPRating.uom)
    //     this.rig.minimumDrillingDepth = convertUnit(rig.minimumDrillDepth.value, this.currentUnitSystem, rig.minimumDrillDepth.uom)

    //     this.rig.terrain=rig.terrain
    //     this.rig.hookLoadCapacityRating= convertUnit(rig.hookLoadCapacityRating.value, this.currentUnitSystem, rig.hookLoadCapacityRating.uom)

    //     this.rig.rotarySwivelMinWorkingpressure= convertUnit(rig.rotarySwivelMinWorkingpressure.value, this.currentUnitSystem, rig.rotarySwivelMinWorkingpressure.uom)
    //     this.rig.rotarySwivelAPIDeadLoadRating= convertUnit(rig.rotarySwivelAPIDeadLoadRating.value, this.currentUnitSystem, rig.rotarySwivelAPIDeadLoadRating.uom)
    //     this.rig.travelingBlockMinApiWorkingPressure= convertUnit(rig.travelingBlockMinApiWorkingPressure.value, this.currentUnitSystem, rig.travelingBlockMinApiWorkingPressure.uom)

    //     this.rig.additionalComment=rig.additionalComment

    //     this.rig.id=rig.id

    //     this.rig.rigContracts = rig.rigContracts

    //     if(rig.photo){
    //         this.uploadedPhoto = "data:image/png;base64," + rig.photo
    //         this.rig.photo = null
    //         this.showPreview = true
    //     }
    // }

    onSubmit (evt: any){
        evt.preventDefault();
        this.error.generalMessage = ''
        
        if(this.isCreate){
            this.activeScreen = 'loading'
            this.loadingTitle = 'Saving...'
            this.loadingMessage = "Please wait while we create new rig."
            EventBus.$emit('activeScreen', this.activeScreen)
            let formData = new FormData();
            this.outGoingDataTransformation(formData)
            this.createRig(formData).then((response: any) => {
                this.activeScreen = 'successRemain'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.successMessage = 'Successfully Created '+ response.data.data.name
                this.additionalSuccessMessage = 'You can now use the newly created rig'
                this.rig.name = ''
                this.rig.owner = ''
                this.rig.yearBuilt= new Date()

                // this.rig.type = ''
                this.rig.minimumHPRating = 0
                this.rig.minimumDrillingDepth = 0

                this.rig.terrain = ''
                this.rig.hookLoadCapacityRating = 0

                this.rig.rotarySwivelMinWorkingpressure = 0
                this.rig.rotarySwivelAPIDeadLoadRating = 0
                this.rig.travelingBlockMinApiWorkingPressure= 0

                this.rig.additionalComment = ''

                this.rig.id = 0
                this.rig.photo = null
                this.$v.$reset()
                this.showPreview = false
            },(error: any) => { 
                this.error.generalMessage = error.response.data.message
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
            })
        }else{
            this.activeScreen = 'loading'
            this.loadingTitle = 'Saving...'
            this.loadingMessage = "Please wait while we update this rig."
            EventBus.$emit('activeScreen', this.activeScreen)
            let formData = new FormData();
            this.outGoingDataTransformation(formData)
            this.updateRigAction({id: this.rig.id, rig: formData}).then(response => {
                this.activeScreen = 'successNoGotIt'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.successMessage = 'Rig Updated Successfully'
                this.additionalSuccessMessage = ''
            },error => {        
                this.error.generalMessage = error.response.data.message
                this.activeScreen = 'error'
                EventBus.$emit('activeScreen', this.activeScreen)
            })
        }
    }



    // VUEX METHODS
    updateRigAction (rig: any) {
        return this.$store.dispatch('rigsModule/updateRigAction', rig)
    }

    createRig (rig_object: any) {
        return this.$store.dispatch('rigsModule/createRig', rig_object)
    }

    getRigs () {
        return this.$store.dispatch('rigsModule/getRigs')
    }

    // COMPUTED PROPERTIES

    get rigContracts(){
        // window.console.log(JSON.stringify(this.rig.rigContracts,null,4))
        if(this.rig.rigContracts)return this.rig.rigContracts
        return []
    }
    get getRigById () {
        return this.$store.getters['rigsModule/getRigById']
    }
    get currentUnitSystem(){
        return this.$store.getters['UnitsModule/getCurrentUnitSystem']
    }

    // CREATED
    created() {
        let queryId = this.$route.query.id
       
        if( queryId ) {
            let rig = this.getRigById(queryId)
            if (!rig) {
                this.getRigs().then(response => {
                    rig=this.getRigById(queryId)
                    this.incomingDataTransformation(rig)
                })
            }else{
                this.incomingDataTransformation(rig)
            }
            this.isCreate = false;
        } else {
            this.checkErrors()
        }
    }
}




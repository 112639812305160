





















import Vue from 'vue'
import Component from 'vue-class-component';

@Component ({
    props: {
        successMessage: {
            type: String,
            required: true
        },
        back: {
            type: String,
            required: false
        }
    }
})
export default class SuccessComponent extends Vue{

}

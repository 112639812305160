











































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import UnitConverter from '../../mixins/unitConverter.ts'

@Component({
  mixins: [UnitConverter]
})
export default class MudLosses extends Vue {
  @Prop({}) 
  volLostShakerSurf!:any;
  @Prop({}) 
  volLostMudCleanerSurf!:any;
  @Prop({}) 
  volLostPitsSurf!:any;
  @Prop({}) 
  volLostTrippingSurf!:any;
  @Prop({}) 
  volLostOtherSurf!:any;
  @Prop({}) 
  volLostCircHole!:any;
  @Prop({}) 
  volLostCsgHole!:any;
  @Prop({}) 
  volLostCmtHole!:any;
  @Prop({}) 
  volLostBhdCsgHole!:any;
  @Prop({}) 
  volLostAbandonHole!:any;
  @Prop({}) 
  volLostOtherHole!:any;

  private get volLostShakerSurfInput() {
    return this.volLostShakerSurf;
  }
  private set volLostShakerSurfInput(volume:any) {
    this.$emit("update:volLostShakerSurf", volume);
  }
   private get volLostMudCleanerSurfInput() {
    return this.volLostMudCleanerSurf;
  }
  private set volLostMudCleanerSurfInput(volume:any) {
    this.$emit("update:volLostMudCleanerSurf", volume);
  }
   private get volLostPitsSurfInput() {
    return this.volLostPitsSurf;
  }
  private set volLostPitsSurfInput(volume:any) {
    this.$emit("update:volLostPitsSurf", volume);
  }
   private get volLostTrippingSurfInput() {
    return this.volLostTrippingSurf;
  }
  private set volLostTrippingSurfInput(volume:any) {
    this.$emit("update:volLostTrippingSurf", volume);
  }
  private get volLostCsgHoleInput() {
    return this.volLostCsgHole;
  }
  private set volLostCsgHoleInput(volume:any) {
    this.$emit("update:volLostCsgHole", volume);
  }
   private get volLostCircHoleInput() {
    return this.volLostCircHole;
  }
  private set volLostCircHoleInput(volume:any) {
    this.$emit("update:volLostCircHole", volume);
  }
   private get volLostOtherSurfInput() {
    return this.volLostOtherSurf;
  }
  private set volLostOtherSurfInput(volume:any) {
    this.$emit("update:volLostOtherSurf", volume);
  }
   private get volLostCmtHoleInput() {
    return this.volLostCmtHole;
  }
  private set volLostCmtHoleInput(volume:any) {
    this.$emit("update:volLostCmtHole", volume);
  }
     private get volLostBhdCsgHoleInput() {
    return this.volLostBhdCsgHole;
  }
  private set volLostBhdCsgHoleInput(volume:any) {
    this.$emit("update:volLostBhdCsgHole", volume);
  }
     private get volLostAbandonHoleInput() {
    return this.volLostAbandonHole;
  }
  private set volLostAbandonHoleInput(volume:any) {
    this.$emit("update:volLostAbandonHole", volume);
  }
       private get volLostOtherHoleInput() {
    return this.volLostOtherHole;
  }
  private set volLostOtherHoleInput(volume:any) {
    this.$emit("update:volLostOtherHole", volume);
  }
}




















































































import Vue from 'vue'
import Component from 'vue-class-component'
import EventBus from './../../../eventBus'
import { required } from "vuelidate/lib/validators"; 
import { Validations } from 'vuelidate-property-decorators';

@Component
export default class ManageWellActivities extends Vue {
    @Validations()
    validations = {
        activity: {
            name: {required},
            deptId: {required},
            textcolor: {required},
            color: {required},
        }
    }
    activeScreen: string = 'main'
    loadingMessage: string = 'Please wait while we create new activity'
    loadingTitle: string = 'Saving...'
    additionalSuccessMessage: string = ''
    successMessage: string = 'New Activity Created Successfully'
    links: Array<object> = [
        {
            text: 'Well Operations',
            to: '/operations'
        },
        {
            text: 'Manage Well Operation',
            active: true
        }
    ]
    isCreate: boolean = true
    index: any = null
    error: any = {
        generalMessage: '',
        errors: []
    }
    activity: any = {
        name: '',
        color: '',
        textcolor: '',
        deptId: 0,
        isCritical: true,
    }
    activeItem: any = null
    formErrors: string[] = []



    // METHODS
    checkErrors () {
        this.formErrors = []

        if (this.activity.name.length < 1) {
            this.formErrors.push("Well Activity Name is required")
        }
        if(!this.checkActivityDepartment) {
            this.formErrors.push("Well Activity Department is Required")
        }
    }
    
    persistData(){
        this.activeScreen = 'loading';
        if(this.activeItem == null)
            this.saveNew();
        else
            this.update();
    }

    update(){
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.loadingMessage = 'Please wait while we update this activity'
        this.loadingTitle = 'Saving...'
        var payload = this.createPayload()
        this.$store.dispatch('wellactivitiesModule/updateWellActivityAction', payload)
        .then((res: any) => 
            {
                this.$v.$reset()
                this.activeScreen = 'successNoGotIt'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.additionalSuccessMessage = ''
                this.successMessage = 'Activity Updated Successfully'
            }
        ).catch((err: any) => {
           this.error.generalMessage = err.response.data.generalMessage
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
        })
    }

    createPayload() {
        let color = this.activity.color
        let textColor = this.activity.textcolor
        if(typeof(color)=="object")color = this.activity.color.hex
        if(typeof(textColor)=="object")textColor = this.activity.textcolor.hex
        return {
            name: this.activity.name,
            id: this.activeItem ? this.activeItem.id : 0,
            color: color,
            isCritical: this.activity.isCritical,
            departmentId: this.activity.deptId,
            textcolor: textColor,
        };
    }

    saveNew(){
        var payload = this.createPayload()
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.$store.dispatch('wellactivitiesModule/createWellActivity', payload)
        .then((res: any) => 
            {
                this.$v.$reset()
                this.activeScreen = 'successRemain'
                EventBus.$emit('activeScreen', this.activeScreen)
                this.additionalSuccessMessage = 'You can now use the newly created Activity'
                this.successMessage = 'Successfully Created '+ payload.name
            }
        ).catch((err: any) => {
            this.error.generalMessage = err.response.data.message
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
        })
    }



    // VUEX METHODS
    getDepartments () {
        return this.$store.dispatch('departmentModule/getDepartments')
    }




    // COMPUTED PROPERTIES
    get getDepartmentOptions () {
        return this.$store.getters['departmentModule/getDepartmentOptions']
    }
    get getActivityType () {
        return this.$store.getters['wellactivitiesModule/getActivityType']
    }
    get checkActivityDepartment (): boolean {
        if (!this.activity.deptId) {
            return false
        } else {
            return true
        }
    }
    get isButtonDisabled(): boolean {
        if(this.formErrors.length > 0 || this.$v.activity.$invalid) {
            return true
        } else {
            return false
        }
    }



    // CREATED
    created() {
        
        //window.localStorage.setItem('LastRoute', this.$route.path)
        if(this.getDepartmentOptions.length==0){
            this.getDepartments();
        }
        
        if(!this.$route.query){this.checkErrors()}
        this.activeItem = this.getActivityType(this.$route.query.id)
        if(!this.activeItem){this.checkErrors()}
        this.activity.name = this.activeItem.name,
        this.activity.color = '#' + this.activeItem.color;
        this.activity.textcolor = '#' + this.activeItem.textColor;
        this.activity.isCritical = this.activeItem.isCritical;
        this.activity.deptId = this.activeItem.departmentId;
        this.isCreate = false
        // window.console.log(this.activity)
    }
}




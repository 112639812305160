








































































































































import Vue from "vue";
import { BudgetColumn } from "../types/BudgetColumn";
import { BudgetRow } from "../types/BudgetRow";
import { Component, Prop, Watch } from "vue-property-decorator";
import Currencies from "../utils/Currencies";

@Component({
  filters:{
    ammountify:(value:any)=>{
      return (new Intl.NumberFormat('en-GB',{minimumFractionDigits:2,maximumFractionDigits:2}).format(value))
    }
  }
})
export default class BudgetSheet extends Vue {
  @Prop({ default: () => [] })
  columns!: Array<any>;

  @Prop({ default: () => [] })
  rows!: Array<any>;

  @Prop({ default: () => (r: any) => r })
  rowKeyLabelExtractor!: (x: any) => BudgetRow;

  @Prop({ default: undefined })
  groupKeyAndRowItemExtractor!: (x: any) => any;

  @Prop({ default: () => (c: any) => c })
  columnDataExtractor!: (x: any) => BudgetColumn;

  @Prop({ default: true })
  showRowSum!: boolean;

  @Prop({ default: false })
  unitCost!: boolean;

  @Prop({ default: false })
  dayRate!: boolean;

  @Prop({ default: false })
  showSplit!: boolean;

  @Prop({default:false})
  readOnlyMode!:boolean

  @Prop({default:305})
  conversionFactor!:number

  @Prop({
    default: () => ({
      firstCurrency: "USD",
      firstCurrencyRatio: 0.6,
      secondCurrency: "NGN",
      secondaCurrencyRatio: 0.4,
    }),
  })
  splitData!: Object;

  Currencies = Currencies;

  activeCell = ""

  @Watch("computedRows")
  onRowschanged(){
    
  }

  get computedRows(): Array<any> {
    let rowsToReturn: Array<any> = [];
    let columnsAsProperties = {} as any;
    
    this.computedColumns.forEach((c) => {
      if (c.key != "label") columnsAsProperties[c.key] = 0;
    });

    if (this.groupKeyAndRowItemExtractor != undefined) {
      this.rows.forEach((r) => {
        let group = this.groupKeyAndRowItemExtractor(r);
        rowsToReturn.push({
          key: group.key + "s",
          label: group.label,
          editable: false,
          type: "GROUP_HEADER",
        });
        group.rows.forEach((r: any) => {
          var rowKeyAndLabel = this.rowKeyLabelExtractor(r);
          rowsToReturn.push({
            ...rowKeyAndLabel,
            editable: true,
            type: "LINE_ITEM",
            ...columnsAsProperties,
            groupKey: group.key,
          });
        });
        rowsToReturn.push({
          key: group.key + "ss",
          label: group.label + " SUM",
          editable: false,
          type: "GROUP_SUM",
          ...columnsAsProperties,
        });
      });
    } else {
      this.rows.forEach((r) =>
        rowsToReturn.push({
          ...this.rowKeyLabelExtractor(r),
          editable: true,
          type: "LINE_ITEM",
          ...columnsAsProperties,
        })
      );
    }
    //set initial values here
    this.computedColumns.forEach((c: BudgetColumn) => {
      switch(c.key){
        case "label":
          break;
        case "lineSum":
          var columnsWithValues = this.computedColumns.filter(c=>c.key!="label"&&c.key!="lineSum")
          rowsToReturn.forEach((r, i, rows) => {
            columnsWithValues.filter(c => !c.ignoreInSum).forEach(c=>r.lineSum+=r[c.key])
          })
          break;
        default:
          rowsToReturn.forEach((r, i, rows) => {
            if(r.type=="LINE_ITEM"){
              let thisLineItem = c.budgetLines==undefined?undefined:c.budgetLines.find(bl=>bl.lineItemId==r.key)
              if(thisLineItem)r[c.key] = thisLineItem.lineValue
            }
            if(r.type=="GROUP_SUM"){
              r[c.key] = rowsToReturn.filter(fr => (fr.groupKey+'ss') == r.key).reduce((a, ris) => a + parseFloat(ris[c.key]), 0)
            }
          });
      }
    });
    return rowsToReturn;
  }

  get computedColumns(): Array<BudgetColumn> {
    let columnsToReturn: Array<BudgetColumn> = [];
    columnsToReturn.push({
      label: "Line Items",
      key: "label",
      variant:"light",
      stickyColumn: true,
      isRowHeader: true,
      editable: false,
    });

    if (this.unitCost)
      columnsToReturn.push({
        label: "Unit Cost (uc)",
        key: "unitCost",
        editable: true,
        type: "UNIT_COST",
      });
    if (this.dayRate && !this.unitCost)
      columnsToReturn.push({
        label: "Day Rate",
        key: "dayRate",
        editable: true,
        type: "DAY_RATE",
      });

    this.columns.forEach((col) => {
      
      let retrievedCol = this.columnDataExtractor(col) as any;
      if (this.unitCost) {
        columnsToReturn.push({
          key: retrievedCol.key + "units",
          rawKey: retrievedCol.key,
          budgetLines:(retrievedCol.budgetLines as Array<any>).map((b:any) => ({lineItemId:b.lineItemId,lineValue:0})),
          label: "units (u)",
          class: "px-0",
          editable: true,
          type: "UNITS",
          hasHeader:true,
          header:retrievedCol.label,
          headerSpan:this.dayRate?3:2
        });
      }
      if (this.dayRate) {
        columnsToReturn.push({
          key: retrievedCol.key + "days",
          rawKey: retrievedCol.key,
          label: "days (u)",
          class: "px-0",
          editable: true,
          type: "DAYS",
          hasHeader:!this.unitCost,
          header:retrievedCol.label,
          headerSpan:this.unitCost?3:2,
          skipHeader:this.unitCost
        });
      }
      if (this.dayRate || this.unitCost) {
        let label =
          this.dayRate && this.unitCost
            ? "cost ((uc * u)*d)"
            : this.unitCost
            ? "cost (uc * u)"
            : "cost (dr * d)";
        columnsToReturn.push({
          key: retrievedCol.key + "columnTotal",
          rawKey: retrievedCol.key,
          label,
          editable: false,
          class: "px-0",
          type: "COLUMN_TOTAL",
          skipHeader:true
        });
        return;
      }
      columnsToReturn.push({
        ...retrievedCol,
        rawKey: retrievedCol.key,
        class: "px-0",
        type: "VALUE"
      });
    });
    if (this.showRowSum)
      columnsToReturn.push({
        label: "Line Sum",
        key: "lineSum",
        editable: false,
      });
    return columnsToReturn;
  }

  get secondaryHeaders() {
    return this.columns.map((m) => this.columnDataExtractor(m));
  }

  viewTapped(columnKey:any,rowKey:any){
    if(this.readOnlyMode)return
    this.activeCell=columnKey+rowKey;
    this.$nextTick(() => {
      (this.$refs[this.activeCell] as any).focus()
    })
  }

  rowClass(item:any,type:any){
    if(type=='row-bottom')return "bg-success"
    if(item){
      if(item.type=='GROUP_HEADER') return "bg-danger"
      if(item.type=='GROUP_SUM') return "bg-warning"
    }
  }

  startButtonClicked() {
    this.$emit("startButtonClicked");
  }

  inputChanged(
    lineItemId: any,
    columnId: any,
    type: any,
    rawKey: any,
    groupKey: any,
    value: any
  ) {
    let index = this.computedRows.findIndex((r) => r.key == lineItemId);
    switch (type) {
      case "VALUE":
        this.$emit('lineValueChanged',{lineItemId,columnId,groupKey,value,lineSum:this.computedColumns.filter((c) => c.type == "COLUMN_TOTAL").reduce((a, b) => a + parseFloat(this.computedRows[index][b.key]), 0)})
        break;
      case "UNIT_COST":
        this.computedColumns.forEach((c) => {
          if (c.type == "COLUMN_TOTAL") {
            if (this.dayRate) {
              this.computedRows[index][c.rawKey + "columnTotal"] =
                value *
                this.computedRows[index][c.rawKey + "units"] *
                this.computedRows[index][c.rawKey + "days"];
            } else {
              this.computedRows[index][c.rawKey + "columnTotal"] =
                value * this.computedRows[index][c.rawKey + "units"];
            }
          }
        });
        break;

      case "DAY_RATE":
        this.computedColumns.forEach((c) => {
          if (c.type == "COLUMN_TOTAL") {
            this.computedRows[index][c.rawKey + "columnTotal"] =
              value * this.computedRows[index][c.rawKey + "days"];
          }
        });
        break;

      case "DAYS":
        if (this.unitCost) {
          this.computedRows[index][rawKey + "columnTotal"] =
            value *
            (this.computedRows[index]["unitCost"] *
              this.computedRows[index][rawKey + "units"]);
        } else {
          this.computedRows[index][rawKey + "columnTotal"] =
            value * this.computedRows[index]["dayRate"];
        }
        break;

      case "UNITS":
        if (this.dayRate) {
          this.computedRows[index][rawKey + "columnTotal"] =
            value *
            this.computedRows[index]["unitCost"] *
            this.computedRows[index][rawKey + "days"];
        } else {
          this.computedRows[index][rawKey + "columnTotal"] =
            value * this.computedRows[index]["unitCost"];
        }
        break;
    }
    if (this.dayRate || this.unitCost) {
      this.computedRows[index]["lineSum"] = this.computedColumns
        .filter((c) => c.type == "COLUMN_TOTAL")
        .reduce((a, b) => a + parseFloat(this.computedRows[index][b.key]||0), 0);
    } else {
      this.computedRows[index]["lineSum"] = this.computedColumns
        .filter((c) => (c.type == "VALUE"&&!c.ignoreInSum))
        .reduce((a, b) =>   a + parseFloat(this.computedRows[index][b.key]||0), 0);
    }

    let sectionSumIndex = this.computedRows.findIndex(
      (r) => r.key == groupKey + "ss"
    );
    if (sectionSumIndex > -1) {
      this.computedColumns
        .filter((c) => c.key != "label")
        .forEach((c) => {
          this.computedRows[sectionSumIndex][c.key] = this.computedRows
            .filter((r) => r.groupKey != undefined && r.groupKey == groupKey)
            .reduce((a, b) => a + parseFloat(b[c.key]), 0);
        });
    }
  }
}

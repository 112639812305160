


















































import Vue from 'vue'
import {Component, Mixins} from 'vue-property-decorator';
import UserProvisoning from '@/mixins/userProvisioning'
import EventBus from './../../eventBus'

@Component
export default class DepartmentsOverview extends Mixins(UserProvisoning){
    // DATA
   search: string = ''
   error: string = ''
   activeScreen: string = 'loading'
   loadingMessage: string = 'Loading Departments'
   loadingTitle: string = 'Loading...'
   selectedDept: any = {
       name: '',
       people: ''
   }
   deletePromptProperty: boolean = false
   successMessage: string = ''
   additionalSuccessMessage = ''
   loader: boolean = true



    // METHODS 
    deleteDeptPrompt (selectedDept: any) {
        this.selectedDept.name=selectedDept.name
        this.selectedDept.id = selectedDept.id
        this.selectedDept.people=selectedDept.people

        this.deletePromptProperty = true
    }

    doDelete(bvEvt: any){
        this.activeScreen = 'loading'
        this.loadingMessage = 'Please wait while we delete this department'
        this.loadingTitle = 'Deleting...'
        EventBus.$emit('activeScreen', this.activeScreen) 
        this.deleteDepartment(this.selectedDept.id).then((response:any) => {
            this.activeScreen = 'successNoBack'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.additionalSuccessMessage = 'This department will no longer be available when creating users'
            this.successMessage = this.selectedDept.name + ' deleted succesfully'
            this.deletePromptProperty = false
        }, (error:any) => {          
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.response.data.message
            this.deletePromptProperty = false
        })
    }

    gotoEdit(dept_id: number){
        this.$router.push({path:'/manage-department/', query:{id:dept_id.toString()} })
    }

    searchValue(e: any) {
        this.search = e
    }

    // VUEX METHODS

    getDepartments () {
        return this.$store.dispatch('departmentModule/getDepartments')
    }

    deleteDepartment (id: any) {
        return this.$store.dispatch('departmentModule/deleteDepartment', id)
    }



    // COMPUTED PROPERTIES
    get fillteredDepartments() {
        return this.$store.getters['departmentModule/fillteredDepartments']
    }
    get getAllDepartments() {
        return this.$store.getters['departmentModule/getAllDepartments']
    }
     get getFields() {
       if(this.canWriteDepartment){
        return [{key: 'name', sortable: 'true'},{key: 'code', sortable: 'false'},
                {key: 'button', label: "Actions"}]
       }
    return [{key: 'name', sortable: 'true'},{key: 'code', sortable: 'false'}]
       
    }


    // CREATED
    created() {
        if(this.fillteredDepartments('').length===0){
            this.getDepartments().then((response:any)=>{
                this.activeScreen = 'main';
                EventBus.$emit('activeScreen', this.activeScreen)
                this.loader = false
            })
        }else{
            this.activeScreen = 'main';
            EventBus.$emit('activeScreen', this.activeScreen)
            this.loader = false
        }   
    }
}

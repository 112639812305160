



















































































import Vue from 'vue'
import Bar from "./charts/Bar.vue"
export default Vue.extend({
    name:"KPIForm",
    components:{
        'bar-chart':Bar
    },
    data(){
        return{
            targetColumn:"",
            kpiData:{
                targets:[] as Array<any>
            }
        }
    },
    methods:{
        addTempKPI(){
            this.kpiData.targets.push({sourceTable:"Project",targetColumns:[],conditions:[]})
        }
    }
})

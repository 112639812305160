

















































































































































import project from '@/store/modules/project'
import Vue from 'vue'
export default Vue.extend({
    props:["projectId"],
    data(){
        return{
            fields :[
                {key:"sn",label:"S/N"},
                {key:"name",label:"Name"},
                {key:"value",label:"Value"},
                {key:"criticality",label:"Critical"},
                {key:"team",label:"Team"},
                {key:"actions",label:"Actions"},          
            ],
            valueTypes : [
                {text:"Boolean (Yes or No)",value:"BOOLEAN"},
                {text:"Range",value:"RANGE"},
                {text:"Percentage",value:"PERCENTAGE"},
                {text:"Status (Not Started, In Progress, Completed) ",value:"STATUS"},
            ],
            planningItem: {
                name:"",
                valueType:null,
                minValue:0,
                maxValue:0,
                targetTeamId:null,
                criticality:false
            },
            showPlanningItemModal: false,
            isCreated: true,
            editingPreProcess: {id:""},
            showPlanningItemListModal: false,
            projects: [],
            selectedPreProcesses: []
        }
    },
    computed:{
        sequences():Array<any>{
            return this.$store.getters["sequencesModule/getSequences"]
        },
        project():any{
            return this.$store.getters ['projectModule/getProjectById'](this.projectId)
        },
        allPlanningItems(){
            let planningItems=[] as Array<any>
            this.projects.forEach((project:any) => {
               if(project.id != this.project.id){
                    project.preProcesses.forEach((preProcess:any) => {
                         planningItems.push({text:preProcess.name,value:preProcess});
                    });
                }
            });
            let items = new Map();
            this.project.preProcesses.forEach((preProcess:any) => {
               items.set(preProcess.name,true)
            });
           this.removeRepeatedItems(planningItems,items);
            window.console.log(planningItems.length)
            return planningItems
        },
        getTeamOptions(){
            let selectedSequence = this.sequences.find((x:any)=>x.id == this.project.sequenceId);
            let teamsToReturn:Array<any> = []
            selectedSequence.departments.forEach((d:any) => {
                d.teams.map((t:any)=>(teamsToReturn.push({text:t.teamName,value:t.id})))
            });
            return teamsToReturn
        },
        modifiedPreprocesses():Array<any>{
            return this.project.preProcesses.map((x:any) => {
                return {...x, preprocessValue: x.valueType === 'BOOLEAN'? 'No': x.valueType === "RANGE"? 10: x.valueType === "PERCENTAGE"? 50: x.valueType === "STATUS"? 'NOT STARTED': ''}
            })
        }
    },
    methods:{
        reInitPalnningItem(){
            this.planningItem = {
                name:"",
                valueType:null,
                minValue:0,
                maxValue:0,
                criticality:false,
                targetTeamId:null
            }
        },
        removeRepeatedItems (planningItem:any,currentItems:Map<string,boolean>) {
            var items = {} as any, name;
            for (var index = 0; index < planningItem.length; index++) {
                name = planningItem[index].text;
                if (name in items || currentItems.has(name)) {
                    planningItem.splice(index, 1);
                    --index;
                } else {    
                    items[name] = true;
                }
            }
        },
        addExistingPlanningItems(){
            this.selectedPreProcesses.forEach((element:any) => {
                let preProcess = {
                    name:element.name,
                    valueType:element.valueType,
                    maxValue:element.maxValue,
                    minValue:element.minValue,
                    targetTeamId:element.targetTeamId,
                    criticality:element.criticality,
                    preprocessValue:element.preprocessValue
                }
                this.$store.commit("projectModule/addOrUpdatePlanningItemToProject",{sequenceId:this.project.sequenceId,id:this.project.id,preProcess})  
                let elementIndex = this.allPlanningItems.indexOf(element)
                this.allPlanningItems.splice(elementIndex,1)
            });
            this.selectedPreProcesses=[]
            this.showPlanningItemListModal =false
        },
        showPlanningItemList(){
            if(this.projects.length<1){
                this.$store.dispatch("projectModule/getProjectsForSequence",this.project.sequenceId).then(()=>
                { 
                    this.projects=this.$store.getters['projectModule/getProjectsForSequence'](this.project.sequenceId)
                })
            }
            this.showPlanningItemListModal = true
            this.showPlanningItemModal =false
        },
        editPreProcess(data:any){
            this.isCreated=false
            this.editingPreProcess=data
            this.planningItem = {
                name:data.name,
                valueType:data.valueType,
                minValue:data.minValue,
                maxValue:data.maxValue,
                targetTeamId:data.targetTeamId,
                criticality:data.criticality
            }
            this.showPlanningItemModal=true
        },
        deletePreProcess(data:any){
            const index = this.project.preProcesses.findIndex((x:any) => data.id === x.id);
            if(index>-1){
                this.project.preProcesses.splice(index,1)
            }
            this.onPlanningItemValueChanged();
        },
        showPlanningModal(){
            this.reInitPalnningItem()
            this.isCreated=true
            this.showPlanningItemModal=true
        },
        doCreatePlanningItem(){
            let preProcess = {
                name:this.planningItem.name,
                valueType:this.planningItem.valueType,
                maxValue:this.planningItem.maxValue,
                minValue:this.planningItem.minValue,
                criticality:this.planningItem.criticality,
                targetTeamId:this.planningItem.targetTeamId,
                preprocessValue: this.planningItem.valueType === 'BOOLEAN'? 'No': this.planningItem.valueType === "RANGE"? this.planningItem.minValue: this.planningItem.valueType === "PERCENTAGE"? 50: this.planningItem.valueType === "STATUS"? 'NOT STARTED': ''
            }
            this.$store.commit("projectModule/addOrUpdatePlanningItemToProject",{sequenceId:this.project.sequenceId,id:this.project.id,preProcess})
            this.showPlanningItemModal=false
            this.reInitPalnningItem()
        },
        updateCreatePlanningItem(){
            const index = this.project.preProcesses.findIndex((x:any)=>x.id===this.editingPreProcess.id);
            if(index>-1){
                this.project.preProcesses[index].name=this.planningItem.name
                this.project.preProcesses[index].maxValue=this.planningItem.maxValue
                this.project.preProcesses[index].minValue=this.planningItem.minValue
                this.project.preProcesses[index].valueType=this.planningItem.valueType
                this.project.preProcesses[index].criticality=this.planningItem.criticality
                this.project.preProcesses[index].targetTeamId=this.planningItem.targetTeamId
            }
            this.onPlanningItemValueChanged()
            this.showPlanningItemModal=false
            this.reInitPalnningItem()
        },
        onPlanningItemValueChanged(){
            this.$store.commit("projectModule/registerProjectChange",{sequenceId:this.project.sequenceId,projectId:this.project.id})
        }
    }
})

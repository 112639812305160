

























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import departmentModule from '../../../sore/modules/departments'
import UnitConverter from '../../../mixins/unitConverter.ts'
import convertSymbol from '../../../utils/convertSymbol.ts'
import convertUnit from '../../../utils/convertUnit.ts'
import ProjectHeatMapChart from '../ProjectHeatMapChart'
import printHeatMap from '@/printHeatMap.ts'
import { throws } from 'assert'


@Component({
  components: {
   'heat-map':ProjectHeatMapChart,
  },
  mixins:[UnitConverter]
})
export default class ProjectHeatMap extends Vue {
    @Prop({default: 'Drilling Sequence Report Sheet'})
    sequence_name !: string
    @Prop()
    projects: any

    tableID: string = 'heatmap'

    newTab () {
        var wnd = window.open("", "_blank", "scrollbars,menubar,toolbar,status") as any;
        const div = this.$refs.printDiv as any
        wnd.document.write(div.outerHTML);
        wnd.document.close();
        return false
    }
    printPDF  () {
        const printDiv = this.$refs.printDiv as any;
        const newWindow = window.open('') as any;
        newWindow.document.write(printDiv.outerHTML);
        newWindow.print();
        newWindow.close();
    }
    print() {
        return printHeatMap(this.sequence_name, this.tableID)
    }

    sendInMail(){
        printHeatMap(this.sequence_name, this.tableID,true).then((generatedFile:any) => {
            this.$emit("sendMail",{file:generatedFile})
        })
    }
    // writeToExcel(filename = '') {
    //     var downloadLink;
    //     var dataType = 'application/vnd.ms-excel'
    //     var tableSelect = this.$refs.heatmaptable as any;
    //     var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20')

    //     filename = 'download_excel_data.xls'

    //     downloadLink = document.createElement('a')
    //     document.body.appendChild(downloadLink)

    //     if (navigator.msSaveOrOpenBlob) {
    //         var blob = new Blob(['\ufeff', tableHTML], {
    //             type: dataType
    //         })
    //         navigator.msSaveOrOpenBlob(blob, filename)
    //     } else {
    //         downloadLink.href = 'data:' + dataType + ', ' + tableHTML
    //         downloadLink.download = filename
    //         downloadLink.click()
    //     }
    // }
    close () {
        const modal = this.$refs.modal as any
        modal.hide()
    }

}

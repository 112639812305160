import { render, staticRenderFns } from "./milestoneComponent.vue?vue&type=template&id=621d27f9&scoped=true&"
import script from "./milestoneComponent.vue?vue&type=script&lang=ts&"
export * from "./milestoneComponent.vue?vue&type=script&lang=ts&"
import style0 from "./milestoneComponent.vue?vue&type=style&index=0&id=621d27f9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "621d27f9",
  null
  
)

export default component.exports
import PptxGenJS from "pptxgenjs";

const convertToInches = function (val: number) {
    return val / 160;
}
const convertToInchesWidth = function (val: number) {
    return val / 96;
}

function computeSections(projectToSection: any, computeDuration: any, currentSlideStartDate: any, currentSlideMaxDate: any){
    let visibleActivityIds: Array<any> = []
    if (projectToSection.activities && projectToSection.activities.length > 0) {

        let sections: Array<any> = []

        const activitiesToWorkWith = projectToSection.activities.filter((a: any) => (a.departmentId == 3) && (new Date(a.end).getTime() > currentSlideStartDate.getTime()) && (new Date(a.start).getTime() < currentSlideMaxDate.getTime())).sort((a1: any, a2: any) => {
            const a1Start = new Date(a1.start).getTime()
            const a2Start = new Date(a2.start).getTime()
            if (a1Start == a2Start) return 0
            if (a1Start < a2Start) return -1
            if (a1Start > a2Start) return 1
        })
        
        activitiesToWorkWith.forEach((a: any, index: any) => {
            visibleActivityIds.push(a.wellActivity.id)
            let start = a.start
            let end = a.end
            if(new Date(start).getTime() < currentSlideStartDate.getTime()) {
                start = `${currentSlideStartDate.getFullYear()}-${currentSlideStartDate.getMonth()+1}-${currentSlideStartDate.getDate()}`
            }
            if (new Date(end).getTime() > currentSlideMaxDate.getTime()) {
                end = `${currentSlideMaxDate.getFullYear()}-${currentSlideMaxDate.getMonth()+1}-${currentSlideMaxDate.getDate()}`
            }
            let sectionDays = computeDuration(start, end)
            sections.push({ color: '#' + a.wellActivity.color, width: sectionDays })
        })

        return sections
    } else {
        return []
    }
}

function getBudgetForProject(activities:Array<any>){
    let totalBudget = 0
    activities.forEach((activity:any) => {
      if(activity.budget){
        activity.budget.budgetLines.forEach((budgetLine:any) => {
          totalBudget+=budgetLine.lineValue
        })
      }
    });
    return totalBudget
}
  

const printer = function (isWBS: any, sequenceObject: any, settings: any, getProjectDatesGetter: any, getProjectDatesGetterStart: any, getProjectDatesGetterEnd: any, getProjectsBetweenYears: any, getProjectsForRig: any, computeDuration: any, wellActivitiesData: Array<any>, printStatus: any) {
    //used for getting month name when running loops
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC']
    //used for getting color codes for Rig Terrains
    const terrain_categories: any = {
        Land: settings[1].value,
        Swamp: settings[2].value,
        Offshore: settings[3].value
    }
    const terrain_text_categories: any = {
        Land: '000000',
        Swamp: '000000',
        Offshore: '000000',
    }
    const sequenceName = sequenceObject.name;
    const currentDate = new Date();
    //variable used in position calculation
    const margin = convertToInches(32);
    const headerHieght = convertToInches(30);
    const headerLineHeight = convertToInches(10);
    const legendHeight = convertToInches(20);
    // const legendWidth = convertToInchesWidth(180);
    const monthNamesHeight = printStatus.number < 2 ?convertToInches(20): convertToInches(40)
    const hieghtPerRig = convertToInches(60);
    const rigNamesWidth = convertToInchesWidth(120);
    const rigNameHieght = convertToInches(30)
    const rigTerrainHieght = convertToInches(20)
    const activityHeight = convertToInches(30);
    const activityPaddingTop = convertToInches(0)
    const totalHieght = (2 * margin) + headerHieght + headerLineHeight + legendHeight + (2 * monthNamesHeight) + (sequenceObject.rigs.length * hieghtPerRig) + (sequenceObject.riglessBuckets.length * hieghtPerRig)
    var numberOfSlides = (getProjectDatesGetterEnd - getProjectDatesGetterStart + 1)
    // Number of years per slide
    const numberOfYearsInOne = printStatus.all ? numberOfSlides : printStatus.number
    const totalWidth = rigNamesWidth * 8
    var chartWidth = totalWidth - (rigNamesWidth + (2 * margin));
    var chartHeight = (sequenceObject.rigs.length * hieghtPerRig) + (sequenceObject.riglessBuckets.length * hieghtPerRig)
    var monthNamesWidth = (chartWidth / 12)/numberOfYearsInOne
    var weekNameWidth = (chartWidth / 48)/numberOfYearsInOne
    var dayWidth = (chartWidth / 365)/numberOfYearsInOne
    var sequenceStartMonth = getProjectDatesGetter.getMonth()
    var masterObjects: any = [];
    var pptx: any = new PptxGenJS();


    //draw header backgroud
    masterObjects.push({
        'text': {
            text: '',
            options: {
                x: margin,
                y: 0,
                w: convertToInches(520),
                h:  margin + headerHieght + headerLineHeight + legendHeight,
                fontSize: 12,
                bold: true,
                valign: 'middle',
                fill:'#DCDCDC'
            }
        }
    })
    //draw Header
    masterObjects.push({
        'text': {
            text: sequenceName ,
            options: {
                x: margin,
                y: margin,
                w: convertToInches(520),
                h: headerHieght,
                fontSize: 12,
                bold: true,
                valign: 'middle'
            }
        }
    })
    //draw Legend area background
    masterObjects.push({
        'text': {
            text: '',
            options: {
                x: margin+convertToInches(522),
                y: 0,
                w: totalWidth-convertToInches(522)-(margin*2),
                h:  margin + headerHieght + headerLineHeight + legendHeight,
                fontSize: 12,
                bold: true,
                valign: 'middle',
                fill:'#DCDCDC'
            }
        }
    })

    //date Printed section
    masterObjects.push({
        'text': {
            text: "Printed: " + currentDate.getDate() + "/" + (currentDate.getMonth() + 1) + "/" + currentDate.getFullYear(),
            options: {
                x: margin,
                y: margin + headerHieght + headerLineHeight + legendHeight,
                w: rigNamesWidth,
                h: headerHieght+monthNamesHeight,
                fontSize: 8,
                bold: true,
                valign: 'middle',
                fill:'#DCDCDC'
            }
        }
    })

    //draw legends
    const fluids = [{name:"Oil",color:settings[6].value,type:'fluid'},{name:"OilGas",color:settings[5].value,type:'fluid'},{name:"Gas",color:settings[4].value,type:'fluid'},{name:"Condensate",color:settings[7].value,type:'fluid'},{name:"Rig Ready",color:'#006400',type:'Rig'},{name:"Rig Not Ready",color:'#FF0000',type:'Rig'}]
    
    fluids.forEach((f:any,fIndex:number) => {
        let previousWbsSum = 0
        if (fIndex > 0) {
            previousWbsSum = fluids.slice(0, fIndex).map(x => x.name.length*0.13).reduce((a, b) => a + b)
        }
        if(f.type == "fluid"){
            masterObjects.push({
                'text': {
                    text: f.name,
                    options: {
                        x: margin+convertToInches(526)+previousWbsSum,
                        y: convertToInches(2),
                        w: 0.13*f.name.length,
                        h:  convertToInches(30),
                        fontSize: 8,
                        valign: 'middle',
                        align: 'center',
                        fill:f.color,
                        color:'ffffff'
                    }
                }
            })
        }else{
            masterObjects.push({
                'text': {
                    text:' ',
                    options:{
                        x: margin+convertToInches(600)+previousWbsSum,
                        y: convertToInches(7.5),
                        w: convertToInches(20),
                        h:  convertToInches(20),
                        color:f.color,
                        bullet:true
                    }
                }
            })
            masterObjects.push({
                'text': {
                    text: f.name,
                    options: {
                        x: margin+convertToInches(600)+previousWbsSum+convertToInches(15),
                        y: convertToInches(2),
                        w: 0.1*f.name.length,
                        h:  convertToInches(30),
                        fontSize: 8,
                        valign: 'middle',
                        align: 'left',
                        bold: true,
                    }
                }
            })
        }
    })

    wellActivitiesData.forEach((w:any,activityIndex:number) => {
        let previousActivitiesSum = 0
        if (activityIndex > 0) {
            previousActivitiesSum = wellActivitiesData.slice(0, activityIndex).map(x => convertToInches(84)).reduce((a, b) => a + b)
        }

        masterObjects.push({
            'text': {
                text: w.name,
                options: {
                    x: margin+convertToInches(526)+previousActivitiesSum,
                    y: convertToInches(34),
                    w: convertToInches(84),
                    h:  convertToInches(30),
                    bold:true,
                    fontSize: 4,
                    valign: 'middle',
                    align: 'center',
                    fill:w.fill,
                    color:w.color
                }
            }
        })

    })

    //week boxes and vertical thick lines
    for (let g = 0; g < (12*numberOfYearsInOne); g++) {
        //start point for each Month
        var startPoint = margin + rigNamesWidth + (g * monthNamesWidth);
        for (let k = 0; k < 4; k++) {
            //start point for each in a month
            var innerStartPoint = startPoint + (weekNameWidth * k)
            //write the number representing each week
            masterObjects.push({
                'text': {
                    text: k + 1,
                    options: {
                        x: innerStartPoint,
                        y: margin + headerHieght + headerLineHeight + legendHeight + monthNamesHeight,
                        w: weekNameWidth,
                        h: monthNamesHeight,
                        align: 'center',
                        fill: settings[8].value,
                        fontSize: 10/numberOfYearsInOne,
                        color: '#FFFFFF',
                        line: {
                            pt: 2,
                            color: '#FFFFFF'
                        }
                    }
                }
            })
            //draw the vertical thick line
            masterObjects.push({
                'line': {
                    x: innerStartPoint,
                    y: (margin + headerHieght + headerLineHeight + legendHeight + monthNamesHeight + monthNamesHeight),
                    w: 0,
                    h: chartHeight,
                    line: '808080',
                    lineSize: 1
                }
            })
        }
    }
    // draw the horizontal thicks
    for (let k = 1; k <= sequenceObject.rigs.length + sequenceObject.riglessBuckets.length; k++) {
        masterObjects.push({
            'line': {
                x: margin,
                y: margin + headerHieght + headerLineHeight + legendHeight + monthNamesHeight + monthNamesHeight + (k * hieghtPerRig),
                w: ((totalWidth) - (2 * margin)),
                h: 0,
                line: '808080',
                lineSize: 1
            }
        })
    }
    // set the layout
    pptx.defineLayout({
        name: 'DSP',
        width: totalWidth,
        height: totalHieght
    });
    // set the master slide that gets repeated everywhere
    pptx.defineSlideMaster({
        title: "DSPMASTER",
        margin: 1,
        objects: masterObjects
    })
    // create 1 slide for each 12 month periods, add month names first, then add activities for each rig
    for (let i = 0; i < numberOfSlides; i=i+Number(numberOfYearsInOne)) {
        var slide = pptx.addSlide("DSPMASTER");
        var currentYear = getProjectDatesGetterStart + i
        var currentSlideStartDate = new Date(currentYear, 0, 1)
        var currentSlideStartDatePlusOne = new Date(currentYear+(numberOfYearsInOne-1), 0, 1)
        
        var currentSlideMaxDatePlusOne = new Date(currentSlideStartDate.getFullYear()+(numberOfYearsInOne-1), ((currentSlideStartDate.getMonth() - 1) < 0) ? 11 : (currentSlideStartDate.getMonth()+1 - 1), (currentSlideStartDate.getMonth() - 1) == 1 ? 27 : 30)

        var projectsForThisYear = getProjectsBetweenYears(currentSlideStartDate,currentSlideMaxDatePlusOne)

        //draw month names
        for (let z = 0; z <numberOfYearsInOne; z++) {
            for (let g = 0; g < 12; g++) {
                // replace 0 with sequenceStartMonth
                var normalizedMonthCode = ((g + 0) > 11) ? ((g + 0) - 12) : (g + 0)
                var yearToDisplay = z == 0 ? ((g + 0) > 11) ? currentYear + 1 : currentYear : ((g + 0) > 11) ? currentYear + (numberOfYearsInOne) : currentYear+(numberOfYearsInOne-1)
                var startPointx = margin + rigNamesWidth+(12*z*monthNamesWidth) + (g * monthNamesWidth);
                
                slide.addText(monthNames[normalizedMonthCode] + ' ' + "'" + String(yearToDisplay).substring(2, 4), {
                    x: startPointx,
                    y: margin + headerHieght + headerLineHeight + legendHeight,
                    w: monthNamesWidth,
                    h: monthNamesHeight,
                    align: 'center',
                    fontSize: 8/numberOfYearsInOne,
                    fill: settings[9].value,
                    color: 'FFFFFF',
                    line: {
                        pt: 2,
                        color: 'FFFFFF'
                    }
                })
            }
        }

        //draw rig names and  the activities for each rig        
        let rigsAndRiglessUnits = []
        for (let k = 0; k < sequenceObject.rigs.length; k++) {
            let rig =  sequenceObject.rigs[k]
            let color = terrain_text_categories[rig.terrain]
            let fill = terrain_categories[rig.terrain]
            let terrain = rig.terrain
            rigsAndRiglessUnits.push({color,fill,name:rig.name,id:rig.id,terrain})
        }

        for (let k = 0; k < sequenceObject.riglessBuckets.length; k++) {
            let riglessUnit =  sequenceObject.riglessBuckets[k]
            let color = "#FFFFFF"
            let fill = riglessUnit.color
            rigsAndRiglessUnits.push({color,fill,name:riglessUnit.alias,id:riglessUnit.id,terrain:""})
        }

        for (let k = 0; k < rigsAndRiglessUnits.length; k++) {
            //draw rig names and Terrain
            slide.addText(rigsAndRiglessUnits[k].name, {
                x: margin,
                y: margin + headerHieght + headerLineHeight + legendHeight + monthNamesHeight + monthNamesHeight + (k * hieghtPerRig),
                h: hieghtPerRig,
                w: rigNamesWidth,
                fontSize: 7,
                color: rigsAndRiglessUnits[k].color,
                fill: rigsAndRiglessUnits[k].fill,
                valign: 'middle',
                bold: true
            });
            slide.addText('Terrain: ' + rigsAndRiglessUnits[k].terrain, {
                x: margin,
                y: margin + headerHieght + headerLineHeight + legendHeight + monthNamesHeight + monthNamesHeight + (k * hieghtPerRig) + rigNameHieght,
                h: rigTerrainHieght,
                w: rigNamesWidth,
                fontSize: 7,
                color: rigsAndRiglessUnits[k].color,
                valign: 'top'
            });
            var projectsForRig = getProjectsForRig(rigsAndRiglessUnits[k].id, projectsForThisYear)

            for (let q = 0; q < projectsForRig.length; q++) {
                var projectStartDateRig = new Date(projectsForRig[q].start)
                var projectEndDateRig = new Date(projectsForRig[q].end)
                let estimatedProduction = projectsForRig[q].estimatedProduction?projectsForRig[q].estimatedProduction.toString()+' bbl/d':'0 bbl/d'
                let totalBudget = 'K$ '+getBudgetForProject(projectsForRig[q].activities).toString()

                var wbs = computeSections(projectsForRig[q], computeDuration, currentSlideStartDate, currentSlideMaxDatePlusOne)
                const rigReadiness = projectsForRig[q].preProcesses?projectsForRig[q].preProcesses.filter((x: any) => x.name.toLowerCase() === 'rig readiness'):[]
                    
                //normalizing the project start date, in the case where it is started the previous year
                if (projectStartDateRig < currentSlideStartDate) {
                    projectStartDateRig = currentSlideStartDate
                }
                //normalizing the project end date, in the case where it ends the following year
                if (projectEndDateRig > currentSlideMaxDatePlusOne) {
                    projectEndDateRig = currentSlideMaxDatePlusOne
                }
                //get the difference between the nomalized project start date and the chart start date
                var startTimeDiffxRig = Math.abs(projectStartDateRig.getTime() - currentSlideStartDate.getTime());
                //get the difference between the nomalized project start date and the project end date
                var endTimeDiffxRig = Math.abs(projectEndDateRig.getTime() - projectStartDateRig.getTime());
                //convert the differences above to days
                var numberOfDaysToProjectStartxRig = startTimeDiffxRig / (1000 * 3600 * 24)
                var numberOfDaysInProjectxRig = endTimeDiffxRig / (1000 * 3600 * 24)
                  
                var colorCodeForProjectRig = "FFA500"
                if (projectsForRig[q].well == null) {
                    projectsForRig[q].well = {
                        name: "No Well Assigned",
                        wellType: "NONE"
                    }
                }
                if (projectsForRig[q].well.wellType == "Gas") {
                    colorCodeForProjectRig = settings[4].value
                }
                if (projectsForRig[q].well.wellType == "OilGas") {
                    colorCodeForProjectRig = settings[5].value
                }
                if (projectsForRig[q].well.wellType == "Oil") {
                    colorCodeForProjectRig = settings[6].value
                }
                if (projectsForRig[q].well.wellType == "Condensate") {
                    colorCodeForProjectRig = settings[7].value
                }

                slide.addText(0.1 * estimatedProduction.length > (numberOfDaysInProjectxRig * dayWidth)? '' : estimatedProduction, {
                    x: margin + rigNamesWidth + (dayWidth * (numberOfDaysToProjectStartxRig)) + convertToInches(10),
                    y: margin + headerHieght + headerLineHeight + legendHeight + monthNamesHeight + monthNamesHeight + (k * hieghtPerRig) + activityPaddingTop - convertToInches(1),
                    w: 0.1 * estimatedProduction.length > (numberOfDaysInProjectxRig * dayWidth) ? numberOfDaysInProjectxRig * dayWidth : 0.1 * estimatedProduction.length,
                    h: activityHeight/2,
                    align: 'center',
                    color: 'ffffff',
                    fill: settings[9].value,
                    fontSize: 7
                });

                slide.addText(totalBudget, {
                    x: margin + rigNamesWidth + (dayWidth * (numberOfDaysToProjectStartxRig)) + convertToInches(10) + (numberOfDaysInProjectxRig * dayWidth) - (0.1 * totalBudget.length),
                    y: (margin + headerHieght + headerLineHeight + legendHeight + monthNamesHeight + monthNamesHeight + (k * hieghtPerRig) + activityPaddingTop + convertToInches(14)) + activityHeight,
                    w: 0.1 * totalBudget.length,
                    h: activityHeight/2,
                    align: 'center',
                    color: 'ffffff',
                    fill: settings[8].value,
                    fontSize: 7,
                });

                if (rigReadiness.length > 0) {
                    if (rigReadiness[0].valueType === 'BOOLEAN') {
                        if (rigReadiness[0].preprocessValue === 'Yes') {
                            slide.addShape(pptx.ShapeType.ellipse, { 
                                x: margin + rigNamesWidth + (dayWidth * (numberOfDaysToProjectStartxRig)) + convertToInches(10),
                                y: (margin + headerHieght + headerLineHeight + legendHeight + monthNamesHeight + monthNamesHeight + (k * hieghtPerRig) + activityPaddingTop + convertToInches(16)) + activityHeight,
                                w: convertToInches(15),
                                h: convertToInches(15),
                                fill: { type: "solid", color: "#006400" } 
                            });
                        } else {
                            slide.addShape(pptx.ShapeType.ellipse, {
                                x: margin + rigNamesWidth + (dayWidth * (numberOfDaysToProjectStartxRig)) + convertToInches(10),
                                y: (margin + headerHieght + headerLineHeight + legendHeight + monthNamesHeight + monthNamesHeight + (k * hieghtPerRig) + activityPaddingTop + convertToInches(16)) + activityHeight,
                                w: convertToInches(15),
                                h: convertToInches(15),
                                fill: { type: "solid", color: "E63316" }
                            });
                        }
                    }
                }

                
                    
                if (wbs.length > 0&&isWBS) {
                    wbs.forEach((x: any, wbsIndex: any) => {
                        let previousWbsSum = 0
                        if (wbsIndex > 0) {
                            previousWbsSum = wbs.slice(0, wbsIndex).map(x => x.width).reduce((a, b) => a + b)
                        }
                        slide.addText(
                            '', {
                                x: margin + rigNamesWidth + (dayWidth * (numberOfDaysToProjectStartxRig + previousWbsSum)) + convertToInches(10),
                                y: margin + (margin / 2) + headerHieght + headerLineHeight + legendHeight + monthNamesHeight + monthNamesHeight + (k * hieghtPerRig) + activityPaddingTop,
                                h: activityHeight,
                                w: x.width * dayWidth,
                                fontSize: 8,
                                color: 'ffffff',
                                fill: {
                                    type: 'solid',
                                    color: x.color
                                },
                                align: 'center',
                                valign: 'middle',
                                rectRadius: 10
                            }
                        );
                    })

                    slide.addText(
                        projectsForRig[q].well.name.length * 0.1 > (numberOfDaysInProjectxRig * dayWidth) ? `${projectsForRig[q].well.name[0]}${projectsForRig[q].well.name[1]}-${projectsForRig[q].well.wellId}` : projectsForRig[q].well.name, {
                            x: margin + rigNamesWidth + (dayWidth * (numberOfDaysToProjectStartxRig)) + convertToInches(10),
                            y: margin + (margin / 2) + headerHieght + headerLineHeight + legendHeight + monthNamesHeight + monthNamesHeight + (k * hieghtPerRig) + activityPaddingTop,
                            h: activityHeight,
                            w: (numberOfDaysInProjectxRig * dayWidth),
                            fontSize: 6,
                            color: 'ffffff',
                            lineDash: projectsForRig[q].funding == undefined ? '' : projectsForRig[q].funding.lineStyle == 'Dotted' ? 'sysDot' : projectsForRig[q].funding.lineStyle == 'Dashed' ? 'dash' : 'solid',
                            line: {
                                pt: 2,
                                color: projectsForRig[q].funding ? projectsForRig[q].funding.lineColor : colorCodeForProjectRig
                            },
                            align: 'center',
                            valign: 'middle',
                            rectRadius: 10
                        }
                    );
                }else{
                    slide.addText(
                        projectsForRig[q].well.name.length * 0.1 > (numberOfDaysInProjectxRig * dayWidth) ? `${projectsForRig[q].well.name[0]}${projectsForRig[q].well.name[1]}-${projectsForRig[q].well.wellId}` : projectsForRig[q].well.name, {
                            x: margin + rigNamesWidth + (dayWidth * (numberOfDaysToProjectStartxRig)) + convertToInches(10),
                            y: margin + (margin / 2) + headerHieght + headerLineHeight + legendHeight + monthNamesHeight + monthNamesHeight + (k * hieghtPerRig) + activityPaddingTop,
                            h: activityHeight,
                            w: (numberOfDaysInProjectxRig * dayWidth),
                            fontSize: 6,
                            color: 'ffffff',
                            fill: {
                                type: 'solid',
                                color: colorCodeForProjectRig
                            },
                            lineDash: projectsForRig[q].funding == undefined ? '' : projectsForRig[q].funding.lineStyle == 'Dotted' ? 'sysDot' : projectsForRig[q].funding.lineStyle == 'Dashed' ? 'dash' : 'solid',
                            line: {
                                pt: 2,
                                color: projectsForRig[q].funding ? projectsForRig[q].funding.lineColor : colorCodeForProjectRig
                            },
                            align: 'center',
                            valign: 'middle',
                            rectRadius: 10
                        }
                    );
                }
            }
        }
    }
    var pptName = sequenceName + "(" + currentDate.getDay() + "-" + currentDate.getMonth() + "-" + currentDate.getFullYear() + ")"
    pptx.writeFile(pptName);
}

export default printer
import { HTTP } from '@/axios_api/http-common'

const state = {
    fundings:  [],
}

interface IState {
    fundings: Array <any>
}

const mutations ={
    setFundings(state: IState, fundings: Array<any>){
        state.fundings = fundings;
    },
    createFunding(state: IState, funding: any) {
        state.fundings.push(funding)
    },
    deleteFunding(state: IState, activity: any){
        var _exIndex = state.fundings.indexOf(activity);
        if(_exIndex > -1)
            state.fundings.splice(_exIndex,1);
    },
    updateFunding(state: IState, funding: any) {
        var index_ = -1;
        for (let index = 0; index < state.fundings.length; index++) {
            if(state.fundings[index].id == funding.id) {
               index_ = index;
                break;
            } 
       }
       if(index_ > -1)
           state.fundings.splice(index_,1,funding);
    }
}   

const getters = {
    getFunding:(state: IState) => (id: number) =>{
        let activity = null
        if(!state.fundings)return null;
        for (let index = 0; index < state.fundings.length; index++) {
             if(state.fundings[index].id == id) {
                activity = state.fundings[index];
                 break;
             } 
        }
        return activity
    },
    getFundingOptions:(state: IState) => {
        if(!state.fundings) return [];
        return JSON.parse(JSON.stringify(state.fundings)).sort((a: any, b: any) => {
                    if (a.name < b.name) return -1
                    else if (a.name > b.name) return 1
                    return 0
                }).map((funding: any) => {
                    return {text: funding.name, value:funding.id}
                })      
    },
    getFundingOptionsModel:(state: IState) =>{
        if(!state.fundings) return [];
        return JSON.parse(JSON.stringify(state.fundings)).sort((a: any, b: any) => {
                    if (a.name < b.name) return -1
                    else if (a.name > b.name) return 1
                    return 0
                }).map((funding: any) => {
                    return {text: funding.name, value:funding}
                })      
    },

    getFundings: (state: IState) =>{
        return state.fundings;
    }
}

const actions = {
    fetchFundings ({ commit }: { commit: any }) { 
        return new Promise((resolve, reject) => {
            HTTP.get('funding')
            .then((response: any) => {
                commit('setFundings', response.data.data)                
                resolve(response)
                
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    deleteFundingAciton ({commit}: {commit: any}, funding: any) {
        return new Promise((resolve, reject) => {
            HTTP.delete('funding/delete/' + funding.id).then((response: any) => {
                commit('deleteFunding', funding)
                resolve(response)
            }).catch((err: any) => {
                reject(err)
            })
        })
    },
    updateFundingAction({commit}: {commit: any}, funding: any) {
        return new Promise((resolve, reject) => {
            HTTP.post('funding/update', funding).then((response: any) => {
                commit('updateFunding', response.data.data)
                resolve(response)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    saveNewFunding({commit}: {commit: any}, funding: any) {
        return new Promise((resolve, reject) => {
            HTTP.post('funding/create', funding).then((res: any) => {
                commit('createFunding', res.data.data)
                resolve(res)
            }).catch((error: any) => {
                reject(error)
            })
        })
    }
}

const fundingsModule = {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}

export default fundingsModule;
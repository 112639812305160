




















































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import departmentModule from '../../../store/modules/departments'

@Component
export default class ProjectSheets extends Vue {
    @Prop()
    sequenceId !: any
    showFilter = false
    tableOptions:Array<any> = []
    projectProperties = [
        {
            key:"sn",
            tdClass: 'project-report-table',
            label:"S/N",
            stickyColumn:true
        },
        {
            key:"name",
            label:"Project Name",
            stickyColumn:true,
            tdClass: 'project-report-table',
        },
        {
            key:"wellName",
            label:"Well Name",
            sortByFormatted:true,
            tdClass: 'project-report-table',
            sortable:true,
            stickyColumn:true,
            formatter: (value:any,key:any,item:any) => {
                return item.well.name
            }
        },
        {
            key:"projectType",
            label:"Category",
        },
        {
            key:"rigName",
            label:"Rig",
            tdClass: 'project-report-table',
        },
        {
            key:"funding",
            label:"Funding",
            tdClass: 'project-report-table',
        },
        {
            key:"wellType",
            label:"Well Fluid",
            tdClass: 'project-report-table',
        },
        {
            key:"budgetYear",
            label:"Budget Year",
            sortable:true,
            tdClass: 'project-report-table',
            sortByFormatted:true,
            formatter: (value:any,key:any,item:any) => {
                return Number.parseInt(item.start.split('-')[0])
            }
        },
        {
            key:"activities",
            label:"Budget (K$)",
            tdClass: 'project-report-table',
            formatter: (value:any,key:any,item:any) => {
                return this.getPlanBudgetForProject(item.activities)
            }
        },
        {
            key:"field",
            label:"Field",
            tdClass: 'project-report-table',
        },
        {
            key:"location",
            label:"Location",
            tdClass: 'project-report-table',
            formatter:(value:any,key:any,item:any) => {
                return item.well ? item.well.location : 'Not defined';
            }
        },
        {
            key:"terrain",
            label:"Terrain",
            tdClass: 'project-report-table',
            formatter:(value:any,key:any,item:any) => {
                return item.well ? item.well.terrain : 'Not defined';
            }
        },
        {
            key:"wellConfig",
            label:"Well Config",
            tdClass: 'project-report-table',
            formatter:(value:any,key:any,item:any) => {
                return item.well ? item.well.wellConfiguration : 'Not defined';
            }
        },
        {
            key:"start",
            label:"Start",
            tdClass: 'project-report-table',
        },
        {
            key:"end",
            label:"End",
            tdClass: 'project-report-table',
        },
        {
            key:"duration",
            label:"Duration(days)",
            tdClass: 'project-report-table',
            sortable:true,
            sortByFormatted:true,
            formatter: (value:any,key:any,item:any) => {
                let date1:any = new Date(item.start)
                let date2: any = new Date(item.end)
                let timeDifference = Math.abs(date2 - date1)
                let duration = Math.ceil(timeDifference/(1000*60*60*24))
                return duration
            }
        },
        {
            key:"norminalDepth",
            label:"Along Hole Depth",
            tdClass: 'project-report-table',
            formatter: (value:any,key:any,item:any) => {
                return item.well ? item.well.normDepth.value : 'Not defined';
            }
        },
        {
            key:"trueDepth",
            label:"TVD",
            tdClass: 'project-report-table',
            formatter: (value:any,key:any,item:any) => {
                return item.well ? item.well.trueDepth.value : 'Not defined';
            }
        },
    ]
    selectedFields: Array<any> = []
    selectedProjects: Array<any> = []

    getPlanBudgetForProject(activities:Array<any>){
        let totalBudget = 0
        activities.forEach((activity:any) => {
        if(activity.budget){
            activity.budget.budgetLines.forEach((budgetLine:any) => {
            totalBudget+=budgetLine.lineValue
            })
        }
        });
        return totalBudget
    }

    get projectTableFields():Array<any>{
        let fieldsToReturn:Array<any> = []
        fieldsToReturn.push(...this.projectProperties.filter(p=>this.selectedFields.includes(p.key)))
        /*
        this.sequence.departments.forEach((d:any) => {
            let showDeptSum = this.selectedFields.includes('d'+d.id)
            d.teams.filter((t:any)=>this.selectedFields.includes('t'+t.id)).forEach((t:any,i:any,allTeams:Array<any>) => {
                fieldsToReturn.push({
                    key:"t"+t.id,
                    label:t.teamName + "(K$)",
                    tdClass: 'project-report-table',
                    hasHeader:i==0,
                    skipHeader:i!=0,
                    header:d.name,
                    headerSpan:showDeptSum? (allTeams.length+1):allTeams.length,
                    formatter:(value:any,key:any,item:any) => {
                        let totalBudget = 0
                        item.activities.filter((a:any) => a.teamId == t.id).forEach((a:any) => {
                            if(a.budget){
                                a.budget.budgetLines.forEach((budgetLine:any) => {
                                    totalBudget+=budgetLine.lineValue
                                })
                            }
                        });
                        return totalBudget
                    }
                })
            })
            if(showDeptSum){
                fieldsToReturn.push({
                    key:"d"+d.id,
                    label:d.name + " sum (K$)",
                    skipHeader:true,
                    tdClass: 'project-report-table',
                    formatter:(value:any,key:any,item:any) => {
                        let totalBudget = 0
                        item.activities.filter((a:any) => a.departmentId == d.id).forEach((a:any) => {
                            if(a.budget){
                                a.budget.budgetLines.forEach((budgetLine:any) => {
                                    totalBudget+=budgetLine.lineValue
                                })
                            }
                        });
                        return totalBudget
                    }
                })
            }
        })
        */
        return fieldsToReturn
    }

    get sequence(){
        return this.$store.getters['sequencesModule/getSequence'](this.sequenceId)
    }

    get projects () {
        return this.$store.getters['projectModule/getProjectsForSequence'](this.sequenceId)
    }

    get getAllDepartments () {
        return this.$store.getters['departments/getAllDepartments']
    }

    getDepartments () {
        return this.$store.dispatch('departmentModule/getDepartments')
    }

    newTab () {
        var wnd = window.open("", "_blank", "scrollbars,menubar,toolbar,status") as any;
        const div = this.$refs.printDiv as any
        wnd.document.write(div.outerHTML);
        wnd.document.close();
        return false
    }
    printPDF  () {
        const printDiv = this.$refs.printDiv as any;
        const newWindow = window.open('') as any;
        newWindow.document.write(printDiv.outerHTML);
        newWindow.print();
        newWindow.close();
    }

    writeToExcel() {
        const projectIds=this.selectedProjects.map(x=>x.id);
        const exportModel = {headers:this.selectedFields,projectIds:projectIds}
          this.$store.dispatch('projectModule/exportProject', exportModel)
           .then((res: any) => {
          var blob = new Blob([res.data], {
            type: res.headers["content-type"],
          });
          const link = document.createElement("a");
          const downloadUrl = window.URL.createObjectURL(blob);
          link.href = downloadUrl;
          //link.download = res.headers.filename
          link.setAttribute("download", res.headers.filename + ".xlsx"); //any other extension
          document.body.appendChild(link);
          link.click();
         // link.remove();
        })
        .catch((error: any) => {});
    }
        // var downloadLink;
        // var dataType = 'application/vnd.ms-excel'
        // var tableSelect = this.$refs.projtable as any;
        // var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20')

        // filename = '_excel_data.xls'

        // downloadLink = document.createElement('a')
        // document.body.appendChild(downloadLink)

        // if (navigator.msSaveOrOpenBlob) {
        //     var blob = new Blob(['\ufeff', tableHTML], {
        //         type: dataType
        //     })
        //     navigator.msSaveOrOpenBlob(blob, filename)
        // } else {
        //     downloadLink.href = 'data:' + dataType + ', ' + tableHTML
        //     downloadLink.download = filename
        //     downloadLink.click()
        // }
    
    close () {
        const modal = this.$refs.modal as any
        modal.hide()
    }

    setupFieldOptions(){
        this.tableOptions.push(...this.projectProperties.map(p=>({value:p.key,text:p.label})))
        this.selectedFields.push(...this.projectProperties.map(p=>p.key))
        /*
        this.sequence.departments.forEach((d:any) => {
            d.teams.forEach((t:any,i:any,allTeams:Array<any>) => {
                this.tableOptions.push({
                    value:'t'+t.id,
                    text:t.teamName + "(K$)",
                })
                this.selectedFields.push('t'+t.id)
            })
            this.tableOptions.push({
                value:'d'+d.id,
                text:d.name + " sum (K$)",
            })
            this.selectedFields.push('d'+d.id)
        })
        */
    }

    mounted () {
        this.projects.forEach((project: any) => {
            this.selectedProjects.push(project)
        })
        this.getDepartments()
        this.setupFieldOptions()
    }
}

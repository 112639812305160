






































































































































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import baseForm from '../../FormComponents/baseFormComponent.vue'
import EventBus from '../../../eventBus'

@Component({
    components: {
        baseForm,
    }
})
export default class DailyReports extends Vue {
    searchValue: string = ''
    formColumn: number = 8
    listColumn: number = 4
    isShow: boolean = true
    operations: any[] = [
        { value: 'Drilling', text: 'Drilling' },
        { value: 'Production', text: 'Production' },
    ]
    operation: string = ''
    view: string = 'General'
    today: any = {}
    reports: any[] = [
        
    ]
    general: any = {
        startDepth: '',
        endDepth: '',
        operation: '',
        startTime: '',
        endTime: '',
        date: ''
    }
    nptOperationOption: any = []
    log: any = {
        from: '',
        to: '',
        description: '',
        nptInfo: {}
    }
    items: any[] = []
    fields: any[] = ['from', 'to', 'NPT', 'description']
    fieldSummary: any[] = ['from', 'to', 'description']

    get filteredReports () {
        return this.reports.filter((report) => {
            return report.milestone.toUpperCase().match(this.searchValue.toUpperCase())
        })
    }
    sum = 0
    sums () {
        let count: number = 0
        if (this.budgetLineModelCost !== null) {
            this.budgetLineModelCost.forEach((element: any) => {
                count = Number(element.cost) + count
                this.sum = count
            });
        } else {
            this.sum =  0
        }
    }
    get activities () {
        return this.$store.getters['ActivityModule/getActivitiesForProject'](this.$store.state.projectModule.activeProjectId).map((s:any)=> {
            return {
                text: s.name,
                value: s
            }
        })
    }
    get budgetLines () {
        return this.$store.getters['budgetLineModule/getAllBudgetLines']
    }
    get allNpt () {
        return this.$store.getters['nptModule/getAllNpt']
    }
    get budgetLineModelCost () {
        return this.budgetLines.map((budgetLine: any) => {
            return {
                lineItem: budgetLine.lineItem,
                cost: 0
            }
        })
    }
    get milestones () {
        return this.$store.getters['MilestoneModule/getMilestonesForActivity']
    }
    get milestoneOptions () {
        return this.milestones.map((milestone: any) => {
            return {
                text: milestone.name,
                value: milestone
            }
        })
    }

    search (e: any) {
        this.searchValue = e
    }

    saveReport() {
        // window.console.log('I am the save button')
    }

    otherTabFunction (tabName: string) {
        this.view = tabName
        this.formColumn = 8
        this.listColumn = 4
        this.isShow = true
    }

    addRow() {
        this.items.push({
                from: this.log.from,
                to: this.log.to,
                description: this.log.description,
                nptInfo:{
                    hours: this.log.nptInfo.hours,
                    operation: this.log.nptInfo.operation,
                    summary: this.log.nptInfo.summary,
                }
            })
    }
    removeRow(obj: any) {
        var _exIndex = this.items.indexOf(obj);
        if(_exIndex > -1)
            this.items.splice(_exIndex,1);
    }

    mounted () {
        this.$store.dispatch('budgetLineModule/fetchBudgetLines')
        this.$store.dispatch('nptModule/fetchNptOperation').then((response: any) => {
            let data = response.data.data
            data.map((obj: any) => {
                this.nptOperationOption.push({
                    text: obj.operationName,
                    value: obj.id
                })
            })
        })
    }
}

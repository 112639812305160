import { HTTP } from '@/axios_api/http-common' 

const state={
    templates:[]
}

interface IState {
    templates: Array<any>
}

const mutations={
    setTemplates (state: IState, templates: Array<any>) {
        state.templates = templates
        if(state.templates)
            state.templates.map((p: any) => {
                p.operations.map((x: any) => {
                    x.days = 0
                })
            })
    },
    addTemplate(state: IState, template: any){
        state.templates.push(template)
    },
    updateTemplate(state: IState, template: any){
        var _exIndex = -1;
        for (let index = 0; index < state.templates.length; index++) {
            if(state.templates[index].id == template.id) {
               _exIndex = index;
                break;
            } 
       }
       if(_exIndex > -1){
           JSON.stringify(template,null,4)
            state.templates.splice(_exIndex,1,template);
       }
           
    },
    deleteTemplate(state: IState, id: number){
        for (let index = 0; index < state.templates.length; index++) {
            if(state.templates[index].id == id) {
                state.templates.splice(index,1)
                break;
            }
       }
    }
}
const getters={
    fillteredTemplates:(state: IState) =>(filter: string)=> {
        return state.templates;
    },
    getTemplateById:(state: IState)=>(id: number)=>{
       let template = null
       for (let index = 0; index < state.templates.length; index++) {
            if(state.templates[index].id == id) {
                template = state.templates[index];
                break;
            } 
       }
       return template
    },
    getTemplateOptions:(state: IState)=>(return_obj: any) => {
        let templateOptions: Array<any> = []
        state.templates.forEach((template)=>{
            templateOptions.push({text:template.templateName,value: return_obj ? template : template.id})
        })
        return templateOptions
    }
}
const actions = {
    getTemplates ({ commit } : { commit: any }) {
        return new Promise((resolve, reject) => {
            HTTP.get('projecttemplates')
            .then((response: any) => {
                commit('setTemplates', response.data.data)                
                resolve(response)
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    createTemplate ({ commit } : { commit: any }, template_object: any) {
        return new Promise((resolve, reject) => {
            HTTP.post('projecttemplates/create', template_object)
            .then((response: any) => {
                
                commit('addTemplate',response.data.data)   

                resolve(response)
                
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    updateTemplate ({ commit } : { commit: any }, template: any) {
        return new Promise((resolve, reject) => {
            HTTP.put('projecttemplates/update/' + template.id, template )
            .then((response: any) => {
                JSON.stringify(response.data.data,null,4)
                commit('updateTemplate', response.data.data)
                resolve(response)
            })
            .catch((error: any) => {
                reject(error)
            });
        })        
    },
    deleteTemplate ({ commit } : { commit: any }, id: number) {
        return new Promise((resolve, reject) => {
            HTTP.delete('projecttemplates/delete/' +  id)
            .then((response: any) => {
                
                commit('deleteTemplate', id)

                resolve(response)
            })
            .catch((error: any) => {
                reject(error)
            })
        })      
    }
}

const templatesModule = {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
};
  
export default templatesModule;


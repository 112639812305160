import { HTTP } from '@/axios_api/http-common'

const state = {
    activityTypes:  [],
}

interface IState {
    activityTypes: Array<any>
}

interface IWellActivity {

}

const mutations ={
    setActivityTypes(state: IState, activityTypes: Array<IWellActivity>){
        state.activityTypes = activityTypes;
    },
    deleteActivityType(state: IState, activity: any){
        var _exIndex = state.activityTypes.indexOf(activity);
        if(_exIndex > -1)
            state.activityTypes.splice(_exIndex,1);
    },
    add(state: IState, new_activity: any) {
        state.activityTypes.push(new_activity)
    },
    update(state: IState, update: any){
        for (let index = 0; index < state.activityTypes.length; index++) {
             if(state.activityTypes[index].id == update.id) {
                state.activityTypes[index] = update;
                break;
             } 
        }
    },
}   

const getters = {
    getActivityType:(state: IState) => (id: any) =>{
        let activity = null
        if(!state.activityTypes)return;
        for (let index = 0; index < state.activityTypes.length; index++) {
             if(state.activityTypes[index].id == id) {
                activity = state.activityTypes[index];
                 break;
             } 
        }
        return activity
    },
    getWellOperations: (state: IState) =>{
        return state.activityTypes;
    },
    wellActivityOptions: (state: IState) => {
        if(!state.activityTypes) return [];
        return JSON.parse(JSON.stringify(state.activityTypes)).sort((a: any, b: any) => {
                    if (a.name < b.name) return -1
                    else if (a.name > b.name) return 1
                    return 0
                }).map((type: any) => {
                    return {text: type.name, value:type}
                })      
    }
}

const actions = {
    getWellActivities ({ commit }: { commit: any}) { 
        return new Promise((resolve, reject) => {
            HTTP.get('wellactivity')
            .then((response: any) => {
                commit('setActivityTypes', response.data.data)      
                resolve(response)
                
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    deleteWellActivity({commit}: {commit: any}, wellActivity: any) {
        return new Promise ((resolve, reject) => {
            HTTP.delete('wellactivity/delete/' + wellActivity.id).then((res: any) => {
                commit('deleteActivityType', wellActivity)
                resolve(res)
            }).catch((error: any) => {
                reject(error)
            })
        })
    },
    updateWellActivityAction({commit}: {commit: any}, wellActivity: any) {
        return new Promise((resolve, reject) => {
            HTTP.put('wellactivity/update', wellActivity).then((res: any)=> {
                commit('update', res.data.data)
                resolve(res)
            }).catch((err: any) => {
                reject(err)
            })
        })
    },
    createWellActivity({commit}: {commit: any}, wellActivity: any) {
        return new Promise ((resolve, reject) => {
            HTTP.post('wellactivity/create', wellActivity).then((res: any) => {
                commit('add', res.data.data)
                resolve(res)
            }).catch((error: any) => {
                reject(error)
            })
        })
    }
}

const wellactivitiesModule = {
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}

export default wellactivitiesModule;












































import Vue from "vue"
export default Vue.extend({
    props:{
        showModal:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            modalVisible:false,
            fields:[
                "subject",
                "timeSent"
            ],
            selectedNotifications:[] as Array<any>,
            notificationBody: "",
            isBodyVisible:false,
            searchText:""
        }
    },
    computed:{
        notifications():Array<any>{
            return  this.$store.getters["Notifications/getNotifications"]
        },
        bodycols(){
            if( this.isBodyVisible == true){
              return 4;
            }
            return 0;
        },
         titlecols(){
            if( this.isBodyVisible == true){
              return 8;
            }
            return 12;
        },
        filteredNotification() : Array<any>{
            if(this.searchText){
                return this.notifications.filter(x=> x.subject.toLowerCase().includes(this.searchText.toLowerCase()))
            }
            return this.notifications
        },

    },
    methods:{
        checkBoxSelectionChanged(notificationId:any){
            if(this.selectedNotifications.includes(notificationId)){
                this.selectedNotifications.splice(this.selectedNotifications.indexOf(notificationId),1)
            }else{
                this.selectedNotifications.push(notificationId)
            }
        },
        showNotificationBody(body:string){
            this.isBodyVisible= true;
            this.notificationBody = body
        },
        deleteNotification(notificationId:number){
            this.isBodyVisible = false;
            this.$store.dispatch("Notifications/deleteNotification",notificationId).then((response: any) => {
             let index = this.filteredNotification.findIndex(x=> x.id == notificationId)
             this.filteredNotification.splice(index,1)
            })
            this.bodycols = 0;
        },
        clearAllNotifications(){
            this.$store.dispatch("Notifications/clearAllNotifications").then((response: any) => {
             this.filteredNotification.splice(0,this.filteredNotification.length)
            })
            this.bodycols = 0;  
        }
    },
    watch:{
        showModal:{
            immediate:true,
            handler(){
                if(this.showModal){
                    this.$bvModal.show("notification-modal")
                    this.modalVisible = true
                }else{
                    if(this.modalVisible){
                        this.$bvModal.hide("notification-modal")
                        this.$emit("notification-modal-closed")
                        this.modalVisible = false
                    }
                }
            }
        }
    }
})

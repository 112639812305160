export default {
    methods:{
        persistMouseDownPosition(data){
            this.mouseDownPositionY = data.y
            this.mouseDownPositionX = data.x
        },
        
        onMouseDown(data){
            data.stopPropagation()
            data.preventDefault()
            this.persistMouseDownPosition(data)
            this.activeHandler = this.$watch('position',this.handleMove)
            this.$emit('cardselected',{id:this.$vnode.key,state:'MOUSE_DOWN'})
        },
        topLeftArdonerMouseDown(data){
            data.stopPropagation()
            this.persistMouseDownPosition(data)
            this.activeHandler = this.$watch('position',this.handleTopLeftArdoner)
            this.$emit('cardselected',{id:this.$vnode.key,state:'TOP_LEFT_MOUSE_DOWN'})
        },
        topRightArdonerMouseDown(data){
            data.stopPropagation()
            this.persistMouseDownPosition(data)
            this.activeHandler = this.$watch('position',this.handleTopRightArdoner)
            this.$emit('cardselected',{id:this.$vnode.key,state:'TOP_RIGHT_MOUSE_DOWN'})
        },
        bottomLeftArdonerMouseDown(data){
            data.stopPropagation()
            this.persistMouseDownPosition(data)
            this.activeHandler = this.$watch('position',this.handleBottomLeftArdoner)
            this.$emit('cardselected',{id:this.$vnode.key,state:'BOTTOM_LEFT_MOUSE_DOWN'})
        },
        bottomRightArdonerMouseDown(data){
            data.stopPropagation()
            this.persistMouseDownPosition(data)
            this.activeHandler = this.$watch('position',this.handleBottomRightArdoner)
            this.$emit('cardselected',{id:this.$vnode.key,state:'BOTTOM_RIGHT_MOUSE_DOWN'})
        },
        
        handleMove(){
            this.top = this.top+(this.position.top-this.mouseDownPositionY)
            this.left = this.left+(this.position.left-this.mouseDownPositionX)
            this.mouseDownPositionY = this.position.top
            this.mouseDownPositionX = this.position.left
            if (!this.atPositionChanged) {
                this.atPositionChanged = true
            }
        },

        handleBottomLeftArdoner(){
            this.height = this.height+(this.position.top-this.mouseDownPositionY)
            this.width = this.width-(this.position.left-this.mouseDownPositionX)
            this.left = this.left+(this.position.left-this.mouseDownPositionX)
            this.mouseDownPositionY = this.position.top
            this.mouseDownPositionX = this.position.left
            if (!this.atPositionChanged) {
                this.atPositionChanged = true
            }
        },

        handleBottomRightArdoner(){
            this.height = this.height+(this.position.top-this.mouseDownPositionY)
            this.width = this.width+(this.position.left-this.mouseDownPositionX)
            this.mouseDownPositionY = this.position.top
            this.mouseDownPositionX = this.position.left
            if (!this.atPositionChanged) {
                this.atPositionChanged = true
            }
        },

        handleTopLeftArdoner(){
            this.height = this.height-(this.position.top-this.mouseDownPositionY)
            this.width = this.width-(this.position.left-this.mouseDownPositionX)
            this.left = this.left+(this.position.left-this.mouseDownPositionX)
            this.top = this.top+(this.position.top-this.mouseDownPositionY)
            this.mouseDownPositionY = this.position.top
            this.mouseDownPositionX = this.position.left
            if (!this.atPositionChanged) {
                this.atPositionChanged = true
            }
        },

        handleTopRightArdoner(){
            this.height = this.height-(this.position.top-this.mouseDownPositionY)
            this.width = this.width+(this.position.left-this.mouseDownPositionX)
            this.top = this.top+(this.position.top-this.mouseDownPositionY)
            this.mouseDownPositionY = this.position.top
            this.mouseDownPositionX = this.position.left
            if (!this.atPositionChanged) {
                this.atPositionChanged = true
            }
        } ,
    }
}


















import Vue from 'vue'
import {Component, Prop, Watch} from 'vue-property-decorator'
import BudgetSheet from '../../components/BudgetSheet'
@Component({
    components:{'budget-sheet':BudgetSheet}
})
export default class BudgetView extends Vue{

    @Prop({default:""})
    activityId!:any

    @Prop({default:""})
    budgetTemplateId!:any

    @Prop({default:()=>[]})
    milestones!:Array<any>

    @Prop()
    reportView!:boolean

    @Prop()
    budgetLumpSum!:boolean

    @Prop({default:()=>false})
    dayRate!:boolean

    @Prop({default:()=>false})
    unitCost!:boolean

    @Prop({default:()=>false})
    showSplit!:boolean

    @Prop({default:false})
    showRowSum!:boolean

    get columns(): Array<any> {
        //if(this.activity!=undefined)window.console.log(this.activity.budget)
        let columnsToReturn = [] as Array<any>
        if (this.budgetLumpSum){
            let budget = {budgetLines:[]}
            if(this.activity!=undefined&&this.activity.budget!=undefined)budget = this.activity.budget
            columnsToReturn.push({id:this.activityId,name:"Item Cost (K$)",budget:budget,editable:true});
        }else{
            this.milestones.forEach((m: any) => {
                columnsToReturn.push(
                    {id:m.id,name:m.name,budget:m.budget==null?{budgetLines:[]}:m.budget,editable:true}
                );
            });
        }
        return columnsToReturn
    }

    get rowsData():any{
        let result:any = {rows:[],groupExtractor:undefined}
        if(this.budgetTemplateId==""||this.budgetTemplateId==null) return result
        let budgetTemplate = this.$store.getters["budgetTemplateModule/getAllTemplates"].find((thisBudgetTeplate:any) => thisBudgetTeplate.id == this.budgetTemplateId)
        if(budgetTemplate){
            result.groupExtractor = (s:any)=>({key:s.id,label:s.sectionName,rows:s.budgetLineItems})
            if((this as any).canWriteProject) result.rows = budgetTemplate.sections
            else result.rows = budgetTemplate.sections.filter((s:any) => s.departmentId == this.activeUser.departmentId)
        }
        return result
    }

    get activeUser():any{
        return this.$store.getters["activeUser"]
    }

    get activity():any{
        return this.$store.getters["ActivityModule/getActivityById"](this.activityId)
    }
    get selectedProject() {
        return this.$store.getters['projectModule/getSelectedProject']
    }

    get getProjectIndexById(){
        return this.$store.getters["projectModule/getProjectIndexById"](this.selectedProject.id)
    }

    onLineValueChanged(item:any){
        if(this.budgetLumpSum){
            this.$store.dispatch('ActivityModule/updateActivityBudget',{sequenceId:this.selectedProject.sequenceId,projectIndex:this.getProjectIndexById,id:this.activityId,sectionId:item.groupKey,lineItem:{lineValue:item.value,lineItemId:item.lineItemId}})
        }
    }
}

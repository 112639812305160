import { HTTP } from '@/axios_api/http-common'
import { stat } from 'fs';

const state = {
    notifications: [],
}

interface IState {
    notifications: Array<any>
    unreadCount:number
}

const mutations = {
    setNotifications(state: IState, notifications: Array<any>) {
        state.notifications = notifications
    },
    markAsRead(state: IState, unreadCount:number) {
        state.unreadCount = unreadCount
    },
}


const getters = {
    isNotificationEmpty:(state:IState) => {
        return state.notifications.length<1
    },
    getNotifications:(state:IState) => {
        const sortedNotifications = state.notifications.sort((a, b) => Date.parse(b.timeSent) - Date.parse(a.timeSent))
        return sortedNotifications
    },
    getNumberOfUnreadNotifications: (state: IState) =>{
        let unreadNotifications = state.notifications.filter(x=>Date.parse(x.lastQueriedDate) < Date.parse(x.timeSent))
        state.unreadCount = unreadNotifications.length
        return state.unreadCount
    }
}

const actions={
    getNotificaionsAction({ commit,rootState }: { commit : any,rootState:any }) {
        return new Promise((resolve: any, reject: any) => {
            HTTP.get('notifications?userEmail='+rootState.activeUser.email).then((response: any) => {                       
              commit('setNotifications', response.data.data)	
              resolve(response)    
            })
            .catch((error: any) => {
              reject(error)
            })
        })         
    },
    markNotificationAsReadAction ({ commit } : { commit : any}, ids:Array<Number>) {
        return new Promise((resolve, reject) => {
            commit('markAsRead',0)   
            HTTP.post('notifications/read', ids)
            .then((response: any) => {
                resolve()
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    updateNotificationStatus ({ commit,rootState }: { commit : any,rootState:any }) {
        return new Promise((resolve, reject) => {  
            HTTP.put('notifications/updatestatus?userEmail='+rootState.activeUser.email)
            .then((response: any) => {
                resolve()
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    deleteNotification ({ commit,rootState }: { commit : any,rootState:any },notificationId: number) {
        return new Promise((resolve, reject) => {  
            HTTP.put('notifications/delete?notificationId='+notificationId+'&userEmail='+rootState.activeUser.email)
            .then((response: any) => {
                resolve()
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
    clearAllNotifications ({ commit,rootState }: { commit : any,rootState:any }) {
        return new Promise((resolve, reject) => {  
            HTTP.put('notifications/delete-all?userEmail='+rootState.activeUser.email)
            .then((response: any) => {
                resolve()
            })
            .catch((error: any) => {
              reject(error)
            })
        })
    },
}

const notificationsModule={
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}

export default notificationsModule
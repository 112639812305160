import { HTTP } from '@/axios_api/http-common'
import Vue from 'vue'

const state = {
    aars: {}
}
  
interface IState {
    aars: Record<string,Array<any>>,
}

const getters = {

    getAARsForProject:(state:IState) => (projectId:any) => {
        const reportsToReturn = state.aars[projectId]
        if(reportsToReturn==undefined)return []
        return reportsToReturn
    },

    getAARById:(state:IState) => (reportId:any) => {
        for(let k in state.aars){
            let aar = state.aars[k].find((thisAAR:any) => thisAAR.id==reportId)
            if(aar) return aar
        }
    }

}

const mutations = {
    setAARs(state:IState,data:any){
        Vue.set(state.aars,data.projectId,data.data)
    },
    setProjectId(state:IState,projectId:any){
        if(!state.aars[projectId])Vue.set(state.aars,projectId,[])
    },
    addAAR(state:IState,data:any){
        state.aars[data.projectId].push(data.data)
    },
    deleteDepartment(state: IState, id: number){
        for(var key in state.aars){
            for (let index = 0; index < state.aars[key].length; index++) {
                if(state.aars[key][index].id == id) {
                    state.aars[key].splice(index,1)
                    break;
                }
           }
        }
    }
}

const actions = {

    getAARsForProject({ commit,state } : { commit: any,state:IState},projectId:any){
        return new Promise((resolve, reject) => {
            HTTP.get('aar/?projectId='+projectId)
            .then((response: any) => {
                commit('setAARs', {projectId:projectId,data:response.data.data} )         
                resolve(response)                    
            })
            .catch((error: any) => {
                reject(error)
            })
        })
    },

    createAAR({commit}:{commit:any},data:any){
        return new Promise((resolve,reject) => {
            HTTP.post('aar/create',data.data)
            .then((response:any) => {
                commit('addAAR',{projectId:data.projectId,data:response.data.data})
                resolve()
            })
            .catch((error:any) => {
                reject(error)
            })
        })
    },

    deleteDepartment ({ commit } : { commit : any }, id: any) {
        return new Promise((resolve, reject) => {
            HTTP.delete('aar/delete/' +  id)
            .then((response: any) => {
                commit('deleteDepartment', id)
                resolve(response)
            })
            .catch((error: any) => {
                reject(error)
            })
        })      
    }

}


const AARReportModule = {
    namespaced:true,
    state,
    mutations,
    getters,
    actions
  }
  
  export default AARReportModule;





















































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import milestone from '@/components/FormComponents/milestoneComponent.vue'
import dailyReport from '@/components/FormComponents/DailyReports/parentDailyReport.vue'
import budgetUploads from '@/components/FormComponents/budgetUploads.vue'
import sequencesModule from '@/store/modules/sequence';

@Component({
    components: {
        milestone,dailyReport,budgetUploads
    },
    filters: {
        prettydate(passedDate: any) {
            if(typeof(passedDate)=="string")return passedDate
            let passedMonth = ((passedDate.getMonth()+1)>9)?(passedDate.getMonth()+1):'0'+(passedDate.getMonth()+1)
            let passedDay = ((passedDate.getDate())>9)?(passedDate.getDate()):'0'+(passedDate.getDate())
            return passedDate.getFullYear()+"-"+passedMonth+"-"+passedDay
        }
    }
})

export default class ActivityPane extends Vue {
    private activityModel = {id:"",name:"",start:"",end:"",parentActivityId:"",departmentId:null,supportTeamsIds:[],wellActivityId:null,activityDependencyId:null,duration:0}
    private deletePromptProperty: boolean = false;
    private milestone: boolean = false;
    private dailyReport: boolean = false;
    private budget: boolean = false;
    private selectedMilestone: any = {};
    view = 'General'

    get selectedSequence() {
        return this.$store.getters['projectModule/getSelectedSequence']
    }

    get milestones () {
        return this.$store.getters['MilestoneModule/getMilestonesForActivity']
    }

    get selectedProject() {
        return this.$store.getters['projectModule/getSelectedProject']
    }

    get getProjectIndexById(){
        return this.$store.getters["projectModule/getProjectIndexById"](this.selectedProject.id)
    }

    get getActivitiesForProject(){
        return this.$store.getters["ActivityModule/getActivitiesForProject"](this.selectedProject.id)
    }

    get activity () {
        return this.$store.getters['ActivityModule/getActiveActivity']
    }

    get activities(){
        return this.$store.getters['ActivityModule/getActivitiesForProject'](this.selectedProject.id)
    }

    get getWellActivities () {
        return this.$store.getters['wellactivitiesModule/getWellOperations']
    }

    get getWellActivityOptions () {
        return this.getWellActivities.map((data: any) => {
            return {text: data.name, value: data.id}
        })
    }

    get getActivityDepedencyOptions(){
        let thisActivityStartTime = new Date(this.activityModel.start).getTime()
        return this.getActivitiesForProject
        .filter((thisActivity:any) => (
        new Date(thisActivity.end).getTime()<=thisActivityStartTime)&&this.activityModel.id!=thisActivity.id)
        .map((thisFilteredActivity:any) => {
            return {text:thisFilteredActivity.wellActivity.name,value:thisFilteredActivity.id}
        })
    }

     get getTeamOptions():Array<any>{
        let selectedSequence = this.sequences.find((x:any)=>x.id == this.selectedSequence.Id);
        let teamsToReturn:Array<any> = []
        selectedSequence.departments.forEach((d:any) => {
           d.teams.map((t:any)=>(teamsToReturn.push({text:t.teamName,value:t.id})))
        });
        return teamsToReturn
    }
    get sequences() {
       return this.$store.getters["sequencesModule/getSequences"]
    }

    //methods
    addDays(date:string, daysToAdd:any) {
        var result = null
        if(typeof(date)=="string"){
            let splitedDate = date.split('-')
            result = new Date(Number.parseInt(splitedDate[0]),Number.parseInt(splitedDate[1])-1,Number.parseInt(splitedDate[2]));
        }else{
            result = date
        }
        if(daysToAdd=="") return result
        result.setDate(result.getDate() + Number.parseInt(daysToAdd));
        return result;
    }

    otherTabFunction (tabName: string) {
        this.view = tabName
    } 

    deleteItemClicked(data:any){
        this.$store.commit('MilestoneModule/deleteMilestone', data)
    }

    onTeamChanged(){
        this.$nextTick(() => {
              this.$store.dispatch('ActivityModule/updateActivity',{sequenceId:this.selectedSequence.Id,projectIndex:this.getProjectIndexById,id:this.activityModel.id,supportTeamsIds:this.activityModel.supportTeamsIds})
    })   
    }

    editItem(data: any) {
        this.selectedMilestone = data
        this.milestone = true
    }

    computeDuration(start:string,end:string){
        if(start==""||end=="")return 0
        let startDateMill = (new Date(start)).getTime()
        let endDateMill = (new Date(end)).getTime()
        let diff = endDateMill - startDateMill
        if(diff<1)return 1
        return Math.round(diff/(1000 * 3600 * 24))
    }

    OnNamePropertyChanged(){
        this.$store.dispatch('ActivityModule/updateActivity',{sequenceId:this.selectedSequence.Id,projectIndex:this.getProjectIndexById,id:this.activityModel.id,name:this.activityModel.name})
    }

    OnDurationPropertyChanged(newVal:number){
        let newEndDate:any = this.addDays(this.activityModel.start,this.activityModel.duration)
        this.activityModel.end = newEndDate
        this.OnEndDateChanged()
    }         

    OnStartDateChanged(){
        this.$store.dispatch('ActivityModule/updateActivity',{sequenceId:this.selectedSequence.Id,projectIndex:this.getProjectIndexById,id:this.activityModel.id,start:this.activityModel.start})
        this.activityModel.duration = this.computeDuration(this.activityModel.start,this.activityModel.end)
    }

    OnEndDateChanged(){
        this.$store.dispatch('ActivityModule/updateActivity',{sequenceId:this.selectedSequence.Id,projectIndex:this.getProjectIndexById,id:this.activityModel.id,end:this.activityModel.end})
        this.activityModel.duration = this.computeDuration(this.activityModel.start,this.activityModel.end)
    }

    onWellActivityChanged(newVal:any){
        let wellActivity = this.getWellActivities.find((wa:any) => wa.id==this.activityModel.wellActivityId)
        this.$store.dispatch('ActivityModule/updateActivity',{sequenceId:this.selectedSequence.Id,projectIndex:this.getProjectIndexById,id:this.activityModel.id,wellActivity:wellActivity})
    }

    onActivityDependencyChanged(newVal:any){
        this.$store.dispatch('ActivityModule/updateActivity',{sequenceId:this.selectedSequence.Id,projectIndex:this.getProjectIndexById,id:this.activityModel.id,activityDependencyId:this.activityModel.activityDependencyId})
    }

    closePropertyGrid(){
        this.$store.commit("setRightPaneToFalse")
    }

    doDelete() {
        this.$store.dispatch('ActivityModule/deleteActivity', {sequenceId:this.selectedSequence.Id,projectIndex:this.getProjectIndexById,id:this.activityModel.id})
        this.$store.commit('setRightPaneToFalse')
    }

    @Watch("activity",{immediate:true,deep:true})
    onActivityChanged(){
        if(this.activity){
            this.activityModel.id = this.activity.id
            this.activityModel.parentActivityId = this.activity.parentActivityId
            this.activityModel.departmentId = this.activity.departmentId
            this.activityModel.start = this.activity.start
            this.activityModel.end = this.activity.end
            this.activityModel.wellActivityId = this.activity.wellActivity.id
            this.activityModel.name = this.activity.name
            this.activityModel.supportTeamsIds = this.activity.supportTeamsIds?this.activity.supportTeamsIds:[]
            this.activityModel.duration = this.computeDuration(this.activity.start,this.activity.end)
            this.activityModel.activityDependencyId = this.activity.activityDependencyId?this.activity.activityDependencyId:null

            //before attempting to load milestones from backend, first
            if(this.activity.milestones.length>0){
                if(this.$store.getters["MilestoneModule/getMilestonesForActivity"].length<1)this.$store.dispatch("MilestoneModule/getMilestonesForActivity")
            }
        }
    }

    mounted () {      
        if (this.getWellActivities.length == 0) {
            this.$store.dispatch('wellactivitiesModule/getWellActivities')
        }
         if(this.$store.state.departmentModule.departments,length<1){
            this.$store.dispatch("departmentModule/getDepartments")
        }
    }
}











































































































































import Vue from 'vue'
import Component from 'vue-class-component';
import EventBus from './../../eventBus'
import UnitConverter from '../../mixins/unitConverter.ts'
import RigImport from "@/components/RigImport.vue"

@Component({
    mixins: [UnitConverter],
    components:{
    "rigs-import": RigImport
    }
})
export default class RigsOverview extends Vue{
    //Data
    deletePromptProperty: boolean = false
    loader: boolean = true
    loadingTitle: string = 'Loading...'
    loadingMessage: string = 'Loading Rigs'
    successMessage: string = ''
    additionalSuccessMessage = ''
    error: string = ''
    showModal:boolean = false
    fields: Array<any> = [ 
        {key: 'photo',label: 'Image'},
        {key: 'name', sortable: 'true'},
        {key: 'owner', label: 'Rig Owner'},
        {key: 'minimumHPRating', label: 'Drawworks Rating'},
        {key: 'hookLoadCapacityRating', label: 'Hook Load Capacity Rating'},
        'terrain','actions'
    ]
    selectedRig: any = {
        name: '', 
        status: '',
        no_activities: '',
        type: '', 
        hp: '', 
        depth:'', 
        capacity: '', 
        minimumDrillDepth: {value: 0, uom: 'm'},
        minimumHPRating: {value: 0, uom: 'm'},
        travelingBlockMinApiWorkingPressure: {value: 0, uom: 'm'},
        rotarySwivelMinWorkingpressure: {value: 0, uom: 'm'},
        rotarySwivelAPIDeadLoadRating: {value: 0, uom: 'm'},
        hookLoadCapacityRating: {value: 0, uom: 'm'},
        description: ''
    }
    activeRig: any = null //under investigation
    search: string = ''
    activeScreen: string = ''
    isView: boolean = false

    get disableDelete(){
        return (rigs:any) => {
            let q = this.filteredSequences('').findIndex((seq:any) => seq.rigs.some((rig:any)=> rig.id === rigs.id))
            if (q >= 0) {
                return true
            } else {
                return false
            }
        }
    }

    // Methods
    deleteRigPrompt(selectedRig: any){
        this.selectedRig.id = selectedRig.id
        this.selectedRig.name = selectedRig.name
        this.selectedRig.no_activities = selectedRig.no_activities
        this.selectedRig.status = selectedRig.status
        this.selectedRig.type = selectedRig.type
        this.selectedRig.hp = selectedRig.hp
        this.selectedRig.depth = selectedRig.depth
        this.selectedRig.capacity = selectedRig.capacity
        this.selectedRig.description = selectedRig.description
        this.deletePromptProperty = true
    }

      fetchSequences() {
        return this.$store.dispatch('sequencesModule/fetchSequences')
    }

    view(selected: any){
        this.selectedRig = selected
        this.isView = true
    }

    editRig (rig_id: number){
        this.$router.push('/manage-rig/?id='+ rig_id.toString())
    }

    searchValue(e: any) {
        this.search = e
    }

    doDelete(bvEvt: any){
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.loadingMessage = 'Deleting '+ this.selectedRig.name
        this.loadingTitle = 'Deleting'
        this.deleteRigAction(this.selectedRig.id).then( (response: any) => {
            this.activeScreen = 'successNoBack'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.successMessage = this.selectedRig.name + ' deleted succesfully'
            this.additionalSuccessMessage = 'This Rig will no longer be available'
            this.deletePromptProperty = false
        },(error: any) => {          
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.message
            this.deletePromptProperty = false
        })  
    }


    // VUEX ACTIONS
    deleteRigAction(id: any){
        return this.$store.dispatch('rigsModule/deleteRigAction', id)
    }

    getRigs () {
        return this.$store.dispatch('rigsModule/getRigs')       
    }




    // Computed propertied
    get filteredRigs () {
        return this.$store.getters['rigsModule/filteredRigs']
    }

    get filteredSequences() {
        return this.$store.getters['sequencesModule/filteredRootSequences']
    }
    



    // CREATED
    created() {
        if(this.filteredRigs('').length===0){
            this.getRigs().then(response=>{
                // window.console.log(response.data.data)
                this.activeScreen = 'main';
                EventBus.$emit('activeScreen', this.activeScreen)
                this.loader = false            
            })
        }else {
            // window.console.log(this.filteredRigs(''))
            this.activeScreen = 'main';
            EventBus.$emit('activeScreen', this.activeScreen)
            this.loader = false            
        }
        if(this.filteredSequences('').length === 0) {
            this.fetchSequences();
        }
    }
    
}

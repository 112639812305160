import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faPlus, faChevronCircleLeft, faTasks, faCopy, faCircle, faExternalLinkAlt, faPowerOff, faTrashAlt, 
  faTh, faList, faAngleDown, faHome, faEnvelope, faIdCard, faAngleUp, faTable, faDollarSign,faBolt, faPrint,
  faPlayCircle,faPlay, faPauseCircle, faPause, faSignOutAlt,faBell, faAnchor, faArrowsAlt, faAdjust, faMoneyBill,
  faSave, faCog,  faUserCircle, faLongArrowAltLeft, faCalendar, faLongArrowAltUp, faExclamationTriangle, faFile,
  faBuilding, faLongArrowAltRight, faFileExcel, faHandshake, faChartBar, faBars, faTrash, faEdit, faArrowCircleLeft, 
  faTachometerAlt, faUsers, faTimes, faPlusCircle, faArrowLeft, faCogs, faCalendarAlt, faUser, faDrawPolygon, 
  faCheckCircle,faTimesCircle, faEllipsisV, faLock, faLockOpen, faEye, faPalette, faChartLine, faProjectDiagram, faColumns, faThLarge, faWindowRestore, faArrowDown, faArrowUp, faArrowRight, faFilter, faChevronLeft, faFrown,faBroom, faCheckSquare, faFire, faBroadcastTower} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import LoaderComponent from './components/LoadingComponent.vue'
import BaseComponent from './components/BaseComponent.vue'
import OverviewComponent from './components/OverviewComponent.vue'
import FormComponent from './components/FormComponent.vue'
import ErrorComponent from './components/ErrorComponent.vue'
import SuccessComponent from './components/SuccessComponent.vue'
import DeleteModal from './components/DeleteModal.vue'
import NotificationComponent from './components/NotificationComponent.vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './style.css'
import 'vue2-timepicker/dist/VueTimepicker.css'
import BootstrapVue from 'bootstrap-vue'
import Datepicker from 'vuejs-datepicker'
import {Sketch, Slider} from 'vue-color'
import gantt from 'dsogantt'
import VueTimepicker from 'vue2-timepicker'
import Vuelidate from 'vuelidate'
import EventBus from './eventBus'
import unitManager from './unitManager.ts'
import UserProvisioning from './mixins/userProvisioning.ts'
import Mailer from '@/components/MailModal'


Vue.config.productionTip = false

// Font awesome icons
library.add(
  faTable, faCircle, faPlusCircle, faPlay, faPlayCircle, faPause, faHome, faEnvelope, faIdCard,
  faPauseCircle, faPlus, faChevronCircleLeft,faChevronLeft, faAdjust, faArrowsAlt, faBolt, faAnchor, faTrashAlt,
  faSave, faBars, faTachometerAlt, faUserCircle, faArrowCircleLeft, faPrint, faFile,faCheckSquare,
  faSpinner, faLongArrowAltUp, faLongArrowAltLeft, faLongArrowAltRight, faMoneyBill,
  faTimes, faEdit, faTrash, faPlusCircle, faChartBar, faArrowLeft, faCogs,
  faCalendarAlt, faExclamationTriangle, faTasks, faBell, faCalendar, faCog, faDollarSign,faFire,faBroadcastTower,
  faSignOutAlt, faPowerOff, faUsers, faUser, faBuilding, faHandshake, faDrawPolygon,
  faCheckCircle, faEllipsisV, faFileExcel, faLock, faLockOpen, faTimesCircle, faExclamationTriangle,
  faEye, faPalette, faAngleDown, faAngleUp, faTh, faList, faExternalLinkAlt, faCopy, faChartLine, faProjectDiagram, faArrowLeft, faColumns,faThLarge,faWindowRestore,faArrowDown, faArrowUp, faArrowRight, faPrint, faTable, faFilter, faFrown,faBroom)
  

// Components
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('vue-timepicker', VueTimepicker)
Vue.component('loader', LoaderComponent)
Vue.component('error', ErrorComponent)
Vue.component('success', SuccessComponent)
Vue.component('base-container', BaseComponent)
Vue.component('overview-component', OverviewComponent)
Vue.component('notification-component',NotificationComponent)
Vue.component('form-component', FormComponent)
Vue.component('delete-modal', DeleteModal)
Vue.component('datepicker', Datepicker)
Vue.component('color-picker', Sketch)
Vue.component('slider-picker', Slider)
Vue.component('mailer',Mailer)

Vue.use(BootstrapVue)
Vue.use(gantt)
Vue.use(Vuelidate)
Vue.mixin(UserProvisioning);


Vue.filter('unitconverter', (value: any,  system: any, fromUnit: any) => {
  // let unitManager = new UnitManager()
  let unitsArray = store.getters['UnitsModule/getUnits']
  let getQuantityFromUnits = store.getters['UnitsModule/getQuantityFromUnits']
  let fromUnitt = unitsArray.find((unit: any) => unit.symbol === fromUnit)
  let fromQuantityName = fromUnitt.quantity
  let fromQuantity = getQuantityFromUnits.find((quantity: any) => quantity.name === fromQuantityName)
  return (unitManager.convert(fromUnit, fromQuantity.getBaseUnit(system), value)).toFixed(1)
})

Vue.filter('toUnitSymbol', (value: any,  system: any, fromUnit: any) => {
  // let unitManager = new UnitManager()
  let unitsArray = store.getters['UnitsModule/getUnits']
  let getQuantityFromUnits = store.getters['UnitsModule/getQuantityFromUnits']
  let fromUnitt = unitsArray.find((unit: any) => unit.symbol === fromUnit)
  let fromQuantityName = fromUnitt.quantity
  let fromQuantity = getQuantityFromUnits.find((quantity: any) => quantity.name === fromQuantityName)
  return fromQuantity.getBaseUnit(system)
})



Object.defineProperty(Vue.prototype, '$eventBus', { value: EventBus});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

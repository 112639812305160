import unitManager from '../unitManager.ts'
import store from '../store'

const convertUnit = (value: any, system: any, fromUnit: any) => {
    // let unitManager = new UnitManager()
    let unitsArray = store.getters['UnitsModule/getUnits']
    let getQuantityFromUnits = store.getters['UnitsModule/getQuantityFromUnits']
    let fromUnitt = unitsArray.find((unit: any) => unit.symbol === fromUnit)
    let fromQuantityName = fromUnitt.quantity
    let fromQuantity = getQuantityFromUnits.find((quantity: any) => quantity.name === fromQuantityName)
    return (unitManager.convert(fromUnit, fromQuantity.getBaseUnit(system), value)).toFixed(1)
}

export default convertUnit







































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'

@Component
export default class BudgetUploadComponent extends Vue {
    @Prop()
    activityId !: any
    // @Prop()
    // public activities!: any[]
    private chosenbudgetTemplate: any = {}
    budgetTemplates: any[] = []
    budgetTemplate: any = null
    total: number = Number(0)
    activity: any = this.presentActivity

    get presentActivity () {
        return this.$store.getters['getCurrentActivity']
    }
    
    get allBudgetTemplates () {
        return this.$store.getters['budgetTemplateModule/getAllTemplates']
    }

    get mapFunction() {
        if (this.budgetTemplate !== null) {
            return this.budgetTemplate.budgetLineItems.map((o: any) => {
                return {
                    id: o.id, 
                    lineItem: o.lineItem, 
                    lineValue: 0
                }
            })
        } else {
            return null
        }
    }

    get activities () {
        return this.$store.getters['ActivityModule/getActivitiesForProject']
    }

    get activitiesOptions () {
        return this.$store.getters['ActivityModule/getActivitiesForProject'](this.$store.state.projectModule.activeProjectId).map((s:any)=> {
            return {
                text: s.name,
                value: s
            }
        })
    }

    // get activitiesOptions () {
    //     return this.activities.map((data: any) => {
    //         return {
    //             value: data.id,
    //             text: data.name
    //         }
    //     })
    // }

    sum () {
        let count: number = 0
        if (this.budgetTemplate !== null) {
            this.mapFunction.forEach((element: any) => {
                count = Number(element.lineValue) + count
                this.total = count
            });
        } else {
            this.total =  0
        }
    }

    private uploadBudget () {
        window.console.log()
    }

    get budgets () {
        return this.$store.getters['BudgetModule/getBudgetsForActivity']
    }

    @Watch('activity')
    onPropertyChanged(value: string, oldValue: string) {
         this.$store.commit("MilestoneModule/setActivityId", this.activity)
         if(this.activities.length<1)this.$store.dispatch("ActivityModule/getActivitiesForProject")
    }

    mounted() {
        this.$store.commit("BudgetModule/setActivityId", this.activityId)
        this.activity = this.activityId
        if(this.activities.length<1)this.$store.dispatch("ActivityModule/getActivitiesForProject")
        this.$store.dispatch('budgetTemplateModule/fetchBudgetTemplates').then((response: any) => {
            response.data.data.forEach((element: any) => {
                this.budgetTemplates.push({
                    text: element.templateName,
                    value: element
                })
            });
        })
    }
}

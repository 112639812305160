













































import Vue from 'vue'
// import Component from 'vue-class-component';
import { Component, Prop } from 'vue-property-decorator'
import EventBus from './../eventBus'

@Component ({
    props: {
        title : {
            default: 'Loading...',
            type: String,
            required: true
        },
        message : {
            default: '',
            type: String,
        },
        errorMessage: {
            default: 'Unable to complete this operation. Please try again.',
            type: String,
        },
        successMessage: {
            default: 'Created Successfully',
            type: String
        },
        additionalSuccessMessage: {
            default: 'You can now use the newly created item',
            type: String
        }
    }
})
export default class Base extends Vue{
    view: string = 'main'

    created() {
        EventBus.$on('activeScreen', (active: any) => {
            this.view = active
        })
    }
}


















































import Vue from 'vue'
import Component from 'vue-class-component';
// import EventBus from '@/eventBus'

@Component
export default class DependenciesOverview extends Vue{
    // DATA
    fields: Array<any> = [
        {key:'name',label:"Dependency Name", sortable:true},
        {key: 'department', sortable: 'true'},
        'isCritical','sample',
        {key: 'button', label: "Actions"} 
    ]
    activeItem: any = null
    search: string = ''
    error: string = ''
    activeScreen: string = 'loading'
    loadingMessage: string = 'Loading Dependencies'
    loadingTitle: string = 'Loading...'
    deletePromptProperty: boolean = false
    successMessage: string = ''
    additionalSuccessMessage = ''
    loader: boolean = true




    // COMPUTED PROPERTIES
    get filterDependencies () {
        return this.$store.getters['dependenciesModule/filterDependencies']
    }




    // VUEX METHODS AND MUTATIONS
    getDependencies () {
        return this.$store.dispatch('dependenciesModule/getDependencies')
    }



    // METHODS
    updateDependency(item: any){
        this.activeItem = item;
        this.$router.push({path:'/manage-dependency/', query:{ id: item.id }})
    }

    promptDelete(item: any){
        this.activeItem = item;
        this.deletePromptProperty = true
    }

    searchValue(e: any) {
        this.search = e
    }

    doDelete(){
        this.activeScreen = 'loading'
        this.$eventBus.$emit('activeScreen', this.activeScreen)
        this.loadingMessage = 'Please wait while we delete this dependency'
        this.loadingTitle = 'Deleting...'
        this.$store.dispatch('dependenciesModule/deleteDependencyAction', this.activeItem)
        .then(() => {
            this.activeScreen = 'successRemain'
            this.$eventBus.$emit('activeScreen', this.activeScreen)
            this.additionalSuccessMessage = 'This dependency will no longer be available'
            this.successMessage = this.activeItem.name + ' deleted succesfully'
            this.deletePromptProperty = false
            setTimeout(() => {
                this.activeScreen = 'main'
                this.$eventBus.$emit('activeScreen', this.activeScreen)
            }, 10000);
        }).catch((error: any) => {
            this.activeScreen = 'error'
            this.$eventBus.$emit('activeScreen', this.activeScreen)
            this.error = error.message
            this.deletePromptProperty = false
            setTimeout(() => {
                this.activeScreen = 'main'
                this.$eventBus.$emit('activeScreen', this.activeScreen)
            }, 10000);
        })
    }


    // CREATED
    created() {
        if (this.filterDependencies === null) {
            this.getDependencies().then(() => {
                this.activeScreen = 'main';
                this.$eventBus.$emit('activeScreen', this.activeScreen)
                this.loader = false
            })
        }else {
            this.getDependencies()
            this.activeScreen = 'main';
            this.$eventBus.$emit('activeScreen', this.activeScreen)
            this.loader = false
        }
    }
}


























































import Vue from 'vue'
import Component from 'vue-class-component'
import EventBus from './../../eventBus'

@Component
export default class ManageDependency extends Vue {
    activeScreen: string = 'main'
    loadingMessage: string = 'Please wait while we create new dependency'
    loadingTitle: string = 'Saving...'
    additionalSuccessMessage: string = ''
    successMessage: string = 'New Dependency Created Successfully'
    links: Array<object> = [
        {
            text: 'Dependencies',
            to: '/dependencies'
        },
        {
            text: 'Manage Dependencies',
            active: true
        }
    ]
    isCreate: boolean = true
    index: any = null
    error: any = {
        generalMessage: '',
        errors: []
    }
    dependency: any = {
        name: '',
        color: '',
        textColor: '',
        deptId: 0,
        department: {},
        isCritical: true,
    }
    activeItem: any = null



    // METHODS
    persistData(){
        this.activeScreen = 'loading';
        if(this.activeItem == null)
            this.saveNew();
        else
            this.update();
    }

    update(){
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.loadingMessage = 'Please wait while we update this dependency'
        this.loadingTitle = 'Saving...'
        var payload = this.createPayload()
        payload.department = this.departmentById(this.dependency.deptId)
        this.$store.dispatch('dependenciesModule/updateDependency', payload).then(() => {
            this.activeScreen = 'successNoGotIt'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.additionalSuccessMessage = ''
            this.successMessage = 'Dependency Updated Successfully'
            setTimeout(() => {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
            }, 10000);
        }).catch((err: any) => {
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error.generalMessage = err.message
            setTimeout(() => {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
            }, 10000);
        })
    }

    createPayload() {
        return {
            name: this.dependency.name,
            id: this.activeItem ? this.activeItem.id : 0,
            color: this.dependency.color.hex,
            isCritical: this.dependency.isCritical,
            departmentId: this.dependency.deptId,
            department: this.dependency.department,
            textColor: this.dependency.textColor.hex,
        };
    }

    saveNew(){
        var payload = this.createPayload()
        this.activeScreen = 'loading'
        EventBus.$emit('activeScreen', this.activeScreen)
        this.$store.dispatch('dependenciesModule/saveDepartment', payload).then((res) => {
            // window.console.log(payload)
            // window.console.log(res)
            this.activeScreen = 'successRemain'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.additionalSuccessMessage = 'You can now use the newly created dependency'
            this.successMessage = 'Successfully Created '+payload.name
            setTimeout(() => {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
            }, 10000);
        }).catch((err: any) => {
            this.activeScreen = 'error'
            EventBus.$emit('activeScreen', this.activeScreen)
            this.error.generalMessage = err.message
            setTimeout(() => {
                this.activeScreen = 'main'
                EventBus.$emit('activeScreen', this.activeScreen)
            }, 10000);
        })
    }



    // VUEX METHODS
    getDepartments () {
        return this.$store.dispatch('departmentModule/getDepartments')
    }




    // COMPUTED PROPERTIES
    get getDepartmentOptions () {
        return this.$store.getters['departmentModule/getDepartmentOptions']
    }
    get getDependency () {
        return this.$store.getters['dependenciesModule/getDependency']
    }
    get departmentById () {
        return this.$store.getters['departmentModule/getDepartmentById']
    }



    // CREATED
    created() {
        if(this.getDepartmentOptions){
            this.getDepartments();
        }
        
        if (this.$route.query){
            this.activeItem = this.getDependency(this.$route.query.id)
            this.dependency.name = this.activeItem.name,
            this.dependency.color = '#' + this.activeItem.color;
            this.dependency.textColor = '#' + this.activeItem.textColor;
            this.dependency.isCritical = this.activeItem.isCritical;
            this.dependency.deptId = this.activeItem.department.id;
            this.dependency.department = this.activeItem.department
        }
    }
}





























































































































































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import UnitConverter from '../../mixins/unitConverter.ts'

@Component({
  mixins: [UnitConverter]
})
export default class Depthsummary extends Vue {
  @Prop({}) 
  currentHoleSize!:any;
  @Prop({}) 
  currentDepth!:any;
  @Prop({}) 
  previousDepth!:any;
  @Prop({}) 
  depthProgress!:any;
  @Prop({}) 
  totalDepth!:any;
  @Prop({}) 
  actualTotalDepth!:any;
  @Prop({}) 
  conductorCasingSize!:any;
  @Prop({}) 
  conductorCasingDepth!:any;
  @Prop({}) 
  intermidateCasingSize!:any;
  @Prop({}) 
  intermidateCasingDepth!:any;
  @Prop({}) 
  productionCasingSize!:any;
  @Prop({}) 
  productionCasingDepth!:any;
  @Prop({}) 
  linearSize!:any;
  @Prop({}) 
  linearDepth!:any;
  @Prop({}) 
  lot!:any;
  @Prop({}) 
  fit!:any;

  private get currentHoleSizeInput() {
    return this.currentHoleSize;
  }
  private set currentHoleSizeInput(depth:any) {
    this.$emit("update:currentHoleSize", depth);
  }
   private get currentDepthInput() {
    return this.currentDepth;
  }
  private set currentDepthInput(depth:any) {
    this.$emit("update:currentDepth", depth);
  }
   private get previousDepthInput() {
    return this.previousDepth;
  }
  private set previousDepthInput(date:any) {
    this.$emit("update:previousDepth", date);
  }
   private get depthProgressInput() {
    return this.depthProgress;
  }
  private set depthProgressInput(time:any) {
    this.$emit("update:depthProgress", time);
  }
  private get totalDepthInput() {
    return this.totalDepth;
  }
  private set totalDepthInput(time:any) {
    this.$emit("update:totalDepth", time);
  }
  private get actualTotalDepthInput() {
    return this.actualTotalDepth;
  }
  private set actualTotalDepthInput(time:any) {
    this.$emit("update:actualTotalDepth", time);
  }
  private get conductorCasingSizeInput() {
    return this.conductorCasingSize;
  }
  private set conductorCasingSizeInput(time:any) {
    this.$emit("update:conductorCasingSize", time);
  }
  private get conductorCasingDepthInput() {
    return this.conductorCasingDepth;
  }
  private set conductorCasingDepthInput(time:any) {
    this.$emit("update:conductorCasingDepth", time);
  }
  private get intermidateCasingSizeInput() {
    return this.intermidateCasingSize;
  }
  private set intermidateCasingSizeInput(time:any) {
    this.$emit("update:intermidateCasingSize", time);
  }
  private get intermidateCasingDepthInput() {
    return this.intermidateCasingDepth;
  }
  private set intermidateCasingDepthInput(time:any) {
    this.$emit("update:intermidateCasingDepth", time);
  }
  private get productionCasingDepthInput() {
    return this.productionCasingDepth;
  }
  private set productionCasingDepthInput(time:any) {
    this.$emit("update:productionCasingDepth", time);
  }
  private get productionCasingSizeInput() {
    return this.productionCasingSize;
  }
  private set productionCasingSizeInput(time:any) {
    this.$emit("update:productionCasingSize", time);
  }
  private get lotInput() {
    return this.lot;
  }
  private set lotInput(time:any) {
    this.$emit("update:lot", time);
  }
  private get linearSizeInput() {
    return this.linearSize;
  }
  private set linearSizeInput(time:any) {
    this.$emit("update:linearSize", time);
  }
  private get linearDepthInput() {
    return this.linearDepth;
  }
  private set linearDepthInput(time:any) {
    this.$emit("update:linearDepth", time);
  }
  private get fitInput() {
    return this.fit;
  }
  private set fitInput(time:any) {
    this.$emit("update:fit", time);
  }
}

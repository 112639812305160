





import {Vue, Component, Watch} from 'vue-property-decorator';

@Component
export default class App extends Vue {
  
}

import Unit from './units'
import Quantity from './quantity'

 const unitManger = {
     quantities: [] as Array<InstanceType<typeof Quantity>>,

     convertMethod(fromUnit: InstanceType<typeof Unit>, toUnit: InstanceType<typeof Unit>, value: number): number {
        let baseValue: number = fromUnit.toBase(value);
        return toUnit.fromBase(baseValue);
    },

     findUnit(unitSymbol: string): InstanceType<typeof Unit> {
        let lowerCase: string = unitSymbol.toLowerCase().trim();

        for (let quantity of this.quantities) {
            for (let unit of quantity.getUnits()) {
                let symbol = unit.getSymbol()
                if (unitSymbol === symbol) {
                    return unit
                }
            }
        }

        for (let quantity of this.quantities) {
            for (let unit of quantity.getUnits()) {
                let symbol = unit.getSymbol()
                let lowercaseSymbol = symbol.toLowerCase()
                if (lowerCase === lowercaseSymbol) {
                    return unit
                }
            }
        }

         return {} as InstanceType<typeof Unit>
    },

    convert(fromUnitSymbol: string, toUnitSymbol: string, value: number): number {
        let fromUnit: InstanceType<typeof Unit> = this.findUnit(fromUnitSymbol);
        let toUnit: InstanceType<typeof Unit> = this.findUnit(toUnitSymbol);
        
        return fromUnit != null && toUnit != null ? this.convertMethod(fromUnit, toUnit, value) : value;
    }
}

export default unitManger









































import Vue from 'vue'
export default Vue.extend({
    name:"UnitSettings",
    data(){
        return {
            search: '',
            searchQuantity: '',
            quantities: [],
            selectedQuantities: ['length','volume', 'pressure', 'force', 'power', 'mass', 'time', 'density', 'speed', 'compressibility', 'viscosity', 'diffusion'],
            fields: [
                {key: 'name', label: 'Name'},
                {key: 'symbol', label: 'Symbol'},
                {key: 'quantity', label: 'Quantity'}
            ]
        }
    },
    computed:{
        getQuantityFromUnits() {
            return this.$store.getters['UnitsModule/getQuantityFromUnits']
        },
        units():any{
            return this.$store.state.UnitsModule.units
        },
        currentUnitSystem:{
            get () {
                return this.$store.state.UnitsModule.currentUnitSystem
            },
            set (value) {
                this.$store.commit('UnitsModule/updateCurrentUnitSystem', value)
            }
        },
        filteredUnits(): any {
            return this.units.filter((unit: any) => {
                return (unit.name.match(this.search) || unit.symbol.match(this.search)) && this.selectedQuantities.includes(unit.quantity) && this.checkUnit(unit)
            });
        },
        quantityOptions (): any {
            let quantitiesToreturn: any = []
            this.units.forEach((element: any) => {
                if ((quantitiesToreturn.findIndex((el:any) => el.value === element.quantity) < 0)) {
                    quantitiesToreturn.push({
                        text: element.quantity,
                        value: element.quantity
                    })
                }
            });
            return quantitiesToreturn
        },
        filteredCheckedUnits (): any {
            return this.quantityOptions.filter((unit: any) => {
                return unit.text.match(this.searchQuantity)
            });
        }
    },
    methods:{
        checkUnit(obj: any){
            if (obj.isSI === true && this.currentUnitSystem == "si") {
                return true
            } else if (obj.isField === true && this.currentUnitSystem == "field") {
                return true
            } else {
                return false
            }
        },
        onUnitSystemChanged(data:any){
            // window.console.log(data)
            this.$store.dispatch('UnitsModule/updateCurrentUnitSystem',data)
        },
        searchValue(e: any) {
            this.search = e
        },
        getQuantityToReturn(sentUnit: any) {
            let fromUnit = this.units.find((unit: any) => unit.symbol === sentUnit)
            let fromQuantityName = fromUnit.quantity
            let fromQuantity = this.getQuantityFromUnits.find((quantity: any) => quantity.name === fromQuantityName )
            return fromQuantity
        }
    },
    mounted(){
        if(this.units.length<1)this.$store.dispatch("UnitsModule/getUnits")
    }
})

















































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { componentsPlugin } from 'bootstrap-vue'
import settings from '@/store/modules/settings'

@Component
export default class ColorSettings extends Vue {
    error: any = {
        generalMessage: '',
        errors: []
    }
    activeScreen: string = 'main'
    successMessage: string =''
    additionalSuccessMessage: string =''
    loadingMessage: string =''
    loadingTitle: string =''
    view: string = 'rig'
    fields: any[] = ['property', 'color', 'action']
    annotationFields = ['position', 'property', 'options' ,'presentation', 'color']
    annontations = []
    propertOptions = [
        {text:"Budget",value:"BUDGET"},
        {text:"Planning Item",value:"PLANNING_ITEM"},
        {text:"Estimated Production",value:"ESTIMATED_PRODUCTION"}, 
        {text:"Project State",value:"PROJECT_STATE"}
    ]
    shapeOptions = [
        {text:"Bar",value:"RECTANGLE"},
        {text:"Cricle",value:"CIRCLE"},
        {text:"Triangle",value:"TRIANGLE"},
        {text:"Triangle (INVERTED)",value:"TRIANGLE_INVERTED"}
    ]
    activeItem: any = {}
    activeAnnotation: any = {}

    get settings () {
        return this.$store.getters['settingsModule/getSettings']
    }

    get settingsRig() {
        return this.settings.filter((x:any)=> x.id > 1 && x.id <=4)
    }
    get settingsWell () {
        return this.settings.filter((x:any)=> x.id > 4 && x.id <=8)
    }

    get settingsTime () {
        return this.settings.filter((x:any)=> x.id > 8 && x.id <= 11)
    }

    get annotationSettings () {
        return this.settings.filter((x:any)=> x.value == "ANNOTATION")
    }

    get computedAnnotationSettings () {
        return this.annotationSettings.map((x:any) => {
            const settingData = x.meta.split("/")
            const settingsObject = {id:"",position:"",states:[] as Array<string>, property:"",options:"",presentation:"",backgroundColor:"", textColor:""}
            settingsObject.id = x.id
            settingsObject.position = this.convertPositionToReadablePosition(x.key)
            settingsObject.property = settingData[0]
            settingsObject.options = settingData[1]
            settingsObject.states = this.convertStateStates(settingData[2],settingData[4],settingData[5])
            settingsObject.presentation = settingData[3]
            return settingsObject
        })
    }

    get planningItems () {
        return [
            {text:"Rig Readiness", value:"Rig Readiness"}
        ]
    }

    saveAnnotationBackgroundAndTextColor(){
        const backgroundPayload:any = {position:this.activeAnnotation.position,index:this.activeAnnotation.index,changedProperty:'background',background:this.activeAnnotation.background}
        this.$store.commit("settingsModule/updateAnnotationSettings",backgroundPayload)

        const textColorPayload:any = {position:this.activeAnnotation.position,index:this.activeAnnotation.index,changedProperty:'',background:this.activeAnnotation.background}
        this.$store.commit("settingsModule/updateAnnotationSettings",textColorPayload)
    }

    annotationSettingsChanged(newValue:string, changedProperty:string, position:string,index:number){
        const payload:any = {position,index,changedProperty}
        payload[changedProperty] = newValue
        this.$store.commit("settingsModule/updateAnnotationSettings",payload)
    }

    convertPositionToReadablePosition(passedPosition:string){
        switch(passedPosition){
            case "TOP_LEFT_ANNOTATION": return "Top Left Annotation"
            case "TOP_RIGHT_ANNOTATION": return "Top Right Annotation"
            case "BOTTOM_LEFT_ANNOTATION": return "Bottom Left Annotation"
            case "BOTTOM_RIGHT_ANNOTATION": return "Budget"
        }
        return ""
    }

    convertStateStates(state:string, stateBackgrounds: string, stateTextColors:string){
        if(state.includes("*")){
            const statesToReturn:Array<any> = []
            const states = state.split("*")
            const stateBackgroundsData = stateBackgrounds.split("*")
            const stateTextColorsData =  stateTextColors.split("*")
            states.forEach((s,i) => {
                statesToReturn.push(
                    {
                        state:s,
                        background:stateBackgroundsData.length>i?stateBackgroundsData[i]:"white",
                        textColor:stateTextColorsData.length>i?stateTextColorsData[i]:"black"
                    }
                )
            })
            return statesToReturn
        }else{
            return [{state,background:stateBackgrounds,textColor:stateTextColors}]
        }
    }

    otherTabFunction (tabName: string) {
        this.view = tabName
    }

    editColor(data: any) {
        this.activeItem = data
        let modalColor = this.$refs.bvColor as any
        modalColor.show()
    }

    annotationColorEditor(position:string){
        this.activeAnnotation.position = position
        let modalColor = this.$refs.annotationBVColor as any
        modalColor.show()
    }

    select () {
        let modalColor = this.$refs.bvColor as any
        modalColor.hide()  
        this.settingsRig.forEach((element: any) => {
            if (this.activeItem.property === element.property) {
                element.color = this.activeItem.color.hex
            }
        });    
        this.settingsTime.forEach((element: any) => {
            if (this.activeItem.property === element.property) {
                element.color = this.activeItem.color.hex
            }
        });    
        this.settingsWell.forEach((element: any) => {
            if (this.activeItem.property === element.property) {
                element.color = this.activeItem.color.hex
            }
        });    
    }

    doSave() {
        let objToBeSent = {
            id: this.activeItem.id,
            key: this.activeItem.key,
            value: this.activeItem.value.hex.substring(1, this.activeItem.value.hex.length),
            meta: null,
            // link: null
        }
        this.$store.dispatch('settingsModule/saveSettings', objToBeSent)
        let modalColor = this.$refs.bvColor as any
        modalColor.hide()
    }

    mounted() {
        if (this.settings.length == 0) {
            this.$store.dispatch('settingsModule/fetchSettings')
        }
    }

    onAnnotationSettingChanged(){

    }

    saveSettings() {

        this.activeScreen = 'loading'
        this.$eventBus.$emit('activeScreen', this.activeScreen)
        this.loadingMessage = 'Saving all settings'
        this.loadingTitle = 'Please wait...'
        this.$store.commit('settingsModule/setSettingsRig', this.settingsRig)
        this.$store.commit('settingsModule/setSettingsWell', this.settingsWell)
        this.$store.commit('settingsModule/setTimeSettings', this.settingsTime)

        setTimeout(() => {
            this.activeScreen = 'successNoBack'
            this.$eventBus.$emit('activeScreen', this.activeScreen)
            this.additionalSuccessMessage = 'Settings saved successfully'
            this.successMessage = 'Chart Settings Saved'
        }, 2000)

    }


}












































































































































































import Vue from 'vue'
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class WellsImport extends Vue {
 @Prop({default:false,required:true}) showModal!:boolean;

    wellName = ""
    wellId=""
    oml=""
    wellType=""
    targetDepth=""
    surfaceX=""
    surfaceY=""
    completionType=""
    completionSize1=""
    completionSize2=""
    wellConfiguration=""
    wellHeadPreassure=""
    spudDate=""
    tDDate=""
    dateOfCompletion=""
    field=""
    terrain=""
    normDepth=""
    trueDepth=""
    sheetName = null
    sheetNames:Array<string> =[]
    fileName:string=""
    columnNames:Array<string> =[]
    wellFile = null as any
    fileUploaded=false
    sheetSelected=false
    loading=false
    okBtnTitle="Upload"
    errors:Array<string> =[]

    get validated(){
        if(this.okBtnTitle == "Import Wells" && (this.wellName=="" || this.wellId=="" || this.wellConfiguration=="" || this.wellType =="")){
           return true;
        }
        else if(this.okBtnTitle == "OK" && this.sheetName =="" ){
           return true;
        }
       return false;
    }

    get modalSize(){
        if(this.sheetSelected){
            return "xl"
        }
        return "md"
    }

    importWells() {
        this.loading = true
        this.okBtnTitle="Importing Wells..."
        let model = {
        filePath: this.fileName,
        sheetName: this.sheetName,
        columnNames:{
        name: this.wellName,
        wellId: this.wellId,
        oml:this.oml,
        wellType:this.wellType,
        targetDepth:this.targetDepth,
        surfaceX:this.surfaceX,
        surfaceY:this.surfaceY,
        completionType:this.completionType,
        completionSize1:this.completionSize1,
        completionSize2:this.completionSize2,
        wellConfiguration:this.wellConfiguration,
        wellHeadPreassure:this.wellHeadPreassure,
        spudDate:this.spudDate,
        tDDate:this.tDDate,
        dateOfCompletion:this.dateOfCompletion,
        field:this.field,
        terrain:this.terrain,
        normDepth:this.normDepth,
       trueDepth:this.trueDepth
        }
     }
      this.$store.dispatch('wellsModule/createImportedWells', model).then((res)=>{
          this.$bvModal.hide("well-import-modal")
          if(res.data.data.errors.length>0){
            this.errors = res.data.data.errors
            this.$bvModal.show("error-list-modal")
          }

      }).catch(()=>{
          this.loading = false
          this.okBtnTitle="Import Wells"
      })
    }
    

    getColumnNames(){
        this.okBtnTitle = "Loading..."
        this.loading = true
        this.$store.dispatch('wellsModule/getColumnNames', {fileName:this.fileName,sheetName:this.sheetName}).then((res)=>{
          this.columnNames = res.data.data
          this.sheetSelected = true
          this.okBtnTitle = "Import Wells"
          this.loading=false
          }).catch(()=>{
             this.loading = false
             this.okBtnTitle = "OK"
         })

    }

    uploadFile(){
        this.loading = true
        this.okBtnTitle = "Uploading..."
         let form = new FormData()
         form.append("file",this.wellFile)
        this.$store.dispatch('wellsModule/uploadFile', form).then((response=>{
            this.sheetNames = response.data.data.sheetNames
            this.fileName = response.data.data.fileName
             this.fileUploaded = true
            this.loading =false
             this.okBtnTitle = "OK"
         })).catch(()=>{
             this.loading = false
             this.okBtnTitle = "Upload"
         })
    }

    btnClicked(bvModalEvt:any){
        bvModalEvt.preventDefault()
        if(this.okBtnTitle == "Upload"){
           this.uploadFile()
        }
        else if(this.okBtnTitle == "OK"){
            this.getColumnNames()
        }
        else if(this.okBtnTitle == "Import Wells"){
            this.importWells()
        }
    }
    closeModal(){
      this.$emit("well-import-modal-closed")
    }

    @Watch('showModal', {immediate: true}) onShowModalChanged(value: boolean, oldValue: boolean) {
       if(this.showModal){
            this.fileUploaded = false
            this.sheetSelected =false
            this.loading =false
            this.okBtnTitle ="Upload"
            this.$bvModal.show("well-import-modal")
       }
    }
    
}
